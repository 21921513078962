import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Column, Table, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { OverlayTrigger, Popover, ListGroup } from 'react-bootstrap';
import { sortBy, trim, differenceBy, map } from 'lodash';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useLocalization } from 'features/localization/localizationSlice';
import { Can, services, useIQCameraUser } from 'features/permissions';
import { useCurrentCompany } from 'features/company/companySlice';
import { VehicleFootageRequestMenuButtons } from 'features/requestFootage/RequestFootageButton';
import { RequestFootageModal } from 'features/requestFootage/RequestFootageModal';

import { MessageComposeModal } from 'containers/Messaging/MessageComposeModal';
import { RouteToModal, useRouteTo } from 'containers/Tracking/RouteTo';
import { RECIPIENT_TYPE } from 'containers/Messaging/constants';
import { DriverName } from 'containers/Tracking/Common/DiverName';
import { PATH_SPLIT, selectAll } from 'components/form/treeselect/TreeSelect';
import { LoadingSpinner } from 'components/tn/grid/LoadingSpinner';
import { DashboardGridHeader } from './DashboardGridHeader';
import { Tooltip } from 'components/ant';
import { getFleetTooltipForGrid, getFleetDisplayValueForGrid } from 'utils/methods';
import { format } from 'utils/dates';

import styles from './DashboardGrid.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

const cache = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 52
});

export const Columns = {
  VehicleName: {
    name: 'Vehicle Name',
    key: 'vehicleName',
    sortable: true,
    translated: 'Home.VehiclesDevicesColumn',
    filterKey: 'VEHICLE'
  },
  Driver: {
    name: 'Driver',
    key: 'driver',
    filterKey: 'DRIVER',
    sortable: true,
    translated: 'Home.DriverColumn'
  },
  Fleet: {
    name: 'Fleet',
    key: 'fleets',
    sortable: true,
    translated: 'Home.FleetColumn'
  },
  CommsStatus: {
    name: 'COMMS STATUS',
    key: 'commsStatus',
    translated: 'Home.CommStatusColumn',
    filterKey: 'COMMS STATUS',
    sortable: true
  },
  MassD: {
    name: 'Mass D',
    key: 'massD',
    sortable: true,
    translated: 'Home.MassDColumn',
    filterKey: 'MASS D'
  },

  MassA: {
    name: 'Mass A',
    key: 'massA',
    sortable: true,
    translated: 'Home.MassAColumn',
    filterKey: 'MASS A'
  },
  LastLocation: {
    name: 'Last Location',
    key: 'lastLocation',
    translated: 'Home.LastLocationColumn',
    filterKey: 'LAST LOCATION',
    sortable: true
  },
  LastKnownSpeed: {
    name: 'Last KNOWN SPEED',
    key: 'speed',
    translated: 'Home.SpeedColumn',
    sortable: true
  }
};

export const SeperatedVehiclesDevicesColumns = {
  VehicleName: {
    name: 'Vehicle Name',
    key: 'vehicleName',
    sortable: true,
    translated: 'Home.Vehicles',
    filterKey: 'VEHICLE'
  },
  DeviceName: {
    name: 'Device Name',
    key: 'deviceName',
    sortable: true,
    translated: 'Home.Devices',
    filterKey: 'VEHICLE'
  }
};

const filterableColumns = Object.keys(Columns)
  .filter(c => Columns[c].filterKey)
  .map(c => Columns[c]);

const ElipsisButton = ({ data, onViewClick, onMessagingVehicle, onRouteTo, onRequestVideo }) => {
  const { t } = useTranslation();
  const company = useCurrentCompany();
  const [isSmartNav3D, setIsSmartNav3D] = useState(false);

  useEffect(() => {
    setIsSmartNav3D(company.features?.some(feature => feature.code === services.SMARTNAV3D));
  }, [company]);

  const handleViewClick = useCallback(() => {
    if (onViewClick) {
      onViewClick(data);
    }
  }, [data, onViewClick]);

  const handleMessage = useCallback(() => {
    if (onMessagingVehicle) {
      onMessagingVehicle(data);
    }
  }, [data, onMessagingVehicle]);

  const handleRouteTo = useCallback(() => {
    if (onRouteTo) {
      onRouteTo(data);
    }
  }, [data, onRouteTo]);

  const handleRequestFootage = useCallback(
    (vehicleId, deviceId) => {
      if (onRequestVideo) {
        onRequestVideo(vehicleId, deviceId);
      }
    },
    [onRequestVideo]
  );

  const ContextMenu = useMemo(
    () => (
      <Popover className={styles.contextMenu} id={`${data.id}_grid-popover`}>
        <Popover.Content className={styles.contextMenuContent}>
          <ListGroup variant="flush">
            <ListGroup.Item action id="btn_dashboardGridView" onClick={handleViewClick}>
              {data.type === 0 ? t('Home.View Device') : t('Home.View Vehicle')}
            </ListGroup.Item>
            <Can
              oneOfServices={[services.MESSAGING]}
              otherConditions={[() => data.messagingDevice]}
            >
              <ListGroup.Item action id="btn_dashboardGridMessage" onClick={handleMessage}>
                {t('Home.Message Vehicle')}
              </ListGroup.Item>
              {isSmartNav3D && data.routeToDevice && (
                <ListGroup.Item action id="btn_dashboardGridRouteTo" onClick={handleRouteTo}>
                  {t('Tracking.RouteTo')}
                </ListGroup.Item>
              )}
            </Can>
            <VehicleFootageRequestMenuButtons
              vehicleId={data.vehicleId}
              onMenuClicked={handleRequestFootage}
            />
          </ListGroup>
        </Popover.Content>
      </Popover>
    ),
    [data, isSmartNav3D, handleViewClick, handleMessage, handleRouteTo, handleRequestFootage, t]
  );

  return (
    <OverlayTrigger trigger="click" placement="left" rootClose={true} overlay={ContextMenu}>
      <button
        style={{ border: 'none' }}
        className={'tn-btn-elipsis'}
        id={BUTTON_IDS.dashboardGridEllipsis}
      >
        <i style={{ fontSize: '26px' }} className={'tn-i-elipsis'} />
      </button>
    </OverlayTrigger>
  );
};

export const DashboardGrid = ({ rows, isLoading, ...props }) => {
  const localization = useLocalization();
  const { t } = useTranslation();
  const { canAccessNonCameraFeatures } = useIQCameraUser();
  const currentCompany = useCurrentCompany();
  const areMassColumnsEnabled = currentCompany.country === 'AU';

  const fleetsCellRender = ({ dataKey, parent, rowIndex }) => {
    const fleetTooltip = getFleetTooltipForGrid(rows[rowIndex][dataKey], t);
    const fleetDisplay = getFleetDisplayValueForGrid(rows[rowIndex][dataKey], t);

    return (
      <CellMeasurer cache={cache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        <div
          title={fleetTooltip}
          style={{
            padding: '0 15px 0px 0',
            whiteSpace: 'pre-line',
            height: 'inherit'
          }}
        >
          {fleetDisplay}
        </div>
      </CellMeasurer>
    );
  };

  const columnCellRenderer = ({ dataKey, parent, rowIndex }) => {
    return (
      <CellMeasurer cache={cache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        <div
          style={{
            padding: '12px 15px 12px 0',
            whiteSpace: 'normal',
            height: 'inherit'
          }}
        >
          {rows[rowIndex].lastLocation}
        </div>
      </CellMeasurer>
    );
  };

  const driverCellRenderer = ({ dataKey, parent, rowIndex, rowData }) => {
    return (
      <CellMeasurer cache={cache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        <DriverName
          device={{
            driverName: rowData.driver,
            driver: { isVehicleDefaultDriver: rowData.isVehicleDefaultDriver }
          }}
        />
      </CellMeasurer>
    );
  };

  const speedCellRenderer = ({ dataKey, parent, rowIndex }) => {
    return (
      <CellMeasurer cache={cache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        <div
          style={{
            whiteSpace: 'normal',
            height: 'inherit'
          }}
        >
          {rows[rowIndex][dataKey] && rows[rowIndex][dataKey] !== ''
            ? localization.convertSpeed(rows[rowIndex][dataKey])
            : t('Unknown')}
        </div>
      </CellMeasurer>
    );
  };
  const massDCellRenderer = ({ dataKey, parent, rowIndex }) => {
    const sdmValue = rows[rowIndex][dataKey];
    const timeAt =
      rows[rowIndex]['massDAt'] &&
      format(new Date(rows[rowIndex]['massDAt']), localization.formats.time.formats.dby_imp);

    return (
      <CellMeasurer cache={cache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        <div>
          {sdmValue && <Tooltip content={sdmValue} title={`${t('Home.MassDTimeAt')} ${timeAt}`} />}
        </div>
      </CellMeasurer>
    );
  };

  const massACellRenderer = ({ dataKey, parent, rowIndex }) => {
    const obmValue = rows[rowIndex][dataKey];
    const timeAt =
      rows[rowIndex]['massAAt'] &&
      format(
        new Date(rows[rowIndex]['massAAt'] || null),
        localization.formats.time.formats.dby_imp
      );

    return (
      <CellMeasurer cache={cache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        <div>
          {obmValue && <Tooltip content={obmValue} title={`${t('Home.MassATimeAt')} ${timeAt}`} />}
        </div>
      </CellMeasurer>
    );
  };

  const elipsisButtonCellRenderer = ({ rowIndex }) => {
    return (
      <ElipsisButton
        data={rows[rowIndex]}
        onViewClick={props.onViewClick}
        onMessagingVehicle={props.onMessagingVehicle}
        onRouteTo={props.onRouteTo}
        onRequestVideo={props.onRequestVideo}
      />
    );
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Table
          deferredMeasurementCache={cache}
          width={width}
          height={height}
          headerHeight={52}
          rowClassName={styles.tableRow}
          rowHeight={cache.rowHeight}
          rowCount={isLoading ? 0 : rows.length}
          onRowMouseOver={props.onRowMouseOver}
          onRowMouseOut={props.onRowMouseOut}
          onRowClick={props.onRowClick}
          rowGetter={({ index }) => rows[index]}
          noRowsRenderer={() => <LoadingSpinner isLoading={isLoading} />}
        >
          <Column
            style={{ padding: '12px 15px 12px 0' }}
            label={t('Home.VehiclesDevicesColumn')}
            dataKey="vehicleName"
            width={width * 0.116}
          />
          <Column
            style={{ padding: '12px 15px 12px 0' }}
            label={t('Home.DriverColumn')}
            dataKey="driver"
            width={width * 0.116}
            cellRenderer={driverCellRenderer}
          />
          <Column
            style={{ padding: '12px 15px 12px 0' }}
            label={t('Home.FleetColumn')}
            dataKey="fleets"
            cellRenderer={fleetsCellRender}
            width={width * 0.116}
          />
          <Column
            style={{ padding: '12px 15px 12px 0' }}
            label={t('Home.CommStatusColumn')}
            dataKey="commsStatus"
            width={width * 0.116}
          />
          {areMassColumnsEnabled && (
            <Column
              style={{ padding: '12px 15px 12px 0' }}
              label={
                <Tooltip
                  content={t('Home.MassDColumnTooltip')}
                  target={
                    <>
                      {t('Home.MassDColumn')}
                      <i className={`tn-i-info ${styles.massColumnInfo}`} />
                    </>
                  }
                ></Tooltip>
              }
              dataKey="massD"
              width={width * 0.116}
              cellRenderer={massDCellRenderer}
            />
          )}
          {areMassColumnsEnabled && (
            <Column
              label={
                <Tooltip
                  content={t('Home.MassAColumnTooltip')}
                  target={
                    <>
                      {t('Home.MassAColumn')}
                      <i className={`tn-i-info ${styles.massColumnInfo}`} />
                    </>
                  }
                ></Tooltip>
              }
              dataKey="massA"
              width={width * 0.116}
              cellRenderer={massACellRenderer}
            />
          )}
          <Column
            label={t('Home.LastLocationColumn')}
            dataKey="lastLocation"
            width={width * 0.3}
            cellRenderer={columnCellRenderer}
          />
          <Column
            style={{ padding: '12px 15px 12px 0' }}
            label={`${t('Home.SpeedColumn')} (${localization.formats.speed.unit_per_hour})`}
            dataKey="speed"
            width={width * 0.116}
            cellRenderer={speedCellRenderer}
          />
          {canAccessNonCameraFeatures && (
            <Column
              label=""
              dataKey=""
              style={{ padding: '12px 15px 12px 0', textAlign: 'right' }}
              width={width * 0.116}
              cellRenderer={elipsisButtonCellRenderer}
            />
          )}
        </Table>
      )}
    </AutoSizer>
  );
};

export const DashboardTable = ({
  filterFleets,
  rows,
  onFilterFleets,
  onSearchChanged,
  onFilterChanged,
  isLoading,
  onRowMouseOver,
  onRowMouseOut,
  onRowClicked,
  areMassColumnsEnabled
}) => {
  const [filterTree, setFilterTree] = useState({});
  const [search, setSearch] = useState('');
  const [sortKey, setSortKey] = useState(null);
  const [filterSets, setFilterSets] = useState({});
  const history = useHistory();
  const [showPopup, setShowpopup] = useState(false);
  const [recipient, setRecipient] = useState(null);
  const [showFootageModal, setShowFootageModal] = useState(false);
  const [showRouteToModal, setShowRouteToModal] = useState(false);
  const [routeData, setRouteData] = useState(null);
  const [requestVideoDeviceId, setRequestVideoDeviceId] = useState(null);
  const [requestVideoVehicleId, setRequestVideoVehicleId] = useState(null);
  const { t } = useTranslation();
  const routeToProps = useRouteTo(routeData);

  let timeOutHandle = 0;

  const tableRows = useMemo(() => {
    let newRows = [];
    newRows = rows.filter(dt => {
      let isSelect = true;

      //do filter by search keys
      if (search && search.trim()) {
        for (const propName of Object.getOwnPropertyNames(dt)) {
          if (
            dt[propName] &&
            dt[propName]
              .toString()
              .toLowerCase()
              .includes(search.trim().toLowerCase())
          ) {
            isSelect = true;
            break;
          } else {
            isSelect = false;
          }
        }
      } else {
        isSelect = true;
      }

      if (!isSelect) return isSelect;

      //do filter by filter sets
      if (Object.getOwnPropertyNames(filterSets).length < 0) return isSelect;

      for (let i = 0; i < filterableColumns.length; i++) {
        const key = filterableColumns[i].filterKey;
        const value = trim(dt[filterableColumns[i].key]);
        const checkedFilterSets = filterSets[key];

        for (const label in checkedFilterSets) {
          if (label !== value) {
            isSelect = false;
          } else {
            isSelect = true;
            break;
          }
        }

        if (!isSelect) {
          break;
        }
      }

      return isSelect;
    });

    if (sortKey) {
      newRows = sortBy(newRows, [
        o =>
          typeof o[sortKey] === 'string'
            ? ['massA', 'massD'].includes(sortKey)
              ? 0
              : o[sortKey].toLowerCase()
            : o[sortKey]
      ]);
    }

    return newRows;
  }, [sortKey, filterSets, search, rows]);

  useEffect(() => {
    const newFilterTree = formatListForTreeSelect(rows, areMassColumnsEnabled);
    setFilterTree(newFilterTree);
    setFilterSets({});
  }, [rows]);

  function onSearch(val) {
    if (timeOutHandle !== 0) {
      clearTimeout(timeOutHandle);
    }

    timeOutHandle = setTimeout(() => {
      setSearch(val);
      if (onSearchChanged) {
        onSearchChanged(val);
      }
    }, 300);
  }

  function onFilterTreeChanged(filterTree) {
    const updatedFilterSets = {};

    const FilterColumn = {
      ...Columns,
      ...SeperatedVehiclesDevicesColumns
    };

    const filterableColumns = Object.keys(FilterColumn)
      .filter(c => FilterColumn[c].filterKey)
      .map(c => FilterColumn[c]);

    for (let i = 0; i < filterableColumns.length; i++) {
      const key = filterableColumns[i].filterKey;
      const parentNode = filterTree[i + 1];

      for (const nodeId in parentNode.children) {
        const node = parentNode.children[nodeId];
        if (node.id === 0) continue; //skip select all node

        if (node.checked) {
          if (!updatedFilterSets[key]) {
            updatedFilterSets[key] = {};
          }
          if (!updatedFilterSets[key].hasOwnProperty(node.label)) {
            updatedFilterSets[key][node.label] = true;
          }
        }
      }
    }

    setFilterSets(updatedFilterSets);
    if (onFilterChanged) {
      onFilterChanged(updatedFilterSets);
    }
    setFilterTree(filterTree);
  }

  function formatListForTreeSelect(list, areMassColumnsEnabled) {
    let updatedList = {};
    let valueGroups = {};

    const FilterColumn = {
      VehicleName: SeperatedVehiclesDevicesColumns.VehicleName,
      Driver: Columns.Driver,
      Fleet: Columns.Fleet,
      CommsStatus: Columns.CommsStatus,
      ...(areMassColumnsEnabled && { MasD: Columns.MassD }),
      ...(areMassColumnsEnabled && { MasA: Columns.MassA }),
      LastLocation: Columns.LastLocation,
      DeviceName: SeperatedVehiclesDevicesColumns.DeviceName,
      LastKnownSpeed: Columns.LastKnownSpeed
    };

    const filterableColumns = Object.keys(FilterColumn)
      .filter(c => FilterColumn[c].filterKey)
      .map(c => FilterColumn[c]);

    filterableColumns.forEach((c, idx) => {
      let node = {
        label: t(c.translated),
        key: c.key,
        function: undefined,
        nodeKey: (idx + 1).toString(),
        id: idx + 1,
        children: [
          {
            ...selectAll,
            label: t('Common.' + selectAll.name),
            nodeKey: idx + 1 + PATH_SPLIT + 0
          }
        ]
      };

      updatedList[node.id] = node;
      valueGroups[node.id] = {};

      const sortedList = sortBy(list, [o => o[c.key]]);

      for (let i = 0, l = sortedList.length; i < l; i++) {
        const item = sortedList[i];
        const parentNode = updatedList[idx + 1];

        const isDevice = item.type === 0;

        const value = trim(item[c.key]);

        if (!valueGroups[parentNode.id][value]) {
          const nodeId = parentNode.children.length;
          const childNode = {
            id: nodeId,
            label: trim(item[c.key]),
            function: undefined,
            nodeKey: parentNode.nodeKey + PATH_SPLIT + nodeId
          };

          !isDevice &&
            !(['massD', 'massA'].includes(parentNode.key) && childNode.label === '') &&
            parentNode.children.push(childNode);
          valueGroups[parentNode.id][value] = !isDevice && true;
        }
      }
    });

    const vehicles = Object.values(updatedList).find(obj => obj.key === 'vehicleName');
    const devices = Object.values(updatedList).find(obj => obj.key === 'deviceName');

    const allVehiclesDevicesName = list.map(l => l.vehicleName);

    const vehicleChildren = vehicles.children;

    const devicesLabel = differenceBy(allVehiclesDevicesName, map(vehicleChildren, 'label'));

    if (devicesLabel.length) {
      devices.children.pop();
    }

    devicesLabel.forEach(label => {
      const nodeId = devices.children.length;
      const childNode = {
        id: nodeId,
        label: trim(label),
        function: undefined,
        nodeKey: devices.nodeKey + PATH_SPLIT + nodeId
      };

      return devices.children.push(childNode);
    });

    devices.key = 'vehicleName';

    return updatedList;
  }

  const handleViewClick = useCallback(
    rowData => {
      if (rowData.type === 0) {
        history.push(`/settings/devices/id/${rowData.deviceId}`);
      } else {
        history.push(`/settings/vehicles/id/${rowData.vehicleId}`);
      }
    },
    [history]
  );

  const handleMessagingVehicle = useCallback(rowData => {
    const recipient = {
      recipientType:
        rowData.vehicleId !== 'NoVehicle' ? RECIPIENT_TYPE.VEHICLE : RECIPIENT_TYPE.DEVICE,
      recipientId: rowData.vehicleId !== 'NoVehicle' ? rowData.vehicleId : rowData.deviceId
    };
    setRecipient(recipient);
    setShowpopup(true);
  }, []);

  const handleRouteTo = useCallback(rowData => {
    setShowRouteToModal(true);
    setRouteData(rowData);
  }, []);

  const cancelRouteTo = () => {
    setShowRouteToModal(false);
    setRouteData(null);
  };

  const handleRequestVideo = useCallback((vehicleId, deviceId) => {
    setRequestVideoDeviceId(deviceId);
    setRequestVideoVehicleId(vehicleId);
    setShowFootageModal(true);
  }, []);

  return (
    <>
      <DashboardGridHeader
        onSearch={onSearch}
        filterFleets={filterFleets}
        onFilterFleets={onFilterFleets}
        filterTree={filterTree}
        onFilterTree={onFilterTreeChanged}
        sortBy={sortKey}
        sortOptions={Object.keys(Columns)
          .filter(c => {
            if (['massD', 'massA'].includes(Columns[c].key)) {
              return areMassColumnsEnabled && Columns[c].sortable;
            }
            return Columns[c].sortable;
          })
          .map(c => ({
            ...Columns[c],
            name: t(Columns[c].translated)
          }))}
        onSortChange={({ key: sortKey }) => setSortKey(sortKey)}
        rowCount={tableRows.length}
      />
      <div style={{ display: 'flex', flex: '1 0 0' }}>
        <DashboardGrid
          rows={tableRows}
          onRowClick={onRowClicked}
          onRowMouseOver={onRowMouseOver}
          onRowMouseOut={onRowMouseOut}
          onViewClick={handleViewClick}
          onMessagingVehicle={handleMessagingVehicle}
          onRouteTo={handleRouteTo}
          onRequestVideo={handleRequestVideo}
          isLoading={isLoading}
        ></DashboardGrid>
        {showPopup && (
          <MessageComposeModal
            visible={showPopup}
            showModal={setShowpopup}
            recipients={[recipient]}
          />
        )}
        {showFootageModal && (
          <RequestFootageModal
            showModal={showFootageModal}
            vehicleId={requestVideoVehicleId}
            deviceId={requestVideoDeviceId}
            onClose={() => {
              setShowFootageModal(false);
            }}
          />
        )}
        <RouteToModal
          title={t('Tracking.RouteTo')}
          isOpen={showRouteToModal}
          data={routeData}
          handleCancel={cancelRouteTo}
          {...routeToProps}
        />
      </div>
    </>
  );
};
