import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert } from 'antd';
import moment from 'moment';
import { useCurrentRegion } from 'features/regions/regionsSlice';
import { CameraModelConfig } from 'features/camera/CameraModelConfig';
import { useTimer } from 'utils/hooks/useTimer';
import { useCurrentCompanyId } from 'features/company/companySlice';
import { fetchCompanyDVRUsage } from 'features/camera/cameraApi';

const DVR_USAGE = {
  CHECKING_INTERVAL: 10 * 60 * 1000,
  REST_TIME: 30 * 60 * 1000
};

export const CompanyIQDVRAlert = ({
  checkInterval = DVR_USAGE.CHECKING_INTERVAL,
  checkRestTime = DVR_USAGE.REST_TIME
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompanyId = useCurrentCompanyId();
  const currentRegion = useCurrentRegion();
  const dvrCtxRef = useRef({
    request: null
  });
  const [dvrUsage, setDVRUsage] = useState(null);
  const [alertDismissed, setAlertDismissed] = useState(false);
  const isDVRExhausted = useCallback(usage => {
    if (usage) {
      const count = isNaN(Number(usage.count)) ? 0 : Number(usage.count),
        maxThreshold = isNaN(Number(usage.maxThreshold)) ? 0 : Number(usage.maxThreshold);
      return usage && maxThreshold !== 0 && count >= maxThreshold;
    }
    return false;
  }, []);

  const fetchDVRUsage = useCallback(() => {
    const companyCtx = dvrCtxRef.current[currentCompanyId];
    if (alertDismissed || isDVRExhausted(dvrUsage)) {
      return;
    }
    if (
      !companyCtx ||
      (!companyCtx.isFetching &&
        (!(!!dvrUsage || !!companyCtx.error) ||
          moment().valueOf() - companyCtx.fetchTime >= checkRestTime))
    ) {
      if (dvrCtxRef.request) {
        dvrCtxRef.current.request.abort();
        dvrCtxRef.current.request = null;
      }
      dvrCtxRef.current.request = dispatch(
        fetchCompanyDVRUsage({
          companyId: currentCompanyId,
          onSuccess: dvrUsage => {
            dvrCtxRef.current.request = null;
            dvrCtxRef.current[currentCompanyId] = {
              isFetching: false,
              fetchTime: moment().valueOf(),
              error: null
            };
            setDVRUsage(dvrUsage);
          },
          onError: errMsg => {
            dvrCtxRef.current.request = null;
            dvrCtxRef.current[currentCompanyId] = {
              isFetching: false,
              fetchTime: moment().valueOf(),
              error: errMsg
            };
            setDVRUsage(null);
          }
        })
      );
      const fetchData = async () => {
        try {
          dvrCtxRef.current[currentCompanyId] = {
            isFetching: true
          };
          await dvrCtxRef.current.request;
        } catch (e) {
          dvrCtxRef.current[currentCompanyId] = {
            isFetching: false,
            fetchTime: moment().valueOf(),
            error: e
          };
        }
      };
      fetchData();
    }
  }, [
    dispatch,
    dvrCtxRef,
    setDVRUsage,
    dvrUsage,
    currentCompanyId,
    alertDismissed,
    isDVRExhausted
  ]);

  useEffect(() => {
    fetchDVRUsage();
  }, [fetchDVRUsage]);

  useTimer(checkInterval, fetchDVRUsage);

  return isDVRExhausted(dvrUsage) && !alertDismissed ? (
    <div style={{ margin: 9, fontSize: 12 }}>
      <Alert
        message={t('Home.VideoRequestExhausted', {
          date: t(
            `Devices.View.DataPlan.MonthlyRenew.Next.${CameraModelConfig.IQCamera.getDataPlanMonthlyRenewOn(
              currentRegion?.code
            )}`
          )
        })}
        type="warning"
        closable
        onClose={() => setAlertDismissed(true)}
      />
    </div>
  ) : null;
};
