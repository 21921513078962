import { LoadingTable } from 'components/grid/LoadingTable';
import { useCompanies } from 'features/company/companySlice';
import { useFleets } from 'features/fleets/fleetsSlice';
import React, { useState } from 'react';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { Link } from 'react-router-dom';
import {
  cache,
  detailsCellRenderer,
  deviceNameCellRenderer,
  ellipsisButtonCellRenderer,
  typeCellRenderer,
  gpioCellRenderer,
  vehicleCellRenderer,
  modelCellRenderer,
  headerRenderer
} from './CellRenderers';
import { Empty } from 'components/ant';
import { useTranslation } from 'react-i18next';
import {
  useCan,
  entities,
  useIQCameraUser,
  GlobalRoles,
  Can,
  useCanFeatureFlag,
  FeatureFlag
} from 'features/permissions';
import services from 'features/permissions/services';
import { deviceTypeModelTranslate } from './constants';
import { useUser } from 'features/user/userSlice';
import styles from './Devices.module.scss';

export const DevicesTable = ({
  devices,
  isLoading,
  handleDeleteAction,
  typeOfEntityToDelete,
  setTableRef,
  handleAction,
  filterTab,
  getDeviceModelName = modelName => modelName
}) => {
  const can = useCan();
  const canViewDevice = can && can({ everyEntity: [entities.DEVICE_VIEW] });
  const canViewVehicle = can && can({ everyEntity: [entities.VEHICLE_VIEW] });
  const companies = useCompanies();
  const fleetsList = useFleets();
  const { t } = useTranslation();
  const { canAccessNonCameraFeatures } = useIQCameraUser();
  const canControlGpioConfiguration = can({
    oneOfRoles: [GlobalRoles.Reseller, GlobalRoles.SiteAdmin],
    oneOfCompanyServices: [services.GPIO]
  });

  const currentUser = useUser();
  const isSiteAdmin = currentUser.siteAdmin;

  const canFleetLevelGpio = useCanFeatureFlag({
    featureFlag: FeatureFlag.fleetLevelGpio.flag
  });

  const [columnWidths, setColumnWidths] = useState(
    canFleetLevelGpio
      ? {
          name: 300,
          details: 275,
          type: 150,
          model: 145,
          vehicle: 150,
          imei: 150,
          ellipsisButton: 60
        }
      : {
          name: 200,
          details: 225,
          type: 150,
          model: 145,
          vehicle: 150,
          imei: 150,
          GpioTemplate: 150,
          ellipsisButton: 60
        }
  );

  const resizeRow = ({ dataKey, deltaX }) => {
    setColumnWidths(prevState => ({
      ...prevState,
      [dataKey]: Math.max(prevState[dataKey] + deltaX, 10)
    }));
  };

  if (isLoading) return <LoadingTable columnSizes={[70, 42, 105, 47, 58, 43, 29]} />;
  return (
    <AutoSizer>
      {({ height, width }) => {
        const isDeleteTab = filterTab === 'deleted';

        return (
          <Table
            deferredMeasurementCache={cache}
            width={width}
            height={height}
            headerHeight={52}
            headerStyle={{ paddingTop: '18px' }}
            rowClassName="tableRow"
            rowHeight={cache.rowHeight}
            rowCount={devices.length}
            rowGetter={({ index }) => devices[index]}
            rowStyle={{ alignItems: 'flex-start' }}
            ref={ref => setTableRef(ref)}
            noRowsRenderer={() => <Empty />}
          >
            <Column
              label={t('Devices.Table.NameLabel')}
              dataKey="name"
              headerRenderer={props => headerRenderer({ ...props, resizeRow })}
              width={columnWidths.name}
              cellRenderer={props => deviceNameCellRenderer({ ...props, canViewDevice })}
            />
            <Column
              label={t('Devices.Table.DetailsLabel')}
              dataKey="details"
              headerRenderer={props => headerRenderer({ ...props, resizeRow })}
              width={columnWidths.details}
              cellRenderer={props =>
                detailsCellRenderer({
                  ...props,
                  companies: companies,
                  fleetsAPIList: fleetsList
                })
              }
            />
            <Column
              label={t('Devices.Table.TypeLabel')}
              dataKey="type"
              headerRenderer={props => headerRenderer({ ...props, resizeRow })}
              width={columnWidths.type}
              cellRenderer={({ rowData }) =>
                typeCellRenderer({
                  typeName: rowData?.type?.name,
                  modelName: getDeviceModelName(rowData?.model?.name)
                })
              }
            />
            <Column
              label={t('Devices.ActualForm.ModelLabel')}
              dataKey="model"
              //className={styles.ellipsisText}
              headerRenderer={props => headerRenderer({ ...props, resizeRow })}
              width={columnWidths.model}
              cellRenderer={({ cellData }) =>
                modelCellRenderer({
                  modelName: deviceTypeModelTranslate(getDeviceModelName(cellData?.name))
                })
              }
            />
            <Column
              label={t('Devices.Table.VehicleLabel')}
              dataKey="vehicle"
              headerRenderer={props => headerRenderer({ ...props, resizeRow })}
              width={columnWidths.vehicle}
              cellRenderer={props => vehicleCellRenderer({ ...props, canViewVehicle })}
            />
            <Column
              label={t('Devices.Table.ImeiLabel')}
              dataKey="imei"
              headerRenderer={props => headerRenderer({ ...props, resizeRow })}
              width={columnWidths.imei}
            />

            {!canFleetLevelGpio && canControlGpioConfiguration && !isDeleteTab && (
              <Column
                label={t('Devices.Table.GpioTemplate')}
                dataKey="GpioTemplate"
                headerRenderer={props => headerRenderer({ ...props, resizeRow })}
                width={columnWidths.GpioTemplate}
                cellRenderer={props => gpioCellRenderer({ ...props, canViewDevice })}
              />
            )}
            {canAccessNonCameraFeatures && (
              <Column
                label={t('Devices.Table.ActionsLabel')}
                dataKey="actions"
                className={styles.actionsColumn}
                headerRenderer={props => headerRenderer({ ...props, resizeRow })}
                style={{ overflow: 'visible' }}
                width={columnWidths.ellipsisButton}
                cellRenderer={props => {
                  const customAction = (
                    <Can
                      everyEntity={[entities[`DEVICE_UPDATE`]]}
                      oneOfRoles={[GlobalRoles.SubCompanyAdmin]}
                    >
                      <Link
                        to={{
                          pathname: `/settings/devices/agreement`,
                          state: { devices: [{ id: props.rowData?.id }] }
                        }}
                      >
                        <li>{t('Agreement.AssignAgreement')}</li>
                      </Link>
                    </Can>
                  );

                  const customDeleteContent =
                    props.rowData?.type.code === 'EDR' ? (
                      <div>
                        {t('Vehicles.Form.UpdateEDRWarning', {
                          action: t('Vehicles.Form.Delete'),
                          type: t('Common.Device').toLowerCase()
                        })}
                        <br />
                        {t('Vehicles.Form.UpdateEDRWarning2')}
                      </div>
                    ) : (
                      undefined
                    );
                  return ellipsisButtonCellRenderer({
                    ...props,
                    handleDeleteAction,
                    typeOfEntityToDelete,
                    hideConfig: isDeleteTab,
                    hideEdit: isDeleteTab,
                    hideDelete: isDeleteTab || !isSiteAdmin,
                    customDeleteContent,
                    enableRestoreAction: isDeleteTab,
                    customAction: customAction,
                    handleAction
                  });
                }}
              />
            )}
          </Table>
        );
      }}
    </AutoSizer>
  );
};
