import { createAsyncThunk } from '@reduxjs/toolkit';
import { Entities } from 'containers/Scorecard';
import { api } from 'utils/api';
import moment from 'moment';
import {
  FETCH_BUCKET,
  UPDATE_BUCKET,
  FETCH_BUCKET_DEFAULTS,
  FETCH_SUMMARY,
  FETCH_CONFIG,
  UPDATE_CONFIG,
  FETCH_SCORE_EVENTS,
  INCLUDE_EXCLUDE_EVENTS,
  ENABLED_EVENT_TYPES,
  ENABLED_EVENT_TYPES_BRANCH,
  ENABLED_EVENT_TYPES_FLEET
} from '../actions';

export const fetchSummary = createAsyncThunk(
  FETCH_SUMMARY,
  async (
    { startAt, endAt, companyId, entityName = 'users', entityId, groups = null },
    { getState, dispatch }
  ) => {
    const state = getState();
    const authKey = state?.user?.current?.auth?.key;
    const entity = entityName === 'drivers' ? 'users' : entityName;

    let response;
    if (entityId) {
      response = await api.get(`/scorecard/${entity}/summary/${entityId}`, {
        authKey,
        query: {
          companyId,
          startAt,
          endAt
        }
      });
    } else {
      response = await api.post(
        `/scorecard/${entity}/summary`,
        {
          authKey
        },
        {
          companyId,
          startAt,
          endAt,
          groups
        }
      );
    }

    return response.body;
  }
);

/*
export const fetchSummary = createAsyncThunk(
  FETCH_SUMMARY,
  async ({ startAt, endAt, companyId, entityName = 'users', entityId }, { getState, dispatch }) => {
    const state = getState();
    const authKey = state?.user?.current?.auth?.key;
    const entity = entityName === 'drivers' ? 'users' : entityName;
    const url = entityId
      ? `/scorecard/${entity}/summary/${entityId}`
      : `/scorecard/${entity}/summary`;

    const response = await api.get(url, {
      authKey,
      query: {
        companyId,
        startAt,
        endAt
      }
    });
    return response.body;
  }
);
*/

export const fetchConfig = createAsyncThunk(
  FETCH_CONFIG,
  async ({ companyId }, { getState, dispatch }) => {
    const state = getState();
    const authKey = state?.user?.current?.auth?.key;

    const response = await api.get('/scorecard/config', {
      authKey,
      query: {
        companyId
      }
    });
    return response.body;
  }
);

export const updateConfig = createAsyncThunk(
  UPDATE_CONFIG,
  async ({ config, companyId }, { getState, dispatch }) => {
    const state = getState();
    const authKey = state?.user?.current?.auth?.key;
    const response = await api.put(
      `/scorecard/config`,
      {
        authKey,
        query: { companyId }
      },
      config
    );

    return response.body;
  }
);

export const fetchBuckets = createAsyncThunk(
  FETCH_BUCKET,
  async ({ entityName, entityId, companyId }, { getState, dispatch }) => {
    const state = getState();
    const authKey = state?.user?.current?.auth?.key;

    const response = await api.get(`/scorecard/buckets/${entityName}`, {
      authKey,
      query: {
        [entityName === Entities.BRANCH ? 'branchId' : 'fleetId']: entityId || null,
        companyId
      }
    });

    return response.body;
  }
);

export const updateBuckets = createAsyncThunk(
  UPDATE_BUCKET,
  async ({ entityType, type, entityId, values, companyId, enabled }, { getState, dispatch }) => {
    const state = getState();
    const authKey = state?.user?.current?.auth?.key;
    const body = {
      companyId,
      entityId: entityId || null,
      entityType,
      type,
      values,
      enabled
    };
    const response = await api.put(
      `/scorecard/buckets`,
      {
        authKey,
        query: { companyId }
      },
      body
    );

    return response.body;
  }
);

export const fetchBucketDefaults = createAsyncThunk(
  FETCH_BUCKET_DEFAULTS,
  async (payload = {}, { getState, dispatch }) => {
    const state = getState();
    const authKey = state?.user?.current?.auth?.key;

    const response = await api.get(`/scorecard/buckets/defaults`, {
      authKey,
      query: {}
    });

    return response.body;
  }
);

export const fetchScoreEvents = createAsyncThunk(
  FETCH_SCORE_EVENTS,
  async (
    { startAt, endAt, companyId, entityName = 'users', entityId, eventType },
    { getState, dispatch }
  ) => {
    const state = getState();
    const authKey = state?.user?.current?.auth?.key;

    const entity = entityName === 'drivers' ? 'users' : entityName;
    const url = entityId
      ? `/scorecard/${entity}/score/${entityId}/events/${eventType}`
      : `/scorecard/${entity}/score`;

    const response = await api.get(url, {
      authKey,
      query: {
        companyId,
        startAt,
        endAt
      }
    });

    return response.body;
  }
);

export const includeExcludeEvents = createAsyncThunk(
  INCLUDE_EXCLUDE_EVENTS,
  async (
    { comment, events, entityType, entityId, isExclude, companyId },
    { getState, dispatch }
  ) => {
    const state = getState();
    const authKey = state?.user?.current?.auth?.key;

    const entity = entityType === 'drivers' ? 'users' : entityType;
    const operation = isExclude ? 'exclude' : 'include';
    const url = `/scorecard/${entity}/score/${entityId}/events/${operation}`;

    const eventsPayload = events.map(event => {
      return {
        id: event.id,
        timeAt: event.timeAt,
        localTimeAt: event.localTimeAt
      };
    });

    const payload = {
      comment: comment,
      events: eventsPayload
    };

    const response = await api.post(
      url,
      {
        authKey,
        query: { companyId }
      },
      payload
    );

    return response.body;
  }
);

export const fetchEnabledEventTypes = createAsyncThunk(
  ENABLED_EVENT_TYPES,
  async ({ companyId, entityType, entityId, groups = null }, { getState, dispatch }) => {
    const state = getState();
    const authKey = state?.user?.current?.auth?.key;

    const response = await api.post(
      `/scorecard/eventtypes`,
      {
        authKey
      },
      {
        companyId,
        entityType,
        entityId: entityId || null,
        groups
      }
    );

    return response.body;
  }
);

/*
export const fetchEnabledEventTypes = createAsyncThunk(
  ENABLED_EVENT_TYPES,
  async ({ companyId, entityType, entityId }, { getState, dispatch }) => {
    const state = getState();
    const authKey = state?.user?.current?.auth?.key;

    const response = await api.get(`/scorecard/eventtypes`, {
      authKey,
      query: {
        companyId,
        entityType,
        entityId: entityId || null
      }
    });

    return response.body;
  }
);
*/

export const fetchEnabledEventTypesBranch = createAsyncThunk(
  ENABLED_EVENT_TYPES_BRANCH,
  async ({ companyId }, { getState, dispatch }) => {
    const state = getState();
    const authKey = state?.user?.current?.auth?.key;
    const responseBranch = await api.get(`/scorecard/eventtypes`, {
      authKey,
      query: {
        companyId,
        entityType: Entities.BRANCH,
        entityId: null
      }
    });

    return responseBranch.body;
  }
);

export const fetchEnabledEventTypesFleet = createAsyncThunk(
  ENABLED_EVENT_TYPES_FLEET,
  async ({ companyId }, { getState, dispatch }) => {
    const state = getState();
    const authKey = state?.user?.current?.auth?.key;
    const responseFleet = await api.get(`/scorecard/eventtypes`, {
      authKey,
      query: {
        companyId,
        entityType: Entities.FLEET,
        entityId: null
      }
    });
    return responseFleet.body;
  }
);
