import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Modal,
  Row,
  Col,
  DatePicker,
  TimePicker,
  Select,
  Button,
  Space,
  Alert,
  Skeleton
} from 'antd';
import moment from 'moment';
import { useVehicles } from 'features/fleets/fleetsSlice';
import { useDevices } from 'features/devices/devicesSlice';
import { useDevicesStats } from 'features/devices/devicesStatsSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import styles from './RequestFootageModal.module.scss';
import {
  fetchCameraVideo,
  getCameraVideoStatus,
  useCameraDiagnosticStatus
} from 'features/camera/cameraSlice';
import { CameraModelConfig, isIQCamModelName } from 'features/camera/CameraModelConfig';
import { useDispatch } from 'react-redux';
import { ToastType } from 'components/notifications/toasts/Toast';
import { openToast } from 'features/toasts/toastsSlice';
import { useHistory } from 'react-router';
import { BUTTON_IDS } from 'utils/globalConstants';
import {
  CameraTabs,
  ErrorMessageTypes,
  extractCameraError,
  extractCameraSuccessInfo
} from 'containers/home/Camera/constant';
import { GlobalRoles, useCan } from 'features/permissions';
import { useCurrentRegion } from 'features/regions/regionsSlice';
import { useCameraChannel } from './useCameraChannel';

export function RequestVehicle({ vehicleInfo }) {
  const { t } = useTranslation();
  return (
    <Row>
      <Col span={24}>
        <h5>{t('Common.Vehicle')}</h5>
      </Col>
      <Col span={8}>
        <p>{t('Common.Name')}</p>
        <p>{vehicleInfo?.name}</p>
      </Col>
      <Col span={8}>
        <p>{t('Vehicles.View.Status')}</p>
        <p>{vehicleInfo.status}</p>
      </Col>
      <Col span={8}>
        <p>{t('Common.LastUpdate')}</p>
        <p>{vehicleInfo.lastUpdated}</p>
      </Col>
    </Row>
  );
}

export function CameraStatus({ cameraInfo }) {
  const { t } = useTranslation();
  if (cameraInfo?.model !== 'Trajet') {
    return <></>;
  }
  return (
    <Row>
      <Col span={24}>
        <h5 className={styles.cameraTitle}>{t('Footage.Camera')}</h5>
      </Col>
      <Col span={24}>
        <Row>
          <Col span={8}>
            <p>{t('Footage.NumberOfCameras')}</p>
            <p>{cameraInfo.cameraCount}</p>
          </Col>
          <Col span={8}>
            <p>{t('Footage.SIM')}</p>
            <p>{cameraInfo.sim}</p>
          </Col>
          <Col span={8}>
            <p>{t('Footage.LastUpdated')}</p>
            <p>{cameraInfo.lastUpdated}</p>
          </Col>
          <Col span={8}>
            <p>{t('Footage.FirstConnected')}</p>
            <p>{cameraInfo.firstConnected}</p>
          </Col>
          <Col span={8}>
            <p>{t('Footage.State')}</p>
            <p>{cameraInfo.state}</p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export function CameraVideoRequest({
  device,
  channelInfo,
  dateTime = null,
  onDateChanged,
  onTimeChanged,
  onChannelChanged,
  onVideoLengthChanged,
  disabled = false
}) {
  const { t } = useTranslation();

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState('');

  const disabledDate = useCallback(current => {
    return (
      current.isBefore(
        moment()
          .subtract(3, 'month')
          .startOf('month')
      ) || current.isAfter(moment())
    );
  }, []);

  const handleDateChanged = useCallback(
    date => {
      setSelectedDate(date);
      setSelectedTime(oldTime => {
        if (
          moment(date)
            .startOf('day')
            .isSame(moment().startOf('day')) &&
          moment(oldTime, 'HH:mm:ss').isAfter(moment(moment().valueOf(), 'HH:mm:ss'))
        ) {
          return moment();
        } else {
          return oldTime;
        }
      });
      if (onDateChanged) {
        onDateChanged(date);
      }
    },
    [onDateChanged]
  );

  const handleTimeChanged = useCallback(
    time => {
      setSelectedTime(time);
      if (onTimeChanged) {
        onTimeChanged(time);
      }
    },
    [onTimeChanged]
  );

  useEffect(() => {
    if (dateTime && moment.isMoment(dateTime)) {
      handleDateChanged(dateTime);
      handleTimeChanged(dateTime);
    }
  }, [dateTime, handleDateChanged, handleTimeChanged]);

  const disabledTime = useCallback(
    () => ({
      disabledHours: () => {
        if (
          moment(selectedDate)
            .startOf('day')
            .isBefore(moment().startOf('day'))
        ) {
          return [];
        } else {
          const currentHour = moment().hour();
          const disabledHours = [];
          for (let i = currentHour + 1; i <= 23; i++) {
            disabledHours.push(i);
          }
          return disabledHours;
        }
      },
      disabledMinutes: selectedHour => {
        if (
          moment(selectedDate)
            .startOf('day')
            .isBefore(moment().startOf('day'))
        ) {
          return [];
        } else {
          if (selectedHour < moment().hour()) {
            return [];
          }
          const disabledMinutes = [];
          for (let i = moment().minute() + 1; i <= 60; i++) {
            disabledMinutes.push(i);
          }
          return disabledMinutes;
        }
      }
    }),
    [selectedDate]
  );

  return (
    <Row className={styles.requestForm}>
      <Col span={24}>
        <Row gutter={15}>
          <Col span={8}>
            <p>{t('Footage.VideoDate')}</p>
            <DatePicker
              value={selectedDate}
              mode="date"
              disabledDate={disabledDate}
              onChange={handleDateChanged}
              placeholder={t('Common.SelectDate')}
              disabled={disabled}
            />
          </Col>
          <Col span={8}>
            <p>{t('Footage.VideoStartTime')}</p>
            <TimePicker
              value={selectedTime}
              mode="time"
              format="HH:mm:ss"
              hideDisabledOptions={true}
              disabledTime={disabledTime}
              onChange={handleTimeChanged}
              placeholder={t('Common.SelectTime')}
              disabled={disabled}
            />
          </Col>
          <Col span={8}>
            <VideoLengthSelect
              cameraModel={device?.model.name}
              onChange={onVideoLengthChanged}
              disabled={disabled}
            />
          </Col>
          <CameraChannelSelect
            device={device}
            channelInfo={channelInfo}
            onChange={onChannelChanged}
            disabled={disabled}
          />
        </Row>
      </Col>
    </Row>
  );
}

export function RequestFootageModal({ deviceId, vehicleId, showModal, onClose, dateTime = null }) {
  const [show, setShow] = useState(showModal);
  const devicesInfo = useDevices();
  const vehiclesInfo = useVehicles();
  const deviceStats = useDevicesStats();
  const { cameraStatus: cameraInfo, isFetching } = useCameraDiagnosticStatus(deviceId, true);
  const history = useHistory();
  const { t } = useTranslation();

  const device = useMemo(() => devicesInfo?.[deviceId], [deviceId, devicesInfo]);
  const warning = useMemo(() => {
    const getWarning =
      CameraModelConfig[device?.model?.name]?.getRequestFootageWarning ||
      CameraModelConfig.defaultModel.getRequestFootageWarning;
    return getWarning(t);
  }, [t, device?.model?.name]);
  const cameraChannelInfo = useCameraChannel({ device, cameraInfo });

  const can = useCan();
  const currentRegion = useCurrentRegion();
  const {
    isVRExhausted,
    disableVR,
    isFetchingCameraInfo,
    totalVRAllowed,
    monthlyRenewOn
  } = useMemo(() => {
    const isVRUnrestricted = can({ oneOfRoles: [GlobalRoles.SiteAdmin] });
    const isIQCam = isIQCamModelName(device?.model?.name);
    if (isIQCam) {
      const {
        isExhausted,
        max: totalVRAllowed,
        monthlyRenewOn
      } = CameraModelConfig.IQCamera.getDataPlanUsage({
        dataPlan: cameraInfo?.dataPlan,
        planKeys: ['DVR_UPLOADED'],
        currentRegionCode: currentRegion?.code
      });
      const isVRExhausted = !isVRUnrestricted && !isFetching && isExhausted;
      return {
        isVRExhausted,
        disableVR: isVRExhausted || (!isVRUnrestricted && isFetching),
        totalVRAllowed,
        monthlyRenewOn,
        isFetchingCameraInfo: !isVRUnrestricted && isFetching
      };
    }
    return { isVRExhausted: false, disableVR: false, isFetchingCameraInfo: false };
  }, [t, device?.model?.name, cameraInfo, currentRegion, isFetching]);

  const [isLoadingVideo, setIsLoadingVideo] = useState(false);
  const [modal, contextHolder] = Modal.useModal();
  const requestingCancelled = useRef(false);

  const dispatch = useDispatch();

  const [videoRequestInfo, setVideoRequestInfo] = useState({
    date: null,
    time: null,
    videoLength: 10,
    channel: null,
    deviceId: deviceId,
    provider: ''
  });

  const vehicleInfo = useMemo(() => {
    const vehicleInfo = {
      name: t('Common.Unknown'),
      status: t('Common.Unknown'),
      lastUpdated: t('Common.Unknown')
    };

    let vehicle = null;
    if (vehicleId && vehiclesInfo) {
      vehicle = vehiclesInfo.find(v => v.id === vehicleId);
    } else if (deviceId && devicesInfo) {
      const device = devicesInfo[deviceId];
      if (device?.vehicleInfo?.id) {
        vehicle = vehiclesInfo.find(v => v.id === device.vehicleInfo.id);
      }
    }

    vehicleInfo.name = vehicle?.name;
    if (vehicle?.devices) {
      let latestDeviceStats = null;
      vehicle.devices.forEach(d => {
        const stats = deviceStats?.find(ds => d.id === ds.deviceId);
        if (
          !latestDeviceStats ||
          (stats?.ignition &&
            moment(latestDeviceStats.updatedAt).isBefore(moment(stats?.updatedAt)))
        ) {
          latestDeviceStats = stats;
        }
      });
      if (latestDeviceStats) {
        vehicleInfo.lastUpdated = moment(latestDeviceStats.updatedAt).from(moment());
        vehicleInfo.status = latestDeviceStats.ignition || t('Tracking.Events.Ignition Off');
      }
    }
    return vehicleInfo;
  }, [vehicleId, deviceId, devicesInfo, vehiclesInfo, deviceStats, t]);

  const handleRequestVideo = useCallback(() => {
    setIsLoadingVideo(true);
    let requestTime = moment(videoRequestInfo.date);
    requestTime.hour(videoRequestInfo.time.hour());
    requestTime.minute(videoRequestInfo.time.minute());
    requestTime.second(videoRequestInfo.time.second());
    requestTime.millisecond(0);
    const closeModal = ({ success = false, message }) => {
      if (success) {
        dispatch(
          openToast({
            type: ToastType.Success,
            linkable: true,
            delay: 10000,
            message: (
              <Trans
                i18nKey={'Footage.RequestVideoSubmitInfo.SuccessSubmitted'}
                components={{
                  1: (
                    <Button
                      type="link"
                      onClick={_ => {
                        history.push(
                          Object.values(CameraTabs).find(t => t.title === 'Video Requests')?.path
                        );
                      }}
                    />
                  ),
                  info: message ? ` ${message}` : ''
                }}
              />
            )
          })
        );
      } else {
        dispatch(
          openToast({
            type: ToastType.Warning,
            message
          })
        );
      }
      setIsLoadingVideo(false);
      setShow(false);
      if (onClose) {
        onClose();
      }
    };

    const requestVideo = ({
      checkDuplicate,
      onError = ({ message, messageType }) => {},
      onSuccess = () => {}
    }) =>
      dispatch(
        fetchCameraVideo(
          videoRequestInfo.deviceId,
          requestTime.toISOString(),
          videoRequestInfo.videoLength,
          videoRequestInfo.provider,
          videoRequestInfo.channel,
          checkDuplicate
        )
      ).then(async statusId => {
        if (requestingCancelled.current) {
          return;
        }
        const status = dispatch(getCameraVideoStatus(statusId));
        if (!status.error) {
          onSuccess(status?.data);
        } else {
          onError(extractCameraError(status.error));
        }
      });

    requestVideo({
      checkDuplicate: true,
      onSuccess: status => closeModal({ success: true, message: extractCameraSuccessInfo(status) }),
      onError: ({ message, messageType }) => {
        setIsLoadingVideo(false);
        if (messageType === ErrorMessageTypes.MEDIA_REQUEST_DUPLICATED) {
          let errorMessage = '',
            success = false,
            canceled = false,
            successMessage = '';
          modal.confirm({
            centered: true,
            className: styles.confirmationContent,
            content: `${t('Footage.RequestDetails.MEDIA_REQUEST_DUPLICATED')} ${t(
              'Common.Modal.DoYouWishToContinue'
            )}`,
            afterClose() {
              if (success) {
                closeModal({ success, message: successMessage });
              } else if (!canceled) {
                closeModal({ message: errorMessage });
              }
            },
            onCancel: () => {
              canceled = true;
            },
            onOk: () =>
              requestVideo({
                checkDuplicate: false,
                onSuccess: status => {
                  success = true;
                  successMessage = extractCameraSuccessInfo(status);
                },
                onError: ({ message }) => {
                  errorMessage = message;
                }
              })
          });
        } else {
          const errorMessage =
            messageType === ErrorMessageTypes.PENDING_VIDEO_REQUEST_LIMIT_EXCEEDED
              ? t('Footage.RequestDetails.PENDING_VIDEO_REQUEST_LIMIT_EXCEEDED')
              : message;
          closeModal({ message: errorMessage });
        }
      }
    });
  }, [dispatch, videoRequestInfo, onClose, history, requestingCancelled, t]);

  const handleCancel = useCallback(() => {
    setShow(false);
    requestingCancelled.current = true;
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handleDateChanged = useCallback(date => {
    setVideoRequestInfo(prevState => {
      return {
        ...prevState,
        date: date
      };
    });
  }, []);

  const handleTimeChanged = useCallback(time => {
    setVideoRequestInfo(prevState => {
      return {
        ...prevState,
        time: time
      };
    });
  }, []);

  const handleVideoLengthChanged = useCallback(videoLength => {
    setVideoRequestInfo(prevState => {
      return {
        ...prevState,
        videoLength: videoLength
      };
    });
  }, []);

  const handleChannelChanged = useCallback(channel => {
    setVideoRequestInfo(prevState => {
      return {
        ...prevState,
        channel: channel
      };
    });
  }, []);

  const footer = useMemo(() => {
    return (
      <Row>
        <Col>
          <Space>
            <Button
              type="primary"
              onClick={handleRequestVideo}
              disabled={
                disableVR ||
                isLoadingVideo ||
                !videoRequestInfo.date ||
                !videoRequestInfo.time ||
                (cameraChannelInfo.supportChannelSelect &&
                  (Array.isArray(videoRequestInfo.channel)
                    ? !videoRequestInfo.channel.length
                    : !videoRequestInfo.channel))
              }
              id={BUTTON_IDS.requestVideo}
            >
              {t('Footage.RequestVideo')}
            </Button>
            <Button onClick={handleCancel} id={BUTTON_IDS.requestVideoCancel}>
              {t('Common.Cancel')}
            </Button>
          </Space>
        </Col>
      </Row>
    );
  }, [
    videoRequestInfo,
    handleRequestVideo,
    handleCancel,
    isLoadingVideo,
    t,
    disableVR,
    cameraChannelInfo.supportChannelSelect
  ]);

  useEffect(() => {
    if (device?.model?.name) {
      setVideoRequestInfo(prevState => ({
        ...prevState,
        provider: CameraModelConfig[device.model?.name]?.provider
      }));
    }
  }, [device]);
  return (
    <Modal
      title={t('Footage.RequestVideoFootage')}
      maskClosable={false}
      className={styles.videoRequestModal}
      width={700}
      open={show}
      footer={footer}
      onOk={handleRequestVideo}
      onCancel={handleCancel}
    >
      {isLoadingVideo && <div className={styles.loading}></div>}
      {contextHolder}
      <Row>
        {isVRExhausted && (
          <Col span={24}>
            <Alert
              showIcon
              type="error"
              className={styles.warningPanel}
              message={t('Devices.View.DataPlan.Exhausted', {
                max: totalVRAllowed,
                date: t(`Devices.View.DataPlan.MonthlyRenew.Next.${monthlyRenewOn}`)
              })}
            />
          </Col>
        )}
        <Col span={24}>
          <Alert
            showIcon
            type="warning"
            className={styles.warningPanel}
            message={
              <div className={styles.warning}>
                <h5>{t('Easydocs.Information')}:</h5>
                <ol>
                  {warning.map((text, index) => (
                    <li key={`${deviceId}-warning-${index}`}>{text}</li>
                  ))}
                </ol>
              </div>
            }
          />
        </Col>
        <Col span={24}>
          <div className={styles.infoCard}>
            <RequestVehicle vehicleInfo={vehicleInfo} />
            <CameraMetaFields
              cameraInfo={cameraInfo}
              device={device}
              loading={isFetchingCameraInfo}
            />
          </div>
        </Col>
        <Col span={24}>
          <CameraVideoRequest
            vehicleInfo={vehicleInfo}
            dateTime={dateTime}
            onChannelChanged={handleChannelChanged}
            onDateChanged={handleDateChanged}
            onTimeChanged={handleTimeChanged}
            device={device}
            channelInfo={cameraChannelInfo}
            onVideoLengthChanged={handleVideoLengthChanged}
            disabled={disableVR}
          />
        </Col>
      </Row>
    </Modal>
  );
}

const VideoLengthSelect = ({
  cameraModel,
  onChange,
  defaultValue = 10,
  showInMinsSeconds = false,
  disabled = false
}) => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    const maxLength =
      CameraModelConfig[cameraModel]?.video?.maxLength ||
      CameraModelConfig.defaultModel.video.maxLength;
    const stepper =
      CameraModelConfig[cameraModel]?.video?.stepper ||
      CameraModelConfig.defaultModel.video.stepper;
    let options = [0];
    while (options[options.length - 1] < maxLength) {
      options.push(options[options.length - 1] + stepper);
    }
    return Array.from(new Set([defaultValue, ...options.slice(1)])).map(opt => {
      const value = Number(opt);
      let label = '';
      if (showInMinsSeconds) {
        if (value < 60) {
          label = `${value} ${t('Common.seconds')}`;
        } else {
          const mins = Math.floor(value / 60),
            seconds = value % 60;
          label = `${mins} ${mins === 1 ? t('Common.Min') : t('Common.Mins')}`;
          if (seconds) {
            label = `${label} ${seconds} ${t('Common.seconds')}`;
          }
        }
      } else {
        label = `${value} ${t('Common.seconds')}`;
      }
      return { label, value };
    });
  }, [t, cameraModel, defaultValue]);

  return (
    <>
      <p>{`${t('Footage.VideoLength')}${showInMinsSeconds ? '' : ` (${t('Common.seconds')})`}`}</p>
      <Select
        defaultValue={defaultValue}
        getPopupContainer={triggerNode => triggerNode.parentNode}
        onChange={onChange}
        options={options}
        disabled={disabled}
      />
    </>
  );
};

const CameraChannelSelect = ({ device, channelInfo, onChange, disabled = false }) => {
  const { t } = useTranslation();
  const { supportChannelSelect, options, supportMultiChannel } = channelInfo;

  // TODO: Remove the logic on EVO phase2
  useEffect(() => {
    if (device?.model?.name === CameraModelConfig.MultiIQ.name && options) {
      onChange(options.map(option => option.value));
    }
  }, [options?.length]);

  if (supportChannelSelect && device?.model?.name === CameraModelConfig.MultiIQ.name) {
    return (
      <Col span={8}>
        <p>{t('Footage.CameraChannels')}</p>
        <p>{t('Footage.AllChannels')}</p>
      </Col>
    );
  }

  return (
    supportChannelSelect && (
      <Col span={8}>
        <p>{supportMultiChannel ? t('Footage.CameraChannels') : t('Footage.CameraChannel')}</p>
        <Select
          mode={supportMultiChannel ? 'multiple' : 'tags'}
          getPopupContainer={triggerNode => triggerNode.parentNode}
          onChange={onChange}
          options={options}
          disabled={disabled}
        />
      </Col>
    )
  );
};

const CameraMetaFields = ({ device, cameraInfo, loading }) => {
  const { t } = useTranslation();
  const localization = useLocalization();

  const fields = useMemo(
    () =>
      (
        CameraModelConfig[device?.model?.name]?.obtainableMeta ||
        CameraModelConfig.defaultModel.obtainableMeta
      )?.map(({ title, value }) => ({
        title: title(t),
        value: value({ t, localization, cameraInfo, device })
      })),
    [cameraInfo, device, t, localization]
  );

  return device && !!fields?.length ? (
    <Row>
      <Col span={24}>
        <h5 className={styles.cameraTitle}>{t('Footage.Camera')}</h5>
      </Col>
      <Col span={24}>
        <RowFieldsSkeleton loading={loading} count={3}>
          {fields.map((field, index) => (
            <Col key={`cameraMeta-${index}`} span={8}>
              <p>{field.title}</p>
              <p>{field.value}</p>
            </Col>
          ))}
        </RowFieldsSkeleton>
      </Col>
    </Row>
  ) : null;
};

const RowFieldsSkeleton = ({ count = 0, loading, children }) => (
  <Row>
    {loading && count > 0
      ? new Array(count).fill(1).map((_, index) => (
          <Col key={`field-skeleton-${index}`} span={8}>
            <Skeleton.Input active />
          </Col>
        ))
      : children}
  </Row>
);
