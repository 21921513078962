import React from 'react';
import styles from '../GPIOForm.module.scss';
import { Card, Col, Form, Input, Row, Switch, InputNumber, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { inputValidator } from '../helpers';
import { useLocalization } from 'features/localization/localizationSlice';
import classNames from 'classnames';
import { Scope } from '../constants';

const LabeledSwitch = ({ label, ...props }) => (
  <div className={styles.labeledSwitch}>
    <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} {...props} />
    <span>{label}</span>
  </div>
);

export const BasicInfoSection = ({
  selectedScope,
  onScopeChange,
  seatbeltSpeedThresholdDefault,
  hasCompanyScopeTemplate
}) => {
  const { t } = useTranslation();
  const localization = useLocalization();

  return (
    <div>
      <div className={classNames(styles.title, styles.titleSection)}>Basic Information</div>
      <Row gutter={24}>
        <Col span={18}>
          <Form.Item
            colon={false}
            label={t('CompanyConfig.DeviceConfigurations.AddTemplateHeaderForm.NameInputLabel')}
            name="name"
            rules={[
              {
                required: true,
                message: t(
                  'CompanyConfig.DeviceConfigurations.AddTemplateHeaderForm.NameInputRequiredInput'
                )
              }
            ]}
          >
            <Input maxLength={255} />
          </Form.Item>

          <Form.Item
            label={t(
              'CompanyConfig.DeviceConfigurations.AddTemplateHeaderForm.DescriptionInputLabel'
            )}
            name="description"
          >
            <Input.TextArea autoSize={{ minRows: 4, maxRows: 6 }} maxLength={65535} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Card className={styles.additionalInfo}>
            <div>
              <div className={styles.label}>Additional Settings</div>
              <div className={styles.checkBoxes}>
                <Form.Item
                  label={
                    <div className={styles.label}>
                      {t(`CompanyConfig.DeviceConfigurations.GPIOTemplates.Level.SelectLevel`)}
                    </div>
                  }
                  name="scope"
                  rules={[
                    {
                      required: true,
                      message: t(
                        `CompanyConfig.DeviceConfigurations.GPIOTemplates.Level.SelectLevelError`
                      )
                    }
                  ]}
                  required={false}
                  tooltip={{
                    title: (
                      <div className={styles.content}>
                        <div>
                          {t(
                            `CompanyConfig.DeviceConfigurations.GPIOTemplates.Level.Tooltip.Title`
                          )}
                        </div>
                        <ul>
                          <li>
                            {t(
                              `CompanyConfig.DeviceConfigurations.GPIOTemplates.Level.Tooltip.CompanyLevel`
                            )}
                          </li>
                          <li>
                            {t(
                              `CompanyConfig.DeviceConfigurations.GPIOTemplates.Level.Tooltip.FleetLevel`
                            )}
                          </li>
                          <li>
                            {t(
                              `CompanyConfig.DeviceConfigurations.GPIOTemplates.Level.Tooltip.DeviceLevel`
                            )}
                          </li>
                        </ul>
                        <div>
                          {t(
                            `CompanyConfig.DeviceConfigurations.GPIOTemplates.Level.Tooltip.Description`
                          )}
                        </div>
                      </div>
                    ),
                    overlayClassName: styles.levelTooltip
                  }}
                >
                  <div className={styles.verticalFlex}>
                    <Tooltip
                      title={
                        hasCompanyScopeTemplate
                          ? t(
                              `CompanyConfig.DeviceConfigurations.GPIOTemplates.Level.CompanyLevelWarning`
                            )
                          : undefined
                      }
                    >
                      <LabeledSwitch
                        label={t(
                          `CompanyConfig.DeviceConfigurations.GPIOTemplates.Level.SetCompany`
                        )}
                        checked={selectedScope === Scope.COMPANY}
                        disabled={hasCompanyScopeTemplate}
                        onChange={() => onScopeChange(Scope.COMPANY)}
                      />
                    </Tooltip>
                    <LabeledSwitch
                      label={t(`CompanyConfig.DeviceConfigurations.GPIOTemplates.Level.SetFleet`)}
                      checked={selectedScope === Scope.FLEET}
                      onChange={() => onScopeChange(Scope.FLEET)}
                    />
                    <LabeledSwitch
                      label={t(`CompanyConfig.DeviceConfigurations.GPIOTemplates.Level.SetDevice`)}
                      checked={selectedScope === Scope.DEVICE}
                      onChange={() => onScopeChange(Scope.DEVICE)}
                    />
                  </div>
                </Form.Item>
              </div>
              <Form.Item
                label={
                  <div className={styles.label}>
                    {t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Seatbelt Speed Threshold')}
                  </div>
                }
                rules={inputValidator().number}
                name="seatbelt.speed.threshold"
                initialValue={localization.convertDistance(seatbeltSpeedThresholdDefault, 0)}
              >
                <InputNumber precision={0} addonAfter={localization.formats.speed.unit_per_hour} />
              </Form.Item>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
