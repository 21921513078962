import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import dayjs from 'dayjs';

import { prepareAuthHeaders } from 'services/util';

import { API_PATH } from 'config';

export const ngRegulatoryApi = createApi({
  reducerPath: 'ngRegulatoryApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_PATH}`,
    prepareHeaders: prepareAuthHeaders
  }),
  tagTypes: ['sdm', 'sdc'],
  endpoints: builder => ({
    getTimezonesAU: builder.query({
      query: () => `tcaselfdeclarations/timezones`
    }),
    getVehicleRegistration: builder.query({
      query: ({ companyId }) => ({
        url: '/tcaselfdeclarations/vehicles',
        params: { company_id: companyId }
      })
    }),
    addSelfDeclaration: builder.mutation({
      query: ({ deviceId, body }) => ({
        url: `/devices/${deviceId}/events/sd${body.VehicleType ? 'm' : 'c'}`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['sdm', 'sdc']
    }),
    getVehicleTypeDefinitions: builder.query({
      query: () => `/tcaselfdeclarations/definitions`
    }),
    getSDMData: builder.query({
      query: ({ companyId, fromDate, toDate }) => ({
        url: '/tcaselfdeclarations/sdm',
        params: { sources: 'TN360', companyId, from: fromDate, to: toDate }
      }),
      providesTags: ['sdm']
    }),
    getSDCData: builder.query({
      query: ({ companyId, fromDate, toDate }) => ({
        url: '/tcaselfdeclarations/sdc',
        params: { sources: 'TN360', companyId, from: fromDate, to: toDate }
      }),
      providesTags: ['sdc']
    })
  })
});

export const {
  useGetTimezonesAUQuery,
  useGetVehicleRegistrationQuery,
  useAddSelfDeclarationMutation,
  useGetVehicleTypeDefinitionsQuery,
  useGetSDMDataQuery,
  useGetSDCDataQuery
} = ngRegulatoryApi;
