import { ngAwsQsApi } from './ngAwsQsApi';
import { ngAwsQsEmbedApi } from './ngAwsQsEmbedApi';
import { ngDevicesGpioApi } from './ngDevicesGpioApi';
import { ngTimezonesApi } from './ngTimezonesApi';
import { ngRegulatoryApi } from './ngRegulatoryApi';
import { ngAgreementApi } from './ngAgreementApi';
import { ngGpioConfigurationApi } from './ngGpioConfigurationApi';
import { ngAssetConfigurationApi } from './ngAssetConfigurationApi';
import { ngSpeedAssistConfigurationApi } from './ngSpeedAssistConfigurationApi';
import { ngConfigKeyApi } from './ngConfigKeyApi';
import { ngB2bIntegrationsApi } from './ngB2bIntegrationsApi';
import { ngSharedLinksApi } from './ngSharedLinksApi';
import { ngUserPrefConfigApi } from './ngUserPrefConfigApi';
import { ngDriverIdConfigurationApi } from './ngDriverIdConfigurationApi';

export const nextgenReducers = {
  [ngAwsQsApi.reducerPath]: ngAwsQsApi.reducer,
  [ngAwsQsEmbedApi.reducerPath]: ngAwsQsEmbedApi.reducer,
  [ngDevicesGpioApi.reducerPath]: ngDevicesGpioApi.reducer,
  [ngTimezonesApi.reducerPath]: ngTimezonesApi.reducer,
  [ngAgreementApi.reducerPath]: ngAgreementApi.reducer,
  [ngGpioConfigurationApi.reducerPath]: ngGpioConfigurationApi.reducer,
  [ngAssetConfigurationApi.reducerPath]: ngAssetConfigurationApi.reducer,
  [ngDriverIdConfigurationApi.reducerPath]: ngDriverIdConfigurationApi.reducer,
  [ngSpeedAssistConfigurationApi.reducerPath]: ngSpeedAssistConfigurationApi.reducer,
  [ngConfigKeyApi.reducerPath]: ngConfigKeyApi.reducer,
  [ngUserPrefConfigApi.reducerPath]: ngUserPrefConfigApi.reducer,
  [ngB2bIntegrationsApi.reducerPath]: ngB2bIntegrationsApi.reducer,
  [ngSharedLinksApi.reducerPath]: ngSharedLinksApi.reducer,
  [ngRegulatoryApi.reducerPath]: ngRegulatoryApi.reducer
};
