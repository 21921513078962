import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { List } from 'antd';
import _ from 'lodash';

import { QsEntityType, useGetQsUserQuery, useSearchQsFoldersEntitiesQuery } from 'services/nextgen';
import { Comparators } from 'utils/sorting';
import { SortOrders, CategoryFilters } from '../constants';

import { useCan, services, useCanOneOfCompanyServices } from 'features/permissions';
import { useCurrentCompany } from 'features/company/companySlice';
import { useEnvCode } from 'features/regions/regionsSlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';

import DashboardCard from '../Cards/DashboardCard';
import {
  DashboardsPageToolbar,
  isDashboardCategory,
  getDashboardCategoryLabels
} from '../Toolbars/DashboardsPageToolbar';

import imgCustomDashboard from '../Images/custom-dashboard.svg';
import imgCustomAnalysis from '../Images/custom-analysis.svg';

import styles from './pages.module.scss';

export const DashboardsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompany = useCurrentCompany();
  const envCode = useEnvCode();
  const qsUser = useGetQsUserQuery()?.data;
  const isQsUserAuthor = qsUser?.role?.toUpperCase() === 'AUTHOR';
  const can = useCan();
  const canBuilder = can({
    everyService: [services.INSIGHTSBUILDER],
    everyCompanyService: [services.INSIGHTSBUILDER]
    // otherConditions: [() => isQsUserAuthor]
  });

  const [dashboards, setDashboards] = useState([]);
  const [filteredDashboards, setFilteredDashboards] = useState([]);
  const [searchText, setSearchText] = useState();
  const [sortOrder, setSortOrder] = useState(SortOrders.Default);
  const [categories, setCategories] = useState();

  const folderIds = [
    ...(useCanOneOfCompanyServices([services.INSIGHTSBASIC]) ? [services.INSIGHTSBASIC] : []),
    ...(useCanOneOfCompanyServices([services.INSIGHTSADVPROD]) ? [services.INSIGHTSADVPROD] : []),
    ...(useCanOneOfCompanyServices([services.INSIGHTSADVSAFETY])
      ? [services.INSIGHTSADVSAFETY]
      : []),
    ...(useCanOneOfCompanyServices([services.INSIGHTSADVCOMP]) ? [services.INSIGHTSADVCOMP] : []),
    ...(useCanOneOfCompanyServices([services.INSIGHTSSUSTAINABILITYSNAPSHOT])
      ? [services.INSIGHTSSUSTAINABILITYSNAPSHOT]
      : []),
    ...(useCanOneOfCompanyServices([services.INSIGHTSSUSTAINABILITYGOALS])
      ? [services.INSIGHTSSUSTAINABILITYGOALS]
      : []),
    ...(useCanOneOfCompanyServices([services.INSIGHTSSUSTAINABILITYFLEETS])
      ? [services.INSIGHTSSUSTAINABILITYFLEETS]
      : []),
    ...(useCanOneOfCompanyServices([services.ASSETSUSAGE]) ? [services.ASSETSUSAGE] : [])
  ];

  const tnDashboardsQuery = useSearchQsFoldersEntitiesQuery({
    folderIds: folderIds,
    entityType: QsEntityType.Dashboard
  });

  const companyDashboardsQuery = useSearchQsFoldersEntitiesQuery({
    folderIds: [`${envCode?.toLowerCase()}_${currentCompany?.slug}`],
    entityType: QsEntityType.Dashboard
  });

  const companyAnalysesQuery = useSearchQsFoldersEntitiesQuery({
    folderIds: [`${envCode?.toLowerCase()}_${currentCompany?.slug}`],
    entityType: QsEntityType.Analysis
  });

  useEffect(() => {
    dispatch(setPageTitle(t('Common.Insights')));
    dispatch(setBackButton(false));
  }, [dispatch]);

  useEffect(() => {
    // console.debug('DashboardsPage - queries', { tn: tnDashboardsQuery, db: companyDashboardsQuery, an: companyAnalysesQuery });

    if (
      tnDashboardsQuery.isFetching ||
      companyDashboardsQuery.isFetching ||
      companyAnalysesQuery.isFetching
    ) {
      return;
    }

    // console.debug('DashboardsPage - results', { tn: tnDashboardsQuery.data, db: companyDashboardsQuery.data, an: companyAnalysesQuery.data });

    const tnDashboards = tnDashboardsQuery?.data?.map(dashboard => ({
      ...dashboard,
      isCustom: false
    }));

    const companyDashboards = companyDashboardsQuery?.data?.map(dashboard => ({
      ...dashboard,
      isCustom: true
    }));

    const companyAnalyses = companyAnalysesQuery?.data?.map(analysis => ({
      ...analysis,
      isCustom: true
    }));

    const allDashboards = _.orderBy(
      [
        ...(tnDashboardsQuery?.data ? tnDashboards : []),
        ...(canBuilder && companyDashboards ? companyDashboards : []),
        ...(canBuilder && isQsUserAuthor && companyAnalyses ? companyAnalyses : [])
      ],
      ['isCustom', 'entityType', db => db.name.toLowerCase()],
      ['asc', 'desc', 'asc']
    );

    setDashboards(allDashboards);
    setFilteredDashboards(allDashboards);
  }, [tnDashboardsQuery, companyDashboardsQuery, companyAnalysesQuery]);

  useEffect(() => {
    if (
      categories !== undefined &&
      categories !== null &&
      dashboards !== undefined &&
      dashboards !== undefined
    ) {
      handleFilter(categories);
    }
  }, [categories, dashboards]);

  const handleSearch = value => {
    setSearchText(value);

    let filteredDashboards = search(dashboards, value);
    if (categories) {
      filteredDashboards = filter(filteredDashboards, categories);
    }
    if (sortOrder) {
      filteredDashboards = sort(filteredDashboards, sortOrder);
    }

    setFilteredDashboards(filteredDashboards);
    // console.debug('handleSearch', { value, names: filteredDashboards.map(db => db.name), filteredDashboards });
  };

  const handleSort = order => {
    setSortOrder(order);

    const sortedDashboards = sort(filteredDashboards, order);
    setFilteredDashboards(sortedDashboards);
    // console.debug('handleSort', { order, isDescending, names: sortedDashboards.map(db => db.name), sortedDashboards, filteredDashboards });
  };

  const handleFilter = categoryIds => {
    setCategories(categoryIds);

    let filteredDashboards = filter(dashboards, categoryIds);
    if (searchText) {
      filteredDashboards = search(filteredDashboards, searchText);
    }
    if (sortOrder) {
      filteredDashboards = sort(filteredDashboards, sortOrder);
    }

    setFilteredDashboards(filteredDashboards);
    // console.debug('handleFilter', {
    //   categoryIds,
    //   names: filteredDashboards.map(db => db.name),
    //   filteredDashboards,
    //   CategoryFilterDashboards
    // });
  };

  const handleDeleteCard = () => {
    handleFilter(categories);
  };

  const search = (dashboards, searchText) => {
    return dashboards.filter(
      dashboard =>
        dashboard.name.toLowerCase().includes(searchText.toLowerCase()) ||
        (!dashboard.isCustom &&
          t(`Insights.DashboardInfo.${dashboard.name}.Description`)
            .toLowerCase()
            .includes(searchText.toLowerCase()))
    );
  };

  const sort = (dashboards, order) => {
    const isDefault = order === SortOrders.Default;
    const isDescending = order === SortOrders.NameDesc;
    const sortedDashboards = isDefault
      ? _.orderBy(
          dashboards,
          ['isCustom', 'entityType', db => db.name.toLowerCase()],
          ['asc', 'desc', 'asc']
        )
      : dashboards.toSorted(Comparators.String('name', isDescending));

    // console.debug('handleSort', {
    //   dashboards,
    //   order,
    //   isDescending,
    //   names: sortedDashboards.map(db => db.name),
    //   sortedDashboards,
    //   filteredDashboards
    // });
    return sortedDashboards;
  };

  const filter = (dashboards, categoryIds) => {
    return dashboards.filter(dashboard =>
      categoryIds?.reduce(
        (accumulator, categoryId) =>
          accumulator ||
          (categoryId === CategoryFilters.System && !dashboard.isCustom) ||
          (categoryId === CategoryFilters.Published &&
            dashboard.isCustom &&
            dashboard.entityType === QsEntityType.Dashboard.singular) ||
          (categoryId === CategoryFilters.Unpublished &&
            dashboard.isCustom &&
            dashboard.entityType === QsEntityType.Analysis.singular) ||
          (categoryId > CategoryFilters.Unpublished && isDashboardCategory(dashboard, categoryId)),
        false
      )
    );
  };

  return (
    <>
      <DashboardsPageToolbar
        count={filteredDashboards?.length}
        onSearch={handleSearch}
        onSort={handleSort}
        onFilter={handleFilter}
      />

      <List
        className={styles.dashboardList}
        grid={{
          gutter: 12,
          xs: 2,
          sm: 2,
          md: 4,
          lg: 4,
          xl: 4,
          xxl: 5
        }}
        itemLayout={'horizontal'}
        loading={
          tnDashboardsQuery.isFetching ||
          companyDashboardsQuery.isFetching ||
          companyAnalysesQuery.isFetching
        }
        dataSource={filteredDashboards}
        renderItem={dashboard => (
          <List.Item key={dashboard.dashboardId}>
            <DashboardCard
              dashboard={dashboard}
              categories={getDashboardCategoryLabels(dashboard)}
              image={
                dashboard?.isCustom
                  ? dashboard?.dashboardId
                    ? imgCustomDashboard
                    : imgCustomAnalysis
                  : ''
              }
              onDelete={handleDeleteCard}
            />
          </List.Item>
        )}
      />
    </>
  );
};
