import React, { useEffect, useState } from 'react';
import { Modal, Alert, Form, Input } from 'antd';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash';
import { usePublishQsAnalysisMutation } from 'services/nextgen';
import { useUsersList } from 'features/users/usersSlice';
import { openToast } from 'features/toasts/toastsSlice';
import { useSubCompanies } from 'features/company/companySlice';
import { useIsPublishDashboardModalOpen } from 'features/insights/insightsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import MultipleSelectTable from 'components/multipleSelectTable/MultipleSelectTable';
import styles from './modals.module.scss';

export const PublishDashboardModal = ({ analysis, isOpen, onSubmit, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const users = useUsersList();
  const { analysisId } = useParams();
  const isModalOpen = useIsPublishDashboardModalOpen(analysis?.analysisId);
  const subCompanies = useSubCompanies();
  const [form] = Form.useForm();
  const formRef = React.useRef(null);
  const [publishQsAnalysis] = usePublishQsAnalysisMutation();
  const isStandaloneCompany = subCompanies?.length === 1;
  // const envCode = useEnvCode();
  // const { SHOW_PARENT } = TreeSelect;
  // const dashboards = useGetQsFolderEntitiesQuery({
  //     folderId: `${envCode?.toLowerCase()}_${currentCompany?.slug}`,
  //     entityType: QsEntityType.Dashboard
  //   })?.data?.map(dashboard => ({
  //     value: dashboard.name,
  //     label: dashboard.name
  //   }));

  // const [value, setValue] = useState(['0-0-0']);
  // const [replaceDashboard, setReplaceDashboard] = useState([]);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState(
    isStandaloneCompany ? [subCompanies[0]?.id] : []
  );
  const [subCompanyTree, setSubCompanyTree] = useState([]);

  const isDirectorRoute = history?.location?.pathname?.startsWith('/insights/director')
    ? true
    : false;

  useEffect(() => {
    setSubCompanyTree(
      orderBy(
        subCompanies.map(subcompany => ({
          id: subcompany.id,
          label: subcompany.name,
          parent: subCompanies?.find(sc => sc.id === subcompany?.parent?.id)?.name || '-'
        })),
        'parent'
      )
    );

    // console.debug('PublishDashboardModal', { currentCompany, subCompanies, users });
  }, [subCompanies]);

  const handlePublish = () => {
    setIsSubmitLoading(true);
    return new Promise((resolve, reject) => {
      form
        .validateFields()
        .then(values => {
          // console.debug('handlePublish - validateFields success', values);
          publish(
            form.getFieldValue('name'),
            isStandaloneCompany ? [subCompanies[0]?.id] : form.getFieldValue('companyIds'),
            resolve,
            reject
          );
        })
        .catch(error => {
          console.error('handlePublish - validateFields error', error);
          reject();
          setIsSubmitLoading(false);
        });
    });
  };

  const publish = (dashboardName, companyIds, resolve, reject) => {
    let companyPublishPromises = [],
      errors = [];

    companyIds.forEach(companyId => {
      companyPublishPromises.push(
        publishQsAnalysis({
          analysisId: analysis?.analysisId,
          companyId: companyId,
          name: dashboardName
        })
      );
    });

    return Promise.allSettled(companyPublishPromises)
      .then(result => {
        // console.debug('handlePublish', { result });
        if (result.find(result => result.value.error)) {
          errors = result.filter(result => result.value.error).map(result => result.value.error);
          errors.forEach(error => console.error(`handlePublish: ${error.status} - ${error.data}`));
          throw new Error(errors);
        }
        dispatch(
          openToast({
            type: ToastType.Success,
            message: t('Insights.Toasts.PublishDashboardSuccess', {
              name: dashboardName
            })
          })
        );

        let url;
        if (isDirectorRoute) {
          url = `/insights/director/dashboard/${result[0]?.value?.data?.dashboardId}?custom`;
        } else {
          url = `/insights/dashboard/${result[0]?.value?.data?.dashboardId}?custom`;
        }

        analysisId && history.push(url);
        resolve();
      })
      .catch(error => {
        console.error('handlePublish', { error, errors, companyIds });
        dispatch(
          openToast({
            type: errors.length < companyIds.length ? ToastType.Warning : ToastType.Error,
            message: t('Insights.Toasts.PublishDashboardError', {
              name: dashboardName,
              toAllCompanies: errors.length < companyIds.length ? 'to all target companies' : ''
            })
          })
        );
        resolve();
      })
      .finally(() => {
        onClose();
        setIsSubmitLoading(false);
      });
  };

  return (
    <Modal
      className={styles.publishDashboardModal}
      title={t(`Insights.Modals.PublishDashboard.Title`)}
      centered={true}
      visible={isModalOpen || isOpen}
      confirmLoading={isSubmitLoading}
      okText={t(`Insights.Actions.Publish`)}
      cancelText={t('Common.CancelButton')}
      onOk={() => {
        return handlePublish();
      }}
      onCancel={() => {
        setSelectedCompanies(isStandaloneCompany ? [subCompanies[0]?.id] : []);
        formRef.current?.resetFields();
        onClose();
      }}
      width={700}
    >
      <Alert
        type="info"
        message={t(`Insights.Modals.PublishDashboard.Alert`, {
          companyType: (isStandaloneCompany
            ? t(`Common.Company`)
            : t(`Common.Companies`)
          ).toLowerCase()
        })}
      />

      <Form
        form={form}
        ref={formRef}
        layout={'vertical'}
        preserve={false}
        initialValues={{
          name: analysis?.name
        }}
      >
        <Form.Item
          required
          name="name"
          label={t(`Insights.Modals.DashboardNameLabel`)}
          rules={[
            { required: true, message: t(`Insights.Modals.PublishDashboard.DashboardNameRequired`) }
          ]}
        >
          <Input placeholder={analysis?.name} />
        </Form.Item>

        {/*
        <Form.Item
          name="replaceDashboard"
          label="Replace Dashboard"
        >
          <Select
            allowClear
            options={dashboards}
            placeholder={'Select dashboard to replace'}
            defaultValue={'Select dashboard to replace'}
            onChange={value => {
              console.debug('PublishDashboardModal - Replace Dashboard changed', value);
              setReplaceDashboard(value);
            }}
            onSelect={value => {
              console.debug('PublishDashboardModal - Replace Dashboard selected', value);
              setReplaceDashboard(value);
            }}
          />
        </Form.Item>

        <Form.Item
          required
          name="targetCompanies"
          label="Companies"
          rules={[{ required: true, message: 'Please select target companies' }]}
        >
          <TreeSelect
            treeData={treeData}
            value
            onChange={(newValue) => {
              console.debug('TreeSelect - onChange ', value);
              setValue(newValue);
            }}
            treeCheckable={true}
            showCheckedStrategy={SHOW_PARENT}
            placeholder={'Please select'}
          />
        </Form.Item>
        */}

        {!isStandaloneCompany && (
          <Form.Item
            name="companyIds"
            label={t(`Common.Companies`)}
            rules={[
              { required: true, message: t(`Insights.Modals.PublishDashboard.CompaniesRequired`) }
            ]}
          >
            <MultipleSelectTable
              form={form}
              isLoading={!subCompanyTree.length}
              selected={selectedCompanies}
              options={subCompanyTree.map(company => ({
                id: company.id,
                firstColumnValue: company.label,
                secondColumnValue: company.parent,
                ...(users.length
                  ? {
                      thirdColumnValue: users
                        .filter(user => user?.company?.id === company?.id)
                        .length.toString()
                    }
                  : {})
              }))}
              properties={{
                mode: 'multiple',
                placeholder: t(`Insights.Modals.PublishDashboard.CompaniesPlaceholder`),
                columnHeaderLabels: {
                  firstColumnValue: 'Company',
                  secondColumnValue: 'Parent Company',
                  ...(users.length ? { thirdColumnValue: 'Users' } : {})
                }
              }}
              onChange={selectedIds => {
                setSelectedCompanies(selectedIds);
                // console.debug('onCompaniesSelected', selectedIds);
              }}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};
