import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  logout,
  resetRemoteSignIn,
  useCurrentAdminLoggedIn,
  useUser
} from '../../features/user/userSlice';
import Auth from 'utils/auth';
import { logoutTypes } from 'containers/SSO/logoutTypes';

const Logout = props => {
  const dispatch = useDispatch();
  const currentUser = useUser();
  const currentAdminUser = useCurrentAdminLoggedIn();
  const keycloakConfig =
    currentUser?.auth?.keycloakConfig || currentAdminUser?.auth?.keycloakConfig;

  let redirectUrl = '/login';
  if (keycloakConfig != null) {
    redirectUrl = '/sso';
    if (keycloakConfig?.realm) {
      redirectUrl += `?realm=${keycloakConfig.realm}&logoutType=${logoutTypes.MANUAL}`;
    }
    if (window.keycloak?.logout) {
      window.keycloak.logout({
        redirectUri: window.location.origin + redirectUrl
      });
    }
  }

  useEffect(() => {
    dispatch(logout());
    dispatch(resetRemoteSignIn());
  }, []);
  Auth.removeSupportTypeValue();
  Auth.removeSearchText();
  Auth.removeOnStatus();
  Auth.removeFromDate();
  Auth.removeToDate();

  console.log('redirect url is ' + redirectUrl);
  return <Redirect to={redirectUrl} />;
};

export default Logout;
