import { useEffect, useState } from 'react';
import { getGPSByAddress } from 'utils/geo';

export function useGPSByAddress(location = '', when = true) {
  const [gps, setGPS] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    if (!gps && !isLoading && !!when) {
      setIsLoading(true);
      const { lat, lng } = await getGPSByAddress(location);
      setGPS({
        lat,
        lng
      });
      setIsLoading(false);
    }
  }, [location, isLoading, when]);
  return {
    gps,
    isLoading
  };
}
