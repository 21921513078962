import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { Button } from 'antd';

//components
import { LoadingTable } from 'components/grid/LoadingTable';
import InfoTable from 'components/form/info-table/InfoTable';
import FormTitle from 'components/form/form-title/FormTitle';
import Map, { MapMode } from 'components/map/Map';

//slices & hooks
import { useLocations, fetchLocations } from 'features/locations/locationsSlice';
import { fetchGeofences, useGeofences } from 'features/geofences/geofencesSlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { openToast } from 'features/toasts/toastsSlice';
import { useUserKey } from 'features/user/userSlice';
import { useLocalization } from 'features/localization/localizationSlice';

//methods & helpers
import { getGeofenceShape } from '../Geofences/helpers';
import moment from 'moment';
import request from 'superagent';

//constants
import { API_PATH } from 'config';
import { geofenceLinkCellRenderer } from './CellRenderers';

import styles from './Locations.module.scss';
import './Locations.scss';
import { BUTTON_IDS } from 'utils/globalConstants';
import { parseErrorMessage } from 'utils/strings';

export const SelectGeofences = () => {
  const indexBeginingId = window.location.pathname.lastIndexOf('/');
  const id = window.location.pathname.substr(
    indexBeginingId + 1,
    window.location.pathname.length - 1
  );
  const { t } = useTranslation();
  const locations = useLocations();
  const geofences = useGeofences();
  const { isFetching } = useSelector(state => state.geofences.meta);
  const userKey = useUserKey();
  const [locationData, setLocationData] = useState(null);
  const [filteredGeofences, setFilteredGeofences] = useState(null);
  const [selectedGeofences, setSelectedGeofences] = useState([]);
  const [locationCoordinates, setLocationCoordinates] = useState({
    GPS: { Lat: 37.7576948, Lng: -122.4726192 }
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const localization = useLocalization();

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  useEffect(() => {
    if (locations && locations.length > 0) {
      const location = locations.find(loc => parseInt(loc.id, 10) === parseInt(id, 10));
      setLocationData(location);
      const { GPS } = location;
      if (
        GPS &&
        GPS.Lat &&
        GPS.Lng &&
        GPS.Lat >= -90 &&
        GPS.Lat <= 90 &&
        GPS.Lng >= -180 &&
        GPS.Lng <= 180
      ) {
        setLocationCoordinates({
          GPS: { Lat: location.GPS.Lat, Lng: location.GPS.Lng }
        });
      }
    }
  }, [locations, id]);

  useEffect(() => {
    if (locationData && locationData.name) {
      const address = locationData.address;
      let addressTitle;
      if (address) {
        const { number, street, suburb, country } = address;
        addressTitle = `${number} ${street ? street + ',' : ''} ${
          suburb ? suburb + ',' : ''
        } ${country}`;
      }
      dispatch(setPageTitle(`${locationData.name} ${addressTitle ? ' | ' + addressTitle : ''}`));
    }
  }, [locationData, dispatch]);

  useEffect(() => {
    if (locationData && locationData.geofences) {
      // Exclude the geofences that are not of type USER and exclude the assigned geofences to the location
      const userTypeGeofences = geofences.filter(
        geofence => geofence.type === 'USER' || geofence.type === 'TRAFFIC'
      );
      const existingGeofencesArray = locationData.geofences.map(geofence => geofence.id);

      setSelectedGeofences(existingGeofencesArray);
      setFilteredGeofences(userTypeGeofences);
    }
  }, [geofences, locationData]);

  if (!locationData || !geofences) {
    return <LoadingTable columnSizes={[400]} />;
  }

  const geofencesColumns = [
    {
      label: t('Locations.View.Select'),
      width: 100,
      type: 'select'
    },
    {
      label: t('Locations.View.Name'),
      width: 300,
      cellDataGetter: ({ rowData }) => rowData.name,
      cellRenderer: geofenceLinkCellRenderer
    },
    {
      label: t('Locations.View.Shape'),
      width: 300,
      cellDataGetter: ({ rowData }) => getGeofenceShape(rowData)
    },
    {
      label: `${t('Locations.View.Area')}, (${localization.formats.area.unit})`,
      width: 300,
      cellDataGetter: ({ rowData }) =>
        rowData.area_sqm ? localization.convertArea(rowData.area_sqm) : ''
    }
  ];

  const onSubmit = (values, actions) => {
    const newGeofencesArray = [...selectedGeofences];
    const submitValues = {
      locationId: locationData.id,
      geofenceIds: newGeofencesArray,
      updatedAt: moment().format()
    };

    let url = `${API_PATH}/locations/${locationData.id}/geofences`;
    let method = 'POST';
    let successMessage = t('Locations.View.GeofencesAdded');

    request(method, url)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .send(submitValues)
      .then(res => {
        dispatch(
          openToast({
            type: ToastType.Success,
            message: successMessage
          })
        );
        dispatch(fetchLocations());
        dispatch(fetchGeofences());
        actions.setSubmitting(false);
        history.goBack();
      })
      .catch(err => {
        actions.setSubmitting(false);
        dispatch(
          openToast({
            type: ToastType.Error,
            message: parseErrorMessage(err)
          })
        );
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        flex: '1 0 0',
        flexDirection: 'column'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '32px',
          color: '#181c21'
        }}
        className="vehicleView"
      >
        {filteredGeofences && (
          <Row style={{ height: '400px', margin: '0' }}>
            <Map
              mode={MapMode.Geofence}
              location={{
                lat: locationCoordinates && locationCoordinates.GPS.Lat,
                lng: locationCoordinates && locationCoordinates.GPS.Lng
              }}
              geofences={filteredGeofences}
              containerElement={<div style={{ height: `100%`, width: `100%` }} />}
              mapElement={<div style={{ height: `100%`, width: `100%` }} />}
            />
          </Row>
        )}
        <FormTitle title={t('Locations.View.SelectGeofences')} underlined />
        <div style={{ flex: '1 0 0', minHeight: '40vh', marginBottom: '50px' }}>
          <InfoTable
            data={filteredGeofences || []}
            columns={geofencesColumns}
            styles={styles}
            enableSelectColumn
            checkedItems={selectedGeofences}
            setCheckedItems={setSelectedGeofences}
            isLoading={isFetching}
            customRowStyle={{ alignItems: 'center' }}
          />
        </div>
        <Formik initialValues={{ locationId: locationData.id }} onSubmit={onSubmit}>
          {({ isSubmitting, isValid }) => (
            <Form>
              <div className={styles.formFooter}>
                <Button
                  type="primary"
                  id={BUTTON_IDS.selectGeofencesSave}
                  htmlType="submit"
                  disabled={!isValid || isSubmitting}
                >
                  {t('Common.SaveButton')}
                </Button>
                <Button id={BUTTON_IDS.selectGeofencesCancel} onClick={history.goBack}>
                  {t('Common.CancelButton')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
