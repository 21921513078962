import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useUserKey } from 'features/user/userSlice';
import { API_PATH } from 'config';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { Button } from 'antd';
import i18next from 'i18next';
import request from 'superagent';
import { journeyfolderlessFolderFile } from '../Easydocs/constants';
//Hooks
import { useSubCompanies, useCurrentCompany } from 'features/company/companySlice';
import {
  useDeletedJourneys,
  useIsFetching as useIsJourneysFetching,
  restoreJourneyApi
} from 'features/journeyPlanner/journeysSlice';
import {
  useJourneyFolders,
  fetchJourneyFolders,
  setJourneyActiveFolder as setPrevJourneyActiveFolder
} from 'features/easydocs/foldersSlice';
import { useIsFetching as useIsFleetsFetching } from 'features/fleets/fleetsSlice';
import useDebounce from 'utils/hooks/useDebounce';
import ContentFolder from '../JourneyPlanner/ContentFolder';

//Methods
import { JOURNEY_TYPE } from './constants';
import { sortBy, toLower } from 'lodash';
//Components
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import AntMultiselect, {
  formatListForMultiselect
} from 'components/form/antMultiselect/AntMultiselect';
import { cache } from './CellRenderers';
import { deleteJourneyApi } from 'features/journeyPlanner/journeysSlice';
import { useTranslation } from 'react-i18next';
import iconAddFolder from '../../static/images/icons/add-folder-icon.svg';
import ListItems from '../JourneyPlanner/ListItems';
import { BUTTON_IDS } from 'utils/globalConstants';

export const JourneyPlannerNew = ({ journeys, fleets, localization }) => {
  const company = useCurrentCompany();
  const userKey = useUserKey();
  const allFolders = useJourneyFolders();
  const path = window.location.pathname;
  const filterPath = path.substr(path.lastIndexOf('/') + 1, path.length - 1);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const isJourneysFetching = useIsJourneysFetching();
  const isFleetsFetching = useIsFleetsFetching();
  const isFetching = isJourneysFetching || isFleetsFetching;

  const subCompanies = useSubCompanies();
  const deletedJourneys = useDeletedJourneys();
  const [tableRef, setTableRef] = useState(null);
  const [filterTab, setFilterTab] = useState(filterPath);
  const [filterFleets, setFilterFleets] = useState([]);
  const [filterText, setFilterText] = useState('');
  const debouncedSearchText = useDebounce(filterText, 300);
  const [filterCompanies, setFilterCompanies] = useState([]);
  const [folders, setFolders] = useState([]);
  const [foldersForForms, setFoldersForForms] = useState([]);
  const [newActiveFolder, setNewActiveFolder] = useState({ name: '' });
  const [folderAdded, setFolderAdded] = useState(false);
  const [activeFolder, setActiveFolder] = useState({ name: '' });
  const [editFolderName, setEditFolderName] = useState('');
  const [contentJourneys, setContentJourneys] = useState([]);

  const handleFolderClick = folder => {
    setActiveFolder(folder);
    dispatch(setPrevJourneyActiveFolder(folder));
  };

  useEffect(() => {
    let currentFolderJourneys = [];
    if (activeFolder?.id === 'all-journeys') {
      currentFolderJourneys = journeys?.filter(journey => true); // This includes all journeys
      setFilterTab('');
    } else if (activeFolder?.id === 'deleted') {
      currentFolderJourneys = deletedJourneys;
      setFilterTab(activeFolder?.id);
    } else {
      // Normal folder filtering
      currentFolderJourneys = journeys?.filter(journey => {
        const isInActiveFolder =
          journey.folders && journey?.folders.find(folder => folder?.id === activeFolder?.id);
        const isFolderless = !journey.folders || journey.folders.length === 0;
        return activeFolder?.name !== journeyfolderlessFolderFile(t).name
          ? isInActiveFolder
          : isFolderless;
      });
      setFilterTab('');
    }

    if (debouncedSearchText) {
      setEditFolderName('');
      currentFolderJourneys = currentFolderJourneys.filter(
        journey =>
          toLower(journey.name || journey.filename).indexOf(toLower(debouncedSearchText)) > -1
      );
    }

    setContentJourneys(
      sortBy(currentFolderJourneys, [journey => toLower(journey.name || journey.filename)])
    );
  }, [activeFolder, journeys, debouncedSearchText, t]);

  useEffect(() => {
    if (folderAdded) {
      const newFolder = allFolders.find(folder => folder.name === newActiveFolder);
      setActiveFolder(newFolder);
      setFolderAdded(false);
    }
  }, [allFolders]);

  useEffect(() => {
    if (history.location.state?.folder) {
      const historyFolder = allFolders?.find(
        folder => folder.id === history.location.state.folder.id
      );
      setEditFolderName(historyFolder?.name);
    }
  }, [allFolders, history]);

  useEffect(() => {
    let allJourneysFolder = {
      id: 'all-journeys',
      name: t('Easydocs.AllJourneys'),
      companyId: company.id,
      description: ' '
    };
    const editFolder = allFolders.find(folder => folder.name === editFolderName);
    let allFoldersCopy = [
      { ...allJourneysFolder },
      { ...journeyfolderlessFolderFile(t), companyId: company.id },
      ...allFolders
    ];
    setFolders([...allFoldersCopy]);
    if (editFolder) {
      setActiveFolder(editFolder);
    } else if (!folderAdded) {
      setActiveFolder(allFoldersCopy[0]);
    }
    // Make the folder options for forms in select
    const formFolders = allFolders
      .filter(folder => folder.id)
      .map(folder => ({
        ...folder,
        value: folder.id,
        label: folder.name,
        //if in edit, check the ones that are already in the geofence
        checked: false
      }));
    setFoldersForForms(formFolders);
  }, [allFolders, company.id, editFolderName, t]);

  useEffect(() => {
    dispatch(setPageTitle(t('JourneyPlanner.NewTitle')));
    dispatch(setBackButton(false));
  }, [dispatch, t]);

  useEffect(() => {
    setFilterCompanies(
      formatListForMultiselect([{ id: 0, name: `${t('Common.AllCompanies')}` }, ...subCompanies])
    );
  }, [subCompanies, t]);

  useEffect(() => {
    const subCompaniesIdsFromFilter = filterCompanies
      .filter(comp => comp.checked)
      .map(comp => comp.id);
    const filteredFleets = fleets
      .filter(
        fleet => fleet.id && fleet.company && subCompaniesIdsFromFilter.includes(fleet.company.id)
      )
      .map(fleet => {
        return {
          name: fleet.name,
          checked: true,
          id: fleet.id
        };
      });
    const formattedFleets = formatListForMultiselect([
      { id: 0, name: `${t('Common.AllFleets')}` },
      ...filteredFleets,
      { id: -1, name: `${t('Common.NoFleets')}` }
    ]);
    setFilterFleets(formattedFleets);
  }, [fleets, filterCompanies, history, t]);

  const restoreJourney = data => {
    dispatch(restoreJourneyApi(data));
  };

  const deleteModal = data => {
    const deleteJourney = () => {
      dispatch(deleteJourneyApi(data));
    };

    confirmationModal(
      `${t('Common.DeleteButton')} ${data.name}`,
      `${t('Common.SureDelete')} ${t('Common.journey')} ${data.name}?`,
      t('Common.DeleteButton'),
      t('Common.CancelButton'),
      deleteJourney,
      'delete'
    );
  };

  const addFolder = () => {
    const newFoldersName = allFolders.filter(folder => folder.name.includes('New Folder'));
    const newFolderNameStrictly = allFolders.find(folder => folder.name === 'New Folder');
    let folderName = `${t('Easydocs.NewFolderSimple')}`;
    let counter = 0;
    /* eslint-disable no-loop-func */
    if (newFoldersName.length && newFolderNameStrictly) {
      do {
        counter++;
        folderName = `${t('Easydocs.NewFolder')}${counter})`;
      } while (allFolders.some(folder => folder.name === folderName));
    }
    const postBody = {
      name: folderName,
      description: '',
      entityType: 'Ng::Trip'
    };

    request('POST', API_PATH + `/folders?company_id=${company.id}`)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .send(postBody)
      .then(() => {
        setEditFolderName('');
        dispatch(fetchJourneyFolders());
        dispatch(
          openToast({
            type: ToastType.Success,
            message: i18next.t('Easydocs.Notifications.NewFolderNotification', {
              name: folderName || 'New Folder'
            }),
            autoHide: true
          })
        );
      })
      .catch(err => {
        console.warn('ERROR', err);
      });

    setNewActiveFolder(folderName);
    setFolderAdded(true);
  };
  const handleAddButton = () => {
    history.push('/journeyplanner/add');
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          borderBottom: '3px solid #f2f2f2'
        }}
      >
        <Button
          type="primary"
          style={{ marginBottom: '10px', marginRight: '10px' }}
          size="large"
          id={BUTTON_IDS.journeyPlannerAdd}
          onClick={handleAddButton}
        >
          {t('JourneyPlanner.Buttons.AddJourney')}
        </Button>
      </div>

      <div style={{ display: 'flex', overflow: 'scroll' }}>
        {activeFolder && (
          <ListItems
            listValues={folders}
            activeItem={activeFolder.id}
            onClickItem={handleFolderClick}
            header={t('Easydocs.Folders')}
            addItemIcon={iconAddFolder}
            onClickAddItem={addFolder}
          />
        )}

        {folders.length > 0 && (
          <ContentFolder
            activeFolder={activeFolder}
            listValues={folders}
            setEditFolderName={setEditFolderName}
            company={company}
            fleets={fleets}
            userKey={userKey}
            journeys={contentJourneys}
            setTableRef={setTableRef}
            foldersForForms={foldersForForms}
            localization={localization}
            deleteModal={deleteModal}
            filterTab={filterTab}
            restoreJourney={restoreJourney}
          />
        )}
      </div>
    </>
  );
};
