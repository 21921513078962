/* global google */
import moment from 'moment';
import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
  useImperativeHandle,
  forwardRef
} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Donut from 'components/visualization/Donut';
import Gauge from 'components/visualization/Gauge';
import Map, { MapMode } from 'components/map/Map';

import { DashboardTable } from './DashboardGrid';
import {
  useCurrentCompany,
  useSubCompanyEntityConfig,
  CompanyConfigKey,
  CompanyConfigValue,
  useCompanyGeofenceProviders
} from 'features/company/companySlice';
import { useUserKey, useUser } from 'features/user/userSlice';
import {
  actionTypes,
  useDashboardSummaryData,
  useCompaniesFleet,
  fetchDashboardData,
  fetchCompanyFleetData
} from 'features/dashboard/dashboardSlice';
import { useNotificationsData } from 'features/notifications/notificationsSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { useDevices, useIsFetchingDevices } from 'features/devices/devicesSlice';
import { useVehiclesStats } from 'features/vehicles/vehiclesStatsSlice';
import {
  useUserPreferences,
  setUserPreferences,
  updatePreferences
} from 'features/user/userPreferencesSlice';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import colors from 'static/scss/_colors.scss';
import { isMessagingEnabledDevice, isRouteToEnabledDevice } from 'containers/Messaging/helpers';
import { getFleetsWithDynamicLinkVehicleDevice } from 'features/vehicles/hooks';
import { vehicleActiveDeviceComparison } from 'containers/Tracking/helpers';
import { prepareDataForMultiselect } from 'utils/filters';
import { useIsFetching as useIsDriversFetching, useDrivers } from 'features/users/usersSlice';
import { useIQCameraUser } from 'features/permissions';
import { useGeofences } from 'features/geofences/geofencesSlice';
import { filterGeofencesByShowTypes } from 'features/geofences/geofencesUtil';
import { showGeofenceType } from 'containers/Settings/constants';
import { useDevicesStats, useIsFetchingDevicesStats } from 'features/devices/devicesStatsSlice';

const NO_FLEET_ID = 0xffffffff;

const _FilterSetsSupportedMap = forwardRef(
  ({ devices, userPreferences, onMapTypeIdChanged, ...extraProps }, ref) => {
    const [state, setState] = useState({
      filteredDevices: [],
      focusedDeviceId: null,
      clickedDeviceId: null,
      mapCenter: null,
      zoomLevel: null
    });
    const geofences = useGeofences();
    const geofenceProviders = useCompanyGeofenceProviders();

    const mapDashboardRef = useRef(null);

    const onDeviceClicked = deviceId => {
      setState(prevState => ({
        ...prevState,
        clickedDeviceId: deviceId
      }));
    };

    const handleMapUpdate = mapRef => () => {
      const { map } = mapRef.current.state;
      const center = map.getBounds() && map.getBounds().getCenter();
      const { zoom } = map;
      setState(prevState => ({
        ...prevState,
        mapCenter: center,
        zoomLevel: zoom
      }));
    };

    const setMapCenter = center => {
      setState(prevState => ({
        ...prevState,
        mapCenter: center
      }));
    };

    const setZoomLevel = zoom => {
      setState(prevState => ({
        ...prevState,
        zoomLevel: zoom
      }));
    };

    useImperativeHandle(ref, () => ({
      onSetFocusedId: deviceId => {
        setState(prevState => ({
          ...prevState,
          focusedDeviceId: deviceId
        }));
      }
    }));

    useEffect(() => {
      setState(prevState => ({
        ...prevState,
        filteredDevices: devices
      }));
    }, [devices]);

    return (
      <Map
        {...extraProps}
        ref={mapDashboardRef}
        mode={MapMode.Devices}
        devices={state.filteredDevices}
        geofences={filterGeofencesByShowTypes(
          geofences,
          showGeofenceType(userPreferences),
          geofenceProviders
        )}
        enableMapMenu={true}
        enableInfoWindowActions={false}
        enableVehicleClustering={userPreferences?.clustering}
        clickedDevice={state.clickedDeviceId}
        focusedDevice={state.focusedDeviceId}
        onDeviceClicked={onDeviceClicked}
        mapOptions={{
          mapTypeId: userPreferences?.mapType || google.maps.MapTypeId.ROADMAP,
          zoom: state.zoomLevel || extraProps.location?.zoom,
          center:
            state.mapCenter ||
            (state.filteredDevices.length === 0
              ? { lat: extraProps.defaultCenter?.lat, lng: extraProps.defaultCenter?.lng }
              : state.mapCenter)
        }}
        onZoomChanged={handleMapUpdate(mapDashboardRef)}
        onDragEnd={handleMapUpdate(mapDashboardRef)}
        setZoomLevel={setZoomLevel}
        setMapCenter={setMapCenter}
        onMapTypeIdChanged={() => onMapTypeIdChanged(mapDashboardRef)}
        containerElement={<div style={{ height: `100%`, width: `100%`, position: 'relative' }} />}
        mapElement={<div style={{ height: `100%`, width: `100%` }} />}
      />
    );
  }
);

const FilterSetsSupportedMap = React.memo(_FilterSetsSupportedMap);

export const DashboardTab = () => {
  const currentCompany = useCurrentCompany();
  const areMassColumnsEnabled = currentCompany.country === 'AU';
  const nonBusinessCompanyConfig = useSubCompanyEntityConfig(
    currentCompany?.id,
    CompanyConfigKey.HideNonBusiness
  );
  const dispatch = useDispatch();
  const userKey = useUserKey();
  const alertsData = useNotificationsData();
  const summaryData = useDashboardSummaryData();
  const companiesFleetData = useCompaniesFleet();
  const deviceServices = useDevices('services');
  const isFetchingDeviceStats = useIsFetchingDevicesStats();
  const isFetchingDeviceServices = useIsFetchingDevices();
  const isFetchingDrivers = useIsDriversFetching();
  const drivers = useDrivers();
  const deviceStats = useDevicesStats();
  const vehiclesStats = useVehiclesStats();
  const localization = useLocalization();
  const userPreferences = useUserPreferences();
  const user = useUser();
  const [vehicleDevices, setVehicleDevices] = useState({
    active: 0,
    inactive: 0
  });
  const { canAccessNonCameraFeatures } = useIQCameraUser();

  const { t } = useTranslation();

  useEffect(() => {
    if (currentCompany.id == null) {
      return;
    }
    dispatch(fetchDashboardData(userKey, currentCompany.id));
    dispatch(fetchCompanyFleetData(userKey, currentCompany.id, 'fleets,vehicles,devices'));
  }, [userKey, currentCompany, dispatch]);

  const currentCompanyFleetData = useMemo(
    () =>
      getFleetsWithDynamicLinkVehicleDevice({
        fleets: companiesFleetData?.data?.[currentCompany?.id] || [],
        vehiclesStats,
        devicesStats: deviceStats
      }),
    [vehiclesStats, companiesFleetData, currentCompany, deviceStats]
  );

  const onMapTypeIdChanged = mapType => {
    const newPreferences = {
      ...userPreferences,
      mapType
    };
    setUserPreferences(newPreferences, user.id, userKey)
      .then(() => {
        dispatch(updatePreferences(newPreferences));
      })
      .catch(e => {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: `${t('Preferences.Save.ErrorMessage')}.`
          })
        );
      });
  };

  const dashboardData = useMemo(() => {
    const fleetsList = [];
    const vehiclesList = [];
    const devicesList = [];
    const fleetsVehiclesDevicesMap = {};

    if (
      isFetchingDeviceServices ||
      isFetchingDrivers ||
      summaryData.status.fetching === actionTypes.processing ||
      companiesFleetData.status[currentCompany.id] == null ||
      companiesFleetData.status[currentCompany.id].fetching === actionTypes.processing ||
      isFetchingDeviceStats
    ) {
      return {
        companies: [],
        fleets: fleetsList,
        alerts: alertsData,
        fleetsVehiclesDevicesMap: fleetsVehiclesDevicesMap,
        vehicles: vehiclesList,
        devices: devicesList,
        isLoading: true
      };
    }

    const devicesHaveAlerts = {};
    const curCompanyId = currentCompany.id;
    const sixHoursAgo = moment().valueOf() - 21600000;

    alertsData.forEach(al => {
      if (
        al.event == null ||
        al.event.device == null ||
        al.event.company == null ||
        al.event.company.id !== curCompanyId
      )
        return;
      if (devicesHaveAlerts[al.event.device.id] == null) {
        devicesHaveAlerts[al.event.device.id] = 1;
      }
    });

    let vehicleCountMap = {};
    let deviceCountMap = {};

    if (currentCompanyFleetData != null) {
      const fleetsData = currentCompanyFleetData;
      fleetsData.forEach(fl => {
        let fleetId = fl.id;

        if (!(fl.company?.id === curCompanyId || !fleetId)) return;

        if (fleetId == null) {
          fleetId = NO_FLEET_ID;
          fleetsList.push({ id: fleetId, name: t('Home.No Fleet') });
        } else {
          fleetsList.push({ id: fl.id, name: fl.name });
        }

        fleetsVehiclesDevicesMap[fleetId] = { ...fl, vehicles: {} };

        // process vehicle list
        fl.vehicles.forEach(ve => {
          if (ve.id && ve.companyId !== curCompanyId) return;

          const vehicleId = ve.id || 'NoVehicle';

          if (ve.id) {
            vehicleCountMap[ve.id] = vehicleCountMap[ve.id] | false;
          }

          const vehicleDefaultDriver =
            ve.defaultDriver &&
            drivers.find(d => parseInt(d.id, 10) === parseInt(ve.defaultDriver, 10));
          fleetsVehiclesDevicesMap[fleetId].vehicles[vehicleId] = {
            ...ve,
            vehicleDefaultDriver,
            devices: {}
          };
          let active = false;
          // process devices
          ve.devices &&
            ve.devices.forEach(dv => {
              if (dv.companyId !== curCompanyId) return;
              const deviceObject = {
                ...dv,
                vehicleId: vehicleId,
                vehicleDefaultDriver,
                hasAlert: devicesHaveAlerts[dv.id] != null, // check if the device has alert
                fleetId: fleetId,
                deviceStats: Object.assign({}, deviceStats.find(i => i.deviceId === dv.id) || {}),
                services: deviceServices[dv.id]?.services || [],
                markerType: fl.iconType,
                markerSize: fl.iconSize,
                markerColor: fl.colour
              };
              // add device
              devicesList.push(deviceObject);
              fleetsVehiclesDevicesMap[fleetId].vehicles[vehicleId].devices[dv.id] = deviceObject;

              if (
                deviceObject.deviceStats?.lastCommsAt &&
                moment(deviceObject.deviceStats.lastCommsAt).valueOf() >= sixHoursAgo
              ) {
                active = true;
              }

              if (!ve.id) {
                deviceCountMap[dv.id] = deviceCountMap[dv.id] | active;
                active = false;
              }
              // deviceObject.services?.includes('MESSAGING') && console.debug('deviceObject.services', deviceObject.name, deviceObject.services);
            });
          if (active && ve.id) {
            vehicleCountMap[ve.id] = true;
          }

          // add vehicle
          vehiclesList.push({
            ...ve,
            fleetId: fleetId
          });
        });
      });
    }
    const activeVehicleCount = Object.values(vehicleCountMap).filter(v => v).length;
    const activeDeviceCount = Object.values(deviceCountMap).filter(v => v).length;
    setVehicleDevices({
      active: activeVehicleCount + activeDeviceCount,
      inactive:
        Object.keys(vehicleCountMap).length +
        Object.keys(deviceCountMap).length -
        activeVehicleCount -
        activeDeviceCount
    });

    // deviceServices && console.debug('deviceServices', deviceServices);
    // deviceServices && console.debug('deviceServices.includes(MESSAGING)', Object.values(deviceServices).filter(device => device.services.includes('MESSAGING')));
    // deviceServices && console.debug('deviceServices.includes(SMARTNAV3D)', Object.values(deviceServices).filter(device => device.services.includes('SMARTNAV3D')));

    return {
      companies: summaryData.data,
      fleets: fleetsList,
      alerts: alertsData,
      fleetsVehiclesDevicesMap: fleetsVehiclesDevicesMap,
      vehicles: vehiclesList,
      devices: devicesList,
      isLoading: false
    };
  }, [
    currentCompany,
    summaryData,
    companiesFleetData,
    alertsData,
    deviceStats,
    deviceServices,
    t,
    currentCompanyFleetData,
    isFetchingDrivers,
    isFetchingDeviceStats,
    drivers
  ]);

  const [alertGaugeSelectedLabel, setAlertGaugeSelectedLabel] = useState(false);

  const [filterFleets, setFilterFleets] = useState([]);

  const generateFleetsForTable = useMemo(() => {
    const checkedFleets = filterFleets.filter(fleet => fleet.checked);
    const fleetsOptions = dashboardData.fleets
      .filter(f => f.id !== NO_FLEET_ID)
      .sort((a, b) => a.name.localeCompare(b.name));
    const NoFleet = dashboardData.fleets.find(f => f.id === NO_FLEET_ID);
    if (NoFleet) {
      fleetsOptions.push(NoFleet);
    }
    return prepareDataForMultiselect(
      fleetsOptions,
      t('Common.AllFleets'),
      filterFleets.length ? checkedFleets.map(f => f.id) : null
    );
  }, [dashboardData.fleets, filterFleets]);

  const [searchText, setSearchText] = useState(null);
  const [filters, setFilters] = useState(null);

  const [vehicleChartData, setVehicleChartData] = useState({ selectedId: -1 });
  const mapRef = useRef();

  const handleDeviceFocused = useCallback(
    deviceId => {
      mapRef.current.onSetFocusedId(deviceId);
    },
    [mapRef]
  );

  const handleDeviceBlurred = useCallback(
    deviceId => {
      mapRef.current.onSetFocusedId(-1);
    },
    [mapRef]
  );

  const handleDeviceSelected = useCallback(
    device => {
      mapRef.current.onSetFocusedId(device.id);
    },
    [mapRef]
  );

  const handleRowMouseOver = useCallback(
    ({ rowData }) => {
      mapRef.current.onSetFocusedId(rowData['deviceId']);
    },
    [mapRef]
  );

  const handleRowClicked = useCallback(
    ({ rowData }) => {
      mapRef.current.onSetFocusedId(rowData['deviceId']);
    },
    [mapRef]
  );

  function getSelectedCompany() {
    if (dashboardData.companies.length === 0) {
      return null;
    } else {
      return dashboardData.companies.find(c => c.companyId === currentCompany.id);
    }
  }

  function generateVehicleChartData() {
    const [active, inactive] = [vehicleDevices.active, vehicleDevices.inactive];
    return [
      {
        id: 1,
        angle: isNaN(active) ? 0 : +active,
        color: colors.jungleGreen,
        mousehoverColor: colors.puertoRico,
        message: t('Home.ActiveTitle')
      },
      {
        id: 2,
        angle: isNaN(inactive) ? 0 : +inactive,
        color: colors.dustyGrayDark,
        mousehoverColor: colors.darkGray,
        message: t('Home.InactiveTitle')
      }
    ];
  }

  function generateDriverChartData() {
    const curCompany = getSelectedCompany();
    const [onduty, offduty] = (curCompany && [
      curCompany.activeDrivers,
      curCompany.drivers - curCompany.activeDrivers
    ]) || [0, 0];
    return [
      {
        id: 1,
        angle: isNaN(onduty) ? 0 : +onduty,
        color: colors.jungleGreen,
        mousehoverColor: colors.puertoRico,
        message: t('Home.OndutyTitle')
      },
      {
        id: 2,
        angle: isNaN(offduty) ? 0 : +offduty,
        color: colors.dustyGrayDark,
        mousehoverColor: colors.darkGray,
        message: t('Home.OffdutyTitle')
      }
    ];
  }

  const mapData = useMemo(generateMapMarkerData, [
    generateFleetsForTable,
    vehicleChartData,
    alertGaugeSelectedLabel,
    dashboardData.fleetsVehiclesDevicesMap,
    searchText,
    filters
  ]);

  function generateMapMarkerData() {
    const devices = getVehiclesAndDevices();

    let filteredDevices = devices.filter(dt => {
      let isSelect = true;
      if (filters != null && Object.getOwnPropertyNames(filters).length > 0) {
        for (let key in filters) {
          const groupSets = filters[key];
          if (Object.getOwnPropertyNames(groupSets).length === 0) {
            continue;
          }
          for (let value in filters[key]) {
            if (key === 'VEHICLE') {
              isSelect = dt.name === value;
            } else if (key === 'DRIVER') {
              const userName =
                dt.deviceStats?.currentUser?.firstName +
                ' ' +
                dt.deviceStats?.currentUser?.lastName;
              isSelect = userName === value;
            } else if (key === 'COMMS STATUS') {
              isSelect = getVehicleCommsStatus(dt) === value;
            } else if (key === 'MASS D') {
              const sdmData = dt?.deviceStats?.sdmTcm || null;
              const massD = sdmData || '';
              isSelect = massD === '' ? massD === value : massD === parseInt(value, 10);
            } else if (key === 'MASS A') {
              const obmData = dt?.deviceStats?.obm
                ? parseInt(JSON.parse(dt?.deviceStats?.obm)?.TCM, 10)
                : null;
              const massA = obmData || '';
              isSelect = massA === '' ? massA === value : massA === parseInt(value, 10);
            } else if (key === 'LAST LOCATION') {
              const location = (dt.deviceStats && dt.deviceStats.location) || t('Home.NotLoggedIn');
              isSelect = location === value;
            }
            if (isSelect) break;
          }
          if (!isSelect) break;
        }
      } else {
        isSelect = true;
      }

      if (isSelect && searchText != null && searchText.trim() !== '') {
        for (const propName of Object.getOwnPropertyNames(dt)) {
          if (
            dt[propName] &&
            dt[propName]
              .toString()
              .toLowerCase()
              .includes(searchText.trim().toLowerCase())
          ) {
            isSelect = true;
            break;
          } else {
            isSelect = false;
          }
        }
      }

      if (isSelect) {
        isSelect =
          nonBusinessCompanyConfig && dt?.deviceStats?.attr === CompanyConfigValue.Private
            ? false
            : dt?.deviceStats?.gps?.Lat && dt?.deviceStats?.gps?.Lng;
      }
      return isSelect;
    });

    filteredDevices = filteredDevices.map(dt => {
      return {
        ...dt,
        name: dt.deviceName,
        driverName:
          (dt.deviceStats &&
            dt.deviceStats.currentUser &&
            dt.deviceStats.currentUser.firstName + ' ' + dt.deviceStats.currentUser.lastName) ||
          t('Home.NotLoggedIn')
      };
    });
    return filteredDevices;
  }

  const tableRows = useMemo(generateTableRows, [
    generateFleetsForTable,
    vehicleChartData,
    alertGaugeSelectedLabel,
    dashboardData.fleetsVehiclesDevicesMap
  ]);

  function generateTableRows() {
    const devices = getVehiclesAndDevices();
    const result = devices.map(dt => {
      const obmData = dt?.deviceStats?.obm
        ? parseInt(JSON.parse(dt?.deviceStats?.obm)?.TCM, 10)
        : null;
      const sdmData = dt?.deviceStats?.sdmTcm || null;

      const vehicleDefaultDriver =
        dt.vehicleId && dt.vehicleDefaultDriver?.id && dt.vehicleDefaultDriver;
      const driver = (dt.deviceStats && dt.deviceStats.currentUser) || vehicleDefaultDriver;
      return {
        deviceId: dt.id,
        vehicleName: dt.name,
        driver: (driver && driver.firstName + ' ' + driver.lastName) || t('Home.NotLoggedIn'),
        isVehicleDefaultDriver:
          driver && vehicleDefaultDriver && String(driver.id) === String(vehicleDefaultDriver.id),
        fleets: dt.fleets,
        deviceStats: dt.deviceStats,
        commsStatus: getVehicleCommsStatus(dt),
        massD: sdmData || '',
        massA: obmData || '',
        massDAt: dt?.deviceStats?.sdmAt,
        massAAt: dt?.deviceStats?.obmAt,
        lastLocation:
          nonBusinessCompanyConfig && dt.deviceStats?.attr === CompanyConfigValue.Private
            ? t('Home.NonBusiness')
            : dt.deviceStats?.location ?? '',
        speed:
          (dt.deviceStats &&
            dt.deviceStats.gps &&
            dt.deviceStats.gps.Spd >= 0 &&
            dt.deviceStats.gps.Spd) ||
          '',
        vehicleId: dt.vehicleId,
        type: dt.type,
        messagingDevice: dt.messagingDevice,
        routeToDevice: dt.routeToDevice && dt.imei
      };
    });
    return result;
  }

  /*
  function onInfoWindowClose() {
    setInfoWindow({ showInfoWindow: false });
  }

  function onMarkerClicked(data) {
    if (infoWindow.showInfoWindow) {
      setInfoWindow({ showInfoWindow: false });
    } else {
      setInfoWindow({
        lat: data.lat,
        lng: data.lng,
        content: <div>{data.name}</div>,
        showInfoWindow: true,
        options: { pixelOffset: { width: 0, height: -20 } }
      });
    }
  }
  */

  function getVehicleCommsStatus(device) {
    // when active, selectedId is 1
    // when inactive, selectedId is 2
    // 21600000 equals 6 * 60 * 60 * 1000
    const sixHoursAgo = new Date(moment().valueOf() - 21600000);
    return device.deviceStats?.lastCommsAt &&
      moment(device.deviceStats.lastCommsAt).valueOf() >= sixHoursAgo
      ? t('Home.Active')
      : t('Home.Inactive');
  }

  function onTableSearchChanged(searchText) {
    setSearchText(searchText);
  }

  function onVehicleChartSelection(id) {
    if (vehicleChartData.selectedId === id) {
      setVehicleChartData({ ...vehicleChartData, selectedId: -1 });
    } else {
      setVehicleChartData({ ...vehicleChartData, selectedId: id });
    }
  }

  function onLabelClicked(id) {
    if (getNumberOfVehiclesWithAlerts() > 0) {
      setAlertGaugeSelectedLabel(id);
    }
  }

  function onFilterChanged(filters) {
    setFilters(filters);
    // filterRef.current = filters;
    /*
    if (mapRef.current != null) {
      mapRef.current.onFiltered(generateMapMarkerData(filters, searchText));
    }
    */
  }

  function getVehiclesAndDevices() {
    const devicesStore = {};
    const results = [];
    const sixHoursAgo = moment().valueOf() - 21600000;

    // filterFns.push(dt => dt.type != null && (dt.type.code === "IFACE" || dt.type.code === "EXTERNAL" || dt.type.code === "PDA" || dt.type.code === "CAMERA"));

    // go through the map to filter vehicles and devices
    for (let fleetId in dashboardData.fleetsVehiclesDevicesMap) {
      const isFleetSelected = generateFleetsForTable.some(
        f => f.checked && (f.id === 0 || Number(f.id) === Number(fleetId))
      );
      if (isFleetSelected) {
        // fleet
        const curFleet = dashboardData.fleetsVehiclesDevicesMap[fleetId];
        for (let vehicleId in curFleet.vehicles) {
          // vehicle
          const vehicle = curFleet.vehicles[vehicleId];
          const devices = vehicle.devices;
          let vehicleDeivceCount = 0;
          //no devices vehicle
          if (
            vehicle.id &&
            !Object.keys(devices || {})?.length &&
            1 !== vehicleChartData.selectedId &&
            1 !== alertGaugeSelectedLabel
          ) {
            let storeId = 've' + vehicle.id;
            devicesStore[storeId] = {
              vehicleName: vehicle.name,
              vehicleRegistration: vehicle.registration,
              vehicleRegistrationState: vehicle.registrationState,
              deviceName: 'N/A',
              name: vehicle.name,
              icon: vehicle.type?.icon,
              fleets: [curFleet.name],
              vehicleId: vehicle.id,
              type: 1,
              messagingDevice: false,
              routeToDevice: false,
              deviceType: 'N/A',
              vehicleDefaultDriver: vehicle.vehicleDefaultDriver
            };
          }
          for (let deviceId in devices) {
            // device
            let selectDevice = false;
            const dt = devices[deviceId];
            let storeId = 've' + vehicle.id;
            if (vehicleId === 'NoVehicle') {
              storeId = dt.id;
            }

            // filter active/inactive devices
            if (vehicleChartData.selectedId === 1) {
              if (
                dt.deviceStats?.lastCommsAt &&
                moment(dt.deviceStats.lastCommsAt).valueOf() >= sixHoursAgo
              ) {
                selectDevice = true;
              } else {
                selectDevice = false;
                continue;
              }
            } else if (vehicleChartData.selectedId === 2) {
              if (
                !dt.deviceStats?.lastCommsAt ||
                moment(dt.deviceStats.lastCommsAt).valueOf() < sixHoursAgo
              ) {
                selectDevice = true;
              } else {
                selectDevice = false;
                if (devicesStore[storeId] != null) {
                  delete devicesStore[storeId];
                }

                if (vehicleId === 'NoVehicle') {
                  continue;
                } else {
                  break;
                }
              }
            } else {
              selectDevice = true;
            }

            // filter device with/without alert
            if (alertGaugeSelectedLabel === 1) {
              selectDevice = dt.hasAlert;
            } else if (alertGaugeSelectedLabel === 2) {
              selectDevice = !dt.hasAlert;
            } else {
              selectDevice = true;
            }

            // check if device is already in store. if it is in, then it should be under multiple fleets.
            if (selectDevice) {
              if (devicesStore[storeId] == null) {
                if (vehicleId === 'NoVehicle') {
                  devicesStore[storeId] = {
                    ...dt,
                    deviceName: dt.name,
                    fleets: [curFleet.name],
                    type: 0,
                    messagingDevice: isMessagingEnabledDevice(dt),
                    routeToDevice: isRouteToEnabledDevice(dt),
                    deviceType: dt.type
                  };
                } else {
                  // use vehicle's name
                  devicesStore[storeId] = {
                    ...dt,
                    vehicleName: vehicle.name,
                    vehicleRegistration: vehicle.registration,
                    vehicleRegistrationState: vehicle.registrationState,
                    deviceName: dt.name,
                    name: vehicle.name,
                    icon: vehicle.type?.icon,
                    fleets: [curFleet.name],
                    vehicleId: vehicle.id,
                    type: 1,
                    messagingDevice: isMessagingEnabledDevice(dt),
                    routeToDevice: isRouteToEnabledDevice(dt),
                    deviceType: dt.type
                  };
                }
              } else {
                // find duplicate device, update fleets
                if (
                  devicesStore[storeId].fleets.length > 0 &&
                  devicesStore[storeId].fleets[0] === 'No Fleet'
                ) {
                  devicesStore[storeId].fleets = [curFleet.name];
                } else {
                  // a vehicle has mutiple devices, use the device stats which is the latest.
                  // And replace device's details of this vehicle by the latest one
                  if (vehicleDeivceCount > 0) {
                    let vehicleInfo = devicesStore[storeId];
                    if (vehicleInfo.deviceStats != null) {
                      // current user
                      const curUser = vehicleInfo.deviceStats.currentUser;
                      if (
                        dt.deviceStats != null &&
                        vehicleActiveDeviceComparison(dt, {
                          ...vehicleInfo,
                          type: vehicleInfo.deviceType
                        }) < 0
                      ) {
                        devicesStore[storeId] = {
                          ...vehicleInfo,
                          ...dt,
                          deviceStats: dt.deviceStats,
                          deviceName: dt.name,
                          messagingDevice: isMessagingEnabledDevice(dt),
                          routeToDevice: isRouteToEnabledDevice(dt),
                          vehicleName: vehicleInfo.vehicleName,
                          vehicleRegistration: vehicleInfo.vehicleRegistration,
                          vehicleRegistrationState: vehicleInfo.vehicleRegistrationState,
                          name: vehicleInfo.name,
                          icon: vehicleInfo.icon,
                          fleets: vehicleInfo.fleets,
                          vehicleId: vehicleInfo.vehicleId,
                          type: vehicleInfo.type,
                          deviceType: dt.type
                        };
                        vehicleInfo = devicesStore[storeId];
                        // check current user
                        if (vehicleInfo.deviceStats.currentUser == null) {
                          vehicleInfo.deviceStats.currentUser = curUser;
                        }
                      } else {
                        if (
                          curUser == null &&
                          dt.deviceStats != null &&
                          dt.deviceStats.currentUser != null
                        ) {
                          // try to get the current user of the device.
                          vehicleInfo.deviceStats.currentUser = dt.deviceStats.currentUser;
                        }
                      }
                    } else {
                      vehicleInfo.deviceStats = dt.deviceStats;
                    }
                  } else {
                    devicesStore[storeId].fleets.push(curFleet.name);
                  }
                }
              }
              vehicleDeivceCount++;
            }
          }
        }
      }
    }
    for (let deviceId in devicesStore) {
      if (devicesStore[deviceId].deviceStats && !devicesStore[deviceId].deviceStats.currentUser) {
        const vehicleStats = vehiclesStats.find(
          vs => vs.vehicleId === Number(devicesStore[deviceId].vehicleId)
        );
        if (vehicleStats) {
          devicesStore[deviceId].deviceStats.currentUser = vehicleStats.currentUser;
        }
      }
      results.push(devicesStore[deviceId]);
    }

    return results;
  }

  function getNumberOfVehiclesWithAlerts() {
    if (dashboardData.alerts.length <= 0) {
      return 0;
    }

    const devices = {};
    let result = 0;
    const curCompany = getSelectedCompany();

    dashboardData.alerts.forEach(al => {
      if (
        al.event == null ||
        al.event.device == null ||
        al.event.company == null ||
        curCompany == null ||
        al.event.company.id !== curCompany.companyId
      )
        return;
      if (devices[al.event.device.id] == null) {
        devices[al.event.device.id] = 1;
        result++;
      }
    });
    return result;
  }

  function getTotalVehicles() {
    const curCompany = getSelectedCompany();
    if (curCompany == null) {
      return 0;
    }

    return curCompany.active + curCompany.inactive;
  }

  return (
    <>
      <div>
        <div
          style={{
            display: 'flex',
            flex: '1 0 0',
            flexDirection: 'row',
            padding: '8px'
          }}
        >
          <div
            style={{
              display: 'flex',
              flex: '1 0 0',
              marginLeft: '3px',
              borderRadius: '0px',
              borderStyle: 'solid',
              borderWidth: '1px',
              borderColor: '#dadee3',
              minHeight: '200px',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Donut
              title={t('Home.VehiclesDevices')}
              data={generateVehicleChartData()}
              onSelection={onVehicleChartSelection}
              isLoading={false}
            ></Donut>
          </div>
          <div
            style={{
              display: 'flex',
              flex: '1 0 0',
              margin: '0 10px',
              borderRadius: '0px',
              borderStyle: 'solid',
              borderWidth: '1px',
              borderColor: '#dadee3',
              minHeight: '200px',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Donut
              title={t('Home.DriversTitle')}
              data={generateDriverChartData()}
              isLoading={false}
            ></Donut>
          </div>
          {canAccessNonCameraFeatures && (
            <div
              style={{
                display: 'flex',
                flex: '1 0 0',
                marginRight: '3px',
                borderRadius: '0px',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: '#dadee3',
                minHeight: '200px',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Gauge
                message={t('Home.VehiclesWithAlert')}
                color={colors.romanRed}
                mousehoverColor={colors.mandarine}
                activeState={'DEFAULT_STATE'}
                value={getNumberOfVehiclesWithAlerts()}
                max={getTotalVehicles()}
                isLoading={false}
                onLabelClicked={onLabelClicked}
              />
            </div>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flex: '1 0 0',
            flexDirection: 'column',
            padding: '0 8px'
          }}
        >
          <div style={{ height: '500px' }}>
            <FilterSetsSupportedMap
              ref={mapRef}
              devices={mapData}
              defaultZoom={4}
              defaultCenter={localization.formats.geocode}
              containerElement={<div style={{ height: `100%`, width: `100%` }} />}
              mapElement={<div style={{ height: `100%`, width: `100%` }} />}
              onDeviceFocused={handleDeviceFocused}
              onDeviceBlurred={handleDeviceBlurred}
              onDeviceSelected={handleDeviceSelected}
              onMapTypeIdChanged={mapDashboardRef => {
                onMapTypeIdChanged(mapDashboardRef.current.state.map.mapTypeId);
              }}
              userPreferences={userPreferences}
              isLoading={dashboardData.isLoading}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flex: '1 0 0',
          margin: '0 8px 8px 8px',
          flexDirection: 'column',
          minHeight: 'calc(100vh - 70px)'
        }}
      >
        <DashboardTable
          rows={tableRows}
          filterFleets={generateFleetsForTable}
          onFilterFleets={setFilterFleets}
          onSearchChanged={onTableSearchChanged}
          isLoading={dashboardData.isLoading}
          onFilterChanged={onFilterChanged}
          onRowMouseOver={handleRowMouseOver}
          onRowClicked={handleRowClicked}
          onRowMouseOut={() => mapRef.current.onSetFocusedId(null)}
          areMassColumnsEnabled={areMassColumnsEnabled}
        ></DashboardTable>
      </div>
    </>
  );
};
