import { Button, Col, Row, Space, Table, Tooltip } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SortUtil } from 'components/tables/SortUtil';
import styles from './DeviceEventGrid.module.scss';
import { useTranslation } from 'react-i18next';
import i18next from 'i18nextConfig';
import { AttachmentStatus, AvailableTableColumns, StatusFilterOptions } from '../constant';
import { isIQCamera } from 'features/camera/CameraModelConfig';
import { EventMenuItem } from './MenuItem';
import { BUTTON_IDS } from 'utils/globalConstants';
import { RequestStatus } from './RequestStatus';
import {
  useUserManageColumns,
  DB_Registered_Pref,
  ManageColumnsModal
} from 'features/user_manage_columns';
import { useDevices } from 'features/fleets/fleetsSlice';
import { IQCameraSensitivity } from './IQCameraSensitivity';
import { FeatureFlag, useCan } from 'features/permissions';
import { useGPSByAddress } from 'utils/hooks/useGPSByAddress';

const genAntSortColumnConfig = (sortDir, defDir, sortFn, dataKey) => {
  return {
    sortDirections: sortDir,
    defaultSortOrder: defDir,
    sorter: true, //sortFn && ((a, b, sort) => sortFn(a, b, dataKey)),
    ellipsis: false
  };
};

const normalSortConfig = (sortFn, dataKey) =>
  genAntSortColumnConfig(['ascend', 'descend', 'ascend'], null, sortFn, dataKey);

function DeviceEventGridAction({ event, onViewEvent, onRequestVideo, forVideoRequest, ...props }) {
  return (
    <div>
      <EventMenuItem
        showRetry={forVideoRequest}
        event={event}
        onViewEvent={onViewEvent}
        onRequestVideo={onRequestVideo}
        showVideoRequest={!forVideoRequest}
      />
    </div>
  );
}

function DeviceEventGridEntityName({ name, event, forVideoRequest, entity }) {
  const devices = useDevices();
  const isIQCam = useMemo(
    () => isIQCamera(devices?.find(d => String(d.id) === String(event?.deviceId))),
    [devices, event?.deviceId]
  );

  if (!event?.deviceId || !isIQCam) {
    return name || '';
  }
  const isStandaloneDevice = event.deviceId && !event.vehicleId;
  const showCamPreset = entity === 'device' ? isStandaloneDevice : !!event.vehicleId;
  const showEvtCamPreset =
    !forVideoRequest &&
    event.dutyType &&
    (entity === 'device' ? isStandaloneDevice : !!event.vehicleId);
  return (
    <Space size={4} direction={'vertical'}>
      {name}
      {showCamPreset && <IQCameraSensitivity deviceId={event.deviceId} bracket />}
      {showEvtCamPreset && (
        <p className={styles.eventCamPreset}>
          {`${i18next.t('Home.CameraPresetOfEvent')} - ${i18next.t(
            `CompanyConfig.IQCamera.${event.dutyType}`
          )}`}
        </p>
      )}
    </Space>
  );
}

function DeviceEventLoccation({ eventLocation, eventStatus, onViewEventLocation }) {
  const { gps: eventGPS } = useGPSByAddress(
    eventLocation,
    eventLocation && [StatusFilterOptions.AVAILABLE, 'ENABLED'].includes(eventStatus)
  );
  return eventGPS && eventGPS?.lat && eventGPS?.lng ? (
    <Button
      type="link"
      onClick={() =>
        onViewEventLocation({
          title: eventLocation,
          coordinates: {
            lat: eventGPS.lat,
            lng: eventGPS.lng
          }
        })
      }
    >
      {eventLocation}
    </Button>
  ) : (
    eventLocation || '-'
  );
}

function FleetColumnRender({ items, ...props }) {
  const [showExpand, setShowExpand] = useState(false);
  const [contentList, setContentList] = useState(null);
  const { t } = useTranslation();
  const handleClick = evt => {
    evt.preventDefault();
    evt.stopPropagation();
    setShowExpand(prev => !prev);
  };

  useEffect(() => {
    setContentList(null);
    setShowExpand(false);
  }, [items]);

  useEffect(() => {
    setContentList(prev => {
      const list = Array.from(prev || []);
      if (list.length === 0) {
        for (let i = 0; i < items?.length && i < 5; i++) {
          list.push(
            <p className={styles.fleetListItem} key={i}>
              {items[i]}
            </p>
          );
        }
      }
      if (showExpand) {
        for (let i = 5; i < items?.length; i++) {
          list.push(
            <p className={styles.fleetListItem} key={items[i]}>
              {items[i]}
            </p>
          );
        }
      } else if (list.length > 5) {
        list.splice(5, list.length - 5);
      }
      return list;
    });
  }, [items, showExpand]);

  return (
    <div>
      {contentList}
      {items?.length > 5 && (
        <Tooltip
          content={t(showExpand ? 'Common.Hide Content' : 'Common.Show More Content')}
          target={
            <Button
              className={styles.fleetListItem}
              type="link"
              onClick={handleClick}
              id={BUTTON_IDS.deviceEventGridContent}
            >
              {!showExpand ? '+' : '-'}
              {items.length - 5}
            </Button>
          }
        />
      )}
    </div>
  );
}

const makeColumns = (requiredColumns, forVideoRequest, can = () => false) => {
  const columns = [
    {
      get title() {
        return i18next.t('Footage.Request Status');
      },
      dataIndex: 'requestStatus',
      sortKey: AvailableTableColumns.RequestStatus,
      key: 'requestStatus',
      fixedPosition: true,
      render: (text, record, index) => <RequestStatus event={record} />,
      ...normalSortConfig(SortUtil.sortStringIgnoreCase, 'requestStatus')
    },
    {
      get title() {
        return i18next.t('Common.Request Time');
      },
      dataIndex: 'videoRequestTime',
      sortKey: AvailableTableColumns.RequestTime,
      key: 'videoRequestTime',
      fixedPosition: true,
      ...normalSortConfig(SortUtil.sortStringIgnoreCase, 'videoRequestTime')
    },
    {
      get title() {
        return i18next.t('Common.Device');
      },
      dataIndex: 'deviceName',
      sortKey: AvailableTableColumns.Device,
      key: 'device',
      ...normalSortConfig(SortUtil.sortStringIgnoreCase, 'deviceName'),
      render: (text, record, index) => (
        <DeviceEventGridEntityName
          key={record.id}
          event={record}
          name={text}
          entity="device"
          forVideoRequest={forVideoRequest}
        />
      )
    },
    {
      get title() {
        return i18next.t('Common.Vehicle');
      },
      sortKey: AvailableTableColumns.Vehicle,
      dataIndex: 'vehicleName',
      key: 'vehicle',
      ...normalSortConfig(SortUtil.sortStringIgnoreCase, 'vehicleName'),
      render: (text, record, index) => (
        <DeviceEventGridEntityName
          key={record.id}
          event={record}
          name={text}
          entity="vehicle"
          forVideoRequest={forVideoRequest}
        />
      )
    },
    {
      get title() {
        return i18next.t('Common.Fleet');
      },
      sortKey: AvailableTableColumns.Fleet,
      dataIndex: 'fleetName',
      key: 'fleet',
      render: (text, record, index) => (
        <FleetColumnRender key={record.id} items={text?.split('\r\n')} />
      ),
      ...normalSortConfig(SortUtil.sortStringIgnoreCase, 'fleetName')
    },
    {
      get title() {
        return i18next.t('Common.Driver');
      },
      sortKey: AvailableTableColumns.Driver,
      dataIndex: 'driverName',
      key: 'driver',
      ...normalSortConfig(SortUtil.sortStringIgnoreCase, 'driverName')
    } /*
  {
    get title() {
      return 'Branch';
    },
    dataIndex: 'branch',
    key: 'branch',
    ...normalSortConfig(SortUtil.sortStringIgnoreCase, 'branch')
  },*/,
    {
      get title() {
        return i18next.t('Common.Event Time');
      },
      sortKey: AvailableTableColumns.EventTime,
      dataIndex: 'timeAt',
      key: 'eventTime',
      ...normalSortConfig(SortUtil.sortStringIgnoreCase, 'timeAt')
    },
    {
      get title() {
        return i18next.t('Common.Location');
      },
      sortKey: AvailableTableColumns.Location,
      dataIndex: 'location',
      key: 'location',
      renderWithActions: ({ onViewEventLocation }) => (text, record, index) => (
        <DeviceEventLoccation
          key={record.id}
          eventLocation={record.location}
          eventStatus={record.status}
          onViewEventLocation={onViewEventLocation}
        />
      ),
      ...normalSortConfig(SortUtil.sortStringIgnoreCase, 'location')
    } /*
  {
    get title() {
      return 'Provider';
    },
    sortKey: 'provider',
    dataIndex: 'provider',
    key: 'id',
    ...normalSortConfig(SortUtil.sortStringIgnoreCase, 'provider')
  },*/,
    {
      get title() {
        return i18next.t('Tracking.EventType');
      },
      sortKey: AvailableTableColumns.Type,
      dataIndex: 'eventType',
      key: 'type',
      ...normalSortConfig(SortUtil.sortStringIgnoreCase, 'eventType')
    },
    {
      get title() {
        return i18next.t('Common.Status');
      },
      sortKey: AvailableTableColumns.Status,
      dataIndex: 'attachmentStatus',
      render: (text, record, idx) => {
        return i18next.t('Common.AttachmentStatus.' + (text || AttachmentStatus.UNAVAILABLE));
      },
      key: 'attachmentStatus',
      ...normalSortConfig(SortUtil.sortStringIgnoreCase, 'attachmentStatus')
    },
    {
      get title() {
        return i18next.t('Tabs.details');
      },
      sortKey: AvailableTableColumns.Details,
      dataIndex: 'details',
      key: 'details'
    },
    ...(can({ oneOfFeatureFlags: [FeatureFlag.evoPhase1.flag, FeatureFlag.evoPhaseTGE.flag] })
      ? [
          {
            get title() {
              return i18next.t('Footage.CameraChannels');
            },
            sortKey: AvailableTableColumns.CameraPosition,
            dataIndex: 'cameraPosition',
            key: 'cameraPosition',
            render: (text, record, idx) => {
              try {
                return text
                  ?.split(',')
                  ?.filter(camP => camP.trim())
                  ?.map(camP => i18next.t(`Common.CameraChannels.${camP.trim()}`, camP.trim()))
                  ?.join(', ');
              } catch (error) {
                return text;
              }
            }
          }
        ]
      : []),
    {
      get title() {
        return i18next.t('Common.TableColumns.Actions');
      },
      sortKey: AvailableTableColumns.Actions,
      dataIndex: 'actions',
      key: 'actions',
      fixedPosition: true,
      renderWithActions: props => (text, record, index) => (
        <DeviceEventGridAction
          key={record.id}
          event={record}
          forVideoRequest={forVideoRequest}
          {...props}
        />
      )
    }
  ];

  return columns.filter(c => requiredColumns.includes(c.sortKey));
};

export function DeviceEventGrid({
  deviceEvents,
  isLoading,
  onViewEvent,
  onRequestVideo,
  onViewEventLocation,
  onSort,
  tableColumns,
  forVideoRequest,
  ...props
}) {
  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      if (onSort) {
        onSort(sorter.column.sortKey, sorter.order[0] === 'a' ? 'asc' : 'desc');
      }
    },
    [onSort]
  );

  const can = useCan();
  const columns = useMemo(() => makeColumns(tableColumns, forVideoRequest, can), [
    tableColumns,
    forVideoRequest,
    can
  ]);

  const { visibleColumns, manageModalProps } = useUserManageColumns({
    columns,
    pref: DB_Registered_Pref.video_requests
  });

  const visibleColumnsWithActions = useMemo(
    () =>
      (forVideoRequest ? visibleColumns : columns).map(col =>
        col.renderWithActions
          ? { ...col, render: col.renderWithActions({ onRequestVideo, onViewEvent }) }
          : col
      ),
    [forVideoRequest, visibleColumns, columns, onRequestVideo, onViewEvent]
  );

  return (
    <Row className={styles.deviceEventGrid}>
      <Col span={24}>
        <Table
          columns={visibleColumnsWithActions}
          dataSource={deviceEvents}
          pagination={false}
          loading={isLoading}
          onChange={handleTableChange}
          rowKey="id"
          onRow={evt => ({
            className: evt.pristine ? styles.pristine : '',
            onMouseMove: () => {
              if (evt.pristine && evt.dismissPristine) {
                evt.dismissPristine(evt.id);
              }
            }
          })}
        ></Table>
        <ManageColumnsModal {...manageModalProps} />
      </Col>
    </Row>
  );
}
