import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import InfoRow from 'components/form/info-row/InfoRow';
import { Button, Space, Table } from 'antd';
import { Accordion } from 'components/ant';
import { useCurrentCompany } from 'features/company/companySlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { BUTTON_IDS } from 'utils/globalConstants';
import ContainerPageWrapper from 'components/container-page-wrapper/ContainerPageWrapper';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import styles from 'components/tn/grid/grid.module.scss';
import style from './InstallationLog.module.scss';
import { setInstallationLogsViewData } from 'features/installation_logs/InstallationLogsSlice';
import DownloadAuditLogsView from './DownloadAuditLogsView';
import { formatMeterValue, formatOdometer, getMeterValue } from 'features/meters';
import { format } from 'utils/dates';
import {
  findGPIOData,
  findValueByTitle,
  getMultiCameraIQDetails,
  InfoRowWithLink,
  SubTypesEnum
} from './InstallationAuditUtility';
import { InstallationAuditLogsViewPdfGenerator } from './PdfGenerator';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import i18n from 'i18next';
import ProgressModal from './ProgressModal';

export function InstallationAuditLogsView() {
  const dispatch = useDispatch();
  const company = useCurrentCompany();
  //const [selectedCompany, setSelectedCompany] = useState(company?.id);
  const { t } = useTranslation();
  const localization = useLocalization();
  const history = useHistory();
  const [viewData, setViewData] = useState({});
  const odometerData = viewData?.vehicleMeters?.find(i => i.type === 'odometer') || '';
  const engineHoursData = viewData?.vehicleMeters?.find(i => i.type === 'hours') || '';
  const odometerValue = odometerData ? formatMeterValue(localization, odometerData) : '';
  const engineHoursValue =
    engineHoursData !== ''
      ? getMeterValue(engineHoursData).toLocaleString(localization.locale, {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1
        }) +
        ' ' +
        i18n.t('Common.hrs')
      : '';

  const [title, setTitle] = useState('');

  const [supportType, setSupportType] = useState('');
  const [testResults, setTestResults] = useState([]);
  const [attachments, setAttachments] = useState([]);

  const [flag, setFlag] = useState('details');
  const { state } = useLocation();

  const extension = '.pdf';
  const [progress, setProgress] = useState({ total: 0, completed: 0 });
  const [modalVisible, setModalVisible] = useState(false);

  const isOpen = true;
  // const capitalize = s => s && s[0].toUpperCase() + s.slice(1);
  const [icon, setIcon] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true
  ]);
  const cleanData = jsonString => {
    if (!jsonString || jsonString.length === 0) {
      return [];
    }
    const data = JSON.parse(jsonString);
    return (
      data &&
      data?.map(item => {
        const cleanedItem = {};
        cleanedItem.title = item.title;
        if (item.value !== undefined) {
          try {
            const parsedValue = JSON.parse(item.value); // Try parsing the value
            if (typeof parsedValue === 'object' && parsedValue !== null) {
              cleanedItem.value = parsedValue; // If it's a valid JSON object, assign it
            } else {
              cleanedItem.value = item.value; // If it's not an object, keep the original string
            }
          } catch (e) {
            cleanedItem.value = item.value; // If parsing fails, keep it as a string
          }
        }
        if (item.status !== undefined) cleanedItem.status = item.status;
        if (item.subtitle !== undefined) cleanedItem.subtitle = item.subtitle;
        return cleanedItem;
      })
    );
  };
  useEffect(() => {
    dispatch(setBackButton(true));
    dispatch(
      setPageTitle(
        t(
          (state && state?.data?.entityName) || t(`SupportToolsELD.InstallationLogsColumns.Vehicle`)
        )
      )
    );
    setTitle(
      t((state && state?.data?.entityName) || `SupportToolsELD.InstallationLogsColumns.Vehicle`)
    );
    setViewData(state && state?.data?.rowData);
    setAttachments(state && state?.data?.rowData?.attachments);
    setSupportType(state && state?.data?.supportType);
    setTestResults(
      JSON.stringify(cleanData((state && state?.data?.rowData?.testResults) || []), null, 2)
    );
    dispatch(
      setInstallationLogsViewData({
        ...(state && state?.data?.rowData),
        supportType: state && state?.data?.supportType
      })
    );
  }, [dispatch, state]);

  const handleFilterClick = value => {
    setFlag(value);
  };

  const [triggerButton, setTriggerButton] = useState(false);
  const handleExportToPdf = () => {
    setTriggerButton(true);
  };

  const gpioData = findGPIOData(testResults, localization);
  const multiCameraIQValues = getMultiCameraIQDetails(testResults);

  const handleProgress = ({ total, completed }) => {
    setProgress({ total, completed });
  };
  const handlePDFDownloadClick = async () => {
    setProgress({ total: 0, completed: 0 });
    setModalVisible(true);
    try {
      await InstallationAuditLogsViewPdfGenerator(
        title,
        viewData,
        t,
        supportType,
        localization,
        testResults,
        attachments,
        handleProgress
      );
      setTimeout(() => {
        dispatch(
          openToast({
            type: ToastType.Success,
            message: i18n.t('Common.DownloadedSuccessfully', {
              title: i18n.t(title) + extension
            })
          })
        );
      }, 5000);
    } catch (error) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: error?.message
        })
      );
      console.error('Error fetching images:', error);
    } finally {
      setModalVisible(false);
    }
  };

  const testResEngineHoursData = findValueByTitle(
    testResults,
    'VPM Information: Hours',
    localization
  ).title;
  const testResEngineHoursValue =
    testResEngineHoursData !== ''
      ? testResEngineHoursData.toLocaleString(localization.locale, {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1
        }) +
        ' ' +
        i18n.t('Common.hrs')
      : '';
  return (
    <>
      <ContainerPageWrapper>
        <HeaderPageWrapper>
          <div style={{ display: 'flex' }}></div>
          <div style={{ marginLeft: 'auto' }}>
            <Space size={16}>
              <Button
                type="primary"
                size="medium"
                id={BUTTON_IDS.viewPhotosBtn}
                onClick={() => {
                  history.push(
                    `/supportTools/installationAuditLog/photos/${supportType}/${viewData?.company?.id}/${viewData?.id}`,
                    {}
                  );
                }}
                // disabled={}
              >
                {t('SupportToolsELD.ViewPhotos')}
              </Button>
              <Button
                type="secondary"
                size="medium"
                id={BUTTON_IDS.exportToPDFBtn}
                onClick={handlePDFDownloadClick}
                // disabled={isFetchingFleets}
              >
                {t('SupportToolsELD.ExportToPdf')}
              </Button>
            </Space>
          </div>
        </HeaderPageWrapper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0 32px',
            color: '#181c21'
          }}
        >
          {viewData && (
            <>
              <InfoRow
                label={t(`SupportToolsELD.InstallationLogsColumns.Vehicle`)}
                value={viewData?.vehicle?.name}
                styles={styles}
              />
              <InfoRow
                label={t(`SupportToolsELD.InstallationLogsColumns.Company`)}
                value={viewData?.company?.name || ''}
                styles={styles}
              />
              <InfoRow
                label={t(`SupportToolsELD.InstallationLogsColumns.Fleet`)}
                value={
                  viewData?.fleets && viewData.fleets?.length > 0
                    ? viewData.fleets.map(f => f.name)?.join(', ')
                    : ''
                }
                styles={styles}
              />
              <InfoRow
                label={t(`SupportToolsELD.InstallationLogsColumns.Engineer`)}
                value={viewData?.user?.name || ''}
                styles={styles}
              />
              <InfoRow
                label={t(`SupportToolsELD.InstallationLogsColumns.WorkOrder`)}
                value={viewData?.jobNumber || ''}
                styles={styles}
              />
              <InfoRow
                label={t(`SupportToolsELD.InstallationLogsColumns.OperationType`)}
                value={viewData?.action === 'Test' ? 'Validate' : viewData?.action || ''}
                styles={styles}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  maxWidth: '100%',
                  borderBottom: '1px solid #EEE',
                  padding: '10px 0'
                }}
              >
                <div style={{ width: '100%' }}>
                  <Accordion
                    isOpen
                    title={t('SupportToolsELD.InstallationLogsView.OperationDescription')}
                    body={
                      <>
                        <div style={{ display: 'flex', width: '70%' }}>
                          <p
                            style={{
                              border: '1px solid #EEE',
                              backgroundColor: flag === 'details' ? '#86b341' : '#EEE',
                              padding: '0px 5px 0px',
                              marginRight: '15px',
                              cursor: 'pointer'
                            }}
                            onClick={() => handleFilterClick('details')}
                          >
                            {t(`SupportToolsELD.InstallationLogsView.Details`)}
                          </p>
                          <p
                            style={{
                              border: '1px solid #EEE',
                              backgroundColor: flag === 'odometer' ? '#86b341' : '#EEE',
                              padding: '0px 5px 0px',
                              marginRight: '15px',
                              cursor: 'pointer'
                            }}
                            onClick={() => handleFilterClick('odometer')}
                          >
                            {t(`SupportToolsELD.InstallationLogsView.OdometerEngineHours`)}
                          </p>
                          <p
                            style={{
                              border: '1px solid #EEE',
                              backgroundColor: flag === 'accelerometer' ? '#86b341' : '#EEE',
                              padding: '0px 5px 0px',
                              cursor: 'pointer'
                            }}
                            onClick={() => handleFilterClick('accelerometer')}
                          >
                            {t(`SupportToolsELD.InstallationLogsView.AccelerometerLastCalibrated`)}
                          </p>
                        </div>
                        {flag === 'details' && (
                          <div className={style.megaDropView}>
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.DateAndTime')}
                              value={
                                format(
                                  viewData?.createdAt,
                                  localization.formats.time.formats.dby_imp
                                ) || ''
                              }
                              styles={styles}
                            />
                            <InfoRow
                              label={t(`SupportToolsELD.InstallationLogsColumns.OperationType`)}
                              value={
                                viewData?.action === 'Test' ? 'Validate' : viewData?.action || ''
                              }
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsColumns.OperationStatus')}
                              value={viewData?.status || ''}
                              styles={styles}
                            />
                            {viewData?.action?.toUpperCase() === SubTypesEnum.TEST ? (
                              <div>
                                <InfoRow
                                  label={t(
                                    'SupportToolsELD.InstallationLogsColumns.OperationSubtype'
                                  )}
                                  value={viewData?.subType || ''}
                                  styles={styles}
                                />
                                {/* <InfoRow
                                  label={t(
                                    'SupportToolsELD.InstallationLogsColumns.OperationSubtypeStatus'
                                  )}
                                  value={viewData?.[supportType]?.status || ''}
                                  styles={styles}
                                /> */}
                              </div>
                            ) : (
                              ''
                            )}
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsColumns.Engineer')}
                              value={viewData?.user?.name || ''}
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsColumns.IMEI')}
                              value={viewData?.device?.imei || ''}
                              styles={styles}
                            />
                            <InfoRow
                              label={t(
                                'SupportToolsELD.InstallationLogsView.RegistrationOrLicence'
                              )}
                              value={viewData?.vehicle?.registration || ''}
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsColumns.Vehicle')}
                              value={viewData?.vehicle?.name || ''}
                              styles={styles}
                            />
                            {viewData && viewData?.action?.toUpperCase() === SubTypesEnum.INSTALL && (
                              <div>
                                <InfoRow
                                  label={t('SupportToolsELD.InstallationLogsColumns.Make')}
                                  value={viewData?.vehicle?.make || ''}
                                  styles={styles}
                                />
                                <InfoRow
                                  label={t('SupportToolsELD.InstallationLogsColumns.Model')}
                                  value={viewData?.vehicle?.model || ''}
                                  styles={styles}
                                />
                                <InfoRow
                                  label={t('SupportToolsELD.InstallationLogsColumns.Odometer')}
                                  value={odometerValue}
                                  styles={styles}
                                />
                                <InfoRow
                                  label={t('SupportToolsELD.InstallationLogsColumns.EngineHours')}
                                  value={engineHoursValue}
                                  styles={styles}
                                />
                              </div>
                            )}
                            {viewData && viewData?.action?.toUpperCase() === SubTypesEnum.REPLACE && (
                              <div>
                                <InfoRow
                                  label={t('SupportToolsELD.InstallationLogsColumns.ReplacedIMEI')}
                                  value={viewData?.oldDevice?.oldDeviceImei || ''}
                                  styles={styles}
                                />
                                <InfoRow
                                  label={t('SupportToolsELD.InstallationLogsColumns.Odometer')}
                                  value={odometerValue}
                                  styles={styles}
                                />
                                <InfoRow
                                  label={t('SupportToolsELD.InstallationLogsColumns.EngineHours')}
                                  value={engineHoursValue}
                                  styles={styles}
                                />
                              </div>
                            )}
                          </div>
                        )}
                        {flag === 'odometer' && (
                          <div className={style.megaDropView}>
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.DateAndTime')}
                              value={
                                format(
                                  viewData?.createdAt,
                                  localization.formats.time.formats.dby_imp
                                ) || ''
                              }
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsColumns.EngineHours')}
                              value={engineHoursValue}
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsColumns.Odometer')}
                              value={odometerValue}
                              styles={styles}
                            />
                          </div>
                        )}
                        {flag === 'accelerometer' && (
                          <div className={style.megaDropView}>
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.DateAndTime')}
                              value={
                                format(
                                  viewData?.createdAt,
                                  localization.formats.time.formats.dby_imp
                                ) || ''
                              }
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.Accelerometer')}
                              value={''}
                              styles={styles}
                            />
                          </div>
                        )}
                      </>
                    }
                    icon={icon[0]}
                    onChange={() =>
                      setIcon([
                        !icon[0],
                        icon[1],
                        icon[2],
                        icon[3],
                        icon[4],
                        icon[5],
                        icon[6],
                        icon[7],
                        icon[8],
                        icon[9],
                        icon[10],
                        icon[11],
                        icon[12],
                        icon[13],
                        icon[14]
                      ])
                    }
                  />
                  <Accordion
                    isOpen
                    title={t('SupportToolsELD.InstallationLogsView.VPMInformation')}
                    body={
                      <div className={style.megaDropView}>
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.Charge')}
                          value={
                            findValueByTitle(testResults, 'VPM Information: Charge', localization)
                              .title
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.EngineLoad')}
                          value={
                            findValueByTitle(
                              testResults,
                              'VPM Information: Engineload',
                              localization
                            ).title
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.FuelConsumption')}
                          value={
                            findValueByTitle(
                              testResults,
                              'VPM Information: Fuelconsumption',
                              localization
                            ).title
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.FuelLevel')}
                          value={
                            findValueByTitle(
                              testResults,
                              'VPM Information: Fuellevel',
                              localization
                            ).title
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.Gear')}
                          value={
                            findValueByTitle(testResults, 'VPM Information: Gear', localization)
                              .title
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.GPS')}
                          value={
                            findValueByTitle(testResults, 'VPM Information: Gps', localization)
                              ?.value?.At
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.Hours')}
                          value={testResEngineHoursValue}
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.ID')}
                          value={
                            findValueByTitle(testResults, 'VPM Information: Id', localization).title
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t(
                            'SupportToolsELD.InstallationLogsView.MalfunctionIndicatorLightActive'
                          )}
                          value={
                            findValueByTitle(testResults, 'VPM Information: Mil', localization)
                              .title
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.Odometer')}
                          value={formatOdometer(
                            localization,
                            findValueByTitle(testResults, 'VPM Information: Odometer', localization)
                              .title
                          )}
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.OilPressure')}
                          value={
                            findValueByTitle(
                              testResults,
                              'VPM Information: Oilpressure',
                              localization
                            ).title
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.OilTemp')}
                          value={
                            findValueByTitle(testResults, 'VPM Information: Oiltemp', localization)
                              .title
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.RPM')}
                          value={
                            findValueByTitle(testResults, 'VPM Information: Rpm', localization)
                              .title
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.Speed')}
                          value={localization.formatSpeed(
                            findValueByTitle(testResults, 'VPM Information: Speed', localization)
                              .title
                          )}
                          styles={styles}
                        />
                      </div>
                    }
                    icon={icon[1]}
                    onChange={() =>
                      setIcon([
                        icon[0],
                        !icon[1],
                        icon[2],
                        icon[3],
                        icon[4],
                        icon[5],
                        icon[6],
                        icon[7],
                        icon[8],
                        icon[9],
                        icon[10],
                        icon[11],
                        icon[12],
                        icon[13],
                        icon[14]
                      ])
                    }
                  />
                  <Accordion
                    isOpen
                    title={t('SupportToolsELD.InstallationLogsView.LastCommDetails')}
                    body={
                      <div className={style.megaDropView}>
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.LastCommValue')}
                          value={
                            findValueByTitle(testResults, 'General: Last Comm', localization).title
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.LastCommStatus')}
                          value={
                            findValueByTitle(testResults, 'General: Last Comm', localization).status
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.LastCommDetails')}
                          value={
                            findValueByTitle(testResults, 'General: Last Comm', localization)
                              .subtitle
                          }
                          styles={styles}
                        />
                      </div>
                    }
                    icon={icon[2]}
                    onChange={() =>
                      setIcon([
                        icon[0],
                        icon[1],
                        !icon[2],
                        icon[3],
                        icon[4],
                        icon[5],
                        icon[6],
                        icon[7],
                        icon[8],
                        icon[9],
                        icon[10],
                        icon[11],
                        icon[12],
                        icon[13],
                        icon[14]
                      ])
                    }
                  />
                  <Accordion
                    isOpen
                    title={t('SupportToolsELD.InstallationLogsView.IgnitionDetails')}
                    body={
                      <div className={style.megaDropView}>
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.IgnitionValue')}
                          value={
                            findValueByTitle(testResults, 'General: Ignition', localization).title
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.IgnitionStatus')}
                          value={
                            findValueByTitle(testResults, 'General: Ignition', localization).status
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.IgnitionDetails')}
                          value={
                            findValueByTitle(testResults, 'General: Ignition', localization)
                              .subtitle
                          }
                          styles={styles}
                        />
                      </div>
                    }
                    icon={icon[3]}
                    onChange={() =>
                      setIcon([
                        icon[0],
                        icon[1],
                        icon[2],
                        !icon[3],
                        icon[4],
                        icon[5],
                        icon[6],
                        icon[7],
                        icon[8],
                        icon[9],
                        icon[10],
                        icon[11],
                        icon[12],
                        icon[13],
                        icon[14]
                      ])
                    }
                  />
                  <Accordion
                    isOpen
                    title={t('SupportToolsELD.InstallationLogsView.DriverDetails')}
                    body={
                      <div className={style.megaDropView}>
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.DriverValue')}
                          value={
                            findValueByTitle(testResults, 'General: Driver', localization).title
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.DriverStatus')}
                          value={
                            findValueByTitle(testResults, 'General: Driver', localization).status
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.DriverDetails')}
                          value={
                            findValueByTitle(testResults, 'General: Driver', localization).subtitle
                          }
                          styles={styles}
                        />
                      </div>
                    }
                    icon={icon[4]}
                    onChange={() =>
                      setIcon([
                        icon[0],
                        icon[1],
                        icon[2],
                        icon[3],
                        !icon[4],
                        icon[5],
                        icon[6],
                        icon[7],
                        icon[8],
                        icon[9],
                        icon[10],
                        icon[11],
                        icon[12],
                        icon[13],
                        icon[14]
                      ])
                    }
                  />
                  <Accordion
                    isOpen
                    title={t('SupportToolsELD.InstallationLogsView.GPSDetails')}
                    body={
                      <div className={style.megaDropView}>
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.GPSTimeValue')}
                          value={findValueByTitle(testResults, 'GPS: GPS Time', localization).title}
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.GPSTimeStatus')}
                          value={
                            findValueByTitle(testResults, 'GPS: GPS Time', localization).status
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.GPSTimeDetails')}
                          value={
                            findValueByTitle(testResults, 'GPS: GPS Time', localization).subtitle
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.GPSPositionValue')}
                          value={
                            findValueByTitle(testResults, 'GPS: GPS Position', localization).title
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.GPSPositionDetails')}
                          value={
                            findValueByTitle(testResults, 'GPS: GPS Position', localization)
                              .subtitle
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.GPSHDOPSATValue')}
                          value={
                            findValueByTitle(testResults, 'GPS: GPS HDOP/SAT', localization).title
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.GPSHDOPSATStatus')}
                          value={
                            findValueByTitle(testResults, 'GPS: GPS HDOP/SAT', localization).status
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.GPSHDOPSATDetails')}
                          value={
                            findValueByTitle(testResults, 'GPS: GPS HDOP/SAT', localization)
                              .subtitle
                          }
                          styles={styles}
                        />
                      </div>
                    }
                    icon={icon[5]}
                    onChange={() =>
                      setIcon([
                        icon[0],
                        icon[1],
                        icon[2],
                        icon[3],
                        icon[4],
                        !icon[5],
                        icon[6],
                        icon[7],
                        icon[8],
                        icon[9],
                        icon[10],
                        icon[11],
                        icon[12],
                        icon[13],
                        icon[14]
                      ])
                    }
                  />
                  <Accordion
                    isOpen
                    title={t('SupportToolsELD.InstallationLogsView.ValidationDetails', {
                      title: 'GPIO'
                    })}
                    body={
                      <div className={style.megaDropView}>
                        {gpioData.length > 0 ? (
                          gpioData.map((gpio, index) => (
                            <div key={index} className={style.gpioItem}>
                              <InfoRow
                                label={t('SupportToolsELD.InstallationLogsView.GPIOTitle', {
                                  title: gpio.title
                                })}
                                value={gpio.value || ''}
                                styles={style}
                              />
                              <InfoRow
                                label={t('SupportToolsELD.InstallationLogsView.GPIODetails', {
                                  title: gpio.title
                                })}
                                value={gpio.subtitle || ''}
                                styles={style}
                              />
                              {/* <InfoRow
                                label={`Details: ${gpio.subtitle}`}
                                value={gpio.subtitle || 'N/A'}
                                subtitle=""
                                styles={style}
                              /> */}
                            </div>
                          ))
                        ) : (
                          <p>
                            {t('SupportToolsELD.InstallationLogsView.GPIOvalidationnotcompleted')}
                          </p>
                        )}
                      </div>
                    }
                    icon={icon[6]}
                    onChange={() =>
                      setIcon([
                        icon[0],
                        icon[1],
                        icon[2],
                        icon[3],
                        icon[4],
                        icon[5],
                        !icon[6],
                        icon[7],
                        icon[8],
                        icon[9],
                        icon[10],
                        icon[11],
                        icon[12],
                        icon[13],
                        icon[14]
                      ])
                    }
                  />
                  <Accordion
                    isOpen
                    title={t('SupportToolsELD.InstallationLogsView.ValidationDetails', {
                      title: 'Satellite'
                    })}
                    body={
                      <div className={style.megaDropView}>
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.LastSatelliteTimeTitle', {
                            title: t('Common.Value')
                          })}
                          value={
                            findValueByTitle(
                              testResults,
                              'Satellite: Last Satellite Time',
                              localization
                            ).title
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.LastSatelliteTimeTitle', {
                            title: t('Common.Status')
                          })}
                          value={
                            findValueByTitle(
                              testResults,
                              'Satellite: Last Satellite Time',
                              localization
                            ).status
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.LastSatelliteTimeTitle', {
                            title: t('Common.Details')
                          })}
                          value={
                            findValueByTitle(
                              testResults,
                              'Satellite: Last Satellite Time',
                              localization
                            ).subtitle
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t(
                            'SupportToolsELD.InstallationLogsView.SatelliteValidationStatus'
                          )}
                          value={
                            findValueByTitle(testResults, 'Satellite Event', localization).title
                          }
                          styles={styles}
                        />
                      </div>
                    }
                    icon={icon[7]}
                    onChange={() =>
                      setIcon([
                        icon[0],
                        icon[1],
                        icon[2],
                        icon[3],
                        icon[4],
                        icon[5],
                        icon[6],
                        !icon[7],
                        icon[8],
                        icon[9],
                        icon[10],
                        icon[11],
                        icon[12],
                        icon[13],
                        icon[14]
                      ])
                    }
                  />
                  <Accordion
                    isOpen
                    title={t(
                      'SupportToolsELD.InstallationLogsView.DriverIDValidationTabletDetails',
                      { title: 'Tablet (ELD)' }
                    )}
                    body={
                      <div className={style.megaDropView}>
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.DriverIDValidationTitle', {
                            title: t('Common.Value')
                          })}
                          value={
                            findValueByTitle(
                              testResults,
                              'Driver ID Validation: Tablet (ELD)',
                              localization
                            ).title
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.DriverIDValidationTitle', {
                            title: t('Common.Status')
                          })}
                          value={
                            findValueByTitle(
                              testResults,
                              'Driver ID Validation: Tablet (ELD)',
                              localization
                            ).status
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.DriverIDValidationTitle', {
                            title: t('Common.Details')
                          })}
                          value={
                            findValueByTitle(
                              testResults,
                              'Driver ID Validation: Tablet (ELD)',
                              localization
                            ).subtitle
                          }
                          styles={styles}
                        />
                      </div>
                    }
                    icon={icon[8]}
                    onChange={() =>
                      setIcon([
                        icon[0],
                        icon[1],
                        icon[2],
                        icon[3],
                        icon[4],
                        icon[5],
                        icon[6],
                        icon[7],
                        !icon[8],
                        icon[9],
                        icon[10],
                        icon[11],
                        icon[12],
                        icon[13],
                        icon[14]
                      ])
                    }
                  />
                  <Accordion
                    isOpen
                    title={t(
                      'SupportToolsELD.InstallationLogsView.DriverIDValidationTabletDetails',
                      { title: 'Tablet (Non-ELD)' }
                    )}
                    body={
                      <div className={style.megaDropView}>
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.DriverIDValidationTitle', {
                            title: t('Common.Value')
                          })}
                          value={
                            findValueByTitle(
                              testResults,
                              'Driver ID Validation: Tablet (Non-ELD)',
                              localization
                            ).title
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.DriverIDValidationTitle', {
                            title: t('Common.Status')
                          })}
                          value={
                            findValueByTitle(
                              testResults,
                              'Driver ID Validation: Tablet (Non-ELD)',
                              localization
                            ).status
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.DriverIDValidationTitle', {
                            title: t('Common.Details')
                          })}
                          value={
                            findValueByTitle(
                              testResults,
                              'Driver ID Validation: Tablet (Non-ELD)',
                              localization
                            ).subtitle
                          }
                          styles={styles}
                        />
                      </div>
                    }
                    icon={icon[9]}
                    onChange={() =>
                      setIcon([
                        icon[0],
                        icon[1],
                        icon[2],
                        icon[3],
                        icon[4],
                        icon[5],
                        icon[6],
                        icon[7],
                        icon[8],
                        !icon[9],
                        icon[10],
                        icon[11],
                        icon[12],
                        icon[13],
                        icon[14]
                      ])
                    }
                  />
                  <Accordion
                    isOpen
                    title={t(
                      'SupportToolsELD.InstallationLogsView.DriverIDValidationTabletDetails',
                      { title: '(DID3)' }
                    )}
                    body={
                      <div className={style.megaDropView}>
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.DriverIDValidationTitle', {
                            title: t('Common.Value')
                          })}
                          value={
                            findValueByTitle(
                              testResults,
                              'Driver ID Validation: Key Fob/PIN Pad/RFID Card',
                              localization
                            ).title
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.DriverIDValidationTitle', {
                            title: t('Common.Status')
                          })}
                          value={
                            findValueByTitle(
                              testResults,
                              'Driver ID Validation: Key Fob/PIN Pad/RFID Card',
                              localization
                            ).status
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.DriverIDValidationTitle', {
                            title: t('Common.Details')
                          })}
                          value={
                            findValueByTitle(
                              testResults,
                              'Driver ID Validation: Key Fob/PIN Pad/RFID Card',
                              localization
                            ).subtitle
                          }
                          styles={styles}
                        />
                      </div>
                    }
                    icon={icon[10]}
                    onChange={() =>
                      setIcon([
                        icon[0],
                        icon[1],
                        icon[2],
                        icon[3],
                        icon[4],
                        icon[5],
                        icon[6],
                        icon[7],
                        icon[8],
                        icon[9],
                        !icon[10],
                        icon[11],
                        icon[12],
                        icon[13],
                        icon[14]
                      ])
                    }
                  />
                  <Accordion
                    isOpen
                    title={t('SupportToolsELD.InstallationLogsView.ValidationDetails', {
                      title: 'IQ Camera'
                    })}
                    body={
                      <div className={style.megaDropView}>
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.IQCameraValidationStatus')}
                          value={InfoRowWithLink({
                            value: findValueByTitle(
                              testResults,
                              'IQ Camera Validation:',
                              localization
                            ).title,
                            styles: style
                          })}
                          styles={style}
                        />
                      </div>
                    }
                    icon={icon[11]}
                    onChange={() =>
                      setIcon([
                        icon[0],
                        icon[1],
                        icon[2],
                        icon[3],
                        icon[4],
                        icon[5],
                        icon[6],
                        icon[7],
                        icon[8],
                        icon[9],
                        icon[10],
                        !icon[11],
                        icon[12],
                        icon[13],
                        icon[14]
                      ])
                    }
                  />
                  <Accordion
                    isOpen
                    title={t('SupportToolsELD.InstallationLogsView.ValidationDetails', {
                      title: 'Multi-IQ Camera'
                    })}
                    body={
                      <div className={style.megaDropView}>
                        <InfoRow
                          label={t(
                            'SupportToolsELD.InstallationLogsView.MultiIQCameraValidationTV',
                            { index: 1 }
                          )}
                          value={InfoRowWithLink({
                            value: multiCameraIQValues.TV1,
                            styles: style
                          })}
                          styles={style}
                        />
                        <InfoRow
                          label={t(
                            'SupportToolsELD.InstallationLogsView.MultiIQCameraValidationTV',
                            { index: 2 }
                          )}
                          value={InfoRowWithLink({
                            value: multiCameraIQValues.TV2,
                            styles: style
                          })}
                          styles={style}
                        />
                      </div>
                    }
                    icon={icon[12]}
                    onChange={() =>
                      setIcon([
                        icon[0],
                        icon[1],
                        icon[2],
                        icon[3],
                        icon[4],
                        icon[5],
                        icon[6],
                        icon[7],
                        icon[8],
                        icon[9],
                        icon[10],
                        icon[11],
                        !icon[12],
                        icon[13],
                        icon[14]
                      ])
                    }
                  />
                  <Accordion
                    isOpen
                    title={t('SupportToolsELD.InstallationLogsView.ValidationDetails', {
                      title: 'Camera Driver Event'
                    })}
                    body={
                      <div className={style.megaDropView}>
                        <InfoRow
                          label={t(
                            'SupportToolsELD.InstallationLogsView.CameraDriverEventValidationTitle',
                            { title: t('Common.Value') }
                          )}
                          value={
                            findValueByTitle(testResults, 'Camera Driver Event:', localization)
                              .value
                          }
                          styles={styles}
                        />

                        <InfoRow
                          label={t(
                            'SupportToolsELD.InstallationLogsView.CameraDriverEventValidationTitle',
                            { title: t('Common.Status') }
                          )}
                          value={
                            findValueByTitle(testResults, 'Camera Driver Event:', localization)
                              .status
                          }
                          styles={styles}
                        />
                      </div>
                    }
                    icon={icon[13]}
                    onChange={() =>
                      setIcon([
                        icon[0],
                        icon[1],
                        icon[2],
                        icon[3],
                        icon[4],
                        icon[5],
                        icon[6],
                        icon[7],
                        icon[8],
                        icon[9],
                        icon[10],
                        icon[11],
                        icon[12],
                        !icon[13],
                        icon[14]
                      ])
                    }
                  />

                  <Accordion
                    isOpen
                    title={t('SupportToolsELD.InstallationLogsView.ValidationDetails', {
                      title: 'Temperature Sensor'
                    })}
                    body={
                      <div className={style.megaDropView}>
                        <InfoRow
                          label={t(
                            'SupportToolsELD.InstallationLogsView.TemperatureSensorValidationTitle',
                            { title: t('Common.Value') }
                          )}
                          value={
                            findValueByTitle(
                              testResults,
                              'Temperature Sensor Data: Last Temperature',
                              localization
                            ).title
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t(
                            'SupportToolsELD.InstallationLogsView.TemperatureSensorValidationTitle',
                            { title: t('Common.Status') }
                          )}
                          value={
                            findValueByTitle(
                              testResults,
                              'Temperature Sensor Data: Last Temperature',
                              localization
                            ).status
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t(
                            'SupportToolsELD.InstallationLogsView.TemperatureSensorValidationTitle',
                            { title: t('Common.Details') }
                          )}
                          value={
                            findValueByTitle(
                              testResults,
                              'Temperature Sensor Data: Last Temperature',
                              localization
                            ).subtitle
                          }
                          styles={styles}
                        />
                      </div>
                    }
                    icon={icon[14]}
                    onChange={() =>
                      setIcon([
                        icon[0],
                        icon[1],
                        icon[2],
                        icon[3],
                        icon[4],
                        icon[5],
                        icon[6],
                        icon[7],
                        icon[8],
                        icon[9],
                        icon[10],
                        icon[11],
                        icon[12],
                        icon[13],
                        !icon[14]
                      ])
                    }
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </ContainerPageWrapper>
      <div style={{ display: 'none' }}>
        {triggerButton && (
          <DownloadAuditLogsView
            triggerButton={triggerButton}
            setTriggerButton={setTriggerButton}
            state={{
              ...(state && state?.data?.rowData),
              supportType: state && state?.data?.supportType
            }}
            findValueByTitle={findValueByTitle}
            testResults={testResults}
          />
        )}
      </div>
      <div>
        <ProgressModal
          title={`${title}${extension}`}
          open={modalVisible}
          progress={progress}
          onClose={() => setModalVisible(false)}
          t={t}
        />
      </div>
    </>
  );
}
