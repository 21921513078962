import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useCurrentCompany } from 'features/company/companySlice';
import { updateConfig, useIsConfigUpdating, useConfig } from 'features/scorecard';
import { isValid, updateColors } from './helpers';
import { Input } from 'components/ant';
import { Button, Modal } from 'antd';
import { EmptyRange } from './constants';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { clearScore } from 'features/scorecard/reducers';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { ReactComponent as RemoveIcon } from 'static/images/icons/remove.svg';
import { useCanOneOfEntities } from 'features/permissions/canHooks';
import entities from 'features/permissions/entities';
import Can from 'features/permissions/Can';

import style from './ScoreConfiguration.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const ScoreConfigurationModal = ({ isOpen = false, handleCancel, handleConfirm, title }) => {
  const { t } = useTranslation();
  const currentCompany = useCurrentCompany();
  const dispatch = useDispatch();
  const currentConfig = useConfig();
  const [newConfig, setNewConfig] = useState({ companyTarget: 90, colorBands: [] });
  const [isValidRange, setIsValidRange] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const isConfigUpdating = useIsConfigUpdating();
  const canUpdateConfig = useCanOneOfEntities([entities.SCORECARD_UPDATE]);

  useEffect(() => {
    setNewConfig({
      companyTarget: currentConfig.companyTarget,
      colorBands: currentConfig.colorBands.map(range => {
        const newLabel = t(`Scorecard.${range.label}`, range.label);
        return {
          ...range,
          label: newLabel
        };
      })
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentConfig, t]);

  useEffect(() => {
    setIsValidRange(isValid(newConfig.colorBands));
  }, [newConfig]);

  const onCancel = () => {
    if (isDirty) {
      confirmationModal(
        t('Easydocs.Notifications.AreYouSure'),
        t('Scorecard.CancelChangesMessage'),
        t('Common.Modal.CancelChanges'),
        t('Common.Modal.Stay'),
        handleCancel
      );
    } else {
      handleCancel();
    }
  };

  const handleRemove = index => {
    const newColorBands = [...newConfig.colorBands];

    newColorBands.splice(index, 1);

    if (index === 0) {
      newColorBands[0] = {
        ...newColorBands[0],
        value: 0
      };
    }

    setNewConfig({
      companyTarget: newConfig.companyTarget,
      colorBands: updateColors(newColorBands)
    });
    setIsDirty(true);
  };

  const handleSave = async () => {
    const result = await dispatch(
      updateConfig({ config: newConfig, companyId: currentCompany?.id })
    );
    if (result.error) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('Scorecard.ErrorSave')
        })
      );
    } else {
      dispatch(clearScore());
      dispatch(
        openToast({
          type: ToastType.Success,
          message: t('Scorecard.SuccessSave')
        })
      );
      handleConfirm();
    }
  };

  const handleAdd = () => {
    const lastValue = newConfig.colorBands[newConfig.colorBands.length - 1].value;
    const newColorBands = [
      ...newConfig.colorBands,
      {
        ...EmptyRange,
        value: lastValue + 1
      }
    ];

    setNewConfig({
      companyTarget: newConfig.companyTarget,
      colorBands: updateColors(newColorBands)
    });

    setIsDirty(true);
  };

  const handleCompanyTargetValueChange = e => {
    let newCompanyTarget = Number(e.target.value) || 0;

    if (newCompanyTarget < 0) newCompanyTarget = 0;
    if (newCompanyTarget > 100) newCompanyTarget = 100;

    setNewConfig({
      companyTarget: newCompanyTarget,
      colorBands: [...newConfig.colorBands]
    });
    setIsDirty(true);
  };

  const handleColorBandValueChange = (e, index) => {
    let colorBands = JSON.parse(JSON.stringify(newConfig.colorBands));
    colorBands[index].value = Number(e.target.value) || 0;
    setNewConfig({
      companyTarget: newConfig.companyTarget,
      colorBands: colorBands
    });
    setIsDirty(true);
  };

  const handleColorBandLabelChange = (e, index) => {
    let colorBands = JSON.parse(JSON.stringify(newConfig.colorBands));
    colorBands[index].label = e.target.value;
    setNewConfig({
      companyTarget: newConfig.companyTarget,
      colorBands: colorBands
    });
    setIsDirty(true);
  };

  const handleAfterClose = () => {
    setNewConfig(currentConfig);
    setIsDirty(false);
  };

  return (
    <Modal
      title={title}
      open={isOpen}
      onCancel={onCancel}
      width={700}
      okText={t('Common.Save')}
      onOk={handleSave}
      wrapClassName={style.modalWrapper}
      okButtonProps={{
        loading: isConfigUpdating,
        disabled: !isValidRange || !canUpdateConfig
      }}
      afterClose={handleAfterClose}
    >
      <div className={style.headerText}>{t('Scorecard.CompanyTargetScore')}</div>
      <Input
        disabled={!canUpdateConfig}
        className={style.companyTargetInput}
        value={newConfig.companyTarget}
        onChange={e => handleCompanyTargetValueChange(e)}
      />
      <div className={style.headerText}>{t('Scorecard.ScoreRanges')}</div>
      <div className={style.table}>
        <div className={style.tableHeader}>
          <div className={style.color}>{t('Scorecard.Color')}</div>
          <div className={style.scoreRange}>{t('Scorecard.ScoreRange')}</div>
          <div className={style.scoreBandLabel}>{t('Scorecard.ScoreBandLabel')}</div>
          <div className={style.remove}>{t('Scorecard.Remove')}</div>
        </div>
        {newConfig.colorBands.map((range, index) => {
          const { label, value, color } = range;
          const squareClass = cn(style.square, style[`square${color}`]);
          const removeClass = cn(style.remove, {
            [style.noEvents]: newConfig.colorBands.length <= 2
          });

          const rangeMin = value;
          const rangeMax = newConfig.colorBands[index + 1]
            ? newConfig.colorBands[index + 1].value - 0.1
            : 100;

          return (
            <div className={style.row} key={`row-${index}`}>
              <div className={style.color}>
                <div className={squareClass}></div>
              </div>
              <div className={style.scoreRange}>
                <Input
                  disabled={index === 0 || !canUpdateConfig}
                  className={style.input}
                  value={value}
                  onChange={e => handleColorBandValueChange(e, index)}
                />
                <div className={style.rangeDetails}>{`${rangeMin} - ${rangeMax}`}</div>
              </div>
              <div className={style.scoreBandLabel}>
                <Input
                  disabled={!canUpdateConfig}
                  className={style.inputLabel}
                  onChange={e => handleColorBandLabelChange(e, index)}
                  value={label}
                />
              </div>
              <Can oneOfEntities={[entities.SCORECARD_UPDATE]}>
                <div className={removeClass}>
                  <RemoveIcon onClick={() => handleRemove(index)} />
                </div>
              </Can>
            </div>
          );
        })}
      </div>
      {newConfig.colorBands.length < 4 && (
        <div className={style.addButtonWrapper}>
          <Button
            onClick={handleAdd}
            id={BUTTON_IDS.scoreConfigurationAdd}
            disabled={!canUpdateConfig}
          >
            {t('Scorecard.AddNewRange')}
          </Button>
        </div>
      )}
      <div className={style.bottomBuffer}></div>
    </Modal>
  );
};
