export const TILES_DIRECTION = 'horizontal';

export const TILES_GRID_SYSTEM = {
  gutter: 12,
  xs: 1,
  sm: 2,
  md: 2,
  lg: 3,
  xl: 4,
  xxl: 6
};

export const TYLE_LIST_STYLE = { padding: 20 };

export const PATHS = {
  REGULATORY: '/regulatory',
  TMA_DECLARATION: '/regulatory/tma_declaration'
};

export const TILES = [
  {
    translationKey: 'Regulatory.TMADeclaration',
    img: null,
    path: PATHS.TMA_DECLARATION
  }
];

export const commentTypes = t => [
  {
    id: 'Road Closure',
    label: t('Regulatory.Modal.Form.CommentTypes.Road Closure')
  },
  {
    id: 'Redirection By Authorised Officer',
    label: t('Regulatory.Modal.Form.CommentTypes.Redirection by Authorised Officer')
  },
  {
    id: 'Operation Under Special Permit',
    label: t('Regulatory.Modal.Form.CommentTypes.Operation Under Special Permit')
  },
  {
    id: 'Other',
    label: t('Regulatory.Modal.Form.CommentTypes.Other')
  }
];

export const radioOptions = t => [
  {
    label: t('Regulatory.Modal.Form.RadioOptions.VehicleType'),
    value: 1
  },
  {
    label: t('Regulatory.Modal.Form.RadioOptions.Comment'),
    value: 2
  }
];
