import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import PropTypes from 'prop-types';
import { Form, Spin, Switch } from 'antd';
import FormFooter from 'components/form-footer/FormFooter';
import AlertStatusBar from 'components/alertStatusBar/AlertStatusBar';
import AlertSelect from 'components/alertSelect/AlertSelect';
import AntFormInput from 'components/AntFormInput/AntFormInput';
import MultipleSelectTable from 'components/multipleSelectTable/MultipleSelectTable';
import { FormInputNumber } from './helpers/FormInputNumber/FormInputNumber';

import { useIsAddAlertSaving, useIsEditAlertSaving } from 'features/company_alerts';
import { useCurrentCompany } from 'features/company/companySlice';
import { useUserInfo } from 'features/user/userSlice';

import { saveAlert } from './helpers/handleAPICalls';
import { FormCheckboxes } from './helpers/FormCheckboxes/FormCheckboxes';
import alertStyles from './BaseAlertForm.module.scss';
import { Scheduler } from 'components/scheduler/Scheduler';
import { useIsEditUserAlertSaving, useIsUserAlertsEnabled } from 'features/user_alerts';
import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';

import styles from './BaseAlertForm.module.scss';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { useTranslation } from 'react-i18next';
import { ENTITIES, PAGE_TYPES } from 'components/alertStatusBar/constants';
import { isEntitySelected } from 'components/alertStatusBar/helpers/isEntitySelected';
import { isFolderSelected } from 'components/alertStatusBar/helpers/isFolderSelected';
import { ALERT_TYPES, MULTISELECT_TYPE } from 'containers/Configuration/CompanyAlerts/constants';
import MeterConfiguration from 'components/meterConfiguration/MeterConfiguration';
import { FeatureFlag, useCanFeatureFlag } from 'features/permissions';

const handleCancel = history => () => {
  history.goBack();
};

const BaseAlertForm = ({
  form,
  alertState,
  initialValues,
  config,
  multiTieredConfig,
  action,
  entityType,
  alerts
}) => {
  const dispatch = useDispatch();
  const { clone, type } = useParams();
  const { t } = useTranslation();
  const currentCompany = useCurrentCompany();
  const history = useHistory();
  const isAddAlertSaving = useIsAddAlertSaving();
  const isEditAlertSaving = useIsEditAlertSaving();
  const isEditUserAlertSaving = useIsEditUserAlertSaving();
  const isUserAlertsEnabled = useIsUserAlertsEnabled();
  const currentUser = useUserInfo();
  const [isDirty, setIsDirty] = useState(false);
  const formRef = useRef(null);

  const getSelectedOptions = (alertState, type) => {
    switch (type) {
      case MULTISELECT_TYPE.FLEETS:
        return alertState?.selectedFleets;
      case MULTISELECT_TYPE.VEHICLES:
        return []
          .concat(alertState?.selectedVehicles)
          .concat(alertState?.selectedDevices)
          .filter(val => typeof val !== 'undefined');
      case MULTISELECT_TYPE.BRANCHES:
        return alertState?.selectedBranches;
      case MULTISELECT_TYPE.DRIVERS:
        return alertState?.selectedDrivers;
      case MULTISELECT_TYPE.USERS:
        return alertState?.selectedUsers;
      case MULTISELECT_TYPE.SECONDALERTUSERS:
        return alertState?.selectedSecondAlertUsers;
      case MULTISELECT_TYPE.THIRDALERTUSERS:
        return alertState?.selectedThirdAlertUsers;
      case MULTISELECT_TYPE.FORMS:
        return alertState?.selectedForms;
      case MULTISELECT_TYPE.GEOFENCES:
        return alertState?.selectedGeofences;
      case MULTISELECT_TYPE.MANAGED_GEOFENCES:
        return alertState?.selectedManagedGeofences;
      case MULTISELECT_TYPE.DOCUMENTEXPIRATIONFOLDERS:
        return alertState?.selectedDocumentExpirationFolders;
      case MULTISELECT_TYPE.PRETRIP_CHECKLISTS:
        return alertState?.selectedPretrips;
      case MULTISELECT_TYPE.GPIO:
        return alertState?.selectedGpioTypes;
      case MULTISELECT_TYPE.DURESS:
        return alertState?.selectedDuressTypes;
      default:
        return [];
    }
  };

  const getActiveStatus = (entityType, isUserAlertsEnabled) => {
    if (entityType === ENTITIES.USER) {
      return isUserAlertsEnabled
        ? alertState?.mail || alertState?.desktop || alertState?.phone
        : isUserAlertsEnabled;
    }
    return alertState?.mail || alertState?.desktop || alertState?.phone;
  };

  const oneWireMultiProbeFlag = useCanFeatureFlag({
    featureFlag: FeatureFlag.oneWireMultiProbe.flag
  });

  const payloadProperties = {
    dispatch,
    action,
    id: currentCompany.id,
    history,
    entityType,
    activeStatus: getActiveStatus(entityType, isUserAlertsEnabled),
    currentUser,
    config: oneWireMultiProbeFlag ? config : null
  };

  useEffect(() => {
    if (formRef.current) {
      form.setFieldsValue(initialValues);
    }
  }, [form, formRef, initialValues]);

  const onFinish = () => {
    if (
      (alertState.isSecondAlertEnabled &&
        ((entityType === ENTITIES.COMPANY && alertState.selectedSecondAlertUsers?.length == 0) ||
          !alertState.secondAlertTimer)) ||
      (alertState.isThirdAlertEnabled &&
        ((entityType === ENTITIES.COMPANY && alertState.selectedThirdAlertUsers?.length == 0) ||
          !alertState.thirdAlertTimer))
    ) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${t('Alerts.Toast.SelectionRequiredTimer')}.`
        })
      );
      return;
    }

    if (
      (isEntitySelected(alertState) &&
        alertState.selectedUsers?.length &&
        entityType === ENTITIES.COMPANY) ||
      (isEntitySelected(alertState) && entityType === ENTITIES.USER) ||
      (alertState.selectedUsers?.length &&
        alertState.selectedGeofences?.length &&
        alertState.type === ALERT_TYPES.GEOFENCE_EXPIRY) ||
      (alertState.selectedGeofences?.length &&
        alertState.type === ALERT_TYPES.GEOFENCE_EXPIRY &&
        entityType === ENTITIES.USER) ||
      isFolderSelected(alertState)
    ) {
      dispatch(saveAlert(alertState, payloadProperties, setIsDirty, type));
    } else {
      if (alertState.type === 'Ng::DocumentExpirationSubscription') {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: `${t('Alerts.Toast.SelectionFolderRequired')}.`
          })
        );
      } else if (alertState.type === 'Ng::GeofenceExpirationSubscription') {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: `${t('Alerts.Toast.SelectionRequiredGeofence')}.`
          })
        );
      } else {
        dispatch(
          openToast({
            type: ToastType.Error,
            message:
              entityType === ENTITIES.COMPANY
                ? `${t('Alerts.Toast.SelectionRequiredCompany')}.`
                : `${t('Alerts.Toast.SelectionRequiredUser')}.`
          })
        );
      }
    }
  };

  const renderConfig = elem => {
    const listOfAlerts = {
      entries: [alertState],
      action: 'edit',
      pageType: PAGE_TYPES.EDIT,
      entityType,
      currentCompanyId: currentCompany.id
    };

    switch (elem.type) {
      case 'alertStatusBar':
        if (clone) {
          if (alertState?.id > 0) {
            return (
              listOfAlerts?.entries && (
                <AlertStatusBar
                  key={elem.id}
                  listOfAlerts={listOfAlerts}
                  onChange={elem.onChange}
                  setIsDirty={setIsDirty}
                />
              )
            );
          } else {
            return (
              <div key={elem.id} className={styles.alertStatusBarLoader}>
                <Spin />
              </div>
            );
          }
        } else {
          return (
            listOfAlerts?.entries && (
              <AlertStatusBar
                key={elem.id}
                listOfAlerts={listOfAlerts}
                onChange={elem.onChange}
                setIsDirty={setIsDirty}
              />
            )
          );
        }

      case 'checkbox':
        return (
          <FormCheckboxes
            key={elem.id}
            options={elem.options}
            properties={elem.properties}
            onChange={elem.onChange}
            initialValues={initialValues}
            setIsDirty={setIsDirty}
          />
        );
      case 'inputNumber':
        return (
          <FormInputNumber
            key={elem.id}
            properties={elem.properties}
            onChange={elem.onChange}
            setIsDirty={setIsDirty}
          />
        );
      case 'formInput':
        return (
          <AntFormInput
            key={elem.id}
            properties={elem.properties}
            alerts={alerts}
            alertId={alertState?.id}
            onChange={elem.onChange}
            setIsDirty={setIsDirty}
          />
        );
      case 'select':
        return (
          <AlertSelect
            name={elem.properties.name}
            key={elem.id}
            properties={elem.properties}
            options={elem.options}
            onChange={elem.onChange}
            setIsDirty={setIsDirty}
            mode={elem?.mode}
          />
        );
      case 'meterConfiguration':
        return (
          <MeterConfiguration
            key={elem.id}
            properties={elem.properties}
            options={elem.options}
            onChange={elem.onChange}
            setIsDirty={setIsDirty}
            alertState={alertState}
            isLoading={elem.isLoading}
            action={action}
          />
        );
      case 'multiselect':
        return (
          <MultipleSelectTable
            form={form}
            key={elem.id}
            id={elem.id}
            selected={getSelectedOptions(alertState, elem?.multiSelectType)}
            options={elem.options}
            properties={elem.properties}
            isLoading={elem.isLoading}
            onChange={elem.onChange}
            setIsDirty={setIsDirty}
            disabled={elem?.disable && elem.disable(elem.options)}
          />
        );
      case 'scheduler':
        return (
          <Form.Item
            wrapperCol={{
              offset: 1,
              span: 16
            }}
            key={elem.id}
          >
            <Scheduler initialSchedule={initialValues.calendar} onChange={elem.onChange} />
          </Form.Item>
        );
      case 'switch':
        return (
          <Form.Item
            key={elem.id}
            name={t(elem.properties?.name)}
            label={t(elem.properties?.label)}
            style={elem.style}
            properties={elem.properties}
            valuePropName="checked"
          >
            <Switch onChange={elem.onChange} />
          </Form.Item>
        );
      default:
        return <div>Loading...</div>;
    }
  };

  return (
    <div className={alertStyles.mainContainer}>
      {alertState?.id > 0 || (action === 'add' && !clone) ? (
        <>
          <EditRouteGuard when={isDirty} navigate={history.push} />
          <Spin spinning={isAddAlertSaving || isEditAlertSaving || isEditUserAlertSaving}>
            <Form
              initialValues={initialValues}
              form={form}
              ref={formRef}
              labelCol={2}
              wrapperCol={16}
              layout="horizontal"
              size="large"
              onFinish={onFinish}
            >
              <div className={alertStyles.formContainer}>
                {config.map(elem => renderConfig(elem))}
              </div>
              <div className={alertStyles.additionalFormContainer}>
                {multiTieredConfig.map(elem => renderConfig(elem))}
              </div>
              <FormFooter
                handleCancel={handleCancel(history)}
                isSaving={isAddAlertSaving || isEditAlertSaving || isEditUserAlertSaving}
              />
            </Form>
          </Spin>
        </>
      ) : (
        <div className={alertStyles.loaderContainer}>
          <Spin />
        </div>
      )}
    </div>
  );
};

BaseAlertForm.propTypes = {
  form: PropTypes.shape({}).isRequired,
  alertState: PropTypes.shape({}).isRequired,
  config: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      onChange: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
          deviceType: PropTypes.func,
          meterEntityType: PropTypes.func,
          meterType: PropTypes.func,
          meterValue: PropTypes.func,
          operation: PropTypes.func,
          source: PropTypes.func
        })
      ]),
      options: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({})),
        PropTypes.shape({
          DeviceMeters: PropTypes.arrayOf(
            PropTypes.shape({
              sources: PropTypes.arrayOf(PropTypes.string),
              type: PropTypes.string
            })
          ),
          VehicleMeters: PropTypes.arrayOf(
            PropTypes.shape({
              sources: PropTypes.arrayOf(PropTypes.string),
              type: PropTypes.string
            })
          )
        })
      ]),
      properties: PropTypes.shape({})
    })
  ).isRequired
};

export default BaseAlertForm;
