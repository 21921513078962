import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Alert, Input, Form, Typography, Tag } from 'antd';
import {
  ShareAltOutlined,
  CopyOutlined,
  DeleteOutlined,
  FontColorsOutlined
} from '@ant-design/icons';
import cn from 'classnames';

import { toLowerCaseDashDelimited } from 'utils/strings';

import {
  useAddQsAnalysisMutation,
  useUpdateQsAnalysisMutation,
  useDeleteQsDashboardMutation,
  useDeleteQsAnalysisMutation,
  useGetQsUserQuery,
  QsEntityType
} from 'services/nextgen';

import { openToast } from 'features/toasts/toastsSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import { Can, services } from 'features/permissions';
import {
  openPublishDashboardModal,
  closePublishDashboardModal,
  useIsPublishDashboardModalOpen
} from 'features/insights/insightsSlice';

import { ToastType } from 'components/notifications/toasts/Toast';
import { confirm, confirmDeleteEntity } from 'components/tn/modal/confirm';
import { PublishDashboardModal } from '../Modals';

import imgDefaultDashboard from '../Images/default-dashboard.svg';

import { ActionsMenu } from '../ActionsMenu';

import styles from './cards.module.scss';

const { Meta } = Card;
const { Text } = Typography;

const DashboardCard = ({ dashboard, categories, image, onDelete, showTags = true, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentCompany = useCurrentCompany();
  const [form] = Form.useForm();
  const formRef = React.useRef(null);
  const [addQsAnalysis] = useAddQsAnalysisMutation();
  const [updateQsAnalysis] = useUpdateQsAnalysisMutation();
  const [deleteQsAnalysis] = useDeleteQsAnalysisMutation();
  const [deleteQsDashboard] = useDeleteQsDashboardMutation();
  const isPublishModalOpen = useIsPublishDashboardModalOpen(dashboard?.analysisId);
  const qsUser = useGetQsUserQuery()?.data;
  const isQsUserAuthor = qsUser?.role?.toUpperCase() === 'AUTHOR';
  const isPublished = dashboard?.dashboardId;
  const entityType = isPublished
    ? t('Insights.PublishedDashboard')
    : t('Insights.UnpublishedDashboard');

  const isDirectorRoute = history?.location?.pathname?.startsWith('/insights/director')
    ? true
    : false;
  const [menuItems, setMenuItems] = useState([]);

  let imgDashboard;
  try {
    imgDashboard = require(`../Images/${toLowerCaseDashDelimited(dashboard?.name)}.svg`);
  } catch {
    imgDashboard = '';
  }

  useEffect(() => {
    setMenuItems([
      ...(dashboard.analysisId
        ? [
            {
              label: t('Insights.Actions.Rename'),
              key: 'rename',
              icon: <FontColorsOutlined />
            },
            {
              label: t('Insights.Actions.Copy'),
              key: 'copy',
              icon: <CopyOutlined />
            },
            {
              label: t('Insights.Actions.Publish'),
              key: 'publish',
              icon: <ShareAltOutlined />
            }
          ]
        : []),
      {
        label: t('Insights.Actions.Delete'),
        key: 'delete',
        icon: <DeleteOutlined />
      }
    ]);
  }, [dashboard]);

  const handleRenameAnalysis = () => {
    confirm({
      t,
      title: t(`Insights.Modals.RenameDashboard.Title`),
      width: '700px',
      content: (
        <>
          <div>{t(`Insights.Modals.RenameDashboard.Message`)}</div>
          <Form
            form={form}
            ref={formRef}
            layout={'vertical'}
            preserve={true}
            initialValues={{
              name: dashboard.name
            }}
          >
            <Form.Item
              required
              name="name"
              label={t(`Insights.Modals.DashboardNameLabel`)}
              rules={[
                {
                  required: true,
                  message: t(`Insights.Modals.RenameDashboard.DashboardNameRequired`)
                }
              ]}
            >
              <Input placeholder={dashboard.name} />
            </Form.Item>
          </Form>
        </>
      ),
      confirmButtonText: t(`Insights.Actions.Rename`),
      onConfirm: () => {
        return new Promise((resolve, reject) => {
          form
            .validateFields()
            .then(values => {
              // console.debug('handleRenameAnalysis - validateFields success', values);
              // copyDeleteAnalysis(form.getFieldValue('name'), resolve, reject);
              renameAnalysis(form.getFieldValue('name'), resolve, reject);
            })
            .catch(error => {
              console.error('handleRenameAnalysis - validateFields error', error);
              reject();
            });
        });
      },
      cancelButtonText: t('Common.CancelButton'),
      onCancel: () => {
        formRef.current?.resetFields();
      },
      iconStyles: { display: 'none' }
    });
  };

  const renameAnalysis = (dashboardName, resolve, reject) => {
    updateQsAnalysis({
      analysisId: dashboard.analysisId,
      name: dashboardName
    })
      .then(result => {
        // console.debug('renameAnalysis', { result: result.data });
        if (result.error) {
          throw new Error(result.error);
        }
        dispatch(
          openToast({
            type: ToastType.Success,
            message: t('Insights.Toasts.RenameDashboardSuccess', {
              sourceName: dashboard.name,
              destName: dashboardName
            })
          })
        );
        resolve();
      })
      .catch(error => {
        console.error('renameAnalysis', { error: error });
        dispatch(
          openToast({
            type: ToastType.Error,
            message: t('Insights.Toasts.RenameDashboardError', {
              sourceName: dashboard.name,
              destName: dashboardName
            })
          })
        );
        resolve();
      });
  };

  const copyDeleteAnalysis = (dashboardName, resolve, reject) => {
    addQsAnalysis({
      sourceAnalysisId: dashboard.analysisId,
      companyId: currentCompany.id,
      name: dashboardName
    })
      .then(result => {
        // console.debug('copyDeleteAnalysis', { result: result.data });
        if (result.error) {
          throw new Error(result.error);
        }

        deleteQsAnalysis({ analysisId: dashboard?.analysisId }).then(result => {
          dispatch(
            openToast({
              type: ToastType.Success,
              message: t('Insights.Toasts.RenameDashboardSuccess', {
                sourceName: dashboard.name,
                destName: dashboardName
              })
            })
          );
          resolve();
        });
      })
      .catch(error => {
        console.error('copyDeleteAnalysis', { error: error });
        dispatch(
          openToast({
            type: ToastType.Error,
            message: t('Insights.Toasts.RenameDashboardError', {
              sourceName: dashboard.name,
              destName: dashboardName
            })
          })
        );
        resolve();
      });
  };

  const handleCopyAnalysis = () => {
    confirm({
      t,
      title: t(`Insights.Modals.CopyDashboard.Title`),
      width: '700px',
      content: (
        <>
          <div>{t(`Insights.Modals.CopyDashboard.Message`)}</div>
          <Form
            form={form}
            layout={'vertical'}
            preserve={false}
            initialValues={{
              name: dashboard.name
            }}
          >
            <Form.Item
              required
              name="name"
              label={t(`Insights.Modals.DashboardNameLabel`)}
              rules={[
                {
                  required: true,
                  message: t(`Insights.Modals.CopyDashboard.DashboardNameRequired`)
                }
              ]}
            >
              <Input placeholder={dashboard.name} />
            </Form.Item>
          </Form>
        </>
      ),
      confirmButtonText: t(`Insights.Actions.Copy`),
      onConfirm: () => {
        return new Promise((resolve, reject) => {
          form
            .validateFields()
            .then(values => {
              // console.debug('handleCopyAnalysis - validateFields success', values);
              copyAnalysis(form.getFieldValue('name'), resolve, reject);
            })
            .catch(error => {
              console.error('handleCopyAnalysis - validateFields error', error);
              reject();
            });
        });
      },
      cancelButtonText: t('Common.CancelButton'),
      iconStyles: { display: 'none' }
    });
  };

  const copyAnalysis = (dashboardName, resolve, reject) => {
    addQsAnalysis({
      sourceAnalysisId: dashboard.analysisId,
      companyId: currentCompany.id,
      name: dashboardName
    })
      .then(result => {
        // console.debug('copyAnalysis', { result: result.data });
        if (result.error) {
          throw new Error(result.error);
        }
        dispatch(
          openToast({
            type: ToastType.Success,
            message: t('Insights.Toasts.CopyDashboardSuccess', {
              sourceName: dashboard.name,
              destName: dashboardName
            })
          })
        );
        resolve();
      })
      .catch(error => {
        console.error('copyAnalysis', { error: error });
        dispatch(
          openToast({
            type: ToastType.Error,
            message: t('Insights.Toasts.CopyDashboardError', {
              sourceName: dashboard.name,
              destName: dashboardName
            })
          })
        );
        resolve();
      });
  };

  const handleDeleteEntity = () => {
    confirmDeleteEntity({
      t,
      width: '700px',
      preContent: isPublished && (
        <Alert type="error" message={t('Insights.Modals.DeleteDashboard.Alert')} />
      ),
      onConfirm: deleteEntity,
      entityType,
      entityName: dashboard.name
    });
  };

  const deleteEntity = () => {
    (dashboard?.analysisId
      ? deleteQsAnalysis({ analysisId: dashboard?.analysisId })
      : deleteQsDashboard({ dashboardId: dashboard?.dashboardId })
    )
      .then(result => {
        // console.debug('deleteEntity', { result: result.data });
        if (result.error) {
          throw new Error(result.error);
        }
        dispatch(
          openToast({
            type: ToastType.Success,
            message: t('Insights.Toasts.DeleteEntitySuccess', {
              entityType: entityType,
              entityName: dashboard.name
            })
          })
        );
        onDelete && onDelete();
      })
      .catch(error => {
        console.error('deleteEntity', { error: error });
        dispatch(
          openToast({
            type: ToastType.Error,
            message: t('Insights.Toasts.DeleteEntityError', {
              entityType: entityType.toLowerCase(),
              entityName: dashboard.name
            })
          })
        );
      });
  };

  const onActionsMenuItemClick = e => {
    // console.debug('onActionsMenuItemClick', { key: e.key, dashboard });
    e.domEvent.preventDefault();
    e.domEvent.stopPropagation();
    switch (e.key) {
      case 'rename':
        handleRenameAnalysis();
        break;
      case 'copy':
        handleCopyAnalysis();
        break;
      case 'publish':
        dispatch(openPublishDashboardModal({ id: dashboard.analysisId }));
        break;
      case 'delete':
        handleDeleteEntity();
        break;
      default:
    }
  };

  return (
    <Card
      hoverable
      id={dashboard.dashboardId}
      className={styles.dashboardCard}
      cover={
        dashboard?.name !== 'Sustainability Solutions' ? (
          <img alt={dashboard.Name} src={image || imgDashboard || imgDefaultDashboard} />
        ) : (
          <div>
            <img alt={dashboard.Name} src={image || imgDashboard || imgDefaultDashboard} />
            <Tag className={styles.betaTag}>{t(`Insights.Tags.Beta`)}</Tag>
          </div>
        )
      }
      onClick={() => {
        if (isDirectorRoute) {
          !isPublishModalOpen &&
            history.push(
              `/insights/director/${
                dashboard?.dashboardId
                  ? `dashboard/${dashboard.dashboardId}${dashboard.isCustom ? '?custom' : ''}`
                  : `analysis/${dashboard.analysisId}`
              }`
            );
        } else {
          !isPublishModalOpen &&
            history.push(
              `/insights/${
                dashboard?.dashboardId
                  ? `dashboard/${dashboard.dashboardId}${dashboard.isCustom ? '?custom' : ''}`
                  : `analysis/${dashboard.analysisId}`
              }`
            );
        }
      }}
      actions={[
        <Can
          everyService={[services.INSIGHTSBUILDER]}
          everyCompanyService={[services.INSIGHTSBUILDER]}
          otherConditions={[() => dashboard?.isCustom, () => isQsUserAuthor]}
        >
          <ActionsMenu menuItems={menuItems} onItemClick={onActionsMenuItemClick} />

          {dashboard.analysisId && (
            <PublishDashboardModal
              analysis={dashboard}
              onClose={() => {
                dispatch(closePublishDashboardModal());
              }}
            />
          )}
        </Can>
      ]}
      {...props}
    >
      <Meta
        title={
          <Text style={{ width: '90%' }} ellipsis={{ tooltip: dashboard.name }}>
            {dashboard.isCustom || dashboard.id === 'new'
              ? dashboard.name
              : t(`Insights.DashboardInfo.${dashboard.name}.Name`)}
          </Text>
        }
        description={
          dashboard.isCustom || dashboard.id === 'new'
            ? ''
            : t(`Insights.DashboardInfo.${dashboard.name}.Description`)
        }
      />
      {showTags && (
        <footer className={styles.footer}>
          {/* category tags */}
          {Array.isArray(categories) &&
            categories?.map(category => (
              <Tag className={styles.categories} key={category}>
                {t(`Insights.Tags.${category}`)}
              </Tag>
            ))}

          {/* type tag */}
          <Tag
            className={cn(
              { [styles.type]: true },
              !dashboard.isCustom
                ? styles.system
                : dashboard.entityType === QsEntityType.Dashboard.singular
                ? styles.published
                : styles.draft
            )}
          >
            {!dashboard.isCustom
              ? t(`Insights.Tags.System`)
              : dashboard.entityType === QsEntityType.Dashboard.singular
              ? t(`Insights.Tags.Published`)
              : t(`Insights.Tags.Draft`)}
          </Tag>
        </footer>
      )}
    </Card>
  );
};

export default DashboardCard;
