import { getCalendarStateFromPayload } from 'components/scheduler/helpers';
import i18n from 'i18nextConfig';
import { ALERT_TYPES, companyAlertForms } from '../../constants';

export function mapCompanyAlertsAPIResponseToState(
  alerts,
  clone,
  localization,
  alertMeterThresholds = {},
  classifiedGeofences = { geofences: [], managedGeofences: [] }
) {
  return alerts?.map?.(alert => {
    const extras = alert?.extras ? JSON.parse(alert?.extras) : {};
    let selectedSecondAlertUsers = [];
    let selectedThirdAlertUsers = [];
    let isThirdAlertEnabled = false;
    let isSecondAlertEnabled = false;
    let secondAlertTimer = 0;
    let thirdAlertTimer = 0;

    if (alert.multiTieredConfig) {
      const secondAlertSetting = alert.multiTieredConfig.find(i => i.level === 2);
      const thirdAlertSetting = alert.multiTieredConfig.find(i => i.level === 3);

      if (secondAlertSetting) {
        selectedSecondAlertUsers = secondAlertSetting.userIds;
        secondAlertTimer = secondAlertSetting.timeout / 60 / 1000;
        isSecondAlertEnabled = true;
      }

      if (thirdAlertSetting) {
        selectedThirdAlertUsers = thirdAlertSetting.userIds;
        thirdAlertTimer = thirdAlertSetting.timeout / 60 / 1000;
        isThirdAlertEnabled = true;
      }
    }

    return {
      id: alert?.id,
      type: alert?.type,
      link: companyAlertForms.find(companyAlert => companyAlert.type === alert?.type)?.link,
      active: alert?.active,
      get name() {
        return clone ? `${alert?.name} - ${i18n.t('Alerts.CopiedAlert')}` : alert?.name;
      },
      phone: alert?.associations?.some(assoc => assoc.sms),
      mail: alert?.associations?.some(assoc => assoc.email),
      desktop: alert?.associations?.some(assoc => assoc.onscreen),
      expiry: alert?.expiry,
      ...(alert?.type === ALERT_TYPES.ENGINE && {
        eventTypes: alert?.extras && JSON.parse(alert?.extras).event_types
      }),
      ...(alert?.type === ALERT_TYPES.ROUTE && {
        routeTypes: (alert?.associations?.find(assoc => assoc)?.options &&
          JSON.parse(alert?.associations?.find(assoc => assoc)?.options)) || {
          started: false,
          exit: false,
          finished: false
        }
      }),
      ...(alert?.type === ALERT_TYPES.SMARTJOBS && {
        smartjobTypes: {
          completed: extras[`job.completed`] || false,
          partially_completed: extras[`job.partially_completed`] || false,
          non_delivered: extras[`job.non_delivered`] || false
        },
        inputNumberValue: extras['job.driver_feedback'] || 0
      }),

      ...(alert?.type === ALERT_TYPES.DOCUMENTEXPIRATION && {
        selectedDocumentExpirationFolders: extras.folders || [],
        inputNumberValue: extras.daystoexpire || 0
      }),
      ...(alert?.type === ALERT_TYPES.METER && {
        meterThresholdId: alert?.meterThresholdAlertAssociations[0]?.meterThresholdId
      }),
      ...(alert?.type === ALERT_TYPES.METER && {
        deviceType: alertMeterThresholds?.deviceType,
        meterOperation: alertMeterThresholds?.expression?.split(' ')[1],
        meterSource: alertMeterThresholds?.source || alertMeterThresholds?.label,
        meterType: alertMeterThresholds?.type,
        meterEntityType: alertMeterThresholds?.entityType,
        meterValue: alertMeterThresholds?.expression?.split(' ')[2]
      }),
      ...(alert?.type === ALERT_TYPES.GEOFENCE && {
        geofencesTypes: {
          entry: alert?.associations?.find(assoc => assoc)?.options
            ? JSON.parse(alert?.associations?.find(assoc => assoc)?.options).entry
            : false,
          exit: alert?.associations?.find(assoc => assoc)?.options
            ? JSON.parse(alert?.associations?.find(assoc => assoc)?.options).exit
            : false,
          thrsh_undertime: alert?.associations?.find(assoc => assoc)?.options
            ? JSON.parse(alert?.associations?.find(assoc => assoc)?.options).thrsh_undertime
            : false,
          thrsh_overtime: alert?.associations?.find(assoc => assoc)?.options
            ? JSON.parse(alert?.associations?.find(assoc => assoc)?.options).thrsh_overtime
            : false
        },
        managedGeofencesTypes: {
          entry: alert?.associations?.find(assoc => assoc)?.options
            ? JSON.parse(alert?.associations?.find(assoc => assoc)?.options).managed_entry
            : false,
          exit: alert?.associations?.find(assoc => assoc)?.options
            ? JSON.parse(alert?.associations?.find(assoc => assoc)?.options).managed_exit
            : false
        },
        selectedGeofences: (
          alert?.geofenceAlertAssociations?.map(g => g.geofenceId) || []
        ).filter(gId => classifiedGeofences.geofences.some(g => String(g.id) === String(gId))),
        selectedManagedGeofences: (
          alert?.geofenceAlertAssociations?.map(g => g.geofenceId) || []
        ).filter(gId =>
          classifiedGeofences.managedGeofences.some(g => String(g.id) === String(gId))
        ),
        speedLimit:
          alert?.associations?.length > 0 &&
          localization.convertDistance(
            JSON.parse(alert?.associations?.find(assoc => assoc)?.options)?.thrsh_speed || 0
          )
      }),
      ...(alert?.type === ALERT_TYPES.GEOFENCE_EXPIRY && {
        selectedGeofences: (
          alert?.geofenceAlertAssociations?.map(g => g.geofenceId) || []
        ).filter(gId => classifiedGeofences.geofences.some(g => String(g.id) === String(gId)))
      }),
      ...(alert?.type === ALERT_TYPES.SENTINEL && {
        fatigueViolationsNotif:
          (alert?.associations?.find(assoc => assoc)?.options &&
            JSON.parse(alert?.associations?.find(assoc => assoc)?.options)) ||
          []
      }),
      ...(alert?.type === ALERT_TYPES.SPEED && {
        speedLimit:
          alert?.associations?.length > 0 &&
          localization.convertDistance(
            JSON.parse(alert?.associations?.find(assoc => assoc)?.options)?.threshold || 0
          ),
        signposted:
          alert?.associations?.length > 0 &&
          JSON.parse(alert?.associations?.find(assoc => assoc)?.options)?.signposted
            ? 'signposted'
            : []
      }),
      ...(alert?.type === ALERT_TYPES.DRIVER_AND_VEHICLE && {
        alarmCodes: alert?.extras && JSON.parse(alert?.extras)?.alarm_codes,
        eventTypes: alert?.extras && JSON.parse(alert?.extras).event_types
      }),
      ...(alert.type === ALERT_TYPES.TACHO && {
        tachoCodes: JSON.parse(alert?.associations?.find(as => as)?.options || '{}') || []
      }),
      ...(alert?.type === ALERT_TYPES.ELD && {
        eldCodes:
          (alert?.associations?.find(assoc => assoc)?.options &&
            JSON.parse(alert?.associations?.find(assoc => assoc)?.options)) ||
          []
      }),
      selectedVehicles:
        alert?.associations.filter(assoc => assoc.vehicleId).map(assoc => assoc.vehicleId) || [],
      selectedDevices:
        alert?.associations.filter(assoc => assoc?.deviceId).map(assoc => assoc?.deviceId) || [],
      selectedFleets:
        alert?.associations.filter(assoc => assoc.fleetId).map(assoc => assoc.fleetId) || [],
      selectedDrivers:
        alert?.associations.filter(assoc => assoc.userId).map(assoc => assoc.userId) || [],
      selectedBranches:
        alert?.associations.filter(assoc => assoc.locationId).map(assoc => assoc.locationId) || [],
      selectedUsers: alert?.userIds,
      selectedSecondAlertUsers,
      selectedThirdAlertUsers,
      secondAlertTimer,
      thirdAlertTimer,
      isSecondAlertEnabled,
      isThirdAlertEnabled,
      ...(alert?.type === ALERT_TYPES.FORM && {
        selectedForms: alert?.formAlertAssociations?.map(assoc => assoc.formId)
      }),
      ...(alert?.type === ALERT_TYPES.PRETRIP && {
        selectedPretrips: alert?.preTripAlertAssociations?.map(assoc => assoc.checklistId),
        inspectionTypes: {
          dvir_failed: alert?.associations?.find(assoc => assoc)?.options
            ? JSON.parse(alert?.associations?.find(assoc => assoc)?.options)?.dvir_failed || false
            : false,
          dvir_passed: alert?.associations?.find(assoc => assoc)?.options
            ? JSON.parse(alert?.associations?.find(assoc => assoc)?.options)?.dvir_passed || false
            : false,
          dvir_repaired: alert?.associations?.find(assoc => assoc)?.options
            ? JSON.parse(alert?.associations?.find(assoc => assoc)?.options)?.dvir_repaired || false
            : false
        }
      }),
      ...(alert.type === ALERT_TYPES.OOH && {
        eventTypes: (() => {
          const oohSelectedOptions = [];
          if (extras?.io?.enabled) {
            oohSelectedOptions.push('io');
          }
          if (extras?.movement?.enabled) {
            oohSelectedOptions.push('movement');
          }
          if (extras?.geofence?.enabled) {
            oohSelectedOptions.push('geofence');
          }

          return oohSelectedOptions;
        })()
      }),
      ...(alert?.type === ALERT_TYPES.OOH && {
        timezone: alert?.calendar?.timezone
      }),
      ...(alert.type === ALERT_TYPES.OOH && getCalendarStateFromPayload(alert?.calendar)),
      ...(alert?.type === ALERT_TYPES.GPIO && {
        selectedGpioTypes: alert?.extras && JSON.parse(alert?.extras)?.gpio_types
      }),
      ...(alert?.type === ALERT_TYPES.DURESS && {
        selectedDuressTypes: alert?.extras && JSON.parse(alert?.extras)?.duress_types
      })
    };
  });
}
