import React, { useCallback, useEffect, useRef } from 'react';
import { DeviceEventGrid } from './DeviceEventGrid';
import styles from './ListView.module.scss';

export function ListView({
  events,
  totalCount,
  onLoadMoreData,
  onViewEvent,
  onRequestVideo,
  onViewEventLocation,
  isLoading,
  onSort,
  forVideoRequest,
  tableColumns,
  ...props
}) {
  const containerRef = useRef();

  const handleScroll = useCallback(
    evt => {
      if (
        containerRef.current.scrollTop > 0 &&
        containerRef.current.scrollHeight > 0 &&
        containerRef.current.scrollTop + containerRef.current.offsetHeight >=
          containerRef.current.scrollHeight * 0.75
      ) {
        if (onLoadMoreData) {
          onLoadMoreData();
          containerRef.current.removeEventListener('scroll', handleScroll);
        }
      }
    },
    [onLoadMoreData]
  );

  useEffect(() => {
    if (containerRef.current !== null && events?.length < totalCount) {
      containerRef.current.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [events, totalCount, handleScroll]);

  return (
    <div
      ref={containerRef}
      id="eventsListView"
      className={styles.listView + ' showScrollbarsOnHover'}
    >
      <DeviceEventGrid
        onSort={onSort}
        deviceEvents={events}
        isLoading={isLoading}
        onViewEvent={onViewEvent}
        onViewEventLocation={onViewEventLocation}
        onRequestVideo={onRequestVideo}
        tableColumns={tableColumns}
        forVideoRequest={forVideoRequest}
      />
    </div>
  );
}
