import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from 'antd';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import styles from '../JourneyPlanner/JourneyPlanner.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { BUTTON_IDS } from 'utils/globalConstants';

export const JourneysToolbar = ({
  isFetching,
  filteredJourneysCount,
  searchText,
  companiesFilter,
  fleetsFilter,
  typesFilter,
  foldersFilter,
  onSearchChange,
  onCompaniesFilterChange,
  onFleetsFilterChange,
  onTypesFilterChange,
  onFoldersFilterChange,
  onRestoreDefault
}) => {
  const { t } = useTranslation();
  const timeoutHandler = useRef(-1);
  const handleSearchChange = useCallback(value => {
    if (timeoutHandler.current !== -1) {
      clearTimeout(timeoutHandler.current);
      timeoutHandler.current = -1;
    }
    timeoutHandler.current = setTimeout(() => {
      if (onSearchChange) {
        onSearchChange(value);
      }
      timeoutHandler.current = -1;
    }, 100);
  }, []);
  return (
    <div className={styles.JourneysToolbar}>
      <AntSearchbar defaultValue={searchText} onFilter={handleSearchChange} />
      <AntMultiselect
        title={
          companiesFilter?.some(value => !value.checked)
            ? t('Common.Companies')
            : t('Common.AllCompanies')
        }
        data={companiesFilter}
        onFilter={onCompaniesFilterChange}
      />
      <AntMultiselect
        title={
          fleetsFilter?.some(value => !value.checked) ? t('Common.Fleets') : t('Common.AllFleets')
        }
        data={fleetsFilter}
        onFilter={onFleetsFilterChange}
      />
      <AntMultiselect
        title={
          typesFilter?.some(value => !value.checked) ? t('Common.Types') : t('Common.AllTypes')
        }
        data={typesFilter}
        onFilter={onTypesFilterChange}
      />
      <AntMultiselect
        title={
          foldersFilter?.some(value => !value.checked)
            ? t('Common.Folders')
            : t('Common.AllFolders')
        }
        data={foldersFilter}
        onFilter={onFoldersFilterChange}
      />
      <Tooltip title={t('InspectionSummary.ResetFilter')} placement="rightTop">
        <Button
          type="secondary"
          size="large"
          onClick={onRestoreDefault}
          id={BUTTON_IDS.btn_journeyRestoreDefault}
        >
          <FontAwesomeIcon icon={faRedo} style={{ color: '#64748b' }} />
        </Button>
      </Tooltip>
      <div>
        <div className={styles.journeysCount}>
          {(!isFetching ? filteredJourneysCount : 0) +
            ' ' +
            (filteredJourneysCount === 1
              ? t('JourneyPlanner.Journey')
              : t('JourneyPlanner.Journeys'))}
        </div>
      </div>
    </div>
  );
};
