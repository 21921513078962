import React, { createRef } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import { usePageTitle, useBackButton } from 'features/page/pageSlice';

import TopNav from '../nav/TopNav';

const Main = styled.div`
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  order: 2;
`;

const Subpage = styled.section`
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const Page = ({ page }) => {
  const ref = createRef(null);
  const Component = page.component;
  const TopNavComponent = page.topNav ? page.topNav : TopNav;
  const pageTitle = usePageTitle();
  const backButton = useBackButton();

  const location = useLocation();

  const hideTopNavPaths = ['/insights/director'];
  const hideTopNav = hideTopNavPaths.some(path => location?.pathname?.startsWith(path));

  return (
    <Main>
      {!hideTopNav && (
        <TopNavComponent
          title={page.usePageTitle ? pageTitle : page.title}
          showBackButton={page.useBackButton ? backButton : page.showBackButton}
        />
      )}
      <Subpage ref={ref}>
        <Component containerRef={ref} />
      </Subpage>
    </Main>
  );
};

export default Page;
