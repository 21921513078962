import { format, isValidDate } from 'utils/dates';
import style from './InstallationLog.module.scss';

export const SupportTypesEnum = {
  VEHICLE: 'vehicle',
  DEVICE: 'device',
  ENGINEER: 'engineer',
  USER: 'user'
};

export const SubTypesEnum = {
  INSTALL: 'INSTALL',
  REPLACE: 'REPLACE',
  TEST: 'TEST',
  DEINSTALL: 'DEINSTALL'
};

export const InstallMatch = ['NEWINSTALL', 'REINSTALL', 'REPAIR'];
export const ReplaceMatch = ['UPGRADE', 'REPAIRRMA'];

export const getValue = text => {
  if (text && text !== 'null') return text;
  return '';
};

export const handleDownload = async attachment => {
  try {
    const response = await fetch(attachment.url, { mode: 'cors', cache: 'no-cache' });
    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', attachment.name);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error('Error downloading the file', error);
  }
};

export const findValueByTitle = (data, title, localization) => {
  if (!data || data.length === 0) {
    return {
      title: '',
      subtitle: '',
      status: '',
      value: ''
    };
  }
  try {
    const parsedData = JSON.parse(data);
    const found = parsedData.filter(item => item.title === title);
    return (
      found && {
        title: (found[0]?.value && found[0]?.value) || '',
        subtitle:
          (found[0]?.subtitle &&
            isValidDate(found[0]?.subtitle) &&
            format(found[0]?.subtitle, localization.formats.time.formats.dby_imp)) ||
          '',
        status: (found[0]?.status && found[0]?.status) || '',
        value:
          title === 'Camera Driver Event:'
            ? (found[0]?.value &&
                isValidDate(found[0]?.value) &&
                format(found[0]?.value, localization.formats.time.formats.dby_imp)) ||
              ''
            : found[0]?.value
      }
    );
  } catch (error) {
    console.error('Error parsing JSON:', error.message);
    return {
      title: '',
      subtitle: '',
      status: ''
    };
  }
};

export const getMultiCameraIQDetails = data => {
  let latestTVI1Link = '';
  let latestTVI2Link = '';
  if (!data || data?.length === 0) {
    return { TV1: latestTVI1Link, TV2: latestTVI2Link };
  }
  try {
    const parsedData = typeof data === 'string' ? JSON.parse(data) : data;
    const multiIqEvents = parsedData
      ?.filter(event => event?.title?.startsWith('Multi-IQ Camera Validation'))
      .reduce((acc, event) => {
        const category = event?.title?.split(': ')[1];
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(event?.value);
        return acc;
      }, {});

    if (multiIqEvents['TVI1']) {
      latestTVI1Link = multiIqEvents['TVI1'][multiIqEvents['TVI1']?.length - 1];
    }

    if (multiIqEvents['TVI2']) {
      latestTVI2Link = multiIqEvents['TVI2'][multiIqEvents['TVI2']?.length - 1];
    }
    return { TV1: latestTVI1Link, TV2: latestTVI2Link };
  } catch (error) {
    console.error('Error parsing JSON:', error.message);
    return { TV1: latestTVI1Link, TV2: latestTVI2Link };
  }
};

export const findGPIOData = (data, localization) => {
  if (!data || data.length === 0) {
    return [];
  }
  try {
    const parsedData = typeof data === 'string' ? JSON.parse(data) : data;
    const GPIOData = parsedData?.filter(item => item?.title?.startsWith('GPIO:'));
    return GPIOData?.map(item => ({
      title: item?.title || '',
      value: item?.value || '',
      subtitle:
        (item?.subtitle &&
          isValidDate(item?.subtitle) &&
          format(item?.subtitle, localization.formats.time.formats.dby_imp)) ||
        ''
    }));
  } catch (error) {
    console.error('Error parsing JSON:', error.message);
    return [];
  }
};

export const InfoRowWithLink = ({ value, styles }) => {
  const isUrl = value && (value.startsWith('http://') || value.startsWith('https://'));
  return isUrl ? (
    <a
      href={value}
      target="_blank"
      rel="noopener noreferrer"
      className={styles ? styles.link : style.link}
    >
      {value}
    </a>
  ) : (
    ''
  );
};
