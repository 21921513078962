import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Formik, Form } from 'formik';
import { startCase, toLower, sortBy } from 'lodash';
import request from 'superagent';
import {
  FormGroup,
  FormCheck,
  Row,
  Col,
  Tabs,
  Tab,
  ToggleButton,
  ToggleButtonGroup,
  FormLabel
} from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import bcrypt from 'bcryptjs';
import { API_PATH } from 'config';
import {
  useCompanies,
  useCurrentCompany,
  useIsELDCompany,
  useCompanyConfig,
  fetchCompanyConfig,
  useRedirectToMainFeaturePageOnCompanyChange
  // useIsCompanyKeyDifferent
} from 'features/company/companySlice';
import { useGetTimezonesQuery } from 'services/nextgen';
import { usePendingStartOfDay, fetchPendingStartOfDay } from 'features/eld/eldSlice';
import { useUserKey, useUserInfo, getCurrentUserInfo } from '../../../features/user/userSlice';
import {
  attachFiles,
  removeFiles,
  useUsers,
  useIsCompanyKeyDifferent
} from '../../../features/users/usersSlice';
import { useAllRegions } from 'features/regions/regionsSlice';
import { useUserData } from './APICalls';
import { getBranchesByCompany, useRoles } from './APICalls';
import { getFleetsByCompany } from 'containers/Administration/Fleets/APICalls';
import {
  tooltip,
  initialValues,
  validationSchema,
  limitedUserRoles,
  USER_TYPES,
  ACTIONS,
  BRANCH_TYPE,
  FLEET_TYPE,
  PATHS
} from './constants';
import { FormDatePicker } from 'components/ant';
import { FileUpload } from 'components/FileUpload';
import { DocumentsDisplay } from 'components/Documents';
import FormInput from 'components/form/form-input/FormInput';
import FormTitle from 'components/form/form-title/FormTitle';
import FormSelect from 'components/form/form-select/FormSelect';
import { ToastType } from 'components/notifications/toasts/Toast';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { PhoneInputField, usePhoneNumber } from 'components/form/phone-input-field';
import {
  getLicenceStateLabel,
  getLicenceStatePlaceholder
} from 'features/localization/localization';
import { ImageUpload, Select, Table, Button } from 'components/ant';
import { saveImage, removeImage } from 'features/images/imagesSlice';
import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';
import { useLocalization } from 'features/localization/localizationSlice';
import { format } from 'utils/dates';
import { Alert, DatePicker, Space } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import moment from 'moment';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { openToast } from 'features/toasts/toastsSlice';
import { useCan, services, useIQCameraUser, FeatureFlag } from 'features/permissions';
import { EWDTab } from './UserFormTabs/EWDTab';
import { fetchEWDUser, useEWDUser } from 'features/user/EWDUserHooks';
import { useTranslation } from 'react-i18next';
import { UserSecurityFields } from './UserSecurityFields';
import { canHistoryGoBack, formatBytes, usePrevious } from 'utils/methods';
import { parseUserErrorMessage } from 'utils/strings';
import { SPTab } from './UserFormTabs/SPTab';
import { ELDDriverTab } from './UserFormTabs/ELDDriverTab';
import { ELDRulesetTab } from './UserFormTabs/ELDRulesetTab';
import { fetchELDDrivingConditions } from 'features/users/usersSlice';
import {
  getPermissionsQueryParams,
  getExternalPins,
  getDefaultTimezoneValue,
  getElectronicPins,
  getElectronicPin2s
} from './helpers';
import { filterFilesBy } from 'utils/files';
import GlobalRoles from 'features/permissions/GlobalRoles';

//styles
import styles from './Users.module.scss';
import cn from 'classnames';
import { useDriverManagementFields, useMulticarrierFields } from 'features/company_config';
import { useVehicles } from 'features/fleets/fleetsSlice';
import { isDefaultDriverOfVehicle } from 'containers/Administration/Vehicles/DefaultDriver/constants';
import { BUTTON_IDS } from 'utils/globalConstants';
import { getConfig, useConfig } from 'features/easydocs/documentsSlice';
import { getCountryRegions } from 'utils/countryState';

const checkCommonValues = (allValues, checkedValues) => {
  const mergedValues = allValues.map(value => {
    const oldValue = value;
    if (checkedValues.find(val => val.id === oldValue.id)) {
      oldValue.checked = true;
    }
    return oldValue;
  });
  return mergedValues;
};

const formatDataForCheckbox = data =>
  data.map(val => ({
    ...val,
    checked: !!val.checked
  }));

const disableRolesByUserType = (userType, roles) =>
  roles.map(role => {
    role.disabled = false;
    if (userType.toUpperCase() === USER_TYPES.DRIVER.toUpperCase()) {
      role.disabled = !role.features.includes(userType.toUpperCase());
    }
    if (userType.toUpperCase() === USER_TYPES.SUPPORT_PERSONNEL.toUpperCase()) {
      role.disabled = !(
        role.features.includes(userType.toUpperCase()) ||
        (role.name === 'Driver' && role.global)
      );
    }
    return role;
  });

const ELD_TACHO_DRIVER_PATTERN = /^ELD|UKT/gi;

const IQCameraUserRoles = [GlobalRoles.IQCameraAdministrator, GlobalRoles.IQCameraUser];

export const UserForm = ({ setToastMessageCb, action, rulesets, setRulesetsByCountryCb }) => {
  const { t } = useTranslation();
  const path = window.location.pathname;
  const userEditId = path.substr(path.lastIndexOf('/') + 1, path.length - 1);
  const [refetch, setRefetch] = useState(0);
  const history = useHistory();
  const handleUserFetchError = useCallback(() => {
    history.replace(PATHS.USERS_DEFAULT);
  }, [history]);
  const data = useUserData({
    id: action === ACTIONS.EDIT ? parseInt(userEditId, 10) : null,
    refetch,
    onError: handleUserFetchError,
    embedParam: `audits,fleets,locations,image_url,user_session,document${
      action === ACTIONS.EDIT ? ',associations' : ''
    }`
  });
  const userKey = useUserKey();
  const users = useUsers();
  const regions = useAllRegions();
  const userExternalIds = users.reduce((acc, curr) => {
    if (curr.externalId) {
      acc.push(curr.externalId);
    }
    return acc;
  }, []);
  const licenceNumbers = users.reduce((acc, curr) => {
    if (curr.licenceNumber) {
      acc.push(curr.licenceNumber);
    }
    return acc;
  }, []);
  const userCompanyId = data && data.userViewData && data.userViewData.companyId;
  const company = useCurrentCompany(userCompanyId);
  const roles = useRoles(userKey);
  const currentUser = useUserInfo();
  const can = useCan();
  const canAccessNonCameraFeatures = can({
    beyondRoles: [...IQCameraUserRoles, ...[GlobalRoles.SubCompanyAdmin]]
  });
  const isSubCompanyAdmin = can({ oneOfRoles: [GlobalRoles.SubCompanyAdmin] });
  const isBeyondSubCompanyAdmin = can({ beyondRoles: [GlobalRoles.SubCompanyAdmin] });

  const [userType, setUserType] = useState(USER_TYPES.USER);
  const [branches, setBranches] = useState([]);
  const [states, setStates] = useState([]);
  const [timezones, setTimezones] = useState([]);
  const [currentRegion, setCurrentRegion] = useState(null);
  const [branchesPermissions, setBranchesPermissions] = useState([]);
  const [fleets, setFleets] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(company?.id);
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const prevSelectedCompany = usePrevious(company?.id);
  const [userData, setUserData] = useState(initialValues);
  const companies = useCompanies();
  const { data: newTimezones } = useGetTimezonesQuery();

  const [globalRoles, setGlobalRoles] = useState([]);
  const [companyRoles, setCompanyRoles] = useState([]);
  const userInfo = useSelector(state => state.user.currentUserInfo);
  const dispatch = useDispatch();
  const localization = useLocalization();
  const uploadConfig = useConfig();
  const { maxFileSizeBytes } = uploadConfig || {};
  const maxDocumentUploadSizeInBytes = maxFileSizeBytes || 5242880;
  const maxFileSize = formatBytes(maxDocumentUploadSizeInBytes);
  const [files, saveFiles] = useState([]);
  const rulesetDateFormat = localization.formats.time.formats.dby_imp
    .toUpperCase()
    .replace(':MM', ':mm')
    .replace('HH', 'hh');
  const [currentCompanyCountry, setCurrentCompanyCountry] = useState('');
  const { phoneNumber, phoneNumberWithoutDialCode, handlePhoneNumberChange } = usePhoneNumber(
    userData.mobile
  );
  const [licenceStateLabel, setLicenceStateLabel] = useState('');
  const [licenceStatePlaceholder, setLicenceStatePlaceholder] = useState('');
  const [readOnlyStatus, setReadOnlyStatus] = useState(false);
  const [image, setImage] = useState();
  const [imageRemoved, setImageRemoved] = useState(false);
  const [promptModalWhenLeaving, setPromptModalWhenLeaving] = useState(true);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isTranstechSystemAdmin, setIsTranstechSystemAdmin] = useState(false);
  const [rulesetsData, setRulesetsData] = useState([]);
  const [selectedRulesetDate, setSelectedRulesetDate] = useState(null);
  const [selectedRuleset, setSelectedRuleset] = useState(null);
  const ewdUser = useEWDUser();
  const userTypes = [USER_TYPES.USER, USER_TYPES.DRIVER, USER_TYPES.CUSTOMER];
  const ewdDriverUpdateRef = useRef(null);
  const [externalPinsArrayPerCompany, setExternalPinsArrayPerCompany] = useState([]);
  const [electronicPinsArrayPerCompany, setElectronicPinsArrayPerCompany] = useState([]);
  const [electronicPin2sArrayPerCompany, setElectronicPin2sArrayPerCompany] = useState([]);
  const userDefinedFields = useDriverManagementFields(selectedCompany);
  const vehicles = useVehicles();
  const hasCompanyChanged = useIsCompanyKeyDifferent();

  useRedirectToMainFeaturePageOnCompanyChange('/settings/users');

  const doRefetch = () => {
    setRefetch(refetch + 1);
  };

  const handleImageRemove = () => {
    setImageRemoved(true);
  };

  const updateUserType = (userType, validateField) => {
    if (userType === USER_TYPES.DRIVER && action === ACTIONS.EDIT) {
      confirmationModal(
        t('Users.Form.Warning'),
        t('Users.Form.ChangingUserTypeDriverWarning'),
        t('Common.Modal.OK'),
        t('Common.Modal.Cancel'),
        () => {
          setUserType(userType);
          setGlobalRoles(disableRolesByUserType(userType, globalRoles));
          setCompanyRoles(disableRolesByUserType(userType, companyRoles));
          setTimeout(() => validateField('username'), 100);
        }
      );
      setIsFormDirty(true);
      return;
    }
    setUserType(userType);
    setGlobalRoles(disableRolesByUserType(userType, globalRoles));
    setCompanyRoles(disableRolesByUserType(userType, companyRoles));
    setIsFormDirty(true);
    setTimeout(() => validateField('username'), 100);
  };

  const isELDCompany = useIsELDCompany();
  const companyConfig = useCompanyConfig();
  const missingCarrier = isELDCompany && companyConfig ? companyConfig?.length === 0 : false;
  const companyIsMultiCarrier = useMulticarrierFields(company?.id);
  useEffect(() => {
    if (company && userKey && isELDCompany) {
      dispatch(fetchCompanyConfig(company, userKey));
    }
  }, [dispatch, company, userKey]);

  const changes = usePendingStartOfDay();
  const pendingChange = changes ?? false;
  const pendingTimezone = (newTimezones || []).find(t => t.id === changes?.timeZone)?.label;
  const pendingStartOfDay = changes?.startOfDay
    ? moment(changes?.startOfDay, 'hh:mm a')?.format('hh:mm A')
    : null;

  const userRolesHandler = (role, userRoles, setUserRoles) => {
    const newRoles = [...userRoles];
    const updatedRole = newRoles.find(rrole => rrole.name === role.name);
    updatedRole.checked = !role.checked;
    setUserRoles(newRoles);
    setIsFormDirty(true);
  };

  const permissionsHandler = (currentObject, fullObject, setFleetBranches) => {
    currentObject.checked = !currentObject.checked;
    if (currentObject.id === 0) {
      // All Fleets / All Branches
      const updatedObject = [...fullObject].map(value => ({
        ...value,
        checked: value.id === 0 ? currentObject.checked : false
      }));
      setFleetBranches(updatedObject);
      setIsFormDirty(true);
    } else {
      const updatedObject = [...fullObject].map(value => {
        return {
          ...value,
          checked: value.id === 0 ? false : value.checked
        };
      });
      setFleetBranches(updatedObject);
      setIsFormDirty(true);
    }
  };

  useEffect(() => {
    if (!ewdUser?.auth?.expiry) {
      dispatch(fetchEWDUser());
    }
    if (ewdUser?.auth?.expiry) {
      const isJWTExpired = ewdUser.auth.expiry * 1000 < new Date().valueOf();
      if (isJWTExpired) {
        dispatch(fetchEWDUser());
      }
    }
  }, [dispatch, ewdUser]);

  useEffect(() => {
    dispatch(getConfig(company?.id));
  }, [company]);

  const getTable = data => {
    if (typeof data !== 'undefined') {
      return <Table columns={rulesetsTableColumns(t)} dataSource={data} pagination={false} />;
    }
  };

  // update validTo and sort the data
  const validateRuleset = data => {
    setIsFormDirty(true);
    const newData = data.sort((a, b) => (a.enabledAt < b.enabledAt ? 1 : -1));
    let newNewData = [];
    newData.forEach((rule, index) => {
      if (index === 0) {
        newNewData.push({ ...rule, key: rule.id || rule.enabledAt });
      } else {
        newNewData.push({
          ...rule,
          expiresAt: newData[index - 1].enabledAt - 60,
          key: rule.id || rule.enabledAt
        });
      }
    });
    setRulesetsData(newNewData);
  };

  const handleRulesetAdd = () => {
    // do not allow add without a selected date
    if (!selectedRulesetDate) {
      dispatch(
        openToast({
          type: ToastType.Warning,
          message: t('Users.Form.SelectADateBeforeAddingRulesets'),
          autohide: true
        })
      );
      return;
    }
    // do not allow add without a selected ruleset
    if (!selectedRuleset) {
      dispatch(
        openToast({
          type: ToastType.Warning,
          message: t('Users.Form.SelectARulesetBeforeAdding'),
          autohide: true
        })
      );
      return;
    }
    let hasDefaultDriverSetting = false;
    let vehicle = null;
    if (selectedRuleset.name.match(ELD_TACHO_DRIVER_PATTERN)) {
      vehicle = vehicles.find(v => isDefaultDriverOfVehicle(data.userViewData, v));
      if (vehicle) {
        hasDefaultDriverSetting = true;
      }
    }

    const action = () => {
      const newRuleset = {
        ruleset: selectedRuleset.name,
        enabledAt: selectedRulesetDate,
        desc: selectedRuleset.desc
      };

      const formattedDate = format(
        new Date(selectedRulesetDate),
        localization.formats.time.formats.dmY
      );
      if (
        rulesetsData.find(
          ruleset =>
            format(ruleset.enabledAt, localization.formats.time.formats.dmY) === formattedDate
        )
      ) {
        dispatch(
          openToast({
            type: ToastType.Warning,
            message: t('Users.Form.TheNewRulesetStartsOnTheSameDate')
          })
        );
        return;
      }

      const newData = [...rulesetsData, newRuleset];
      validateRuleset(newData);
    };

    if (hasDefaultDriverSetting) {
      confirmationModal(
        t('Users.DefaultDriverOfVehicle', { vehicle: vehicle.name }),
        t('Users.RevokeDefaultDriverOfVehicle'),
        t('Common.Yes'),
        t('Common.No'),
        action
      );
    } else {
      action();
    }
  };

  const handleRulesetDelete = (text, record, index) => {
    const newData = rulesetsData.filter((rule, i) => i !== index);
    validateRuleset(newData);
  };

  const handleRulesetDateChange = value => {
    setSelectedRulesetDate(new Date(value).getTime());
  };

  const handleRulesetSelectChange = value => {
    const getRuleset = rulesets.find(rule => rule.name === value);
    setSelectedRuleset(getRuleset);
  };

  const rulesetsTableColumns = t => {
    return [
      {
        title: t('Users.Form.Ruleset'),
        dataIndex: 'desc'
      },
      {
        title: t('Users.View.ValidFrom'),
        dataIndex: '',
        render: record => (
          <span>
            {format(new Date(record.enabledAt), localization.formats.time.formats.dby_imp)}
          </span>
        )
      },
      {
        title: t('Users.View.ValidTo'),
        dataIndex: '',
        render: record => (
          <span>
            {record && record.expiresAt
              ? format(new Date(record.expiresAt), localization.formats.time.formats.dby_imp)
              : ''}
          </span>
        )
      },
      {
        title: t('Users.Form.Remove'),
        dataIndex: '',
        render: (text, record, index) => (
          <DeleteFilled
            onClick={() => {
              confirmationModal(
                t('Easydocs.Notifications.AreYouSure'),
                `${t('Users.Form.AreYouSureYouWantToDeleteRuleset')} ${record.desc}?`,
                t('Common.Modal.Delete'),
                t('Common.Modal.Cancel'),
                () => handleRulesetDelete(text, record, index),
                'delete'
              );
              document.body.click();
            }}
          />
        )
      }
    ];
  };

  useEffect(() => {
    userInfo?.roles?.forEach(role => {
      if (limitedUserRoles.includes(role.name)) {
        setReadOnlyStatus(true);
      }
    });
  }, [userInfo.roles]);

  useEffect(() => {
    setLicenceStateLabel(getLicenceStateLabel(currentCompanyCountry));
    setLicenceStatePlaceholder(getLicenceStatePlaceholder(currentCompanyCountry));
  }, [currentCompanyCountry]);

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  useEffect(() => {
    if (action === ACTIONS.EDIT && userEditId) {
      dispatch(fetchPendingStartOfDay(parseInt(userEditId, 10)));
    }
  }, [dispatch, userEditId, action]);

  useEffect(() => {
    if (action === ACTIONS.EDIT) {
      dispatch(
        setPageTitle(
          userData.firstName && `${t('Users.Form.Edit')} ${userData.firstName} ${userData.lastName}`
        )
      );
    } else {
      dispatch(setPageTitle(`${t('Users.Form.AddNewUser')}`));
    }
  }, [dispatch, userData, action, t]);

  const onCompanyChange = (companyId, setFieldValue) => {
    setFieldValue('companyId', companyId);
    setFieldValue('location', -1); //reset Branch on company change
    setSelectedCompany(companyId);

    //update timezone when company changes
    const companyCountry = companies.find(comp => comp.id === Number(companyId))?.country;
    const region = regions.find(region => region.code === companyCountry);
    const defaultTimezone = JSON.parse(region?.config)?.default_time_zone || '';
    setFieldValue('timeZone', defaultTimezone);

    setSelectedRuleset(null);
    setRulesetsByCountryCb(companyCountry);
  };

  useEffect(() => {
    if (
      userData?.id &&
      (userData.yardMoves === null ||
        userData.yardMoves === undefined ||
        userData.personalConveyance === null ||
        userData.personalConveyance === undefined) &&
      can({ everyService: [services.ELD], everyCompanyService: [services.ELD] })
    ) {
      dispatch(fetchELDDrivingConditions(userData.id)).then(
        data => {
          setUserData(prevData => {
            return {
              ...prevData,
              yardMoves: data?.yardMoves || false,
              personalConveyance: data?.personalConveyance || false
            };
          });
        },
        err => {
          console.error(err);
        }
      );
    }
  }, [dispatch, userData, can]);

  useEffect(() => {
    // when the selectedCompany {companyId} changes, get the fleets and branches for it
    if (
      (action === ACTIONS.ADD || userData.id) &&
      selectedCompany &&
      selectedCompany !== prevSelectedCompany
    ) {
      handleFleetsBranchesAndPermissions(selectedCompany);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany, userData.id]);

  // set new values for: branches, fleets and branch/fleet permissions
  const handleFleetsBranchesAndPermissions = selectedCompany => {
    setRulesetsByCountryCb(company?.country);
    const updateStates = companyId => {
      const companyCountry = companies.find(comp => comp.id === Number(companyId))?.country;
      setCurrentCompanyCountry(companyCountry);

      let defaultSelected;
      regions.forEach(country => {
        if (country.code === companyCountry) {
          const formStates = JSON.parse(country.states);
          Object.keys(formStates).forEach(label => {
            const stateValue = label === 'United Kingdom' ? 'UK' : formStates[label];
            if (stateValue === userData.licenceState) {
              defaultSelected = { label, value: stateValue };
            }
          });
        }
      });

      setStates(
        sortBy(
          getCountryRegions({
            country: companyCountry,
            addCountryAsRegion: true,
            defaultValue: defaultSelected
          }),
          ['label']
        )
      );
      const region = regions.find(region => region.code === companyCountry);
      setCurrentRegion(region);
    };
    getFleetsByCompany(selectedCompany, userKey).then(data => {
      const fleets = data.map(fleet => {
        const companyName = companies?.find(company => fleet?.company?.id === company.id)?.name;
        return { ...fleet, name: `${fleet.name} ${companyName && `(${companyName})`}` };
      });

      const newFleets = [
        ...fleets,
        ...(!data.some(fleet => fleet.id === FLEET_TYPE.NO_FLEET)
          ? [{ name: t('Forms.View.NoFleet'), id: FLEET_TYPE.NO_FLEET }]
          : [])
      ];
      if (!userInfo.fleets) {
        newFleets.unshift({ name: t('Forms.AllFleets'), id: FLEET_TYPE.ALL_FLEETS, checked: true });
      }

      const formattedFleets = formatDataForCheckbox(newFleets);

      if (action === ACTIONS.ADD) {
        setFleets(formattedFleets);
      } else if (action === ACTIONS.EDIT) {
        if (userData.fleets) {
          // No fleets means all Fleets selected
          const updatedFleets = checkCommonValues(
            formattedFleets.map((fleet, index) =>
              index === 0 ? { ...fleet, checked: false } : fleet
            ),
            userData.fleets
          );
          setFleets(updatedFleets);
        } else {
          setFleets(formattedFleets);
        }
      }
    });
    getBranchesByCompany(selectedCompany, userKey).then(data => {
      const branches = data.reduce((filteredBranches, branch) => {
        if (branch.id !== BRANCH_TYPE.NO_BRANCH) {
          const companyName = companies?.find(company => branch?.companyId === company.id)?.name;
          return [
            ...filteredBranches,
            {
              ...branch,
              name: `${branch.name} ${companyName && `(${companyName})`}`
            }
          ];
        }
        return filteredBranches;
      }, []);
      const newBranches = [...branches, { name: t('Users.NoBranch'), id: BRANCH_TYPE.NO_BRANCH }];
      if (!userInfo.locations) {
        newBranches.unshift({
          name: t('Users.AllBranches'),
          id: BRANCH_TYPE.ALL_BRANCHES,
          checked: true
        });
      }

      const formattedBranches = formatDataForCheckbox(newBranches);
      if (action === ACTIONS.ADD) {
        setBranches(formattedBranches);
        setBranchesPermissions(formattedBranches);
      }
      if (action === ACTIONS.EDIT) {
        if (userData.id) {
          // No locations means - All Branches selected
          setBranches(formattedBranches);
          if (userData.locations) {
            const updatedBranches = checkCommonValues(
              formattedBranches.map((branch, index) =>
                index === 0 ? { ...branch, checked: false } : branch
              ),
              userData.locations
            );
            setBranches(formattedBranches);
            setBranchesPermissions(updatedBranches);
          } else {
            setBranchesPermissions(formattedBranches);
          }
        }
      }
    });
    updateStates(selectedCompany);
  };

  // Handling the data returned from the API for the Driver Management Infos
  const handleAPIDriverInfos = userData => {
    return userData?.driverNotes?.reduce((driverInfos, info) => {
      const { key, value, date } = info;
      const keyDate = key.concat('Date');
      return {
        ...driverInfos,
        [key]: value,
        [keyDate]: date ? new Date(date).toISOString() : ''
      };
    }, {});
  };

  // Handling the data returned from the API for Driver Documents
  const handleAPIDriverDocs = userData => {
    return userData?.driverDocs?.map(doc => ({
      ...doc,
      isSaved: true
    }));
  };

  useEffect(() => {
    if (action === ACTIONS.EDIT) {
      if (data?.userViewData) {
        const newUserEdit = { ...data.userViewData, ...handleAPIDriverInfos(data.userViewData) };
        saveFiles(handleAPIDriverDocs(newUserEdit));
        if (newUserEdit && newUserEdit.location && newUserEdit.location.id) {
          newUserEdit.location = newUserEdit.location.id;
        }

        [
          'field1Date',
          'field2Date',
          'field3Date',
          'licence_expiryDate',
          'health_check_expiryDate'
        ].forEach(key => {
          if (newUserEdit[key]) {
            newUserEdit[key] = moment
              .parseZone(newUserEdit[key])
              .utcOffset(moment.parseZone(newUserEdit.createdAt).utcOffset())
              .format('YYYY-MM-DD');
          }
        });

        setUserData(newUserEdit);
        setSelectedCompany(newUserEdit.companyId);
        setSelectedTimezone(newUserEdit.timeZone);
        setUserType(startCase(toLower(newUserEdit?.type?.code)));
        if (newUserEdit.type?.code === 'SUPPORT') {
          setUserType(newUserEdit.type?.name);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, data, dispatch, company]);

  useEffect(() => {
    if (action === ACTIONS.EDIT) {
      if (data?.userViewData && rulesets?.length) {
        const newRulesets = data.userViewData.rulesets
          ?.map(rule => ({
            ...rule,
            desc: rulesets.find(value => value.name === rule.ruleset)?.desc,
            key: rule.id || rule.enabledAt
          }))
          .sort((a, b) => (a.enabledAt < b.enabledAt ? 1 : -1));
        setRulesetsData(newRulesets);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, data, rulesets, dispatch]);

  useEffect(() => {
    if (!users) {
      return;
    }
    setExternalPinsArrayPerCompany(getExternalPins(users, selectedCompany));
    setElectronicPinsArrayPerCompany(getElectronicPins(users, selectedCompany));
    setElectronicPin2sArrayPerCompany(getElectronicPin2s(users, selectedCompany));
  }, [selectedCompany, users]);

  useEffect(() => {
    if (userData.id) {
      handleFleetsBranchesAndPermissions(selectedCompany);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData.id]);

  useEffect(() => {
    // set company roles if the companyId changes
    let filteredRolesGlobal;
    const getIsTranstechSystemAdmin = userInfo.roles?.find(
      role => role.name === 'Transtech System Admin'
    )
      ? true
      : false;
    setIsTranstechSystemAdmin(getIsTranstechSystemAdmin);
    const externalRoles = data?.userViewData?.roles?.reduce(
      (rolesAcc, role) => (role.name.startsWith('External') ? [...rolesAcc, role.name] : rolesAcc),
      []
    );
    filteredRolesGlobal = roles.roles.filter(
      role =>
        role.global && (!limitedUserRoles.includes(role.name) || externalRoles?.includes(role.name))
    );
    const formattedGlobalRoles = formatDataForCheckbox(filteredRolesGlobal);
    setGlobalRoles(disableRolesByUserType(userType, formattedGlobalRoles));

    const filteredRolesCompany = roles.roles.filter(
      role => !role.global && role.companyId?.toString() === selectedCompany?.toString()
    );
    const formattedCompanyRoles = formatDataForCheckbox(filteredRolesCompany);
    setCompanyRoles(disableRolesByUserType(userType, formattedCompanyRoles));

    // For Edit - mark the retrieved roles as checked
    if (action === ACTIONS.EDIT) {
      if (data?.userViewData?.roles) {
        const roles = data.userViewData.roles;
        filteredRolesGlobal = roles.filter(
          role =>
            role.global &&
            (!limitedUserRoles.includes(role.name) || externalRoles.includes(role.name))
        );
        if (externalRoles.length > 0) {
          setReadOnlyStatus(true);
        }
        const filteredRolesCompany = roles.filter(
          role => !role.global && role.companyId.toString() === selectedCompany.toString()
        );
        const updatedGlobalRoles = checkCommonValues(formattedGlobalRoles, filteredRolesGlobal);
        const updatedCompanyRoles = checkCommonValues(formattedCompanyRoles, filteredRolesCompany);
        setGlobalRoles(disableRolesByUserType(userType, updatedGlobalRoles));
        setCompanyRoles(disableRolesByUserType(userType, updatedCompanyRoles));
      }
    }
  }, [selectedCompany, userInfo, action, roles, data.userViewData, data, userType]);

  useEffect(() => {
    if ((userData?.mobile || '') !== (phoneNumber || '')) {
      setIsFormDirty(true);
    } else {
      setIsFormDirty(false);
    }
  }, [phoneNumber, userData.mobile]);

  useEffect(() => {
    //when region changes, update default_timezone and timezones
    if (!currentRegion) return;
    const defaultTimezone = JSON.parse(currentRegion.config)?.default_time_zone;
    const timeZones = Object.values(JSON.parse(currentRegion.timezone));
    setTimezones(timeZones);
    if (defaultTimezone) {
      // on edit - prevent timeZone change to default unless it's a new timezone
      if (action === ACTIONS.EDIT && timeZones.find(tzone => tzone === userData.timeZone)) {
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRegion]);

  const onSubmitDeleteAction = fileObj => () => {
    dispatch(removeFiles(fileObj));
    saveFiles(files.filter(file => file.filename !== fileObj.filename));
  };

  const handleDeleteModal = file => {
    confirmationModal(
      `${t('Common.DeleteButton')} ${file.filename}`,
      `${t('Common.SureDelete')} ${file.filename}?`,
      t('Common.DeleteButton'),
      t('Common.CancelButton'),
      onSubmitDeleteAction(file),
      'delete'
    );
  };

  const removeFile = fileObj => () => {
    // do not show modal confirmation for the files that are not yet uploaded, just delete them
    if (fileObj && fileObj.id) {
      //if the file has id, show modal and remove them from api
      handleDeleteModal(fileObj);
      return;
    }
    saveFiles(files.filter(file => file.name !== fileObj.name));
  };

  const renderFileDetails = () =>
    files?.map(file => {
      const uploadDate = file.uploadedAt
        ? format(
            moment(file.uploadedAt.split('+')[0]).toDate(),
            localization.formats.time.formats.dby_imp
          )
        : format(moment().toDate(), localization.formats.time.formats.dby_imp);
      return {
        id: file.id || file.filename || file.name,
        file: file.filename ?? file.name,
        fileSize: file.filesize ?? file.size,
        uploadedBy:
          file.uploadedBy ||
          `${currentUser.firstName} ${currentUser.lastName}` ||
          `${userData.firstName} ${userData.lastName}`,
        uploadDate,
        actions: removeFile(file)
      };
    });

  // Sets the user avatar in the state.
  const handleChangeImage = file => {
    setImage(file);
    setIsFormDirty(true);
  };

  // Handles the POST data regarding Driver Management Info
  const handlePOSTDriverInfos = values =>
    ['licence_expiry', 'health_check_expiry', 'field1', 'field2', 'field3', 'driver_notes'].map(
      key => ({
        key: key,
        date: values[key.concat('Date')],
        value: values[key]
      })
    );

  const handleAttachment = event => {
    const {
      target: { files: uploadFiles }
    } = event;

    if (!uploadFiles || !uploadFiles.length) return;
    // Since files is a set - we need to convert it to an actual array
    const filesToAdd = [...uploadFiles];

    // Remove duplicates
    const currentFileNames = files.map(file => file.filename || file.name);
    const uniqueFiles = filesToAdd.filter(file => !currentFileNames.some(fn => fn === file.name));

    const filesToSave = filterFilesBy(uniqueFiles, {
      type: {
        values: ['application/pdf'],
        callback: name => {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: t('Users.Notifications.OnlyPdf', {
                name: name
              })
            })
          );
        }
      },
      size: {
        value: maxDocumentUploadSizeInBytes,
        callback: name => {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: t('Users.Notifications.FileSize', {
                name: name,
                fileSize: maxFileSize
              })
            })
          );
        }
      }
    });
    setIsFormDirty(true);
    saveFiles([...files, ...filesToSave]);
  };

  const onSubmit = async (values, actions) => {
    if (values.licence_expiryDate) {
      values.licence_expiryDate = moment(values.licence_expiryDate).format('YYYY-MM-DD');
    }
    if (values.health_check_expiryDate) {
      values.health_check_expiryDate = moment(values.health_check_expiryDate).format('YYYY-MM-DD');
    }
    if (values.field1Date) {
      values.field1Date = moment(values.field1Date).format('YYYY-MM-DD');
    }
    if (values.field2Date) {
      values.field2Date = moment(values.field2Date).format('YYYY-MM-DD');
    }
    if (values.field3Date) {
      values.field3Date = moment(values.field3Date).format('YYYY-MM-DD');
    }

    const fleetPermissions = fleets.reduce(
      (fleetsAcc, fleet) =>
        fleet.checked
          ? [
              ...fleetsAcc,
              {
                id: fleet.id,
                name: fleet.name
              }
            ]
          : fleetsAcc,
      []
    );
    const branchPermissions = branchesPermissions.reduce(
      (branchesAcc, branch) =>
        branch.checked
          ? [
              ...branchesAcc,
              {
                id: branch.id,
                name: branch.name
              }
            ]
          : branchesAcc,
      []
    );
    // Set permissions
    const fleetPermissionsOption = getPermissionsQueryParams(fleetPermissions);
    const branchPermissionsOption = getPermissionsQueryParams(branchPermissions);
    const password = values.password && bcrypt.hashSync(values.password, 10);
    let passwordChange;
    if (action === ACTIONS.EDIT && values.password) {
      passwordChange = bcrypt.hashSync(values.password, 10);
    }

    let pinChange;
    if (action === ACTIONS.EDIT && values.pin) {
      pinChange = values.pin;
    }

    let ePin1Change;
    if (action === ACTIONS.EDIT && values.ePin1) {
      ePin1Change = values.ePin1;
    }

    let ePin2Change;
    if (action === ACTIONS.EDIT && values.ePin2) {
      ePin2Change = values.ePin2;
    }

    let postBody = {};
    const checkedGlobalRoles = globalRoles.reduce(
      (globalRolesAcc, role) =>
        role.checked
          ? [
              ...globalRolesAcc,
              {
                id: role.id
              }
            ]
          : globalRolesAcc,
      []
    );
    const checkedCompanyRoles = companyRoles.reduce(
      (companyRolesAcc, role) =>
        role.checked
          ? [
              ...companyRolesAcc,
              {
                id: role.id
              }
            ]
          : companyRolesAcc,
      []
    );
    let allRoles = checkedGlobalRoles.concat(checkedCompanyRoles);
    const allAvailableRoles = globalRoles.concat(companyRoles);
    //append role that assigned to user but not visible from checked role because of assignable permission
    if (data.userViewData.roles) {
      allRoles = [
        ...allRoles,
        ...data.userViewData.roles
          .filter(i => !allAvailableRoles.find(a => a.id == i.id))
          .map(i => {
            return { id: i.id };
          })
      ];
    }

    const rulesetsArray = rulesetsData.map(el => ({
      ruleset: el.ruleset,
      enabledAt: moment(new Date(el.enabledAt)),
      ...(el.expiresAt && { expiresAt: moment(new Date(el.expiresAt)) }),
      ...(el.id && { id: el.id })
    }));

    postBody = {
      roles: allRoles || [],
      type: {
        name: userType
      },
      firstName: values.firstName,
      lastName: values.lastName,
      username: values.username,
      email: values.email,
      ...(values.location &&
        values.location !== 'undefined' && { location: { id: values.location } }), // id: values.location
      ...(values.location === '' && { location: { id: -1 } }), // follow Nextgen - TN360WEB-2369
      mobile: phoneNumberWithoutDialCode ? phoneNumber : '',
      ...(selectedCompany && { companyId: selectedCompany }),
      ...(selectedTimezone && { timeZone: selectedTimezone }),
      ...(values.externalReference && { externalReference: values.externalReference }),
      ...(values.licenceNumber && { licenceNumber: values.licenceNumber }),
      ...(values.licenceState && { licenceState: values.licenceState }),
      ...(values.licenceCountry && { licenceCountry: values.licenceCountry }),
      ...(values.sentinelExemptionNotes && {
        sentinelExemptionNotes: values.sentinelExemptionNotes
      }),
      ...(values.siteNotes && { siteNotes: values.siteNotes }),
      ...(values.externalId && { externalId: values.externalId }),
      ...(fleetPermissions && { fleets: fleetPermissions }),
      ...(branchPermissions && { locations: branchPermissions }),
      ...(action === ACTIONS.ADD && password && { encryptedPassword: password }),
      ...(passwordChange && { encryptedPassword: passwordChange }),
      ...(action === ACTIONS.ADD && values.pin && { externalPin: values.pin }),
      ...(pinChange && { externalPin: pinChange }),
      ...(action === ACTIONS.ADD && values.ePin1 && { electronicPin: values.ePin1 }),
      ...(ePin1Change && { electronicPin: ePin1Change }),
      ...(action === ACTIONS.ADD && values.ePin2 && { electronicPin2: values.ePin2 }),
      ...(ePin2Change && { electronicPin2: ePin2Change }),
      rulesets: rulesetsArray,
      driverNotes: handlePOSTDriverInfos(values),
      features: values.features || [],
      ...(values.startOfDay && { startOfDay: values.startOfDay })
    };

    if (postBody.type.name === 'Support Personnel') {
      postBody.type.name = 'SUPPORT';
      postBody.rulesets = null;
    }

    let method = 'POST';
    let urlPath = `${API_PATH}/users`;
    const permissionsQSP = `?fleetPermissions=${fleetPermissionsOption}&branchPermissions=${branchPermissionsOption}&company_id=${selectedCompany}`;
    if (action === ACTIONS.EDIT) {
      method = 'PUT';
      urlPath = `${API_PATH}/users/${userData.id}`;
    }

    if (image) {
      const currentUserId = parseInt(userEditId);
      const requestParams = !isNaN(currentUserId) && { userId: userEditId };
      const saveResponse = await dispatch(saveImage(image, requestParams));

      postBody['imageRef'] = saveResponse.imageRef;
    }

    if (data?.userViewData?.imageUrl && imageRemoved) {
      const currentUserId = parseInt(userEditId);
      const requestParams = !isNaN(currentUserId) && { userId: userEditId };
      await dispatch(removeImage(requestParams));
    }

    request(method, `${urlPath}${permissionsQSP}`)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .send(postBody)
      .then(async res => {
        const userId = res.body.id || userData.id;
        const filesToAttach = files.filter(file => !file.isSaved);
        const filesUploadStatus =
          filesToAttach.length && (await dispatch(attachFiles(filesToAttach, userId)));
        if (
          userType === USER_TYPES.DRIVER &&
          can({
            everyService: [services.ELD],
            everyCompanyService: [services.ELD]
          })
        ) {
          const eldDrivingConditionUrl = `${API_PATH}/users/${userId}/config/eld.special_driving_conditions`;
          await request('PUT', eldDrivingConditionUrl)
            .set('Authorization', `Token token="${userKey}"`)
            .set('Content-Type', 'application/json')
            .send({
              yardMoves: values.yardMoves,
              personalConveyance: values.personalConveyance
            });
        }

        if (
          ewdUser &&
          action === ACTIONS.EDIT &&
          values.username !== userData?.username &&
          ewdDriverUpdateRef.current?.usernameUpdate &&
          !ewdDriverUpdateRef.current?.detailsUpdate
        ) {
          await ewdDriverUpdateRef.current.usernameUpdate(values.username);
        } else if (
          ewdUser &&
          action === ACTIONS.EDIT &&
          ewdDriverUpdateRef.current?.detailsUpdate
        ) {
          await ewdDriverUpdateRef.current.detailsUpdate(values.username);
        }

        setPromptModalWhenLeaving(false);
        actions.resetForm();
        actions.setSubmitting(false);
        const user = res.body.username;

        setToastMessageCb({
          text: t(`Users.Notifications.User${action}edSuccess`, {
            user: user
          }),
          type: ToastType.Success
        });
        if (filesUploadStatus.length) {
          filesUploadStatus.some(elem => elem.statusCode === 200) &&
            canHistoryGoBack(history, '/settings/users');
        } else {
          canHistoryGoBack(history, '/settings/users');
        }
        if (currentUser.id === +userEditId) {
          dispatch(getCurrentUserInfo());
        }
        setIsFormDirty(false);
      })
      .catch(err => {
        actions.setSubmitting(false);
        console.warn('ERROR', err);

        const errorMsg = parseUserErrorMessage(err);
        const toastMessageError = `${err.response.statusText} - ${errorMsg}`;
        setToastMessageCb({
          text: `${t(`Users.Notifications.User${action}edError`)}: ${toastMessageError}`,
          type: ToastType.Error
        });
      });
    actions.setSubmitting(false);
  };

  const renderDriverUserDefinedLabel = userDefinedField => {
    if (!userDefinedField) {
      return `${t('Users.View.UserDefined')}`;
    }
    return userDefinedField;
  };

  if (
    can({
      everyService: [services.ELD],
      everyCompanyService: [services.ELD]
    })
  ) {
    userTypes.push(USER_TYPES.SUPPORT_PERSONNEL);
  }

  const handleTimezoneChange = val => {
    setSelectedTimezone(val);
    setIsFormDirty(true);
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        validationSchema={validationSchema(
          userType,
          currentCompanyCountry,
          isELDCompany,
          userExternalIds,
          licenceNumbers,
          userData,
          externalPinsArrayPerCompany,
          electronicPinsArrayPerCompany,
          electronicPin2sArrayPerCompany
        )}
        validateOnMount
        initialValues={{
          timeZone: getDefaultTimezoneValue({ company, regions }),
          ...userData,
          companyId: company?.id
        }}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid, values, dirty, setFieldValue, validateField }) => {
          return (
            <>
              <EditRouteGuard
                when={((dirty && promptModalWhenLeaving) || isFormDirty) && !hasCompanyChanged}
                navigate={history.push}
              />
              <Form id="userForm" className={styles.userForm}>
                <Tabs defaultActiveKey="basic-information" transition={false}>
                  <Tab eventKey="basic-information" title={t('Users.Form.BasicInformation')}>
                    <div className={styles.basicInformationContainer}>
                      <FormTitle title={t('Users.Form.AccountInformation')} underlined />
                      <Row>
                        <FormGroup as={Col}>
                          <FormLabel>{t('Users.Form.ProfilePicture')}</FormLabel>
                          <ImageUpload
                            onChange={handleChangeImage}
                            round={true}
                            imageUrl={data?.userViewData?.imageUrl}
                            disabled={!canAccessNonCameraFeatures}
                            onRemoveImage={handleImageRemove}
                          />
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormInput
                          name="firstName"
                          label={t('Users.Form.FirstName')}
                          placeholder={t('Users.Form.FirstNamePlaceholder')}
                          isReadonly={readOnlyStatus}
                          isRequired
                          disabled={!canAccessNonCameraFeatures}
                        />
                        <FormInput
                          name="lastName"
                          label={t('Users.Form.LastName')}
                          placeholder={t('Users.Form.LastNamePlaceholder')}
                          isReadonly={readOnlyStatus}
                          isRequired
                          disabled={!canAccessNonCameraFeatures}
                        />
                      </Row>
                      <Row>
                        <FormInput
                          name="email"
                          autoComplete="new-password"
                          label={t('Users.Form.Email')}
                          placeholder={t('Users.Form.EmailPlaceholder')}
                          isReadonly={readOnlyStatus}
                          isRequired
                          disabled={!canAccessNonCameraFeatures}
                        />
                      </Row>
                      <Row>
                        <FormInput
                          name="username"
                          autoComplete="new-password"
                          label={t('Users.Form.Username')}
                          placeholder={t('Users.Form.UsernamePlaceholder')}
                          isRequired
                          disabled={!canAccessNonCameraFeatures}
                        />
                      </Row>
                    </div>
                  </Tab>
                  {canAccessNonCameraFeatures && (
                    <Tab eventKey="additional-details" title={t('Users.Form.AdditionalDetails')}>
                      <div className={cn(styles.formContainer, styles.flexContainer)}>
                        <PhoneInputField
                          preferredCountryCodes={[localization.region, currentCompanyCountry]}
                          initialValue={phoneNumber}
                          label={t('Users.Form.MobileNo')}
                          onChange={handlePhoneNumberChange}
                          countryCodeEditable={action === 'edit'}
                        />
                        <FormGroup as={Col}>
                          <FormLabel>{t('Users.Form.UserTimeZone')}</FormLabel>
                          <span className={styles.inputRequired}>*</span>
                          <div className={styles.timeZoneSelect}>
                            <Select
                              size="large"
                              name="newTimezones"
                              data={newTimezones || []}
                              value={selectedTimezone || null}
                              status={!selectedTimezone ? 'error' : null}
                              errorMessage={t('Users.ValidationErrors.RequiredTimezone')}
                              onSelect={handleTimezoneChange}
                              placeholder={t('Users.Form.UserTimeZone')}
                            ></Select>
                            {can({ featureFlag: FeatureFlag.startOfDay.flag }) && pendingChange && (
                              <div className={styles.pendingChange}>
                                {t('ELD.PendingCycleChange', { name: pendingTimezone })}
                              </div>
                            )}
                          </div>
                        </FormGroup>
                        <FormSelect
                          name="companyId"
                          label={t('Users.Form.Company')}
                          onChange={id => onCompanyChange(id, setFieldValue)}
                          placeholder=""
                          values={companies.map(company => ({
                            label: company.name,
                            value: company.id
                          }))}
                          isRequired
                        />
                        <FormSelect
                          name="location"
                          label={t('Users.Form.Branch')}
                          placeholder={t('Users.Form.BranchPlaceholder')}
                          values={branches.reduce(
                            (filteredBranches, branch) =>
                              branch.id !== BRANCH_TYPE.NO_BRANCH &&
                              branch.id !== BRANCH_TYPE.ALL_BRANCHES
                                ? [
                                    ...filteredBranches,
                                    {
                                      label: branch.name,
                                      value: branch.id
                                    }
                                  ]
                                : filteredBranches,
                            []
                          )}
                        />
                        <FormInput
                          name="externalId"
                          label={t('Users.Form.ExternalID')}
                          placeholder={t('Users.Form.ExternalIDPlaceholder')}
                          isReadonly={isTranstechSystemAdmin ? false : readOnlyStatus}
                          isValidated
                        />
                      </div>
                    </Tab>
                  )}
                  {canAccessNonCameraFeatures && (
                    <Tab
                      eventKey="user-setup"
                      title={t('Users.Form.UserSetup')}
                      className={readOnlyStatus ? styles.pointerEventsNone : ''}
                    >
                      <div style={{ display: 'flex' }}>
                        <div style={{ padding: '0 8px', width: '100%' }}>
                          <div className={styles.infoContainer}>
                            {t('Users.Form.UserSetupInfo')}
                          </div>
                          {!userInfo.siteAdmin && action !== ACTIONS.ADD && (
                            <Alert
                              message={t('Users.Form.SiteAdminOnly')}
                              description={t('Users.Form.SiteAdminNotif')}
                              type="error"
                              showIcon
                              style={{ marginBottom: '16px' }}
                            />
                          )}
                          <div className={styles.formContainer}>
                            <div className={styles.userFormLabel}>{t('Users.Form.UserType')}</div>
                            <ToggleButtonGroup
                              type="radio"
                              name="options"
                              value={userTypes.indexOf(userType) + 1}
                            >
                              {userTypes.map((user, index) => (
                                <ToggleButton
                                  key={user}
                                  value={index + 1}
                                  onClick={event => {
                                    if (event?.target?.value) {
                                      updateUserType(user, validateField);
                                    }
                                  }}
                                  disabled={!userInfo.siteAdmin && action !== ACTIONS.ADD}
                                >
                                  {t(`Users.Form.Type.${user}`)}
                                </ToggleButton>
                              ))}
                            </ToggleButtonGroup>
                            {userType === USER_TYPES.DRIVER &&
                              (can({
                                everyService: [services.ELD],
                                everyCompanyService: [services.ELD]
                              }) ? (
                                <ELDDriverTab readOnlyStatus={readOnlyStatus} />
                              ) : (
                                <>
                                  <FormTitle
                                    title={t('Users.Form.LicenceInformation')}
                                    underlined
                                  />
                                  <div style={{ width: '100%' }}>
                                    <Row>
                                      <FormSelect
                                        name="licenceState"
                                        label={licenceStateLabel}
                                        placeholder={licenceStatePlaceholder}
                                        values={states}
                                        isReadonly={readOnlyStatus}
                                        isRequired={!!values.licenceNumber}
                                      />
                                      <FormInput
                                        name="licenceNumber"
                                        label={t('Users.Form.LicenceNumber')}
                                        placeholder={t('Users.Form.LicenceNumberPlaceholder')}
                                        isReadonly={readOnlyStatus}
                                        isValidated
                                      />
                                    </Row>
                                  </div>
                                </>
                              ))}
                            {userType === USER_TYPES.SUPPORT_PERSONNEL && <SPTab />}
                            {userType === USER_TYPES.CUSTOMER && (
                              <div style={{ paddingRight: '32px', width: '50%' }}>
                                <Row>
                                  <FormInput
                                    name="externalReference"
                                    label={t('Users.Form.ExternalReference')}
                                    placeholder={t('Users.Form.ExternalReferencePlaceholder')}
                                  />
                                </Row>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Tab>
                  )}
                  {(canAccessNonCameraFeatures || isSubCompanyAdmin) && (
                    <Tab
                      eventKey="user-roles"
                      title={t('Users.Form.UserRoles')}
                      className={readOnlyStatus ? styles.pointerEventsNone : ''}
                    >
                      <div style={{ display: 'flex' }}>
                        <div style={{ width: '100%' }}>
                          <div className={styles.infoContainer}>
                            {t('Users.Form.UserRolesInfo')}
                          </div>
                          <div className={styles.formContainer}>
                            <FormTitle title={t('Users.Form.GlobalRole')} underlined />
                            <div className={styles.formCheckWrapper}>
                              {globalRoles
                                .filter(role => {
                                  if (!IQCameraUserRoles.includes(role.name)) {
                                    return true;
                                  }

                                  if (
                                    IQCameraUserRoles.includes(role.name) &&
                                    userInfo &&
                                    userInfo.siteAdmin
                                  ) {
                                    return true;
                                  }

                                  return false;
                                })
                                .map(role => {
                                  if (IQCameraUserRoles.includes(role.name)) {
                                    role.disabled = true;
                                  }
                                  return (
                                    <div
                                      className={styles.multipleCheckboxRow}
                                      key={`${role.name}-${role.id}-global`}
                                    >
                                      <FormCheck
                                        style={{ paddingBottom: 'unset', minWidth: '30%' }}
                                        type="checkbox"
                                        id={`checkbox-global${role.name}`}
                                        label={role.name}
                                        checked={role.checked}
                                        onChange={() =>
                                          userRolesHandler(role, globalRoles, setGlobalRoles)
                                        }
                                        disabled={readOnlyStatus || role.disabled}
                                      />
                                      <span className={styles.checkboxRowSpan}>
                                        {role.description}
                                      </span>
                                      <div className={styles.formContainerLine}></div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                          <div className={styles.formContainer}>
                            <FormTitle title={t('Users.Form.CompanyRole')} underlined />
                            <div className={styles.formCheckWrapper}>
                              {companyRoles.map(role => (
                                <div
                                  className={styles.multipleCheckboxRow}
                                  key={`${role.name}-${role.id}-company`}
                                >
                                  <FormCheck
                                    style={{ paddingBottom: 'unset', minWidth: '30%' }}
                                    type="checkbox"
                                    id={`checkbox-company${role.name}`}
                                    label={role.name}
                                    checked={role.checked}
                                    onChange={() =>
                                      userRolesHandler(role, companyRoles, setCompanyRoles)
                                    }
                                    disabled={
                                      role.disabled ||
                                      readOnlyStatus ||
                                      (!isTranstechSystemAdmin &&
                                        role.name === 'Transtech System Admin')
                                    }
                                  />
                                  <span className={styles.checkboxRowSpan}>{role.description}</span>
                                  <div className={styles.formContainerLine}></div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  )}

                  {(canAccessNonCameraFeatures || isBeyondSubCompanyAdmin) && (
                    <Tab
                      eventKey="permissions"
                      title={
                        canAccessNonCameraFeatures
                          ? t('Users.Form.FleetBranchPermissions')
                          : t('Users.Form.FleetPermissions')
                      }
                    >
                      <div style={{ display: 'flex' }}>
                        <div style={{ width: '100%' }}>
                          <div className={styles.infoContainer}>
                            {tooltip(canAccessNonCameraFeatures).permissions.map(permission => (
                              <p key={permission}>{t(permission)}</p>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div style={{ display: 'flex' }}>
                        {isBeyondSubCompanyAdmin && (
                          <div style={{ paddingRight: '32px', width: '50%' }}>
                            <div className={styles.formContainer}>
                              <FormTitle title={t('Users.Form.Fleet')} underlined />
                              <div className={styles.formCheckWrapper}>
                                {fleets.map(
                                  fleet =>
                                    fleet &&
                                    fleet.name && (
                                      <FormCheck
                                        type="checkbox"
                                        key={`${fleet.id}-${fleet.name}`}
                                        id={`checkbox-${fleet.id}-${fleet.name}`}
                                        label={fleet.name}
                                        checked={fleet.checked}
                                        onChange={() =>
                                          permissionsHandler(fleet, fleets, setFleets)
                                        }
                                      />
                                    )
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {canAccessNonCameraFeatures && (
                          <div style={{ paddingRight: '32px', width: '50%' }}>
                            <div className={styles.formContainer}>
                              <FormTitle title={t('Users.Form.Branch')} underlined />
                              <div className={styles.formCheckWrapper}>
                                {branchesPermissions.map(branch => (
                                  <FormCheck
                                    type="checkbox"
                                    key={`${branch.id}-${branch.name}`}
                                    id={`checkbox-${branch.id}-${branch.name}`}
                                    label={branch.name}
                                    checked={branch.checked}
                                    onChange={() =>
                                      permissionsHandler(
                                        branch,
                                        branchesPermissions,
                                        setBranchesPermissions
                                      )
                                    }
                                  />
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </Tab>
                  )}

                  {canAccessNonCameraFeatures && userType === USER_TYPES.DRIVER && (
                    <Tab eventKey="rulesets" title={t('Users.Form.Ruleset')}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {can({
                          everyService: [services.ELD],
                          everyCompanyService: [services.ELD]
                        }) && (
                          <ELDRulesetTab
                            pendingChange={pendingChange}
                            pendingStartOfDay={pendingStartOfDay}
                            missingCarrier={missingCarrier}
                            companyIsMultiCarrier={companyIsMultiCarrier}
                          />
                        )}
                        <div style={{ width: '100%', paddingRight: '32px', paddingBottom: '32px' }}>
                          <Space>
                            <DatePicker
                              onChange={handleRulesetDateChange}
                              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                              disabledDate={current => {
                                let disable = false;
                                if (
                                  can({
                                    everyService: [services.ELD],
                                    everyCompanyService: [services.ELD]
                                  }) &&
                                  current < moment().add(-1, 'day')
                                ) {
                                  disable = true;
                                }
                                return disable;
                              }}
                              style={{ width: 100 }}
                              format={rulesetDateFormat}
                              placeholder={t('Common.SelectDate')}
                              disabled={missingCarrier}
                            />
                            <Select
                              data={rulesets
                                ?.filter(r => toLower(r.name) !== 'eld99')
                                .map(ruleset => ({
                                  id: ruleset.name,
                                  label: ruleset.desc
                                }))}
                              value={selectedRuleset?.name || null}
                              width={400}
                              height={800}
                              onChange={handleRulesetSelectChange}
                              placeholder={t('Users.Form.RulesetPlaceholder')}
                              disabled={missingCarrier}
                            ></Select>
                            <Button
                              id={BUTTON_IDS.userFormRulesetAdd}
                              onClick={handleRulesetAdd}
                              disabled={missingCarrier}
                            >
                              {t('Users.Form.Add')}
                            </Button>
                          </Space>
                          <div style={{ paddingTop: '16px' }}>{getTable(rulesetsData)}</div>
                        </div>
                      </div>
                    </Tab>
                  )}
                  {canAccessNonCameraFeatures && userType === USER_TYPES.DRIVER && (
                    <Tab eventKey="driverinfo" title={`${t('Users.Form.DriverInformation')}`}>
                      <div className={styles.formContainer}>
                        <div className={styles.driverInfoFields}>
                          <FormDatePicker
                            name="licence_expiryDate"
                            label={t('Users.Form.DriversLicence')}
                            format={localization.formats.time.formats.dby}
                            setFieldValue={setFieldValue}
                          />
                          <FormInput
                            name="licence_expiry"
                            placeholder={t('Users.Form.NotePlaceholder')}
                          />
                        </div>
                        <div className={styles.driverInfoFields}>
                          <FormDatePicker
                            name="health_check_expiryDate"
                            label={t('Users.Form.HealthCheck')}
                            format={localization.formats.time.formats.dby}
                            setFieldValue={setFieldValue}
                          />
                          <FormInput
                            name="health_check_expiry"
                            placeholder={t('Users.Form.NotePlaceholder')}
                          />
                        </div>
                        <div className={styles.driverInfoFields}>
                          <FormDatePicker
                            name="field1Date"
                            label={renderDriverUserDefinedLabel(userDefinedFields?.field1)}
                            format={localization.formats.time.formats.dby}
                            setFieldValue={setFieldValue}
                          />
                          <FormInput name="field1" placeholder={t('Users.Form.NotePlaceholder')} />
                        </div>
                        <div className={styles.driverInfoFields}>
                          <FormDatePicker
                            name="field2Date"
                            label={renderDriverUserDefinedLabel(userDefinedFields?.field2)}
                            format={localization.formats.time.formats.dby}
                            setFieldValue={setFieldValue}
                          />
                          <FormInput name="field2" placeholder={t('Users.Form.NotePlaceholder')} />
                        </div>
                        <div className={styles.driverInfoFields}>
                          <FormDatePicker
                            name="field3Date"
                            label={renderDriverUserDefinedLabel(userDefinedFields?.field3)}
                            format={localization.formats.time.formats.dby}
                            setFieldValue={setFieldValue}
                          />
                          <FormInput name="field3" placeholder={t('Users.Form.NotePlaceholder')} />
                        </div>
                        <FormInput
                          name="driver_notes"
                          label={t('Users.Form.Notes')}
                          placeholder={t('Users.Form.NotePlaceholder')}
                        />
                      </div>
                    </Tab>
                  )}
                  {canAccessNonCameraFeatures &&
                    can({
                      everyService: [services.EWD],
                      everyCompanyService: [services.EWD]
                    }) && (
                      <Tab key="external-ewd" eventKey="external-ewd" title={t('Users.Form.EWD')}>
                        <EWDTab
                          userData={userData}
                          timezones={timezones}
                          licenceStateLabel={licenceStateLabel}
                          licenceStatePlaceholder={licenceStatePlaceholder}
                          states={states}
                          ewdDriverUpdateRef={ewdDriverUpdateRef}
                          doRefetch={doRefetch}
                          onFormUpdated={() => setIsFormDirty(true)}
                        />
                      </Tab>
                    )}

                  {canAccessNonCameraFeatures && (
                    <Tab eventKey="security" title={t('Users.Form.Security')}>
                      <UserSecurityFields
                        action={action}
                        userType={userType}
                        localization={localization}
                        userData={userData}
                        isELD={isELDCompany}
                      />
                    </Tab>
                  )}
                  {canAccessNonCameraFeatures && userType === USER_TYPES.DRIVER && (
                    <Tab eventKey="uploads" title={`${t('Users.Form.Uploads')}`}>
                      <FileUpload onAdd={handleAttachment} />
                      <DocumentsDisplay rows={renderFileDetails()} />
                    </Tab>
                  )}
                </Tabs>
                <div className={styles.formFooter}>
                  <Button
                    size="large"
                    type="primary"
                    disabled={
                      (!dirty && !isFormDirty) || !selectedTimezone || !isValid || isSubmitting
                    }
                    htmlType="submit"
                    id={BUTTON_IDS.userFormSave}
                  >
                    {t('Common.SaveButton')}
                  </Button>
                  <Button id={BUTTON_IDS.userFormCancel} size="large" onClick={history.goBack}>
                    {t('Common.CancelButton')}
                  </Button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};
