import { useMemo } from 'react';
import { useCompanies, useCurrentCompany } from 'features/company/companySlice';
import { useGetGpioConfigurationTemplatesQuery } from 'services/nextgen/ngGpioConfigurationApi';
import {
  useDevices,
  useFleets,
  useIsFetching,
  useVehiclesFromFleets
} from 'features/fleets/fleetsSlice';
import { FeatureFlag, useCanFeatureFlag } from 'features/permissions';
import { Scope } from '../constants';

export const useEntityTemplateAssociations = () => {
  const currentCompany = useCurrentCompany();
  const { vehicles, isFetchingVehicles } = useVehiclesFromFleets();
  const allDevices = useDevices();
  const allFleets = useFleets();
  const companies = useCompanies();
  const isFetchingFleets = useIsFetching();

  const canFleetLevelGpio = useCanFeatureFlag({
    featureFlag: FeatureFlag.fleetLevelGpio.flag
  });

  const {
    data: templateData,
    isFetching: isFetchingGpioTemplate
  } = useGetGpioConfigurationTemplatesQuery(
    { companyId: currentCompany?.id, embed: 'devices,fleets' },
    { skip: currentCompany?.id === undefined }
  );

  const vehicleMap = useMemo(
    () =>
      vehicles.reduce((map, vehicle) => {
        map[vehicle.id] = vehicle;
        return map;
      }, {}),
    [vehicles]
  );

  const companyMap = useMemo(
    () =>
      companies.reduce((map, company) => {
        map[company.id] = company;
        return map;
      }, {}),
    [companies]
  );

  const templateList = useMemo(() => {
    const scopeOrder = {
      COMPANY: 1,
      FLEET: 2,
      DEVICE: 3
    };

    return (templateData || [])
      .map(i => {
        const template = i.configurationTemplate;
        const result = {
          ...template,
          associations: { devices: i.associatedDevices, fleets: i.associatedFleets }
        };

        if (!canFleetLevelGpio) {
          result.scope = template.default ? Scope.COMPANY : Scope.DEVICE;
        }

        return result;
      })
      .sort((a, b) => {
        const scopeA = scopeOrder[a.scope] || 0;
        const scopeB = scopeOrder[b.scope] || 0;
        return scopeA - scopeB;
      });
  }, [currentCompany?.id, templateData, isFetchingGpioTemplate]);

  const { deviceTemplateMap, fleetTemplateMap } = useMemo(() => {
    const deviceMap = {};
    const fleetMap = {};

    templateList.forEach(template => {
      template.associations.devices.forEach(device => {
        deviceMap[device.id] = template;
      });

      template.associations.fleets?.forEach(fleet => {
        fleetMap[fleet.id] = template;
      });
    });
    return { deviceTemplateMap: deviceMap, fleetTemplateMap: fleetMap };
  }, [templateList]);

  const deviceList = useMemo(
    () =>
      allDevices.map(device => {
        const template = deviceTemplateMap[device.id];
        const vehicle = device.vehicle ? vehicleMap[device.vehicle.id] : null;
        return { ...device, vehicle: vehicle, template };
      }),
    [allDevices, vehicleMap, deviceTemplateMap]
  );

  const fleetList = useMemo(() => {
    const list = [];
    allFleets.forEach(f => {
      if (f.id) {
        const template = fleetTemplateMap[f.id];
        const fleet = { ...f, company: companyMap[f.company?.id], template };
        list.push(fleet);
      }
    });
    return list;
  }, [allFleets, companyMap, fleetTemplateMap]);

  return {
    templateList,
    deviceList,
    fleetList,
    isFetching: isFetchingGpioTemplate || isFetchingVehicles || isFetchingFleets
  };
};
