import React, { useCallback, useEffect } from 'react';
import { Card, Table } from 'antd';
import styles from './AssignmentTable.module.scss';
import { fleetColumns } from './constants';
import { useEntityTemplateAssociations } from '../GPIO/hooks/useEntityTemplateAssociations';
import { useTranslation } from 'react-i18next';
import useSetState from 'utils/hooks/useSetState';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import debounce from 'lodash/debounce';
import { useSubCompanies } from 'features/company/companySlice';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import { prepareDataForMultiselect } from 'utils/filters';
import { toLower, trim } from 'lodash';

export const FleetsAssignmentTable = ({
  filteredFleets,
  setFilteredFleets,
  titleAddon,
  rowSelection,
  handleViewTemplate
}) => {
  const { t } = useTranslation();
  const { fleetList, templateList } = useEntityTemplateAssociations();
  const subCompanies = useSubCompanies();

  const [filters, setFilters] = useSetState({
    searchText: '',
    companies: [],
    templates: []
  });

  const updateSearchText = useCallback(
    debounce(value => {
      setFilters({ searchText: value });
    }, 300),
    []
  );

  useEffect(() => {
    setFilters({
      companies: prepareDataForMultiselect(subCompanies, t('Common.AllCompanies'), null)
    });
  }, [subCompanies, t]);

  useEffect(() => {
    const fleetLevelTemplate = templateList.filter(template =>
      ['company', 'fleet'].includes(template.scope)
    );
    setFilters({
      templates: prepareDataForMultiselect(
        fleetLevelTemplate,
        t('CompanyConfig.DeviceConfigurations.GPIOTemplates.AllTemplate'),
        null
      )
    });
  }, [templateList]);

  useEffect(() => {
    const { searchText, companies, templates } = filters;
    setFilteredFleets(
      fleetList.filter(fleet => {
        let match = true;
        if (searchText) {
          match = match && toLower(fleet.name).indexOf(toLower(trim(searchText))) > -1;
        }

        let checkedCompanyIds = companies
          .filter(company => company.checked)
          .map(company => company.id);
        match = match && checkedCompanyIds.includes(fleet.company.id);

        // let checkedTemplateIds = templates.filter(template => template.checked).map(template => template.id);
        // match = match && checkedTemplateIds.includes(fleet.template.id);

        return match;
      })
    );
  }, [filters]);

  return (
    <Card className={styles.fleetAssignment}>
      <div className={styles.titleSection}>
        <div className={styles.title}>Assign the configuration to fleets</div>
        {titleAddon}
      </div>

      <div className={styles.filterSection}>
        <AntSearchbar onFilter={value => updateSearchText(value)} />
        <AntMultiselect
          title={
            filters.companies?.some(value => !value.checked)
              ? t('Common.Companies')
              : t('Common.AllCompanies')
          }
          onFilter={v => setFilters({ companies: v })}
          data={filters.companies}
        />
        <AntMultiselect
          title={
            filters.templateList?.some(value => !value.checked)
              ? t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Template')
              : t('CompanyConfig.DeviceConfigurations.GPIOTemplates.AllTemplate')
          }
          onFilter={v => setFilters({ templates: v })}
          data={filters.templates}
        />
      </div>

      <Table
        columns={fleetColumns(t, handleViewTemplate)}
        dataSource={filteredFleets}
        rowKey={row => row.id}
        rowSelection={rowSelection}
      />
    </Card>
  );
};
