import React from 'react';
import { useLocation } from 'react-router-dom';
import { Layout as AntLayout } from 'antd';

import { SalesForceButton } from 'features/saleforce/SalesForceButton';
import { AWSConnectChat } from 'features/awsconnecchat/AWSConnectChat';
import { ChurnZero } from 'features/churnzero/ChurnZero';

import { Loading } from 'components/loading/Loading';
import PageRouter from 'components/route/PageRouter';
import { LeftNav } from './nav';

import { AWS_CHAT_ENABLED, CHURNZERO_ENABLE } from 'config';

import styles from './layout.module.scss';

const { Content } = AntLayout;

export const Layout = ({ isLoading, ...props }) => {
  const location = useLocation();

  const hideLeftNavPaths = ['/insights/director'];
  const hideLeftNav = hideLeftNavPaths.some(path => location?.pathname?.startsWith(path));

  return (
    <>
      {!isLoading ? (
        <AntLayout className={styles.layout}>
          {!hideLeftNav && <LeftNav />}
          <AntLayout>
            <Content>
              <PageRouter />
            </Content>
          </AntLayout>

          {!hideLeftNav && (AWS_CHAT_ENABLED ? <AWSConnectChat /> : <SalesForceButton />)}
          {!hideLeftNav && CHURNZERO_ENABLE && <ChurnZero />}
        </AntLayout>
      ) : (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      )}
    </>
  );
};
