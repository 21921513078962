import React, { useEffect, useMemo, useState } from 'react';
import { toLower } from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useDebounce from 'utils/hooks/useDebounce';
import { Button } from 'antd';
import { addBreadcrumbs, setBackButton, setPageTitle } from 'features/page/pageSlice';
import { useDevices, useFleets } from 'features/fleets/fleetsSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import {
  executeDeleteGpioConfigurationTemplate,
  executeRestoreGpioConfigurationTemplate,
  executeSetCompanyGpioConfigurationTemplate,
  useDeleteGpioConfigurationTemplateByIdMutation,
  useGetGpioConfigurationTemplatesQuery,
  useRestoreGpioConfigurationTemplateByIdMutation,
  useUpdateGpioConfigurationTemplateMutation
} from 'services/nextgen/ngGpioConfigurationApi';
import { prepareDataForMultiselect } from 'utils/filters';
import { sortStrings } from 'utils/strings';
import { SortDirection } from 'react-virtualized';
import { Select } from 'components/ant';

//components
import ContainerPageWrapper from 'components/container-page-wrapper/ContainerPageWrapper';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import { TabNavLink } from 'components/nav/NavLinks';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';

import { PATHS } from 'containers/Configuration/CompanyConfig/utils/constants';
import { PATHS as GPIO_PATHS } from './constants';
import { templateTypeEnum } from '../components/constants';
import { TemplatesTable } from './TemplatesTable';
import { BUTTON_IDS } from 'utils/globalConstants';
import { CloneGPIOModal } from './CloneGPIOModal';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { FeatureFlag, useCanFeatureFlag } from 'features/permissions';

import styles from './GPIO.module.scss';

export const GPIOs = ({ tab = 'all' }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fleets = useFleets();
  const allDevices = useDevices();
  const currentCompany = useCurrentCompany();
  const autoGeneratedTemplate = useCanFeatureFlag({
    featureFlag: FeatureFlag.autoGeneratedTemplate.flag
  });

  const [showCopyGpioModal, setShowCopyGpioModal] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState();
  const [filterFleets, setFilterFleets] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [filterType, setFilterType] = useState('all');
  const debouncedSearchText = useDebounce(filterText, 300);
  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState(SortDirection.ASC);
  const history = useHistory();
  const [restoreGpioConfigurationTemplateById] = useRestoreGpioConfigurationTemplateByIdMutation();
  const [deleteGpioConfigurationTemplateById] = useDeleteGpioConfigurationTemplateByIdMutation();
  const [updateGpioConfigurationTemplate] = useUpdateGpioConfigurationTemplateMutation();
  const { data, isFetching } = useGetGpioConfigurationTemplatesQuery(
    { companyId: currentCompany?.id, embed: 'devices,fleets' },
    { skip: currentCompany?.id === undefined }
  );
  const deviceMap = allDevices.reduce((map, device) => {
    map[device.id] = device;
    return map;
  }, {});

  const handleSort = ({ sortBy: vSortBy }) => {
    if (sortBy === vSortBy) {
      if (sortDirection === SortDirection.ASC) {
        setSortDirection(SortDirection.DESC);
      } else if (sortDirection === SortDirection.DESC) {
        setSortDirection(null);
      } else {
        setSortDirection(SortDirection.ASC);
      }
    } else {
      setSortBy(vSortBy);
      setSortDirection(SortDirection.ASC);
    }
  };

  useEffect(() => {
    dispatch(setPageTitle(t('CompanyConfig.DeviceConfigurations.GPIOConfig.Title')));
    dispatch(setBackButton(false));

    dispatch(
      addBreadcrumbs([
        {
          breadcrumbName: t('CompanyConfig.Title'),
          path: PATHS.COMPANY_CONFIG
        },
        {
          breadcrumbName: t('CompanyConfig.DeviceConfigurations.Title'),
          path: PATHS.DEVICE_CONFIG_LINKS
        },
        {}
      ])
    );

    return () => {
      dispatch(addBreadcrumbs([]));
    };
  }, [dispatch, t]);

  useEffect(() => {
    const fleetsOptions = fleets.filter(
      fleet => fleet.id && fleet.company && currentCompany.id === fleet.company.id
    );
    fleetsOptions.push({ id: -1, name: t('Common.NoFleet') });
    setFilterFleets(prepareDataForMultiselect(fleetsOptions, t('Common.AllFleets')));
  }, [fleets, history, t]);

  const allTemplates = useMemo(() => {
    return (data || []).map(i => {
      const template = i.configurationTemplate;
      const devices = [];
      const vehicleMap = new Map();

      if (i.associatedDevices?.length > 0) {
        i.associatedDevices.forEach(d => {
          const device = deviceMap[d.id];

          if (device && device.status === 'ENABLED') {
            devices.push(device);

            const vehicle = device.vehicle;
            if (vehicle) {
              vehicleMap.set(vehicle.id, vehicle);
            }
          }
        });
      }

      return {
        ...template,
        associationEntity: {
          devices,
          vehicles: [...vehicleMap.values()],
          fleets: i.associatedFleets ?? []
        }
      };
    });
  }, [currentCompany?.id, data, isFetching, deviceMap]);

  const [templates, setTemplates] = useState(allTemplates);

  useEffect(() => {
    let result = allTemplates;
    let checkedFleetsIds = filterFleets
      .filter(fleet => fleet.checked)
      .map(fleet => parseInt(fleet.id, 10));
    if (checkedFleetsIds.length <= 0) {
      setTemplates([]);
      return;
    }
    result = result.filter(t =>
      tab === 'deleted' ? t.status === 'DISABLED' : t.status === 'ENABLED'
    );

    if (autoGeneratedTemplate) {
      if (filterType !== 'all') {
        result = result.filter(t => t.autoGenerated === (filterType === 'auto'));
      }
    }
    if (debouncedSearchText) {
      result = result.filter(t => toLower(t.name).indexOf(toLower(debouncedSearchText)) > -1);
    }
    if (!(checkedFleetsIds.indexOf(-1) > -1)) {
      result = result.filter(t =>
        checkedFleetsIds.some(
          e => t.associations?.fleets?.size > 0 && t.associations?.fleets?.has(e)
        )
      );
    }
    result = result.sort((a, b) => {
      let compare = 0;
      if (sortDirection) {
        if (sortBy === 'name') {
          compare = sortStrings(a?.name, b?.name);
        }
        if (sortDirection === SortDirection.DESC) {
          compare = compare * -1;
        }
      }
      return compare;
    });
    setTemplates(result);
  }, [filterFleets, filterType, debouncedSearchText, sortBy, sortDirection, data, tab]);

  const handleAction = actionObject => {
    if (actionObject.action === 'copy') {
      setShowCopyGpioModal(true);
      setSelectedTemplateId(actionObject.data.id);
    } else if (actionObject.action === 'delete') {
      confirmationModal(
        `${t('Common.DeleteButton')} ${actionObject.data?.name}`,
        `${
          actionObject.data.default
            ? t('CompanyConfig.DeviceConfigurations.GPIOTemplates.DeleteCompanyTemplateWarning')
            : t('Common.SureDelete') + ' ' + actionObject.data?.name + '?'
        }`,
        t('Common.DeleteButton'),
        t('Common.CancelButton'),
        () => {
          executeDeleteGpioConfigurationTemplate(
            { id: actionObject.data.id, name: actionObject.data?.name },
            deleteGpioConfigurationTemplateById,
            dispatch
          );
        },
        'delete'
      );
    } else if (actionObject.action === 'restore') {
      executeRestoreGpioConfigurationTemplate(
        { id: actionObject.data.id, name: actionObject.data?.name },
        restoreGpioConfigurationTemplateById,
        dispatch
      );
    } else if (actionObject.action === 'setCompanyTemplate') {
      const configurationTemplateRequest = {
        configurationTemplate: {
          default: true,
          id: actionObject.data.id,
          company: {
            id: currentCompany.id
          }
        }
      };

      executeSetCompanyGpioConfigurationTemplate(
        { body: configurationTemplateRequest, id: actionObject.data.id },
        updateGpioConfigurationTemplate,
        dispatch
      );
    }
  };

  return (
    <>
      <ContainerPageWrapper>
        <HeaderPageWrapper>
          <div>
            <TabNavLink exact to={GPIO_PATHS.GPIO_DEFAULT}>
              {t('CompanyConfig.DeviceConfigurations.GPIOTemplates.AllTemplate')}
            </TabNavLink>
            <TabNavLink exact to={GPIO_PATHS.GPIO_CONFIGURED}>
              {t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Configured')}
            </TabNavLink>
            <TabNavLink exact to={GPIO_PATHS.GPIO_DELETED}>
              {t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Deleted')}
            </TabNavLink>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <Button
              type="primary"
              size="large"
              onClick={() => {
                history.push(GPIO_PATHS.GPIO_ADD);
              }}
              id={BUTTON_IDS.addGPIOTemplate}
              style={{ margin: '0 10px 0 0' }}
            >
              {t('CompanyConfig.DeviceConfigurations.GPIOTemplates.AddTemplate')}
            </Button>
          </div>
        </HeaderPageWrapper>
        <div style={{ display: 'flex', background: '#f7f8f9' }}>
          <FilterWrapper>
            <AntSearchbar onFilter={value => setFilterText(value)} />
            <AntMultiselect
              title={
                filterFleets?.some(value => !value.checked)
                  ? t('Common.Fleets')
                  : t('Common.AllFleets')
              }
              data={filterFleets}
              onFilter={v => setFilterFleets(v)}
            />
            {autoGeneratedTemplate && (
              <Select
                className={styles.filterType}
                style={{
                  minWidth: '180px',
                  marginRight: '10px',
                  fontSize: '14px',
                  color: '#818ea1',
                  height: '40px'
                }}
                defaultValue="all"
                onChange={v => setFilterType(v)}
                options={[
                  {
                    value: 'all',
                    label: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.templateType.All')
                  },
                  {
                    value: 'user',
                    label: t(
                      'CompanyConfig.DeviceConfigurations.GPIOTemplates.templateType.User-created'
                    )
                  },
                  {
                    value: 'auto',
                    label: t(
                      'CompanyConfig.DeviceConfigurations.GPIOTemplates.templateType.Auto-generated'
                    )
                  }
                ]}
              ></Select>
            )}
          </FilterWrapper>
          <label
            style={{
              display: 'flex',
              width: '100%',
              marginBottom: 0,
              paddingRight: '20px',
              alignItems: 'center',
              justifyContent: 'flex-end',
              minHeight: '52px'
            }}
          >
            {templates.length}{' '}
            {templates.length === 1
              ? `${t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Template')}`
              : `${t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Templates')}`}
          </label>
        </div>
        <div style={{ flex: '1 0 0' }}>
          <TemplatesTable
            templates={templates}
            isLoading={isFetching}
            filterTab={tab}
            sortBy={sortBy}
            sortDirection={sortDirection}
            handleSort={handleSort}
            handleAction={handleAction}
            templateType={templateTypeEnum.GPIO}
          />
        </div>
      </ContainerPageWrapper>
      <CloneGPIOModal
        showModal={showCopyGpioModal}
        onCancel={() => {
          setShowCopyGpioModal(false);
        }}
        selectedTemplateId={selectedTemplateId}
        onOk={() => {
          setShowCopyGpioModal(false);
        }}
      />
    </>
  );
};
