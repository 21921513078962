import React, { useMemo, useState, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Row, Col, Button, Alert } from 'antd';
import moment from 'moment';
import { sortBy } from 'lodash';

import { useCurrentCompanyId } from 'features/company/companySlice';
import { useCameraHealthEventTypes } from 'features/camera/cameraSlice';
import {
  fetchCompanyCameraHealthEvents,
  fetchCompanyCameraHealthSummary
} from 'features/camera/cameraApi';
import { useLocalization } from 'features/localization/localizationSlice';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { parseErrorMessage } from 'utils/strings';
import { format } from 'utils/dates';

import { BarChart } from 'components/ant/Charts/bar-chart/BarChart';
import { VisualizationCard } from './VisualizationCard';
import { Glossary } from './Glossary';
import { StatsCard } from './StatsCard';
import { HintedSearchInput } from './HintedSearchInput';
import { COLS, DeviceList } from './DeviceList';
import { HealthEventDisplayGroupingRules } from '../../constant';

const columns = [COLS.DeviceName, COLS.HealthEventType, COLS.TriggeredTimestamp];

const START_TIME = moment()
    .subtract(3, 'month')
    .startOf('M')
    .format(),
  PAGE_SIZE = 10;

export function HealthCheck({ startTime = START_TIME }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { types: eventTypes, isFetching } = useCameraHealthEventTypes();

  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const [selectedCategoryEventIndex, setSelectedCategoryEventIndex] = useState(null);

  const currentCompanyId = useCurrentCompanyId();
  const reqCtxRef = useRef({
    request: null
  });

  const [companySummary, setCompanySummary] = useState(null);

  const categories = useMemo(() => {
    const validCategories = {};
    (companySummary?.eventAggregate || []).forEach(({ eventType, totalCount }) => {
      const category = eventTypes?.find(eType => eType.code === eventType)?.category;
      if (category) {
        validCategories[category] = validCategories[category] || {
          category,
          count: 0,
          events: [],
          label: t([`CameraHealth.Category.${category}`, category])
        };
        validCategories[category].count += totalCount;
        validCategories[category].events.push({
          eventType,
          totalCount,
          groupAsEventType:
            HealthEventDisplayGroupingRules[category] &&
            Object.keys(HealthEventDisplayGroupingRules[category]).find(groupEventType =>
              HealthEventDisplayGroupingRules[category][groupEventType].test(eventType)
            )
        });
      }
    });
    return sortBy(
      Object.values(validCategories).map(({ events, ...c }) => ({
        ...c,
        events,
        // Group event types for display purposes
        groupedEvents: Object.values(
          events.reduce((acc, { eventType, groupAsEventType, totalCount }) => {
            if (groupAsEventType) {
              acc[groupAsEventType] = acc[groupAsEventType] || {
                eventType: groupAsEventType,
                totalCount: 0,
                events: []
              };
              acc[groupAsEventType].totalCount += totalCount;
              acc[groupAsEventType].events.push({
                eventType,
                totalCount
              });
            } else {
              acc[eventType] = acc[eventType] || {
                eventType,
                totalCount: 0,
                events: []
              };
              acc[eventType].totalCount += totalCount;
              acc[eventType].events.push({
                eventType,
                totalCount
              });
            }
            return acc;
          }, {})
        )
      })),
      'label'
    );
  }, [eventTypes, companySummary]);

  useEffect(() => {
    if (!currentCompanyId) {
      return;
    }
    const ctxParams = {
      companyId: currentCompanyId,
      startTime
    };

    const onReqDone = error => {
      if (reqCtxRef.current.request) {
        reqCtxRef.current.request.abort();
      }
      reqCtxRef.current.request = null;
      reqCtxRef.current.fetchTime = moment().valueOf();
      reqCtxRef.current.error = error || null;
      reqCtxRef.current.ctxParams = ctxParams;
      setIsLoading(false);
    };

    let reqCtx = reqCtxRef.current;
    if (!reqCtx?.fetchTime) {
      if (reqCtx.request) {
        reqCtx.request.abort();
        reqCtx.request = null;
      }
      reqCtx.ctxParams = ctxParams;
      reqCtx.request = dispatch(
        fetchCompanyCameraHealthSummary({
          ...ctxParams,
          onSuccess: summary => {
            onReqDone();
            setCompanySummary(summary);
          },
          onError: errMsg => {
            onReqDone(errMsg);
            setCompanySummary({});
          }
        })
      );
      const fetchData = async () => {
        try {
          setIsLoading(true);
          await reqCtx.request;
        } catch (e) {
          onReqDone(e);
          dispatch(
            openToast({
              type: ToastType.Error,
              message: t('CameraHealth.FetchError', {
                error: parseErrorMessage(e),
                title: t('CameraHealth.Health Check')
              })
            })
          );
        }
      };
      fetchData();
    }
  }, [dispatch, reqCtxRef, currentCompanyId, startTime]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
      <Row gutter={16}>
        <Col span={12}>
          <StatsCard
            title={t('CameraHealth.TotalProvisionedDevices')}
            content={companySummary?.totalDevices}
            loading={isLoading}
          />
        </Col>
        <Col span={12}>
          <StatsCard
            title={t('CameraHealth.TotalCurrentIssues')}
            content={companySummary?.totalRecords}
            loading={isLoading}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <CategorizedEventsBarChart
            loading={isFetching || isLoading}
            categories={categories}
            selectedCategoryIndex={selectedCategoryIndex}
            onCategoryClick={catIdx => {
              setSelectedCategoryIndex(catIdx);
              setSelectedCategoryEventIndex(null);
            }}
          />
        </Col>
      </Row>
      {categories?.[selectedCategoryIndex] && (
        <Row gutter={16}>
          <Col span={16}>
            <CategoryEventsBarChart
              category={categories[selectedCategoryIndex]}
              categoryEvents={categories[selectedCategoryIndex].groupedEvents}
              selectedCategoryEventIndex={selectedCategoryEventIndex}
              onCategoryEventClick={setSelectedCategoryEventIndex}
            />
            <CategoryDevicesList
              startTime={startTime}
              category={categories?.[selectedCategoryIndex]?.category}
              categoryEvents={
                categories?.[selectedCategoryIndex]?.groupedEvents?.[selectedCategoryEventIndex]
                  ? categories[selectedCategoryIndex].groupedEvents[selectedCategoryEventIndex]
                      .events
                  : categories?.[selectedCategoryIndex]?.events
              }
            />
          </Col>
          <Col span={8}>
            <Glossary category={categories[selectedCategoryIndex].category} />
          </Col>
        </Row>
      )}
    </div>
  );
}

const CategoryBarSize = {
  1: '20%',
  2: '8%',
  3: '5%',
  4: '5%'
};
const CategorizedEventsBarChart = ({
  categories,
  selectedCategoryIndex,
  onCategoryClick,
  loading
}) => {
  const { t } = useTranslation();
  return (
    <VisualizationCard
      loading={loading}
      title={t('CameraHealth.CurrentIssues')}
      subTitle={categories?.length ? t('CameraHealth.Select issue for details') : null}
      content={
        categories?.length ? (
          <BarChart
            data={categories}
            xAxisDataKey="category"
            yAxisDataKey="count"
            xAxisDataLabel={category => t([`CameraHealth.Category.${category}`, category])}
            yAxisLabel={`${t('CameraHealth.NoOfEvents')}`}
            barSize={CategoryBarSize[categories.length] || '4%'}
            barColor={['#a8d3f0', '#126399']}
            height={280}
            margin={{
              top: 10,
              bottom: 40,
              right: 10,
              left: 10
            }}
            activeBarIndex={selectedCategoryIndex}
            onBarClick={(entry, entryIndex) => onCategoryClick(entryIndex)}
          />
        ) : (
          <Alert message={t('CameraHealth.NoIssues')} type="info" showIcon />
        )
      }
    />
  );
};

const CategoryEventsBarChart = ({
  category,
  categoryEvents,
  selectedCategoryEventIndex,
  onCategoryEventClick
}) => {
  const { t } = useTranslation();
  return category ? (
    <VisualizationCard
      title={t('CameraHealth.TotalNoOfEvents')}
      subTitle={t('CameraHealth.Select issue for details')}
      titleExtra={<Button onClick={() => onCategoryEventClick(null)}>{t('Common.Clear')}</Button>}
      content={
        <BarChart
          layout="vertical"
          data={categoryEvents}
          xAxisDataKey="totalCount"
          yAxisDataKey="eventType"
          yAxisDataLabel={eventType => t(`CameraHealth.Event.${eventType}`)}
          barColor={['#4d96a0', '#2a5157']}
          xAxisLabel={`${t('CameraHealth.NoOfEvents')}`}
          height={Math.max((categoryEvents?.length || 0) * 55, 300)}
          margin={{
            top: 10,
            right: 20,
            bottom: 10,
            left: 3
          }}
          activeBarIndex={selectedCategoryEventIndex}
          onBarClick={(entry, entryIndex) => onCategoryEventClick(entryIndex)}
        />
      }
    />
  ) : null;
};

const CategoryDevicesList = ({
  startTime = START_TIME,
  pageSize = PAGE_SIZE,
  category,
  categoryEvents
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompanyId = useCurrentCompanyId();
  const localization = useLocalization();

  const reqCtxRef = useRef({
    request: null
  });

  const [events, setEvents] = useState(null);
  const [isListLoading, setIsListLoading] = useState(true);
  const [tableParams, setTableParams] = useState({
    limit: pageSize,
    offset: 0,
    searchText: '',
    pagination: {
      current: 1,
      pageSize
    }
  });

  const fetchEvents = useCallback(() => {
    const healthEventTypes = categoryEvents?.map(event => event.eventType)?.filter(type => !!type);

    if (!currentCompanyId || !healthEventTypes?.length) {
      return;
    }
    const ctxParams = {
      companyId: currentCompanyId,
      limit: tableParams.pagination.pageSize,
      offset: (tableParams.pagination.current - 1) * tableParams.pagination.pageSize,
      search: tableParams.searchText,
      healthEventTypes,
      startTime
    };

    const onReqDone = error => {
      if (reqCtxRef.current.request) {
        reqCtxRef.current.request.abort();
      }
      reqCtxRef.current.request = null;
      reqCtxRef.current.fetchTime = moment().valueOf();
      reqCtxRef.current.error = error || null;
      reqCtxRef.current.ctxParams = ctxParams;
      setIsListLoading(false);
    };

    let reqCtx = reqCtxRef.current;
    if (reqCtx.request) {
      reqCtx.request.abort();
      reqCtx.request = null;
    }
    reqCtx.ctxParams = ctxParams;
    reqCtx.request = dispatch(
      fetchCompanyCameraHealthEvents({
        ...ctxParams,
        onSuccess: eventsSummary => {
          onReqDone();
          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              total: eventsSummary?.totalRecords || 0
            }
          });
          setEvents(eventsSummary?.events || []);
        },
        onError: errMsg => {
          onReqDone(errMsg);
          setEvents([]);
          setTableParams({
            limit: pageSize,
            offset: 0,
            totalRecords: 0,
            searchText: '',
            pagination: {
              current: 1,
              pageSize
            }
          });
        }
      })
    );
    const fetchData = async () => {
      try {
        setIsListLoading(true);
        await reqCtx.request;
      } catch (e) {
        onReqDone(e);
        dispatch(
          openToast({
            type: ToastType.Error,
            message: t('CameraHealth.FetchError', {
              error: parseErrorMessage(e),
              title: t('CameraHealth.Health Check')
            })
          })
        );
      }
    };
    fetchData();
  }, [t, dispatch, reqCtxRef, currentCompanyId, tableParams, startTime, categoryEvents, pageSize]);

  useEffect(() => {
    fetchEvents();
  }, [tableParams.pagination?.current, tableParams.pagination?.pageSize, tableParams.searchText]);

  useEffect(() => {
    let tableParamsChanged = false;
    setTableParams(params => {
      tableParamsChanged = params.pagination.current !== 1 || !!params.searchText;
      return {
        limit: pageSize,
        offset: 0,
        totalRecords: 0,
        searchText: '',
        pagination: {
          current: 1,
          pageSize
        }
      };
    });
    if (!tableParamsChanged && !!reqCtxRef.current.fetchTime) {
      fetchEvents();
    }
  }, [categoryEvents, pageSize]);

  const filterOptions = useMemo(
    () => [
      {
        key: 'searchFilter',
        id: 'healthCheckSearch',
        component: (
          <HintedSearchInput
            hint={t('CameraHealth.SearchHint')}
            placeholder={t('Devices.ActualForm.NamePlaceholder')}
            defaultValue={tableParams.searchText}
            onSearch={text =>
              setTableParams(params => ({
                ...params,
                pagination: {
                  ...params?.pagination,
                  current: 1,
                  pageSize
                },
                searchText: text || ''
              }))
            }
          />
        )
      }
    ],
    [t, pageSize, tableParams.searchText]
  );

  const tableData = useMemo(
    () =>
      (events || []).map((event, idx) => ({
        deviceId: event.deviceId,
        deviceName: event.deviceName,
        healthEventType:
          Object.keys(HealthEventDisplayGroupingRules[category] || {}).find(groupEventType =>
            HealthEventDisplayGroupingRules[category][groupEventType].test(event.eventType)
          ) || event.eventType,
        eventTimestamp: moment(event.timeAt).valueOf(),
        rowKey: `${event.deviceId}-${moment(event.timeAt).valueOf()}-${idx}`,
        formattedEventTimestamp: format(
          new Date(event.timeAt),
          localization.formats.time.formats.dby_imp
        )
      })),
    [category, events, localization?.formats.time.formats.dby_imp]
  );

  const handleListChange = pagination =>
    setTableParams(prev => ({
      ...prev,
      pagination
    }));

  return category ? (
    <VisualizationCard
      title={t('CameraHealth.ListOfEvent')}
      subTitle={t(`CameraHealth.Category.${category}`)}
      content={
        <DeviceList
          filterOptions={filterOptions}
          isListLoading={isListLoading}
          devices={tableData}
          columns={columns}
          rowKey={'rowKey'}
          pagination={tableParams.pagination}
          onChange={handleListChange}
        />
      }
    />
  ) : null;
};
