import dayjs from 'dayjs';

export const filterTMAData = ({
  tmaData,
  debouncedSearchText,
  sdDataRecordTypeFilter,
  registrationFilter,
  dateRange,
  registrations
}) => {
  if (!tmaData) {
    return [];
  }

  return tmaData
    .reduce((acc, entry) => {
      // filters are not selected, return all the data
      if (!sdDataRecordTypeFilter && !registrationFilter) {
        acc.push(entry);
      }

      // all filters selected, filter by all
      if (sdDataRecordTypeFilter && registrationFilter) {
        entry?.type === sdDataRecordTypeFilter &&
          entry.vehicleId === registrationFilter &&
          acc.push(entry);
      }

      // only sdData filter selected
      if (sdDataRecordTypeFilter && !registrationFilter) {
        entry.type === sdDataRecordTypeFilter && acc.push(entry);
      }

      // only registration filter selected
      if (!sdDataRecordTypeFilter && registrationFilter) {
        entry.vehicleId === registrationFilter && acc.push(entry);
      }

      // search text filter
      if (debouncedSearchText) {
        acc = acc.filter(
          data =>
            data.attributes?.comment?.toLowerCase()?.includes(debouncedSearchText.toLowerCase()) ||
            data.attributes?.commentType
              ?.toLowerCase()
              ?.includes(debouncedSearchText.toLowerCase()) ||
            registrations
              .find(reg => reg.vehicleId === data.vehicleId)
              ?.registration?.toLowerCase()
              ?.includes(debouncedSearchText.toLowerCase()) ||
            data.attributes?.vehicleType?.toLowerCase()?.includes(debouncedSearchText.toLowerCase())
        );
      }

      return acc;
    }, [])
    .sort((a, b) => dayjs(b.timeAt).unix() - dayjs(a.timeAt).unix());
};
