import {
  Button,
  Card,
  Divider,
  Form,
  Spin,
  Alert,
  Modal,
  Space,
  Row,
  Col,
  Switch,
  Typography,
  Radio,
  Select,
  InputNumber
} from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React from 'react';
import { BUTTON_IDS } from 'utils/globalConstants';
import { parseErrorMessage } from 'utils/strings';
import { inputValidator } from './helper';
import { GlobalRoles, services, useCan } from 'features/permissions';
import { useCurrentCompany } from 'features/company/companySlice';
import { useDeviceTypesList } from 'features/device_types/deviceTypesSlice';
import {
  fetchFleets,
  useDevices,
  useIsFetching,
  useVehiclesFromFleets
} from 'features/fleets/fleetsSlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { openToast } from 'features/toasts/toastsSlice';

import {
  useGetAssetConfigurationTemplatesQuery,
  useAddAssetConfigurationTemplateMutation,
  useUpdateAssetConfigurationTemplateMutation,
  useGetAssetValuesByCompanyQuery,
  useCurrentCompanyServicesMap,
  useGetGpioChannelsQuery
} from 'services/nextgen/ngAssetConfigurationApi';

import { ToastType } from 'components/notifications/toasts/Toast';
import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';

import { PATHS } from './constants';
import { useLocalization } from 'features/localization/localizationSlice';
import { canHistoryGoBack } from 'utils/methods';
import { DevicesAssignmentTable } from '../components/DevicesAssignmentTable';
import { templateTypeEnum } from '../components/constants';
import { HeaderFormSection } from '../components/HeaderFormSection';
import style from '../GPIO/GPIOForm.module.scss';
import { Collection } from 'react-virtualized';

export const AssetForm = ({ templateId, action }) => {
  const { Option } = Select;
  const can = useCan();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentCompany = useCurrentCompany();
  const [form] = Form.useForm();
  const [companyLevel, setCompanyLevel] = useState(false);
  const [previousCompanyLevel, setPreviousCompanyLevel] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowKeysForFilteredDevices, setSelectedRowKeysForFilteredDevices] = useState([]);
  const serviceMap = useCurrentCompanyServicesMap();
  const [modal, contextHolder] = Modal.useModal();
  const [formValid, setFormValid] = useState(true);
  const [radioSelected, setRadioSelected] = useState('movement');
  const [rpmValue, setRpmValue] = useState(0);
  const [rpmSeconds, setRpmSeconds] = useState(0);
  const [inUse, setInUse] = useState(true);
  const [gpioChannel, setGpioChannel] = useState(null);
  const [gpioStatus, setGpioStatus] = useState(null);
  const [gpioChannelList, setGpioChannelList] = useState([]);
  const [gpioStatusList, setGpioStatusList] = useState([]);
  const [templateModalData, setTemplateModalData] = useState(undefined);
  const localization = useLocalization();

  const types = useDeviceTypesList();
  const iFace = types.find(type => type.code.toLowerCase() === 'iface');
  const external = types.find(type => type.code.toLowerCase() === 'external');
  const allDevices = useDevices();

  const isFetchingFleets = useIsFetching();
  const { vehicles, isFetchingVehicles } = useVehiclesFromFleets();

  const { data: templateData, isFetching } = useGetAssetConfigurationTemplatesQuery(
    { companyId: currentCompany?.id, embed: 'devices' },
    { skip: currentCompany?.id === undefined }
  );
  const {
    data: deviceSpecificConfigs,
    isFetchingDeviceSpecificConfigs
  } = useGetAssetValuesByCompanyQuery(
    { companyId: currentCompany?.id },
    { skip: currentCompany?.id === undefined }
  );

  const [
    addAssetConfigurationTemplate,
    { isLoading: isAddingTemplating }
  ] = useAddAssetConfigurationTemplateMutation();
  const [
    updateAssetConfigurationTemplate,
    { isLoading: isUpdatingTemplating }
  ] = useUpdateAssetConfigurationTemplateMutation();

  const gpioData = useGetGpioChannelsQuery();

  const canControlGpioConfiguration = can({
    oneOfRoles: [GlobalRoles.Reseller, GlobalRoles.SiteAdmin],
    oneOfCompanyServices: [services.GPIO]
  });

  const templates = useMemo(() => {
    const configurationTemplates = (templateData || []).map(i => {
      const template = i.configurationTemplate;
      return { ...template, associations: { devices: i.associatedDevices } };
    });
    return configurationTemplates;
  }, [currentCompany?.id, templateData, isFetching]);

  const deviceTemplateMap = useMemo(() => {
    const map = {};
    templates.forEach(template => {
      template.associations.devices.forEach(device => {
        map[device.id] = template;
      });
    });
    return map;
  }, [templates]);

  const vehicleMap = vehicles.reduce((map, vehicle) => {
    map[vehicle.id] = vehicle;
    return map;
  }, {});
  const companyHermesDevices = useMemo(
    () =>
      allDevices
        .filter(
          device =>
            device?.company?.id === currentCompany.id && device?.services?.includes('ASSETSUSAGE')
        )
        .map(device => {
          const template = deviceTemplateMap[device.id];
          const vehicle = device.vehicle ? vehicleMap[device.vehicle.id] : null;
          const deviceConfiguration =
            template === undefined
              ? deviceSpecificConfigs?.find(d => d.device?.id === device.id)
              : null;
          return {
            ...device,
            vehicle: vehicle,
            template: template,
            deviceConfiguration: deviceConfiguration
          };
        }),
    [iFace, external, currentCompany, allDevices, deviceTemplateMap, deviceSpecificConfigs]
  );
  const [devices, setDevices] = useState(companyHermesDevices);

  useEffect(() => {
    setDevices(companyHermesDevices);
  }, [companyHermesDevices]);

  const handleViewTemplateInfo = record => {
    const configuration =
      record?.template?.configurations || record?.deviceConfiguration?.configurations;
    setTemplateModalData(configuration);
  };

  const handleSaveForm = async () => {
    let confirmed = true;
    const templateName = form.getFieldValue('name');
    const existingTemplates = templateData || [];

    const existingTemplateNames = existingTemplates
      .filter(template => template.configurationTemplate.id !== templateId)
      .map(template => template.configurationTemplate.name);

    const isDuplicate = existingTemplateNames.some(
      existingName => existingName.toLowerCase() === templateName.toLowerCase()
    );

    // Check for duplicates
    if (isDuplicate) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('CompanyConfig.DeviceConfigurations.DuplicateTemplateNameError', {
            templateName
          })
        })
      );
      return;
    }

    if (previousCompanyLevel === true && companyLevel !== previousCompanyLevel) {
      confirmed = await modal.confirm({
        content: t('CompanyConfig.DeviceConfigurations.RemovingCompanyDefaultMessage', {
          templateName: 'Assets' //templateTypeEnum.Assets
        })
      });
    }

    // Count of devices which currently assigned to other template
    const count = selectedRowKeys
      .map(id => deviceTemplateMap[id])
      .filter(t => t && t.id !== templateId).length;

    if (confirmed && count > 0) {
      confirmed = await modal.confirm({
        content: t('CompanyConfig.DeviceConfigurations.DeviceAlreadyAssigned', {
          templateName: 'Assets' //templateTypeEnum.Assets
        })
      });
    }

    const templateServices = new Set();

    if (!companyLevel) {
      const serviceArray = [...templateServices];
      const deviceNotFullfiled = [...new Set(selectedRowKeys)]
        .map(id => devices.find(d => d.id === id))
        .filter(d => !serviceArray.every(e => d?.services?.includes(e))).length;

      if (confirmed && deviceNotFullfiled > 0) {
        confirmed = await modal.confirm({
          content: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.DeviceNotFulfilled', {
            count: deviceNotFullfiled
          })
        });
      }
    }

    if (confirmed) {
      submitForm();
    }
  };

  const submitForm = () => {
    const serviceMapInfo = serviceMap['ASSETSUSAGE'];
    let configs = [];

    form.validateFields().then(values => {
      let config = form.getFieldValue('asset.selection');
      if (config) {
        configs.push({
          service: serviceMapInfo,
          key: 'asset.selection',
          value: config
        });
      }
      config = form.getFieldValue('movement.inuse');
      if (config) {
        configs.push({
          service: serviceMapInfo,
          key: 'movement.inuse',
          value: config
        });
      } else {
        configs.push({
          service: serviceMapInfo,
          key: 'movement.inuse',
          value: inUse
        });
      }
      config = form.getFieldValue('gpio.channel');
      if (config) {
        configs.push({
          service: serviceMapInfo,
          key: 'gpio.channel',
          value: config
        });
      }
      config = form.getFieldValue('gpio.status');
      if (config) {
        configs.push({
          service: serviceMapInfo,
          key: 'gpio.status',
          value: config
        });
      }
      config = form.getFieldValue('rpm.over');
      if (config) {
        configs.push({
          service: serviceMapInfo,
          key: 'rpm.over',
          value: config
        });
      }
      config = form.getFieldValue('rpm.min');
      if (config) {
        configs.push({
          service: serviceMapInfo,
          key: 'rpm.min',
          value: config
        });
      }

      const configurationTemplateRequest = {
        configurationTemplate: {
          name: values.name,
          description: values.description,
          type: 'ASSETS_USAGE',
          company: {
            id: currentCompany.id
          },
          status: 'ENABLED',
          default: values.default,
          configurations: configs
        }
      };

      configurationTemplateRequest.deviceIds = [...new Set(selectedRowKeys)];

      if (action === 'add') {
        addAssetConfigurationTemplate({ body: configurationTemplateRequest })
          .then(handleResult)
          .catch(error => {
            openToast({
              type: ToastType.Error,
              message: 'Error adding new Asset Template'
            });
          });
      } else {
        updateAssetConfigurationTemplate({
          body: configurationTemplateRequest,
          id: templateId
        })
          .then(handleResult)
          .catch(error => {
            openToast({
              type: ToastType.Error,
              message: 'Error adding new Asset Template'
            });
          });
      }
    });
  };

  const handleResult = result => {
    const response = result.data;
    if (result.error) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${t(
            'CompanyConfig.DeviceConfigurations.GPIOTemplates.Error'
          )} ${parseErrorMessage(result?.error?.data)}`
        })
      );
      return;
    }
    if (response?.errors?.length > 0) {
      const mainMessage = t('CompanyConfig.DeviceConfigurations.GPIOTemplates.ResultWarning', {
        count: response.associatedDevices?.length
      });
      const errorMessages = response.errors.map(err => (
        <React.Fragment>
          {t('CompanyConfig.DeviceConfigurations.GPIOTemplates.ResultWarningDetail', {
            count: err.devices?.length,
            error: t(
              'CompanyConfig.DeviceConfigurations.GPIOTemplates.error.' + err.message,
              err.message
            )
          })}
          <br />
        </React.Fragment>
      ));
      const finalMessage = (
        <React.Fragment>
          {mainMessage}
          <br />
          {errorMessages}
        </React.Fragment>
      );
      dispatch(
        openToast({
          type: ToastType.Warning,
          message: finalMessage
        })
      );

      dispatch(fetchFleets());
      canHistoryGoBack(history, PATHS.ASSETS_DEFAULT);
      return;
    }

    dispatch(fetchFleets());
    dispatch(
      openToast({
        type: ToastType.Success,
        message: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Success')
      })
    );
    canHistoryGoBack(history, PATHS.ASSETS_DEFAULT);
  };

  useEffect(() => {
    if (templateId) {
      const editingTemplate = { ...templates.find(item => item.id === templateId) };
      form.setFieldsValue(editingTemplate);
      let config = editingTemplate?.configurations?.find(item => item.key === 'asset.selection');
      if (config) {
        form.setFieldValue('asset.selection', config.value);
        setRadioSelected(config.value);
      }
      config = editingTemplate?.configurations?.find(item => item.key === 'movement.inuse');
      if (config) {
        let useConfig = config.value === 'true' ? true : false;

        form.setFieldValue('movement.inuse', useConfig);
        setInUse(useConfig);
      }
      config = editingTemplate?.configurations?.find(item => item.key === 'gpio.channel');
      if (config) {
        form.setFieldValue('gpio.channel', config.value, 0);
        setGpioChannel(config.value);
      }
      config = editingTemplate?.configurations?.find(item => item.key === 'gpio.status');
      if (config) {
        form.setFieldValue('gpio.status', config.value, 0);
        setGpioStatus(config.value);
      }
      config = editingTemplate?.configurations?.find(item => item.key === 'rpm.over');
      if (config) {
        form.setFieldValue('rpm.over', config.value, 0);
        setRpmValue(config.value);
      }
      config = editingTemplate?.configurations?.find(item => item.key === 'rpm.min');
      if (config) {
        form.setFieldValue('rpm.min', config.value, 0);
        setRpmSeconds(config.value);
      }

      setCompanyLevel(editingTemplate.default);
      setPreviousCompanyLevel(editingTemplate.default);
      const selectedDeviceIds = editingTemplate.associations?.devices?.map(d => d.id);
      setSelectedRowKeys(selectedDeviceIds);
      setSelectedRowKeysForFilteredDevices(selectedDeviceIds);
    }
  }, [templates, templateId]);

  useEffect(() => {
    dispatch(
      setPageTitle(
        t(
          action === 'add'
            ? 'CompanyConfig.DeviceConfigurations.AddTemplate'
            : 'CompanyConfig.DeviceConfigurations.UpdateTemplate',
          { templateName: t('CompanyConfig.DeviceConfigurations.AssetsConfig.Title') }
        )
      )
    );
    dispatch(setBackButton(true));
  }, [dispatch, t]);

  useEffect(() => {
    const deviceKeys = new Set(devices.map(d => d.id));
    // Keep only the state of the selected devices that are out of the filtered list.
    let newSelectedKeys = selectedRowKeys?.filter(k => !deviceKeys.has(k));
    newSelectedKeys = [...newSelectedKeys, ...selectedRowKeysForFilteredDevices];
    setSelectedRowKeys(newSelectedKeys);
  }, [selectedRowKeysForFilteredDevices]);

  useEffect(() => {
    if (gpioData.status === 'fulfilled') {
      const gpios = Object.keys(gpioData?.data);
      setGpioChannelList(gpios);
    }
  }, [gpioData]);

  const initializing = isFetching || isFetchingFleets || isFetchingVehicles;

  if (initializing) {
    return <Spin size="large"></Spin>;
  }

  const formItemStyle = {
    marginBottom: '3px' // Adjust the bottom margin as needed
  };

  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      // Update the selected rows in the state
      setSelectedRowKeysForFilteredDevices(selectedRowKeys);
      setDirty(true);
    }
  };

  const handleRadioChange = e => {
    const newValue = e.target.value;
    setRadioSelected(newValue);
    form.setFieldValue('asset.selection', newValue);
    if (newValue === 'movement') {
      form.setFieldValue('movement.inuse', true);
    } else {
      form.setFieldValue('movement.inuse', null);
    }
    form.setFieldValue('gpio.status', null);
    form.setFieldValue('gpio.channel', null);
    form.setFieldValue('rpm.over', null);
    form.setFieldValue('rpm.min', null);
  };

  const handleMovementInUseSwitch = checked => {
    setInUse(checked);
    form.setFieldValue('movement.inuse', checked);
  };

  const onGpioChannelChange = value => {
    form.setFieldValue('gpio.status', null);

    setGpioChannel(value);

    const selectedValues = gpioData.data[value][0].split(',');
    setGpioStatusList(selectedValues);

    form.setFieldValue('gpio.channel', value);
  };

  const onGpioStatusChange = value => {
    setGpioStatus(value);
    form.setFieldValue('gpio.status', value);
  };

  const handleRpmOverChange = value => {
    setRpmValue(value);
    form.setFieldValue('rpm.over', value);
  };

  const handleRpmSecondsChange = value => {
    setRpmSeconds(value);
    form.setFieldValue('rpm.min', value);
  };

  const radioOptions = [
    {
      label: t('CompanyConfig.DeviceConfigurations.AssetsTemplates.Movement'),
      value: 'movement'
    }
  ];

  if (canControlGpioConfiguration) {
    radioOptions.push({
      label: t('CompanyConfig.DeviceConfigurations.AssetsTemplates.GPIO'),
      value: 'GPIO'
    });
  }

  radioOptions.push({
    label: t('CompanyConfig.DeviceConfigurations.AssetsTemplates.RPM'),
    value: 'RPM'
  });

  return (
    <>
      {contextHolder}
      {/* <EditRouteGuard when={dirty} navigate={history.push} /> */}

      <Form
        form={form}
        layout="vertical"
        initialValues={{ 'asset.selection': radioSelected }} // Set initial value
        onValuesChange={(_, allValues) => {
          setDirty(true);
          setFormValid(allValues.name && allValues.name.trim() !== '');
        }}
      >
        {/* Panel 1 */}
        <Card className={style.headerSectionCard}>
          <Typography.Title level={4}>
            {t('CompanyConfig.DeviceConfigurations.DriverIdTemplates.Basic Information')}
          </Typography.Title>
          <HeaderFormSection
            formItemStyle={formItemStyle}
            setCompanyLevel={setCompanyLevel}
            templateName={'assetsusage'}
          />
        </Card>

        {/* Panel 2 */}
        <Card className={style.configSectionCard}>
          <Typography.Title level={4}>
            {t('CompanyConfig.DeviceConfigurations.DriverIdTemplates.Configurations')}
          </Typography.Title>
          <>
            <Row justify={'start'}>
              <Col>
                <Form.Item
                  label={t('CompanyConfig.DeviceConfigurations.AssetsTemplates.AssetsUsage')}
                  name="asset.selection"
                >
                  <Radio.Group
                    onChange={value => handleRadioChange(value)}
                    value={radioSelected}
                    options={radioOptions}
                  ></Radio.Group>
                </Form.Item>
              </Col>
              <Divider type="vertical" className={style.assetDivider} />
              <Col>
                {radioSelected === 'movement' && (
                  <Form.Item
                    style={{ marginTop: '30px' }}
                    label={t('CompanyConfig.DeviceConfigurations.AssetsTemplates.MovementInUse')}
                    name="movement.inuse"
                    valuePropName="checked"
                  >
                    <Switch
                      defaultChecked={true}
                      onChange={value => handleMovementInUseSwitch(value)}
                      value={inUse}
                    />
                  </Form.Item>
                )}
                {canControlGpioConfiguration && radioSelected === 'GPIO' && (
                  <>
                    <Form.Item
                      style={{
                        display: 'inline-block',
                        width: '250px',
                        marginRight: '14px',
                        marginTop: '30px'
                      }}
                      label={t('CompanyConfig.DeviceConfigurations.AssetsTemplates.GPIOChannel')}
                      name="gpio.channel"
                    >
                      <Select
                        placeholder={t('Devices.DeviceConfigurations.SelectOption')}
                        onChange={onGpioChannelChange}
                        value={gpioChannel}
                      >
                        {gpioChannelList?.map(option => (
                          <Option key={option} value={option}>
                            {option}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      style={{ display: 'inline-block', width: '250px', marginTop: '30px' }}
                      label={t('CompanyConfig.DeviceConfigurations.AssetsTemplates.GPIOInUse')}
                      name="gpio.status"
                    >
                      <Select
                        placeholder={t('Devices.DeviceConfigurations.SelectOption')}
                        onChange={onGpioStatusChange}
                        disabled={!gpioChannel}
                        value={gpioStatus}
                        defaultActiveFirstOption
                      >
                        {gpioStatusList?.map(option => (
                          <Option key={option} value={option}>
                            {option}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </>
                )}
                {radioSelected === 'RPM' && (
                  <>
                    <Col>
                      <Row>
                        <span style={{ marginTop: '30px' }}>
                          {t('CompanyConfig.DeviceConfigurations.AssetsTemplates.RPMinUse')}
                        </span>
                        <Form.Item
                          name="rpm.over"
                          style={{ marginBottom: '14px', marginTop: '26px' }}
                        >
                          <InputNumber
                            placeholder="0"
                            min={0}
                            value={rpmValue}
                            style={{ marginRight: '10px', marginLeft: '10px' }}
                            onChange={value => handleRpmOverChange(value)}
                          />
                        </Form.Item>
                        <span style={{ marginTop: '30px' }}>
                          {t('CompanyConfig.DeviceConfigurations.AssetsTemplates.rpm')}
                        </span>
                      </Row>
                      <Row>
                        <span style={{ marginTop: '5px' }}>
                          {t('CompanyConfig.DeviceConfigurations.AssetsTemplates.RPMnotUse')}
                          {rpmValue}
                          {t('CompanyConfig.DeviceConfigurations.AssetsTemplates.RPMnotUse2')}
                        </span>
                        <Form.Item name="rpm.min">
                          <InputNumber
                            placeholder="0"
                            min={0}
                            value={rpmSeconds}
                            style={{ marginRight: '10px', marginLeft: '10px' }}
                            onChange={value => handleRpmSecondsChange(value)}
                          />
                        </Form.Item>
                        <span style={{ marginTop: '5px' }}>
                          {t('CompanyConfig.DeviceConfigurations.AssetsTemplates.seconds')}
                        </span>
                      </Row>
                    </Col>
                  </>
                )}
              </Col>
            </Row>
          </>
        </Card>
      </Form>

      {/* Panel 3 */}
      {companyLevel && (
        <Card className={style.deviceSectionCardCompanyLevel}>
          <Alert
            message={t('CompanyConfig.DeviceConfigurations.CompanyLevelNote', {
              templateName: templateTypeEnum.Assets
            })}
            type="info"
            showIcon
          />
        </Card>
      )}
      {!companyLevel && (
        <DevicesAssignmentTable
          filteredDevices={companyHermesDevices}
          devices={devices}
          setDevices={setDevices}
          templates={templates}
          currentCompany={currentCompany}
          handleViewTemplate={handleViewTemplateInfo}
          rowSelection={rowSelection}
          templateType={templateTypeEnum.Assets}
          useTitle={true}
        />
      )}

      {/* Template Modal */}
      {templateModalData && (
        <Modal
          title={t('Common.Configuration')}
          onCancel={() => {
            setTemplateModalData(undefined);
          }}
          footer={null}
          open={true}
          width={1000}
          centered
        >
          <Form layout="vertical">
            <Row justify={'start'}>
              <Col>
                <Form.Item
                  style={formItemStyle}
                  className={style.seatbeltSpeedInput}
                  label={t('CompanyConfig.DeviceConfigurations.AssetsTemplates.AssetsUsage')}
                >
                  <Radio.Group
                    disabled={true}
                    options={radioOptions}
                    value={templateModalData?.find(item => item.key === 'asset.selection')}
                  />
                </Form.Item>
              </Col>
              {templateModalData?.find(item => item.key === 'asset.selection') === 'movement' && (
                <Col>
                  <Form.Item
                    style={formItemStyle}
                    className={style.seatbeltSpeedInput}
                    label={t('CompanyConfig.DeviceConfigurations.AssetsTemplates.MovementInUse')}
                  >
                    <Switch
                      disabled={true}
                      checked={
                        templateModalData?.find(item => item.key === 'movement.inuse')?.value ===
                        'true'
                      }
                    />
                  </Form.Item>
                </Col>
              )}
              {templateModalData?.find(item => item.key === 'asset.selection') === 'GPIO' && (
                <>
                  <Col>
                    <Form.Item
                      style={formItemStyle}
                      className={style.seatbeltSpeedInput}
                      label={t('CompanyConfig.DeviceConfigurations.AssetsTemplates.GPIOChannel')}
                    >
                      <Select
                        disabled={true}
                        value={templateModalData?.find(item => item.key === 'gpio.channel')?.value}
                      >
                        {gpioChannelList?.map(option => (
                          <Option key={option} value={option}>
                            {option}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      style={formItemStyle}
                      className={style.seatbeltSpeedInput}
                      label={t('CompanyConfig.DeviceConfigurations.AssetsTemplates.GPIOInUse')}
                    >
                      <Select
                        disabled={true}
                        value={localization.convertSpeed(
                          templateModalData?.find(item => item.key === 'company.max.offset')?.value
                        )}
                      >
                        {gpioStatusList?.map(option => (
                          <Option key={option} value={option}>
                            {option}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              )}
              {templateModalData?.find(item => item.key === 'asset.selection') === 'RPM' && (
                <>
                  <Col>
                    <Row>
                      <Form.Item style={formItemStyle} className={style.seatbeltSpeedInput}>
                        <span>
                          {t('CompanyConfig.DeviceConfigurations.AssetsTemplates.RPMinUse')}
                        </span>
                        <InputNumber
                          disabled={true}
                          precision={0}
                          value={
                            templateModalData?.find(item => item.key === 'rpm.over')?.value || 0
                          }
                        />
                        <span>{t('CompanyConfig.DeviceConfigurations.AssetsTemplates.rpm')}</span>
                      </Form.Item>
                    </Row>
                    <Row>
                      <Form.Item style={formItemStyle} className={style.seatbeltSpeedInput}>
                        <span>
                          {t('CompanyConfig.DeviceConfigurations.AssetsTemplates.RPMnotUse')}
                          {rpmValue}
                          {t('CompanyConfig.DeviceConfigurations.AssetsTemplates.RPMnotUse2')}
                        </span>
                        <InputNumber
                          disabled={true}
                          precision={0}
                          value={
                            templateModalData?.find(item => item.key === 'rpm.min')?.value || 0
                          }
                        />
                        <span>
                          {t('CompanyConfig.DeviceConfigurations.AssetsTemplates.seconds')}
                        </span>
                      </Form.Item>
                    </Row>
                  </Col>
                </>
              )}
            </Row>
          </Form>
        </Modal>
      )}

      {/* Footer */}
      <Card className={style.footerSectionCard}>
        <Space>
          <Button
            type="primary"
            size="large"
            disabled={!dirty || !formValid || isAddingTemplating || isUpdatingTemplating}
            onClick={handleSaveForm}
            id={BUTTON_IDS.assetsFormSave}
          >
            {t('Common.SaveButton')}
          </Button>
          <Button size="large" id={BUTTON_IDS.assetsFormCancel} onClick={history.goBack}>
            {t('Common.CancelButton')}
          </Button>
        </Space>
      </Card>
      <EditRouteGuard when={dirty && templateModalData} navigate={history.push}></EditRouteGuard>
    </>
  );
};
