import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Can, FeatureFlag, services } from 'features/permissions';
import { TabNavLink } from 'components/nav/NavLinks';

import styles from './Insights.module.scss';

const Tabs = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const isDirectorRoute = history?.location?.pathname?.startsWith('/insights/director')
    ? true
    : false;
  const route = isDirectorRoute ? '/insights/director/dashboards' : '/insights/dashboards';

  return (
    <div className={styles.tabs}>
      <TabNavLink
        to={route}
        isActive={(match, location) =>
          location.pathname === '/insights' ||
          location.pathname.startsWith('/insights/dashboard') ||
          location.pathname.startsWith('/insights/analysis') ||
          location.pathname === '/insights/director' ||
          location.pathname.startsWith('/insights/director/dashboard') ||
          location.pathname.startsWith('/insights/director/analysis')
        }
      >
        {t('Insights.Dashboards')}
      </TabNavLink>

      <Can featureFlag={FeatureFlag.insightsQSConsole.flag}>
        <TabNavLink to={'/insights/console'}>{t('Insights.Console')}</TabNavLink>
      </Can>
    </div>
  );
};

export default Tabs;

/* Hide Reports Tab on Insights
<Can oneOfCompanyServices={services.INSIGHTSNPI}>
        <TabNavLink
          to={'/insights/reports/myreports'}
          isActive={(match, location) =>
            location.pathname === '/insights/reports/myreports' ||
            location.pathname.startsWith('/insights/reports/catalogue') ||
            location.pathname.startsWith('/insights/reports/scheduled')
          }
        >
          {t('Insights.Reports')}
        </TabNavLink>
      </Can>
*/
