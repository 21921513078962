import { useState, useCallback } from 'react';

function useSetState(initialState = {}) {
  const [state, setState] = useState(initialState);

  const setMergedState = useCallback(newState => {
    setState(prevState => ({
      ...prevState,
      ...(typeof newState === 'function' ? newState(prevState) : newState)
    }));
  }, []);

  return [state, setMergedState];
}

export default useSetState;
