import React from 'react';
import { Modal } from 'antd';

export const NewScheduledReportModal = ({ isOpen, onClose }) => {
    return (
        <Modal
            title="New Schedule"
            open={isOpen}
            centered
            closable
            footer={null}
            onCancel={onClose}

        >
            {/* <ScheduledReportForm /> */}
        </Modal>
    );
};
