import React from 'react';
import { useHistory, useParams } from 'react-router';
import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Input, Form } from 'antd';
import { SaveOutlined, ShareAltOutlined } from '@ant-design/icons';

import {
  useGetQsAnalysisQuery,
  useGetQsConsoleUrlQuery,
  useAddQsAnalysisMutation,
  useGetQsUserQuery
} from 'services/nextgen';

import { Can, Cannot, FeatureFlag, services } from 'features/permissions';
import { openToast } from 'features/toasts/toastsSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import {
  openPublishDashboardModal,
  closePublishDashboardModal,
  useIsPublishDashboardModalOpen
} from 'features/insights/insightsSlice';

import { ToastType } from 'components/notifications/toasts/Toast';
import { confirm } from 'components/tn/modal/confirm';
import { PublishDashboardModal } from '../Modals';

import { Toolbar } from './Toolbar';

export const AnalysisToolbar = ({ children, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentCompany = useCurrentCompany();
  const [form] = Form.useForm();
  const formRef = React.useRef(null);
  const { analysisId } = useParams();
  const isPublishModalOpen = useIsPublishDashboardModalOpen();
  const analysis = useGetQsAnalysisQuery({ analysisId: analysisId }).data;
  const analysisUrl = useGetQsConsoleUrlQuery({ analysisId: analysisId });
  const qsUser = useGetQsUserQuery()?.data;
  const isQsUserAuthor = qsUser?.role?.toUpperCase() === 'AUTHOR';
  const [addQsAnalysis] = useAddQsAnalysisMutation();

  const isDirectorRoute = history?.location?.pathname?.startsWith('/insights/director')
    ? true
    : false;

  const handleSaveAsAnalysis = () => {
    confirm({
      t,
      title: t(`Insights.Modals.SaveAsDashboard.Title`),
      width: '700px',
      content: (
        <>
          <Form
            form={form}
            ref={formRef}
            layout={'vertical'}
            preserve={true}
            initialValues={{
              name: analysis.name
            }}
          >
            <Form.Item
              required
              name="name"
              label={t(`Insights.Modals.DashboardNameLabel`)}
              rules={[
                {
                  required: true,
                  message: t(`Insights.Modals.SaveAsDashboard.DashboardNameRequired`)
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </>
      ),
      confirmButtonText: t('Common.SaveButton'),
      onConfirm: () => {
        return new Promise((resolve, reject) => {
          form
            .validateFields()
            .then(values => {
              console.debug('handleSaveAsAnalysis - validateFields success', values);
              saveAsAnalysis(form.getFieldValue('name'), resolve, reject);
            })
            .catch(error => {
              console.error('handleSaveAsAnalysis - validateFields error', error);
              reject();
            });
        });
      },
      cancelButtonText: t('Common.CancelButton'),
      onCancel: () => {
        formRef.current?.resetFields();
      },
      iconStyles: { display: 'none' }
    });
  };

  const saveAsAnalysis = (analysisName, resolve, reject) => {
    addQsAnalysis({
      sourceAnalysisId: analysis.analysisId,
      companyId: currentCompany.id,
      name: analysisName
    })
      .then(result => {
        console.debug('handleSaveAsAnalysis', { result: result.data });
        if (result.error) {
          throw new Error(result.error);
        }
        dispatch(
          openToast({
            type: ToastType.Success,
            message: t('Insights.Toasts.SaveAsDashboardSuccess', {
              name: analysisName
            })
          })
        );
        resolve();
        if (isDirectorRoute) {
          history.push(`/insights/director/analysis/${result.data.analysisId}`);
        } else {
          history.push(`/insights/analysis/${result.data.analysisId}`);
        }
      })
      .catch(error => {
        console.error('handleSaveAsAnalysis', { error: error });
        dispatch(
          openToast({
            type: ToastType.Error,
            message: t('Insights.Toasts.SaveAsDashboardError', {
              name: analysisName
            })
          })
        );
        resolve();
      });
  };

  return (
    <Toolbar {...props}>
      <Can
        everyService={[services.INSIGHTSBUILDER]}
        everyCompanyService={[services.INSIGHTSBUILDER]}
        otherConditions={[() => isQsUserAuthor]}
      >
        <Tooltip title={t('Insights.Actions.SaveAs')} placement={'bottom'}>
          <Button
            style={{ marginLeft: '5px' }}
            type="secondary"
            size="large"
            icon={<SaveOutlined />}
            onClick={handleSaveAsAnalysis}
          />
        </Tooltip>
        <Tooltip title={t('Insights.Actions.Publish')} placement={'bottom'}>
          <Button
            style={{ marginLeft: '5px' }}
            type="secondary"
            size="large"
            icon={<ShareAltOutlined />}
            onClick={() => {
              dispatch(openPublishDashboardModal());
            }}
          />
        </Tooltip>
      </Can>
      {children}

      {/* button masks */}
      {analysisUrl.data && (
        <>
          <Can featureFlag={FeatureFlag.insightsUpdatedToolbarMask.flag}>
            <>
              <div
                style={{
                  display: 'block',
                  position: 'absolute',
                  right: '0',
                  marginTop: '10px',
                  width: '16rem',
                  height: '52px',
                  backgroundColor: '#eee'
                }}
              />
              <div
                style={{
                  display: 'block',
                  position: 'absolute',
                  right: '0',
                  marginTop: '52px',
                  width: '16rem',
                  height: '52px',
                  backgroundColor: '#fff'
                }}
              />
            </>
          </Can>
          <Cannot featureFlag={FeatureFlag.insightsUpdatedToolbarMask.flag}>
            <>
              <div
                style={{
                  display: 'block',
                  position: 'absolute',
                  right: '6rem',
                  marginTop: '10px',
                  width: '10rem',
                  height: '2.2rem',
                  backgroundColor: '#eee'
                }}
              />
              <div
                style={{
                  display: 'block',
                  position: 'absolute',
                  right: 0,
                  marginTop: '10px',
                  width: '4rem',
                  height: '2.2rem',
                  backgroundColor: '#eee'
                }}
              />
            </>
          </Cannot>
        </>
      )}

      <PublishDashboardModal
        analysis={analysis}
        isOpen={isPublishModalOpen}
        onClose={() => {
          dispatch(closePublishDashboardModal());
        }}
      />
    </Toolbar>
  );
};
