import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select, Tag } from 'antd';
import styles from './AlertSelect.module.scss';

const AlertSelect = ({
  properties,
  options,
  onChange,
  setIsDirty,
  mode = 'single',
  showArrow = 'true',
  selected = [],
  noStyle
}) => {
  const { name, label, labelCol, wrapperCol, placeholder, rules } = properties;

  const tagRender = props => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = event => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        className={styles.tagContainer}
        color="green"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
      >
        {label}
      </Tag>
    );
  };

  const handleOnChange = value => {
    if (typeof setIsDirty === 'function') {
      setIsDirty(true);
    }
    onChange(value);
  };
  return (
    <Form.Item
      name={name}
      label={label}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      rules={rules}
      noStyle={noStyle}
    >
      <Select
        placeholder={placeholder}
        onChange={value => handleOnChange(value)}
        mode={mode}
        tagRender={tagRender}
        getPopupContainer={triggerNode => triggerNode.parentNode}
        {...(showArrow ? {} : { suffixIcon: null })}
      >
        {options?.map(option => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

AlertSelect.propTypes = {
  properties: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    labelCol: PropTypes.shape({
      offset: PropTypes.number,
      span: PropTypes.number
    }),
    wrapperCol: PropTypes.shape({
      offset: PropTypes.number,
      span: PropTypes.number
    })
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  ),
  onChange: PropTypes.func.isRequired
};

export default AlertSelect;
