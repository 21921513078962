import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import request from 'superagent';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Button, Tag } from 'antd';
import { useHistory } from 'react-router';
import { sortBy } from 'lodash';
import { formatListForMultiselect } from 'components/form/antMultiselect/AntMultiselect';
import { Select } from 'components/ant';
import styles from './JourneyPlanner.module.scss';

import { JourneysToolbar } from './JourneysToolbar';
import { JourneysTableNew } from './JourneysTableNew';
import {
  useJourneyFolders,
  fetchJourneyFolders,
  deleteJourneyFolder
} from 'features/easydocs/foldersSlice';
import { openToast, setFolderUpdateState, useFolderUpdateMode } from 'features/toasts/toastsSlice';
import { Can, entities, useCanOneOfEntities } from 'features/permissions';
import { useCompanies, useSubCompanies } from 'features/company/companySlice';
import { useIsFetching as useIsFleetsFetching } from 'features/fleets/fleetsSlice';
import {
  useIsFetching as useIsJourneysFetching,
  fetchJourneyFilters,
  useUserJourneysFilters,
  updateFilters,
  useIsFiltersFetching,
  bulkUpdateJourneys
} from 'features/journeyPlanner/journeysSlice';
import { useIsCompanyKeyDifferent } from 'features/company/companySlice';

import FormInput from 'components/form/form-input/FormInput';
import { ToastType } from 'components/notifications/toasts/Toast';
import { prepareDataForMultiselect } from 'utils/filters';
import { format } from 'utils/dates';
import { REGEXPS } from '../../utils/regex';

import { API_PATH } from 'config';
import { buttonStyle, journeyfolderlessFolderFile } from '../Easydocs/constants';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import '../Easydocs/ContentFolder.scss';
import { BUTTON_IDS } from 'utils/globalConstants';
import './JourneyPlanner.scss';
import { secondsToHHMMSS } from 'utils/methods';
import { getJourneyType, getBulkActions, journeyTypes as allJourneyTypes } from './constants';
import useDebounce from 'utils/hooks/useDebounce';

const ContentFolder = ({
  // filteredJourneys,
  activeFolder,
  listValues,
  setEditFolderName,
  company,
  userKey,
  journeys,
  fleets,
  foldersForForms,
  localization,
  deleteModal,
  restoreJourney,
  filterTab
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const subCompanies = useSubCompanies();
  const isJourneysFetching = useIsJourneysFetching();
  const isFiltersFetching = useIsFiltersFetching();
  const isFleetsFetching = useIsFleetsFetching();
  const isFetching = isJourneysFetching || isFiltersFetching || isFleetsFetching;
  const folderUpdateMode = useFolderUpdateMode();
  const bulkActionsList = getBulkActions(t);
  const hasCompanyChanged = useIsCompanyKeyDifferent('journeys');

  const [filteredJourneys, setFilteredJourneys] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const debouncedSearchText = useDebounce(searchText, 300);
  const [companiesFilter, setCompaniesFilter] = useState([]);
  const [fleetsFilter, setFleetsFilter] = useState([]);
  const [fleetReset, setFleetReset] = useState(false);
  const [typesFilter, setTypesFilter] = useState([]);
  const [foldersFilter, setFoldersFilter] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [allFoldersSelected, setAllFoldersSelected] = useState(false);
  const [allFleetsSelected, setAllFleetsSelected] = useState(false);
  const [selectedFleets, setSelectedFleets] = useState([]);
  const [bulkFoldersFilter, setBulkFoldersFilter] = useState([]);
  const [bulkFleetsFilter, setBulkFleetsFilter] = useState([]);
  const [selectedJourneys, setSelectedJourneys] = useState([]);
  const [bulkAction, setBulkAction] = useState('');
  const allJourneysFolders = useJourneyFolders();
  const companies = useCompanies();
  const [forceFetchFilters, setForceFetchFilters] = useState(true);
  const journeyFilters = useUserJourneysFilters(forceFetchFilters);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [filterChanged, setFilterChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const prevJourneysRef = useRef();
  const initialLoad = useRef(true);

  const deleteFolders = () => {
    dispatch(deleteJourneyFolder(activeFolder.id, activeFolder.name));
    setEditFolderName('');
    // hideModal();
  };

  // useEffect(() => {
  //   dispatch(setFolderUpdateState());
  // }, [journeys]);

  useEffect(() => {
    dispatch(fetchJourneyFolders());
  }, [hasCompanyChanged]);

  const subCompaniesIdsFromFilter = useMemo(() => {
    return companiesFilter?.filter(comp => comp.checked).map(comp => comp.id);
  }, [companiesFilter]);

  const filteredFleets = useMemo(() => {
    return fleets
      .filter(
        fleet => fleet.id && fleet.company && subCompaniesIdsFromFilter.includes(fleet.company.id)
      )
      .map(fleet => ({
        name: fleet.name,
        checked: true,
        id: fleet.id
      }));
  }, [fleets, subCompaniesIdsFromFilter]);

  const areFiltersEqual = (filter1, filter2) => {
    if (filter1?.length !== filter2?.length) return false;
    return filter1.every((item, index) => item.checked === filter2[index]?.checked);
  };

  const areCompaniesEqual = useMemo(() => {
    return areFiltersEqual(companiesFilter, journeyFilters?.companiesFilter);
  }, [companiesFilter, journeyFilters?.companiesFilter]);

  useEffect(() => {
    const formattedFleets = formatListForMultiselect([
      { id: 0, name: `${t('Common.AllFleets')}` },
      ...filteredFleets,
      { id: -1, name: `${t('Common.NoFleets')}` }
    ]);

    areCompaniesEqual && !fleetReset && journeyFilters?.fleetsFilter?.length !== 0
      ? setFleetsFilter(journeyFilters?.fleetsFilter)
      : (() => {
          setFleetReset(false);
          setFleetsFilter(prevFilterFleets => {
            const hasCheckedInOld = prevFilterFleets.some(fleet => fleet.checked);
            const isAllFleetsChecked = prevFilterFleets[0]?.checked === true;

            if (!hasCheckedInOld || isAllFleetsChecked) {
              return formattedFleets;
            } else {
              const updatedFleets = formattedFleets.map(newFleet => {
                const matchingOldFleet = prevFilterFleets.find(
                  oldFleet => oldFleet.id === newFleet.id
                );
                if (matchingOldFleet && matchingOldFleet.checked && matchingOldFleet.id !== 0) {
                  return { ...newFleet, checked: true };
                }
                return { ...newFleet, checked: false };
              });

              return updatedFleets;
            }
          });
        })();
  }, [fleets, companiesFilter, history, t]);

  useEffect(() => {
    const companiesList = formatListForMultiselect([
      { id: 0, name: `${t('Common.AllCompanies')}` },
      ...subCompanies
    ]);
    const companyChanged =
      journeyFilters?.companiesFilter &&
      companiesList &&
      journeyFilters?.companiesFilter[1]?.id !== companiesList[1]?.id;

    const newCompaniesFilter =
      journeyFilters?.companiesFilter?.length && !companyChanged
        ? journeyFilters?.companiesFilter
        : companiesList;
    setCompaniesFilter(newCompaniesFilter);

    const journeyTypes = [];
    allJourneyTypes.forEach(journey => {
      const type = getJourneyType(journey?.apiName);
      const journeyType = {
        name: type,
        checked: true,
        id: journey?.apiName
      };
      if (!journeyTypes.some(j => j.id === journeyType.id)) {
        journeyTypes.push(journeyType);
      }
    });

    let typesList = formatListForMultiselect([
      { id: 0, name: `${t('Common.AllTypes')}` },
      ...journeyTypes
    ]);
    typesList = sortBy(typesList, [item => item.label.toLowerCase()]);
    let newTypesFilter =
      journeyFilters?.typesFilter?.length &&
      journeyFilters?.typesFilter?.length > 1 &&
      !companyChanged
        ? journeyFilters?.typesFilter
        : typesList;
    newTypesFilter = sortBy(newTypesFilter, [item => item.label.toLowerCase()]);
    setTypesFilter(newTypesFilter);

    let journeyFolders = [];
    allJourneysFolders?.forEach(folder => {
      journeyFolders.push({ id: folder.id, name: folder.name });
      journeyFolders.push();
    });
    journeyFolders = sortBy(journeyFolders, [item => item.name.toLowerCase()]);
    let foldersList = formatListForMultiselect([
      { id: 0, name: `${t('Common.AllFolders')}` },
      ...journeyFolders,
      { id: -1, name: `${t('Common.NoFolder')}` }
    ]);
    let newFoldersFilter =
      journeyFilters?.foldersFilter?.length &&
      journeyFilters?.foldersFilter?.length > 2 &&
      !companyChanged
        ? journeyFilters?.foldersFilter
        : foldersList;

    newFoldersFilter = newFoldersFilter.map(folder => {
      const updatedFolder = journeyFolders.find(journeyFolder => journeyFolder.id === folder.id);
      return updatedFolder ? { ...folder, label: updatedFolder.name } : folder;
    });

    const isAllFoldersChecked = newFoldersFilter.find(folder => folder.id === 0)?.checked;
    journeyFolders.forEach(journeyFolder => {
      if (!newFoldersFilter.some(folder => folder.id === journeyFolder.id)) {
        newFoldersFilter.push({
          id: journeyFolder.id,
          label: journeyFolder.name,
          checked: isAllFoldersChecked
        });
      }
    });

    const allFolders = newFoldersFilter.find(folder => folder.id === 0);
    const noFolder = newFoldersFilter.find(folder => folder.id === -1);
    const middleFolders = newFoldersFilter.filter(folder => folder.id !== 0 && folder.id !== -1);
    const sortedMiddleFolders = middleFolders.sort((a, b) => a.label.localeCompare(b.label));
    newFoldersFilter = [allFolders, ...sortedMiddleFolders, noFolder];

    setFoldersFilter(newFoldersFilter);
  }, [journeys, subCompanies, journeyFilters]);

  useEffect(() => {
    if (filterChanged && fleets?.length > 0 && companies?.length > 0) {
      dispatch(
        updateFilters({
          companiesFilter,
          fleetsFilter,
          typesFilter,
          foldersFilter
        })
      );
      setFilterChanged(false);
    }
  }, [fleets, companies, companiesFilter, fleetsFilter, typesFilter, foldersFilter, isDataLoaded]);

  const handleSearchChange = useCallback(searchText => {
    setSearchText(searchText);
  }, []);

  function handleCompaniesFilterChange(newFilter) {
    setCompaniesFilter(newFilter);
    setFilterChanged(true);
  }

  function handleFleetsFilterChange(newFilter) {
    setFleetsFilter(newFilter);
    setFilterChanged(true);
  }
  const handleTypesFilterChange = useCallback(typesFilter => {
    setTypesFilter(typesFilter);
    setFilterChanged(true);
  }, []);

  const handleFoldersFilterChange = useCallback(foldersFilter => {
    setFoldersFilter(foldersFilter);
    setFilterChanged(true);
  }, []);

  const handleFoldersChange = value => {
    if (value.includes('selectAll')) {
      if (allFoldersSelected) {
        setSelectedFolders([]);
        setAllFoldersSelected(false);
      } else {
        setSelectedFolders(
          bulkFoldersFilter.filter(folder => folder.id !== 'selectAll').map(folder => folder.id)
        );
        setAllFoldersSelected(true);
      }
    } else {
      setSelectedFolders(value);
      setAllFoldersSelected(value.length === bulkFoldersFilter.length - 1);
    }
  };

  const handleFleetsChange = value => {
    if (value.includes('selectAll')) {
      if (allFleetsSelected) {
        setSelectedFleets([]);
        setAllFleetsSelected(false);
      } else {
        setSelectedFleets(
          bulkFleetsFilter.filter(fleet => fleet.id !== 'selectAll').map(fleet => fleet.id)
        );
        setAllFleetsSelected(true);
      }
    } else {
      setSelectedFleets(value);
      setAllFleetsSelected(value.length === bulkFleetsFilter.length - 1);
    }
  };

  const handleJourneySelection = journeys => {
    setSelectedJourneys(journeys);
  };

  const handleSelectAllChange = isChecked => {
    if (isChecked) {
      setSelectedJourneys(filteredJourneys.map(journey => journey.id));
    } else {
      setSelectedJourneys([]);
    }
    handleJourneySelection(isChecked ? filteredJourneys.map(journey => journey.id) : []);
  };

  const handleBulkChanges = async () => {
    if (selectedJourneys.length === 0) return;
    let payload = [];
    if (bulkAction === 'AddToFolders') {
      payload = selectedJourneys.map(journey => ({
        id: journey,
        folders: selectedFolders.map(folderId => ({ id: folderId }))
      }));
    } else if (bulkAction === 'AddToFleets') {
      payload = selectedJourneys.map(journey => ({
        id: journey,
        tripAssociations: selectedFleets.map(folderId => ({ fleetId: folderId }))
      }));
    } else {
      let warningMessage = '';
      let bulkPayload = [];
      if (bulkAction === 'RemoveFromAllFolders') {
        warningMessage = i18next.t(
          'JourneyPlanner.BulkActionNotifications.DeleteJourneysFromFolders'
        );
        bulkPayload = selectedJourneys.map(journey => ({
          id: journey,
          folders: []
        }));
      }
      //other options
      else if (bulkAction === 'RemoveFromAllFleets') {
        warningMessage = i18next.t(
          'JourneyPlanner.BulkActionNotifications.DeleteJourneysFromFleets'
        );
        bulkPayload = selectedJourneys.map(journey => ({
          id: journey,
          tripAssociations: []
        }));
      } else if (bulkAction === 'DeleteAction') {
        warningMessage = i18next.t('JourneyPlanner.BulkActionNotifications.DeleteJourneys');
        bulkPayload = selectedJourneys.map(journey => ({
          id: journey,
          status: 'DELETED'
        }));
      }
      confirmationModal(
        i18next.t('Easydocs.Notifications.AreYouSure'),
        warningMessage,
        bulkAction === 'DeleteAction' ? i18next.t('Common.Modal.Delete') : i18next.t('Common.Yes'),
        bulkAction === 'DeleteAction' ? i18next.t('Common.Modal.Cancel') : i18next.t('Common.No'),
        async () => {
          payload = bulkPayload;

          try {
            await dispatch(bulkUpdateJourneys(payload, bulkAction));
            dispatch(setFolderUpdateState());
            setSelectedJourneys([]);
            setSelectedFleets([]);
            setSelectedFolders([]);
            setBulkAction('');
          } catch (error) {
            console.error('Error during bulk update:', error);
          }
        },
        // ['RemoveFromAllFolders', 'RemoveFromAllFleets', 'DeleteAction'].includes(bulkAction) &&
        //   'delete'
        bulkAction === 'DeleteAction' && 'delete'
      );
      return;
    }
    try {
      await dispatch(bulkUpdateJourneys(payload, bulkAction));
    } catch (error) {
      console.error('Error during bulk update:', error);
    }
    dispatch(setFolderUpdateState());
    setSelectedJourneys([]);
    setSelectedFleets([]);
    setSelectedFolders([]);
    setBulkAction('');
  };

  const resetFilters = filters => {
    return filters.map(filter => ({
      ...filter,
      checked: true
    }));
  };

  const [searchKey, setSearchKey] = useState(0);

  const handleRestoreDefault = () => {
    setSearchText(null);
    setSearchKey(prevKey => prevKey + 1);
    setCompaniesFilter(resetFilters(companiesFilter));
    setTypesFilter(resetFilters(typesFilter));
    setFoldersFilter(resetFilters(foldersFilter));
    setFleetReset(true);
    setFilterChanged(true);

    const subCompaniesIdsFromFilter = companiesFilter
      .filter(comp => comp.checked)
      .map(comp => comp.id);
    const filteredFleets = fleets
      .filter(
        fleet => fleet.id && fleet.company && subCompaniesIdsFromFilter.includes(fleet.company.id)
      )
      .map(fleet => {
        return {
          name: fleet.name,
          checked: true,
          id: fleet.id
        };
      });
    const formattedFleets = formatListForMultiselect([
      { id: 0, name: `${t('Common.AllFleets')}` },
      ...filteredFleets,
      { id: -1, name: `${t('Common.NoFleets')}` }
    ]);
    setFleetsFilter(formattedFleets);
  };

  const updateFolder = () => (e, field) => {
    if (
      (activeFolder[field] !== e.target.value && e.type === 'blur') ||
      e.which === 13 ||
      e.keyCode === 13
    ) {
      if (!e.target.value.length && field === 'name') {
        e.target.value = activeFolder.name;
        dispatch(
          openToast({
            type: ToastType.Error,
            message: `The Name field for the folder is required.`,
            autohide: true
          })
        );
        return;
      } else {
        const payload =
          field === 'name'
            ? { name: e.target.value, description: activeFolder.description }
            : { name: activeFolder.name, description: e.target.value };
        const existingName = foldersForForms
          .filter(folder => folder.name !== activeFolder.name)
          .find(folder => folder.name === payload.name);
        if (existingName) {
          e.target.value = activeFolder.name;
          dispatch(
            openToast({
              type: ToastType.Error,
              message: `The name for the folder already exists.`,
              autohide: true
            })
          );
          return;
        }
        request('PUT', API_PATH + `/folders/${activeFolder.id}?company_id=${company?.id}`)
          .set('Authorization', `Token token="${userKey}"`)
          .set('Content-Type', 'application/json')
          .send(payload)
          .then(() => {
            setEditFolderName(payload.name);
            if (activeFolder.name === 'New Folder' && payload.name !== activeFolder.name) {
              dispatch(
                openToast({
                  type: ToastType.Success,
                  message: `The New Folder has been updated with the name ${payload.name}.`
                })
              );
            }
            dispatch(fetchJourneyFolders());
          })
          .catch(err => {
            // actions.setSubmitting(false);
            console.warn('ERROR', err);
          });
      }
    }
  };

  const restrictedNames = ['folderless', 'alljourneys', 'deleted'];

  const changeFolderName = callback => newValue => {
    newValue = newValue.trimStart();

    const normalizedValue = newValue.toLowerCase().replace(/\s+/g, '');
    if (restrictedNames.includes(normalizedValue)) {
      callback('name', '');
      dispatch(
        openToast({
          type: ToastType.Error,
          message: i18next.t('Easydocs.FoldersMessage', {
            name: newValue
          }),
          autohide: true
        })
      );
      return;
    }
    if (!newValue.length) {
      callback('name', '');
    } else if (newValue.length && newValue[newValue.length - 1].match(REGEXPS.folderEasydocs)) {
      callback('name', newValue);
    }
  };

  const changeFolderDescription = callback => newValue => {
    newValue = newValue.trimStart();
    if (!newValue.length) {
      callback('description', '');
    }
    if (newValue.length && newValue[newValue.length - 1].match(REGEXPS.folderEasydocs)) {
      callback('description', newValue);
    }
  };

  const actionMessages = {
    AddToFolders: t('JourneyPlanner.Folders'),
    AddToFleets: t('JourneyPlanner.Fleets'),
    RemoveFromAllFolders: t('JourneyPlanner.RemoveFromAllFoldersHolder'),
    RemoveFromAllFleets: t('JourneyPlanner.RemoveFromAllFleetsHolder'),
    DeleteAction: t('JourneyPlanner.deleteActionHolder')
  };
  const displayMessage = actionMessages[bulkAction];
  const tagRender = props => {
    const { label } = props;
    return (
      <Tag {...props} className={styles.renderTagStyle}>
        {label}
      </Tag>
    );
  };

  const handleBulkActionChange = value => {
    setBulkAction(value);
    setSelectedFleets([]);
    setSelectedFolders([]);
  };

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      return;
    }

    if (
      prevJourneysRef.current &&
      fleets?.length > 0 &&
      companies?.length > 0 &&
      prevJourneysRef.current !== journeys &&
      !isLoading
    ) {
      setIsLoading(true);
      dispatch(fetchJourneyFilters()).finally(() => {
        setIsLoading(false);
        setIsDataLoaded(true);
      });
    }

    prevJourneysRef.current = journeys;
  }, [journeys, fleets, companies, isLoading]);

  useEffect(() => {
    setForceFetchFilters(false);
  }, [journeyFilters]);

  useEffect(() => {
    let bulkFoldersList = [];
    const selectAllLabel = (
      <b>
        {allFoldersSelected ? t('Home.UnselectAll') : t('Home.SelectAll')}{' '}
        {t('JourneyPlanner.Folders')}
      </b>
    );
    bulkFoldersList.push({ id: 'selectAll', name: selectAllLabel });
    const filteredListValues = listValues.filter(
      item => item.name !== t('Easydocs.AllJourneys') && item.name !== t('Easydocs.Folderless')
    );
    filteredListValues.forEach(folder => {
      if (folder && !bulkFoldersList.find(f => f.id === folder.id)) {
        bulkFoldersList.push({ id: folder.id, name: folder.name });
      }
    });
    setBulkFoldersFilter(prepareDataForMultiselect(bulkFoldersList));
  }, [listValues, allFoldersSelected]);

  useEffect(() => {
    let bulkFleetsList = [];
    const selectAllLabel = (
      <b>
        {allFleetsSelected ? t('Home.UnselectAll') : t('Home.SelectAll')}{' '}
        {t('JourneyPlanner.Fleets')}
      </b>
    );
    bulkFleetsList.push({ id: 'selectAll', name: selectAllLabel });
    fleets.forEach(dleet => {
      if (dleet && !bulkFleetsList.find(f => f.id === dleet.id)) {
        bulkFleetsList.push({ id: dleet.id, name: dleet.name });
      }
    });
    setBulkFleetsFilter(prepareDataForMultiselect(bulkFleetsList));
  }, [fleets, allFleetsSelected]);

  useEffect(() => {
    let journeysToFilter = journeys;
    setFilteredJourneys(
      journeysToFilter.filter(journey => {
        let isValid = true;

        if (debouncedSearchText) {
          const searchArray = debouncedSearchText
            .split(' ')
            .map(value => value.trim().toLowerCase());

          // Check if all split values are present in the journey properties
          isValid = searchArray.every(splitValue => {
            return (
              journey.name?.toLowerCase().includes(splitValue) ||
              journey.type?.toLowerCase().includes(splitValue) ||
              secondsToHHMMSS(journey?.estimatedDuration)
                ?.toString()
                .includes(splitValue) ||
              journey.plannedDuration?.toString().includes(splitValue) ||
              localization
                .convertDistance(journey.calculatedDistance)
                ?.toString()
                .includes(splitValue) ||
              journey.stopsCount?.toString().includes(splitValue) ||
              (journey.tripAssociations &&
                journey.tripAssociations.some(trip => {
                  const fleet = fleets.find(fleet => fleet.id === trip.fleetId);
                  const fleetCompany = companies.find(company => company.id === fleet?.company?.id);
                  return (
                    fleet?.name?.toLowerCase().includes(splitValue) ||
                    fleetCompany?.name?.toLowerCase().includes(splitValue)
                  );
                }))
            );
          });
        }

        // Filter by companies
        const checkedCompaniesIds = companiesFilter
          .filter(company => company.checked)
          .map(company => parseInt(company.id, 10));
        if (!(checkedCompaniesIds.indexOf(0) > -1)) {
          isValid = isValid && checkedCompaniesIds.indexOf(parseInt(journey.companyId, 10)) > -1;
        }

        // Filter by fleets
        const checkedFleetIds = fleetsFilter
          .filter(fleet => fleet.checked)
          .map(fleet => parseInt(fleet.id, 10));
        if (!(checkedFleetIds.indexOf(0) > -1)) {
          isValid =
            isValid &&
            checkedFleetIds.find(
              id =>
                journey?.tripAssociations?.find(association => association?.fleetId === id) ||
                (id === -1 && !journey?.tripAssociations.length)
            );
        }

        //Filter by journey type
        const checkedJourneyTypesIds = typesFilter
          .filter(type => type.checked)
          .map(type => type.id);
        if (checkedJourneyTypesIds.length > 0) {
          isValid = isValid && checkedJourneyTypesIds.includes(journey.type);
        } else {
          isValid = false;
        }

        //Filter by folder
        const checkedFolderIds = foldersFilter
          .filter(folder => folder.checked)
          .map(folder => folder.id);
        if (!(checkedFolderIds.indexOf(0) > -1)) {
          isValid =
            isValid &&
            checkedFolderIds.find(
              id =>
                journey?.folders?.find(folder => folder?.id === id) ||
                (id === -1 && !journey?.folders?.length)
            );
        }

        return isValid;
      })
    );
  }, [
    journeys,
    debouncedSearchText,
    companiesFilter,
    fleetsFilter,
    typesFilter,
    foldersFilter,
    filterTab
  ]);

  //Permisions
  const canFolderUpdate = useCanOneOfEntities(['FOLDER_UPDATE']);
  return (
    <div className={styles.newJourneyForm}>
      <div className="newJourneyForm">
        <div className={styles.bulkEditForm}>
          {activeFolder && (
            <Formik enableReinitialize={true} initialValues={activeFolder}>
              {({ setFieldValue }) => {
                return (
                  <Form>
                    <FormInput
                      name="name"
                      disabled={
                        activeFolder.name === journeyfolderlessFolderFile(t).name ||
                        activeFolder.name === t('Easydocs.AllJourneys') ||
                        activeFolder.name === t('Easydocs.Deleted') ||
                        !canFolderUpdate
                      }
                      onKeyDown={updateFolder(setFieldValue)}
                      onBlur={updateFolder(setFieldValue)}
                      onChange={changeFolderName(setFieldValue)}
                      isValidated
                    />
                  </Form>
                );
              }}
            </Formik>
          )}
          <div className="journey-folder-info">
            {activeFolder && activeFolder.createdAt && (
              <span>
                {i18next.t('Easydocs.CreatedOn')}{' '}
                {format(
                  new Date(activeFolder.createdAt),
                  localization.formats.time.formats.dby_imsp
                )}
              </span>
            )}
            <div className={styles.bulkEditButtonsContainer}>
              {journeys &&
                journeys.length === 0 &&
                activeFolder &&
                activeFolder?.name !== t('Easydocs.Deleted') &&
                activeFolder?.name !== t('Easydocs.AllJourneys') &&
                activeFolder.name !== journeyfolderlessFolderFile(t).name && (
                  <Can everyEntity={[entities.FOLDER_DESTROY]}>
                    <Button
                      onClick={() => {
                        confirmationModal(
                          i18next.t('Easydocs.Notifications.AreYouSure'),
                          i18next.t('Easydocs.Notifications.AreYouSureDeleteFolder', {
                            name: activeFolder?.name
                          }),
                          i18next.t('Common.Modal.Delete'),
                          i18next.t('Common.Modal.Cancel'),
                          deleteFolders,
                          'delete'
                        );
                        document.body.click();
                      }}
                      style={{ ...buttonStyle.default, ...buttonStyle.journeyDeleteBtn }}
                      id={BUTTON_IDS.contentFolderDeleteFolder}
                    >
                      {i18next.t('Easydocs.DeleteFolder')}
                    </Button>
                  </Can>
                )}
              {journeys?.length !== 0 && activeFolder?.name !== t('Easydocs.Deleted') && (
                <Button
                  className={styles.bulkUpdateButton}
                  disabled={folderUpdateMode}
                  onClick={() => {
                    dispatch(setFolderUpdateState());
                    setSelectedJourneys([]);
                    setSelectedFleets([]);
                    setSelectedFolders([]);
                    setBulkAction('');
                  }}
                  id={BUTTON_IDS.journeyPlannerFolderUpdate}
                >
                  {t('JourneyPlanner.BulkAssignment')}
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className={folderUpdateMode ? styles.bulkActionsMargin : ''}>
          {folderUpdateMode && (
            <div className={styles.bulkActionsContainer}>
              <Select
                size="large"
                className={styles.bulkActionsWidth}
                data={bulkActionsList}
                onSelect={handleBulkActionChange}
                placeholder={t('JourneyPlanner.SelectBulkActions')}
              />
              {bulkAction === 'AddToFolders' && (
                <Select
                  mode="multiple"
                  value={selectedFolders}
                  onChange={handleFoldersChange}
                  placeholder={displayMessage}
                  className={styles.bulkDropdownWidth}
                  tagRender={tagRender}
                  maxTagCount={2}
                  maxTagPlaceholder={omittedValues => `+${omittedValues.length} more`}
                  data={bulkFoldersFilter}
                  filterOption={(input, option) => {
                    const label = option?.children;
                    return (
                      typeof label === 'string' && label.toLowerCase().includes(input.toLowerCase())
                    );
                  }}
                />
              )}
              {bulkAction === 'AddToFleets' && (
                <Select
                  mode="multiple"
                  value={selectedFleets}
                  onChange={handleFleetsChange}
                  placeholder={displayMessage}
                  className={styles.bulkDropdownWidth}
                  tagRender={tagRender}
                  maxTagCount={2}
                  maxTagPlaceholder={omittedValues => `+${omittedValues.length} more`}
                  data={bulkFleetsFilter}
                  filterOption={(input, option) => {
                    const label = option?.children;
                    return (
                      typeof label === 'string' && label.toLowerCase().includes(input.toLowerCase())
                    );
                  }}
                />
              )}
              {(bulkAction === 'RemoveFromAllFolders' ||
                bulkAction === 'RemoveFromAllFleets' ||
                bulkAction === 'DeleteAction') && (
                <div className={styles.displayMessage}>{displayMessage}</div>
              )}
              <div className={styles.bulkEditButtons}>
                {folderUpdateMode && (
                  <Button
                    onClick={handleBulkChanges}
                    type="primary"
                    style={{ ...buttonStyle.journeyConfirmBtn }}
                    id={BUTTON_IDS.contentFolderDeleteFolder}
                    disabled={
                      !(
                        (selectedJourneys.length !== 0 && selectedFolders.length !== 0) ||
                        (selectedFolders.length !== 0 && selectedFleets.length !== 0) ||
                        (selectedJourneys.length !== 0 && selectedFleets.length !== 0) ||
                        (['RemoveFromAllFolders', 'RemoveFromAllFleets', 'DeleteAction'].includes(
                          bulkAction
                        ) &&
                          selectedJourneys.length !== 0)
                      )
                    }
                  >
                    {i18next.t('JourneyPlanner.Confirm')}
                  </Button>
                )}
                {journeys?.length !== 0 && (
                  <Button
                    className={styles.bulkUpdateButton}
                    onClick={() => {
                      dispatch(setFolderUpdateState());
                      setSelectedJourneys([]);
                      setSelectedFleets([]);
                      setSelectedFolders([]);
                      setBulkAction('');
                    }}
                    id={BUTTON_IDS.journeyPlannerCancelEdit}
                  >
                    {t('JourneyPlanner.StopEditing')}
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>

        <Can everyEntity={[entities.DOCUMENT]}>
          <JourneysToolbar
            filteredJourneysCount={filteredJourneys?.length}
            searchText={searchText}
            key={searchKey}
            companiesFilter={companiesFilter}
            fleetsFilter={fleetsFilter}
            typesFilter={typesFilter}
            foldersFilter={foldersFilter}
            onSearchChange={handleSearchChange}
            onCompaniesFilterChange={handleCompaniesFilterChange}
            onFleetsFilterChange={handleFleetsFilterChange}
            onTypesFilterChange={handleTypesFilterChange}
            onFoldersFilterChange={handleFoldersFilterChange}
            onRestoreDefault={handleRestoreDefault}
          />

          <div
            className={
              folderUpdateMode ? styles.journeysTableBulkHeight : styles.journeysTableHeight
            }
          >
            <JourneysTableNew
              isLoading={isFetching}
              journeys={filteredJourneys}
              fleets={fleets}
              localization={localization}
              deleteModal={deleteModal}
              filterTab={filterTab}
              restoreJourney={restoreJourney}
              selectedFleets={selectedFleets}
              selectedFolders={selectedFolders}
              onJourneySelection={handleJourneySelection}
              onSelectAllChange={handleSelectAllChange}
            />
          </div>
        </Can>
      </div>
    </div>
  );
};

export default ContentFolder;
