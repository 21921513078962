import React, { useState } from 'react';
import { Modal, Alert, Upload, Progress, Typography, Button } from 'antd';

import styles from './BatchTCMModal.module.scss';
import { useTranslation } from 'react-i18next';

const BatchTCMModal = ({ isBatchModalOpen, onClose }) => {
  const { Dragger } = Upload;
  const { Text, Title } = Typography;
  const { t } = useTranslation();

  const [fileList, setFileList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  return (
    <div>
      <Modal
        centered
        footer={null}
        title={t('Regulatory.BatchTCMModal.Title')}
        onCancel={onClose}
        open={isBatchModalOpen}
      >
        <Alert message={t('Regulatory.BatchTCMModal.Info')} type="info" />
        <div className={styles.uploadWrapper}>
          <Title level={5}>{t('Regulatory.BatchTCMModal.UploadTitle')}</Title>
          <Dragger
            beforeUpload={file => {
              setFileList([file]);
              setSelectedFile(file.name);
              return false;
            }}
            fileList={fileList}
            height={'fit-content'}
            showUploadList={false}
          >
            <div>
              {selectedFile ? selectedFile : t('Regulatory.BatchTCMModal.UploadDescription')}
            </div>
          </Dragger>
          <Button className={styles.uploadButton}>
            {t('Regulatory.BatchTCMModal.UploadButton')}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default BatchTCMModal;
