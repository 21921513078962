import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip, Space, Typography } from 'antd';
import { SyncOutlined, ReloadOutlined } from '@ant-design/icons';

export const CountRefreshButton = ({
  isRefreshing = false,
  addedCount = 0,
  onRefresh = () => {},
  totalCount = 0,
  disabled = false
}) => {
  const { t } = useTranslation();
  const countText = useMemo(
    () =>
      isRefreshing
        ? t('Events.Checking')
        : addedCount
        ? `${t('Events.Added', { count: addedCount })}, ${totalCount} ${t('Tabs.events')}`
        : `${totalCount} ${t('Tabs.events')}`,
    [isRefreshing, addedCount, totalCount]
  );
  return disabled ? (
    `${totalCount} ${t('Tabs.events')}`
  ) : (
    <Space>
      <Tooltip title={countText}>
        <Typography.Text ellipsis style={{ width: 85, textAlign: 'right' }}>
          {countText}
        </Typography.Text>
      </Tooltip>
      <>
        {isRefreshing ? (
          <SyncOutlined spin style={{ color: '#64748B', opacity: 0.5, fontSize: '20px' }} />
        ) : (
          <Tooltip title={t('Home.Refresh')}>
            <Button
              style={{ minWidth: '20px', width: '20px', height: '20px' }}
              type="text"
              icon={<ReloadOutlined style={{ color: '#64748B', fontSize: '20px' }} />}
              onClick={onRefresh}
            ></Button>
          </Tooltip>
        )}
      </>
    </Space>
  );
};
