import React, { useEffect, useMemo, useState } from 'react';
import { Card, Form, Input, Typography, Modal, Alert, Space, Button, Spin, Switch } from 'antd';

import { HeaderFormSection } from '../components/HeaderFormSection';
import { FeatureFlag, useCanFeatureFlag } from 'features/permissions';
import { DevicesAssignmentTable } from '../components/DevicesAssignmentTable';
import { useCurrentCompany } from 'features/company/companySlice';
import { useTranslation } from 'react-i18next';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  useGetDriverIdConfigurationTemplatesQuery,
  useAddDriverIdConfigurationTemplateMutation,
  useUpdateDriverIdConfigurationTemplateMutation,
  useCurrentCompanyServicesMap
} from 'services/nextgen/ngDriverIdConfigurationApi';
import { useDeviceTypesList } from 'features/device_types/deviceTypesSlice';
import style from '../GPIO/GPIOForm.module.scss';
import styles from './DriverIdForm.module.scss';
import { DRIVER_ID_PATHS as PATHS } from './constants';
import { BUTTON_IDS } from 'utils/globalConstants';
import { parseErrorMessage } from 'utils/strings';
import {
  fetchFleets,
  useDevices,
  useIsFetching as useIsFetchingFleets,
  useVehiclesFromFleets
} from 'features/fleets/fleetsSlice';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';
import { templateTypeEnum } from '../components/constants';
import { canHistoryGoBack } from 'utils/methods';
import { inputValidator } from '../GPIO/helpers';

export const DriverIdForm = ({ templateId, action }) => {
  const allDevices = useDevices();
  const currentCompany = useCurrentCompany();
  const [form] = Form.useForm();
  const [companyLevel, setCompanyLevel] = useState(false);
  const [previousCompanyLevel, setPreviousCompanyLevel] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const types = useDeviceTypesList();
  const hermes = types.find(type => type.code === 'HERMES');
  const { vehicles } = useVehiclesFromFleets();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const serviceMap = useCurrentCompanyServicesMap();
  const [modal, contextHolder] = Modal.useModal();
  const [formValid, setFormValid] = useState(true);
  const history = useHistory();
  const [selectedRowKeysForFilteredDevices, setSelectedRowKeysForFilteredDevices] = useState([]);
  const [dirty, setDirty] = useState(false);

  const isFetchingFleets = useIsFetchingFleets();

  const driverLoginalertFlag = useCanFeatureFlag({
    featureFlag: FeatureFlag.driverLoginAlert.flag
  });

  const {
    data: allTemplates,
    isFetching: allTemplatesFetching
  } = useGetDriverIdConfigurationTemplatesQuery(
    { companyId: currentCompany?.id, embed: 'devices' },
    { embed: 'devices' },
    { skip: currentCompany?.id === undefined }
  );

  const [
    addDriverIdConfigurationTemplate,
    { isLoading: isAddingTemplating }
  ] = useAddDriverIdConfigurationTemplateMutation();
  const [
    updateDriverIdConfigurationTemplate,
    { isLoading: isUpdatingTemplating }
  ] = useUpdateDriverIdConfigurationTemplateMutation();

  const templates = useMemo(() => {
    const configurationTemplates = (allTemplates || []).map(i => {
      const template = i.configurationTemplate;
      return { ...template, associations: { devices: i.associatedDevices } };
    });
    return configurationTemplates;
  }, [currentCompany?.id, allTemplates, allTemplatesFetching]);

  const deviceTemplateMap = useMemo(() => {
    const map = {};
    templates.forEach(template => {
      template.associations.devices.forEach(device => {
        map[device.id] = template;
      });
    });
    return map;
  }, [templates]);

  const vehicleMap = vehicles.reduce((map, vehicle) => {
    map[vehicle.id] = vehicle;
    return map;
  }, {});

  const hermesDriverIdDevices = useMemo(
    () =>
      allDevices
        ?.filter(
          device =>
            device?.type?.id === hermes.id &&
            device?.company?.id === currentCompany.id &&
            device?.services?.includes('DRIVERPIN')
        )
        ?.map(device => {
          const template = deviceTemplateMap[device.id];
          const vehicle = device.vehicle ? vehicleMap[device.vehicle.id] : null;
          return { ...device, vehicle: vehicle, template: template };
        }),
    [hermes, currentCompany, allDevices, deviceTemplateMap]
  );

  const [devices, setDevices] = useState(hermesDriverIdDevices);

  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      // Update the selected rows in the state
      setSelectedRowKeysForFilteredDevices(selectedRowKeys);
      setDirty(true);
    }
  };

  const formItemStyle = {
    marginBottom: '3px'
  };

  useEffect(() => {
    if (templateId) {
      const editingTemplate = { ...templates.find(item => item.id === templateId) };
      form.setFieldsValue(editingTemplate);
      let config = editingTemplate?.configurations?.find(item => item.key === 'delayed.logoff');
      if (config) {
        form.setFieldValue('delayed.logoff', config.value);
      }
      config = editingTemplate?.configurations?.find(
        item => item.key === 'driver.loggedoff.threshold'
      );
      if (config) {
        form.setFieldValue('driver.loggedoff.threshold', config.value);
      }
      config = editingTemplate?.configurations?.find(item => item.key === 'driver.pattern.gpio');
      if (config) {
        form.setFieldValue('driver.pattern.gpio', config.value === '8080' ? true : false);
      }
      setCompanyLevel(editingTemplate.default);
      setPreviousCompanyLevel(editingTemplate.default);
      const selectedDeviceIds = editingTemplate.associations?.devices?.map(d => d.id);
      setSelectedRowKeys(selectedDeviceIds);
      setSelectedRowKeysForFilteredDevices(selectedDeviceIds);
    }
  }, [templates, templateId]);

  useEffect(() => {
    dispatch(
      setPageTitle(
        t(
          action === 'add'
            ? 'CompanyConfig.DeviceConfigurations.AddTemplate'
            : 'CompanyConfig.DeviceConfigurations.UpdateTemplate',
          { templateName: t('CompanyConfig.DeviceConfigurations.DriverIdConfig.Title') }
        )
      )
    );
    dispatch(setBackButton(true));
  }, [dispatch, t]);

  useEffect(() => {
    const deviceKeys = new Set(devices.map(d => d.id));
    // Keep only the state of the selected devices that are out of the filtered list.
    let newSelectedKeys = selectedRowKeys.filter(k => !deviceKeys.has(k));
    newSelectedKeys = [...newSelectedKeys, ...(selectedRowKeysForFilteredDevices ?? [])];
    setSelectedRowKeys(newSelectedKeys);
  }, [selectedRowKeysForFilteredDevices]);

  const initializing = allTemplatesFetching || isFetchingFleets;

  if (initializing) {
    return <Spin size="large"></Spin>;
  }

  const handleSaveForm = async () => {
    let confirmed = true;
    if (previousCompanyLevel === true && companyLevel !== previousCompanyLevel) {
      confirmed = await modal.confirm({
        content: t('CompanyConfig.DeviceConfigurations.RemovingCompanyDefaultMessage', {
          templateName: templateTypeEnum.DriverId
        })
      });
    }

    // Count of devices which currently assigned to other template
    const count = selectedRowKeys
      .map(id => deviceTemplateMap[id])
      .filter(t => t && t.id !== templateId).length;
    if (confirmed && count > 0) {
      confirmed = await modal.confirm({
        content: t('CompanyConfig.DeviceConfigurations.DeviceAlreadyAssigned', {
          templateName: templateTypeEnum.DriverId
        })
      });
    }

    const templateServices = new Set();

    if (!companyLevel) {
      const serviceArray = [...templateServices];
      const deviceNotFullfiled = [...new Set(selectedRowKeys)]
        .map(id => devices.find(d => d.id === id))
        .filter(d => !serviceArray.every(e => d?.services?.includes(e))).length;
      if (confirmed && deviceNotFullfiled > 0) {
        confirmed = await modal.confirm({
          content: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.DeviceNotFulfilled', {
            count: deviceNotFullfiled
          })
        });
      }
    }

    if (confirmed) {
      submitForm();
    }
  };

  const submitForm = () => {
    const serviceMapInfo = serviceMap['DRIVERPIN'];
    let configs = [];

    form.validateFields().then(values => {
      let config = form.getFieldValue('delayed.logoff');
      if (config) {
        configs.push({
          service: serviceMapInfo,
          key: 'delayed.logoff',
          value: config
        });
      }
      config = form.getFieldValue('driver.loggedoff.threshold');
      if (config) {
        configs.push({
          service: serviceMapInfo,
          key: 'driver.loggedoff.threshold',
          value: config
        });
      }
      config = form.getFieldValue('driver.pattern.gpio');
      if (config) {
        configs.push({
          service: serviceMapInfo,
          key: 'driver.pattern.gpio',
          value: '8080'
        });
      }

      const driverLoggedOffExists = configs.some(config => config.key === 'driver.pattern.gpio');
      if (driverLoginalertFlag && !driverLoggedOffExists) {
        configs.push({
          service: serviceMapInfo,
          key: 'driver.pattern.gpio',
          value: '0'
        });
      }

      const configurationTemplateRequest = {
        configurationTemplate: {
          name: values.name,
          description: values.description,
          type: 'DRIVERPIN',
          company: {
            id: currentCompany.id
          },
          status: 'ENABLED',
          default: values.default,
          configurations: configs
        }
      };

      configurationTemplateRequest.deviceIds = [...new Set(selectedRowKeys)];

      if (action === 'add') {
        addDriverIdConfigurationTemplate({ body: configurationTemplateRequest })
          .then(handleResult)
          .catch(error => {
            openToast({
              type: ToastType.Error,
              message: error
            });
          });
      } else {
        updateDriverIdConfigurationTemplate({
          body: configurationTemplateRequest,
          id: templateId
        })
          .then(handleResult)
          .catch(error => {
            openToast({
              type: ToastType.Error,
              message: error
            });
          });
      }
    });
  };

  const handleResult = result => {
    const response = result.data;
    if (result.error) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${t(
            'CompanyConfig.DeviceConfigurations.GPIOTemplates.Error'
          )} ${parseErrorMessage(result?.error?.data)}`
        })
      );
      return;
    }
    if (response?.errors?.length > 0) {
      const mainMessage = t('CompanyConfig.DeviceConfigurations.GPIOTemplates.ResultWarning', {
        count: response.associatedDevices?.length
      });
      const errorMessages = response.errors.map(err => (
        <React.Fragment>
          {t('CompanyConfig.DeviceConfigurations.GPIOTemplates.ResultWarningDetail', {
            count: err.devices?.length,
            error: t(
              'CompanyConfig.DeviceConfigurations.GPIOTemplates.error.' + err.message,
              err.message
            )
          })}
          <br />
        </React.Fragment>
      ));
      const finalMessage = (
        <React.Fragment>
          {mainMessage}
          <br />
          {errorMessages}
        </React.Fragment>
      );
      dispatch(
        openToast({
          type: ToastType.Warning,
          message: finalMessage
        })
      );

      dispatch(fetchFleets());
      canHistoryGoBack(history, PATHS.DEFAULT);
      return;
    }

    dispatch(fetchFleets());
    dispatch(
      openToast({
        type: ToastType.Success,
        message: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Success')
      })
    );
    canHistoryGoBack(history, PATHS.DEFAULT);
  };

  return (
    <>
      {contextHolder}
      <EditRouteGuard when={dirty} navigate={history.push} />
      <Form
        form={form}
        layout="vertical"
        onValuesChange={(_, allValues) => {
          setDirty(true);
          setFormValid(allValues.name && allValues.name.trim() !== '');
        }}
      >
        <Card className={styles.headerSectionCard}>
          <Typography.Title level={4}>
            {t('CompanyConfig.DeviceConfigurations.DriverIdTemplates.Basic Information')}
          </Typography.Title>
          <HeaderFormSection
            formItemStyle={formItemStyle}
            setCompanyLevel={setCompanyLevel}
            templateName={'DRIVERID'}
          />
        </Card>
        <Card className={styles.headerSectionCard}>
          <Typography.Title level={4}>
            {t('CompanyConfig.DeviceConfigurations.DriverIdTemplates.Configurations')}
          </Typography.Title>
          <div>
            <Form.Item
              className={styles.setThresholdInput}
              tooltip={t(
                'CompanyConfig.DeviceConfigurations.DriverIdTemplates.DelayAutoLogOffTooltip'
              )}
              label={t('CompanyConfig.DeviceConfigurations.DriverIdTemplates.DelayAutoLogOff')}
              name="delayed.logoff"
              initialValue={0}
              rules={inputValidator({ min: 0, max: 65535 }).number}
            >
              <Input suffix={<div>{t('Common.seconds')}</div>} />
            </Form.Item>
          </div>
          {driverLoginalertFlag ? (
            <>
              <div>
                <Form.Item
                  className={styles.setThresholdInput}
                  tooltip={t(
                    'CompanyConfig.DeviceConfigurations.DriverIdTemplates.DriverLoggedOnTooltip'
                  )}
                  label={t('CompanyConfig.DeviceConfigurations.DriverIdTemplates.DriverLoggedOn')}
                  name="driver.loggedoff.threshold"
                  initialValue={300}
                  rules={inputValidator({ min: 10, max: 65535 }).number}
                >
                  <Input suffix={<div>{t('Common.seconds')}</div>} />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  className={styles.setThresholdInput}
                  tooltip={t(
                    'CompanyConfig.DeviceConfigurations.DriverIdTemplates.AudiableAlertTooltip'
                  )}
                  label={t('CompanyConfig.DeviceConfigurations.DriverIdTemplates.AudiableAlert')}
                  name="driver.pattern.gpio"
                  valuePropName="checked"
                >
                  <Switch defaultChecked={false} />
                </Form.Item>
              </div>
            </>
          ) : (
            ''
          )}
        </Card>
        {!companyLevel && (
          <DevicesAssignmentTable
            filteredDevices={hermesDriverIdDevices}
            devices={devices}
            setDevices={setDevices}
            templates={templates}
            currentCompany={currentCompany}
            handleViewTemplate={() => {}}
            rowSelection={rowSelection}
            templateType={'DRIVERID'}
            useTitle={true}
          />
        )}
      </Form>
      {companyLevel && (
        <Card className={style.deviceSectionCardCompanyLevel}>
          <Alert
            message={t('CompanyConfig.DeviceConfigurations.CompanyLevelNote', {
              templateName: templateTypeEnum.DriverId
            })}
            type="info"
            showIcon
          />
        </Card>
      )}
      <Card className={style.footerSectionCard}>
        <Space>
          <Button
            type="primary"
            size="large"
            disabled={!dirty || !formValid || isAddingTemplating || isUpdatingTemplating}
            onClick={handleSaveForm}
            id={BUTTON_IDS.driverIdFormSave}
          >
            {t('Common.SaveButton')}
          </Button>
          <Button size="large" id={BUTTON_IDS.driverIdFormCancel} onClick={history.goBack}>
            {t('Common.CancelButton')}
          </Button>
        </Space>
      </Card>
      <EditRouteGuard when={dirty} navigate={history.push}></EditRouteGuard>
    </>
  );
};
