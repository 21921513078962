export const services = {
  ACM: 'ACM',
  ADVCOMPMGMT: 'ADVCOMPMGMT',
  ASSETSUSAGE: 'ASSETSUSAGE',
  AUTOVUE: 'AUTOVUE',
  BETA: 'BETA',
  BPM: 'BPM',
  CAMERA: 'CAMERA',
  COMMON: 'COMMON',
  COMPMGMT: 'COMPMGMT',
  DMGT: 'DMGT',
  DRIVERPIN: 'DRIVERPIN',
  DURESS: 'DURESS',
  DVIR: 'DVIR',
  EASYDOCS: 'EASYDOCS',
  EWD: 'EWD',
  ELD: 'ELD',
  ELDUS: 'ELDUS',
  ELDCAN: 'ELDCAN',
  EDL: 'EDL',
  EVREADY: 'EVREADY',
  FBTMANAGER: 'FBT',
  FORM: 'FORM',
  FUELTAX: 'FUELTAX',
  GEOFENCE: 'GEOFENCE',
  GEOFENCETRIP: 'GEOFENCETRIP',
  GEOFENCE_SPEED_OVERRIDE: 'GEOFENCE_SPEED_OVERRIDE',
  GPIO: 'GPIO',
  SPEEDASSIST: 'SPEEDASSIST',
  DRIVERPIN: 'DRIVERPIN',
  HIDENONBUSINESS: 'HIDENONBUSINESS',
  IAP: 'IAP',
  IQ: 'IQ',
  INTEGRATION: 'INTEGRATION',
  INSIGHTSNPI: 'INSIGHTSNPI',
  INSIGHTSBASIC: 'INSIGHTSBASIC',
  INSIGHTSADVPROD: 'INSIGHTSADVPROD',
  INSIGHTSADVSAFETY: 'INSIGHTSADVSAFETY',
  INSIGHTSADVCOMP: 'INSIGHTSADVCOMP',
  INSIGHTSSEARCH: 'INSIGHTSSEARCH',
  INSIGHTSVIZ: 'INSIGHTSVIZ',
  INSIGHTSBUILDER: 'INSIGHTSBUILDER',
  INSIGHTSAI: 'INSIGHTSAI',
  INSIGHTSSUSTAINABILITYSNAPSHOT: 'INSIGHTSSUSTAINABILITYSNAPSHOT',
  INSIGHTSSUSTAINABILITYGOALS: 'INSIGHTSSUSTAINABILITYGOALS',
  INSIGHTSSUSTAINABILITYFLEETS: 'INSIGHTSSUSTAINABILITYFLEETS',
  INSIGHTSREPORTS: 'INSIGHTSREPORTS',
  DIRECTORINSIGHTS: 'DIRECTORINSIGHTS',
  IRIDIUM: 'IRIDIUM',
  JOURNEYPLANNER: 'JOURNEYPLANNER',
  LOGBOOK: 'LOGBOOK',
  MASS: 'MASS',
  MESSAGING: 'MESSAGING',
  NPI: 'NPI',
  OBM: 'OBM',
  ONSITE_TRAFFIC: 'ONSITE_TRAFFIC',
  ONEWIRE: 'ONEWIRE',
  NETRADYNE: 'NETRADYNE',
  PRETRIP: 'PRETRIP',
  PTO: 'PTO',
  QUICKJOBS: 'QUICKJOBS',
  ROUTECOMPLIANCE: 'ROUTECOMPLIANCE',
  RUC: 'RUC',
  SAFETYANALYTICS: 'SAFETYANALYTICS',
  SCORECARD: 'SCORECARD',
  SCORECARDADV: 'SCORECARDADV',
  SDP: 'SDP',
  SENTINEL3NAVMAN: 'SENTINEL3NAVMAN',
  SENTINEL4: 'SENTINEL4',
  SENTINEL4KIOSK: 'SENTINEL4KIOSK',
  SENTINEL4LOGBOOK: 'SENTINEL4LOGBOOK',
  SMARTJOBS: 'SMARTJOBS',
  SMARTJOBS_CUSTOMER: 'SMARTJOBS_CUSTOMER',
  SMARTNAV3D: 'SMARTNAV3D',
  SPEED: 'SPEED',
  SWD: 'SWD',
  TACHO: 'TACHO',
  TASKMANAGER: 'TASKMANAGER',
  TEMPERATURE: 'TEMPERATURE',
  TIMESHEETS: 'TIMESHEETS',
  TMA: 'TMA',
  TOLL: 'TOLL',
  TPMS: 'TPMS',
  TRACKING: 'TRACKING',
  TRACKINGHD: 'TRACKINGHD',
  TRACKINGLIVE: 'TRACKINGLIVE',
  VEHMNT: 'VEHMNT',
  VOIP: 'VOIP',
  VPM: 'VPM',
  VPMHD: 'VPMHD',
  GTT: 'GTT',
  SAFERTOGETHER: 'SAFERTOGETHER',
  UKECONOMICZONES: 'UKECONOMICZONES',
  SMARTJOBS_HEADLESS: 'SMARTJOBS_HEADLESS',
  SPEEDSIREN: 'SPEEDSIREN'
};

export const servicesUINames = {
  ACM: 'Asset Construction Management (ACM)',
  ADVCOMPMGMT: 'Advanced Compliance Management',
  AUTOVUE: 'AutoVue',
  BETA: 'Beta trial service',
  BPM: 'Basic Performance Monitor',
  VOIP: 'CallMate',
  CAMERA: 'Camera',
  COMMON: 'Transtech common base',
  COMPMGMT: 'Compliance Management Report',
  DMGT: 'Driver Management',
  DURESS: 'Duress notification and alerts',
  DVIR: 'DVIR',
  EASYDOCS: 'Easydocs document management',
  EWD: 'Electronic Work Diary (EWD)',
  ELD: 'Electronic Logging Devices (ELD)',
  EDL: 'Electronic Driver Logbook (EDL)',
  RUC: 'Electronic Road User Charges (eRUC)',
  FORM: 'Forms',
  FUELTAX: 'Fuel Tax Calculator',
  GEOFENCE: 'Geofence events, alerts, etc',
  GEOFENCETRIP: 'Geofence Trip',
  TRACKINGHD: 'High definition tracking service',
  IAP: 'Intelligent Access Program (IAP)',
  IQ: 'IQ Camera',
  IRIDIUM: 'Iridium Satellite Service',
  JOURNEYPLANNER: 'Journey Planner',
  LOGBOOK: 'Log Book Checker',
  MASS: 'Mass Management',
  MESSAGING: 'Messaging system',
  OBM: 'OnBoard Mass',
  ONSITE_TRAFFIC: 'Onsite Traffic',
  ONEWIRE: 'One Wire',
  PTO: 'Power Take Off',
  PRETRIP: 'Inspections',
  QUICKJOBS: 'QuickJobs Trips, Job, Delay',
  TOLL: 'Road Attributes - Toll',
  ROUTECOMPLIANCE: 'Route Compliance',
  SCORECARD: 'Scorecard',
  SDP: 'Safe Driving Plan',
  SENTINEL3NAVMAN: 'Sentinel version 3 for Navman',
  SENTINEL4: 'Sentinel version 4',
  SENTINEL4KIOSK: 'Sentinel version 4 kiosk',
  SENTINEL4LOGBOOK: 'Sentinel version 4 log book checker',
  SMARTJOBS: 'SmartJobs configurable job execution',
  SMARTJOBS_CUSTOMER: `SmartJobs Customer's Customer`,
  SPEED: 'Speed events, alerts, etc',
  TACHO: 'Tachograph Service (UK)',
  TASKMANAGER: 'Task Manager',
  TEMPERATURE: 'Temperature',
  TIMESHEETS: 'Timesheet',
  TPMS: 'Tyre Pressure Monitoring System',
  TRACKING: 'Basic tracking service',
  VEHMNT: 'Vehicle Maintenance',
  VPM: 'Vehicle Performance Monitor',
  //NOT SHOWN IN NG
  SENTINEL3: 'Sentinel version 3',
  SENTINEL3KIOSK: 'Sentinel version 3 kiosk',
  INSIGHTSBASIC: 'Insights Basic',
  INSIGHTSSEARCH: 'Insights Search',
  INSIGHTSADVPROD: 'Insights Advanced Productivity',
  INSIGHTSADVSAFETY: 'Insights Advanced Safety',
  INSIGHTSADVCOMP: 'Insights Advanced Compliance',
  INSIGHTSVIZ: 'Insights Viz',
  INSIGHTSAI: 'Insights AI',
  INSIGHTSSUSTAINABILITYSNAPSHOT: 'Insights Sustainability Snaptshot',
  INSIGHTSSUSTAINABILITYGOALS: 'Insights Sustainability Goals',
  INSIGHTSSUSTAINABILITYFLEETS: 'Insights Sustainability Fleets',
  INSIGHTSREPORTS: 'Insights Reports',
  INSIGHTSNPI: 'Insights NPI',
  DIRECTORINSIGHTS: 'Insights in Director',
  SAFERTOGETHER: 'Safer Together',
  UKECONOMICZONES: 'UK Economic Zones',
  SMARTJOBS_HEADLESS: 'Smartjobs Headless'
};

export default Object.freeze(services);
//this has been deprecated, managed geofences services will be in the geofenceProviders of a company
//see more in the ticket https://transtech.atlassian.net/browse/TN360-2578
export const ManagedGeofenceTypes = [services.SAFERTOGETHER, services.UKECONOMICZONES];
