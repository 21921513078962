import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalization } from 'features/localization/localizationSlice';

import { Table } from 'antd';
import { Select } from 'components/ant';
import { useCurrentCompany } from 'features/company/companySlice';
import { useOneWireMultiProbeFields } from 'features/company_config';
import { useDeviceMeters } from 'features/devices/devicesMetersSlice';
import { formatMeterValue } from 'features/meters';
import { format } from 'utils/dates';
import { filter } from 'lodash';

const OneWireTab = ({ device, onMeterLabelChange }) => {
  //const localization, translation etc
  const { t } = useTranslation();
  const localization = useLocalization();
  const company = useCurrentCompany();
  const tableRef = useRef();
  const [scrollSettings, setScrollSettings] = useState({});

  //Get table data
  const meters = useDeviceMeters(device?.id) || [];
  const [metersForTable, SetMetersForTable] = useState([]);
  const userDefinedFieldsValues = useOneWireMultiProbeFields(company.id);
  const [labelsForSelection, SetLabelsForSelection] = useState([]);
  const [selectedLabels, SetSelectedLabels] = useState([]);

  useEffect(() => {
    const tempMeters = meters?.filter(
      m => m.type === 'temperature' && m.source.startsWith('One-Wire')
    );
    SetMetersForTable([...tempMeters]);

    const selMeters = [];
    tempMeters?.map(m => selMeters.push(m.label));
    SetSelectedLabels([...selMeters]);
  }, [meters]);

  useEffect(() => {
    const labelArray = [];
    if (userDefinedFieldsValues) {
      Object.values(userDefinedFieldsValues)
        .filter(label => !selectedLabels.includes(label))
        .map((e, index) => {
          labelArray.push({ value: e, label: e });
        });
    }
    SetLabelsForSelection(labelArray?.sort((a, b) => (a.label > b.label ? 1 : -1)));
  }, [userDefinedFieldsValues, selectedLabels]);

  const onChangeType = (record, label, value) => {
    if (onMeterLabelChange) {
      onMeterLabelChange(record?.source, label);
    }
    let index = selectedLabels.indexOf(value);
    if (index > -1) {
      selectedLabels[index] = label;
    } else {
      selectedLabels.push(label);
    }
    SetSelectedLabels(selectedLabels);

    const labelArray = [];
    Object.values(userDefinedFieldsValues)
      .filter(m => !selectedLabels.includes(m))
      .map((e, index) => {
        labelArray.push({ value: e, label: e });
      });
    SetLabelsForSelection(labelArray?.sort((a, b) => (a.label > b.label ? 1 : -1)));

    index = metersForTable.map(m => m.source).indexOf(record.source);
    if (index > -1) {
      const tableVals = [...metersForTable];
      const object = tableVals[index];
      const modifiableObject = { ...object, label: label };
      tableVals[index] = modifiableObject;
      SetMetersForTable(tableVals);
    }
  };
  const deviceMetersColumns = [
    {
      title: t('Devices.Meters.MeterType'),
      dataIndex: 'type',
      key: 'type',
      width: 150,
      render: (value, rowData, rowIndex) => {
        return t(`MeterTypes.${rowData?.type}`, rowData?.type);
      }
    },
    {
      title: t('Devices.Meters.Source'),
      dataIndex: 'source',
      key: 'source',
      width: 500
    },
    {
      title: t('Devices.Meters.Value'),
      dataIndex: 'value',
      key: 'value',
      width: 150,
      render: (value, rowData, rowIndex) => {
        return formatMeterValue(localization, rowData);
      }
    },
    {
      title: t('Devices.Meters.UpdatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 500,
      render: (value, rowData, rowIndex) => {
        return format(rowData?.updatedAt, localization.formats.time.formats.dby_imsp);
      }
    },
    {
      title: t('Devices.Form.TemperatureLabel'),
      dataIndex: 'label',
      key: 'label',
      width: 200,
      render: (value, rowData, index) => {
        return (
          <Select
            key={`labelSelection`}
            style={{ width: '160px' }}
            options={labelsForSelection}
            onSelect={e => onChangeType(rowData, e, value)}
            defaultValue={rowData?.label}
          />
        );
      }
    }
  ];

  return (
    <>
      <Table
        //className={styles.fbtByVehicleGrid}
        showSorterTooltip={false}
        dataSource={[...metersForTable]}
        columns={deviceMetersColumns}
        pagination={false}
        ref={tableRef}
        scroll={scrollSettings}
        rowKey={'source'}
      />
    </>
  );
};

export { OneWireTab };
