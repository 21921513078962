import { Button, DatePicker, Divider, Select, Tooltip } from 'antd';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import TMADeclarationTable from './TMADeclarationTable';
import { NewDeclarationModal } from './NewDeclarationModal';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';

import useDebounce from 'utils/hooks/useDebounce';

import { filterTMAData } from './helpers';

//styles
import styles from './TMADeclaration.module.scss';
import BatchTCMModal from './BatchTCMModal';
import {
  useGetSDCDataQuery,
  useGetSDMDataQuery,
  useGetVehicleRegistrationQuery
} from 'services/nextgen';
import { useCurrentCompany } from 'features/company/companySlice';
import dayjs from 'dayjs';

export const TMADeclaration = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompany = useCurrentCompany();

  const [dateRange, setDateRange] = useState([
    dayjs()
      .subtract(30, 'days')
      .startOf('day')
      .toISOString(),
    dayjs()
      .endOf('day')
      .toISOString()
  ]);
  const [filteredTMAData, setFilteredTMAData] = useState(null);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isBatchModalOpen, setIsBatchModalOpen] = useState(false);
  const [registrationFilter, setRegistrationFilter] = useState(null);
  const [registrationsFilterOptions, setRegistrationFilterOptions] = useState([]);
  const [sdDataRecordTypeFilter, setSdDataRecordTypeFilter] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [tmaData, setTmaData] = useState([]);

  const { data: sdmData, isLoading: isSDMDataLoading } = useGetSDMDataQuery({
    companyId: currentCompany?.id,
    fromDate: dateRange[0],
    toDate: dateRange[1]
  });
  const { data: sdcData, isLoading: isSDCDataLoading } = useGetSDCDataQuery({
    companyId: currentCompany?.id,
    fromDate: dateRange[0],
    toDate: dateRange[1]
  });
  const { data: registrations } = useGetVehicleRegistrationQuery({ companyId: currentCompany?.id });

  const debouncedSearchText = useDebounce(searchValue, 300);

  const sdRecordOptions = [
    {
      value: '',
      label: t('Common.All')
    },
    {
      value: 'mass',
      label: t('Regulatory.TMADeclarationTable.Mass')
    },
    {
      value: 'comment',
      label: t('Regulatory.TMADeclarationTable.Comment')
    }
  ];

  useEffect(() => {
    setTmaData([sdmData, sdcData].flat());
  }, [sdcData, sdmData]);

  useEffect(() => {
    setFilteredTMAData(
      filterTMAData({
        tmaData,
        debouncedSearchText,
        sdDataRecordTypeFilter,
        registrationFilter,
        registrations
      })
    );
  }, [tmaData, debouncedSearchText, sdDataRecordTypeFilter, registrationFilter, registrations]);

  useEffect(() => {
    registrations &&
      setRegistrationFilterOptions([
        {
          value: '',
          label: 'All'
        },
        ...registrations.map(reg => ({
          value: reg.vehicleId,
          label: reg.registration
        }))
      ]);
  }, [registrations]);

  const onSearch = value => {
    setSearchValue(value);
  };

  const handleDateChange = dates => {
    if (dates && dates.length === 2) {
      setDateRange([
        dayjs(dates[0])
          .startOf('day')
          .toISOString(),
        dayjs(dates[1])
          .endOf('day')
          .toISOString()
      ]);
    } else {
      setDateRange([]);
    }
  };

  const handleCalendarChange = dates => {
    if (dates && dates[0]) {
      setStartDate(dates[0]);
    } else {
      setStartDate(null);
    }
  };

  const disabledDate = (current, { from, type }) => {
    const getYearMonth = date => date.year() * 12 + date.month();
    if (from) {
      const minDate = from.add(-29, 'days');
      const maxDate = from.add(29, 'days');
      switch (type) {
        case 'year':
          return current.year() < minDate.year() || current.year() > maxDate.year();
        case 'month':
          return (
            getYearMonth(current) < getYearMonth(minDate) ||
            getYearMonth(current) > getYearMonth(maxDate)
          );
        default:
          return Math.abs(current.diff(from, 'days')) >= 30;
      }
    }
    return false;
  };

  const handleOpenFormModal = () => {
    setIsFormModalOpen(true);
  };

  const handleCloseFormModal = () => {
    setIsFormModalOpen(false);
  };

  const handleOpenBatchModal = () => {
    setIsBatchModalOpen(true);
  };

  const handleCloseBatchModal = () => {
    setIsBatchModalOpen(false);
  };

  useEffect(() => {
    dispatch(setPageTitle(t('Regulatory.TMADeclaration')));
    dispatch(setBackButton(true));
  }, [t, dispatch]);

  return (
    <>
      <Divider className={styles.divider} />
      <div className={styles.headerActions}>
        <Tooltip placement="bottom" title={t('Regulatory.NewIndividualDeclarationTooltip')}>
          <Button type="primary" size="large" onClick={handleOpenFormModal}>
            {t('Regulatory.NewIndividualDeclaration')}
          </Button>
        </Tooltip>
        <Tooltip placement="bottom" title={t('Regulatory.BatchTCMTooltip')}>
          <Button type="secondary" size="large" onClick={handleOpenBatchModal}>
            {t('Regulatory.BatchTCM')}
          </Button>
        </Tooltip>
        <NewDeclarationModal isFormModalOpen={isFormModalOpen} onClose={handleCloseFormModal} />
        <BatchTCMModal isBatchModalOpen={isBatchModalOpen} onClose={handleCloseBatchModal} />
      </div>
      <div className={styles.filterWrapper}>
        <Tooltip title={t('Regulatory.SearchTooltip')}>
          <AntSearchbar className={styles.filterElement} onFilter={onSearch} size={'default'} />
        </Tooltip>
        <Select
          className={styles.statusesSelect}
          onSelect={value => setRegistrationFilter(value)}
          options={registrationsFilterOptions}
          placeholder={t('Regulatory.Registration')}
        />
        <Select
          className={styles.statusesSelect}
          options={sdRecordOptions}
          onSelect={value => setSdDataRecordTypeFilter(value)}
          placeholder={t('Regulatory.SDRecordType')}
        />
        <DatePicker.RangePicker
          defaultValue={[dayjs().subtract(30, 'days'), dayjs()]}
          // disabledDate={disabledDate}
          onCalendarChange={handleCalendarChange}
          onChange={handleDateChange}
        />
      </div>
      <div className={styles.tableWrapper}>
        <TMADeclarationTable
          isDataLoading={isSDMDataLoading || isSDCDataLoading}
          registrations={registrations}
          tmaData={filteredTMAData}
        />
      </div>
    </>
  );
};
