import React, { useEffect } from 'react';
import ViewHeaderWrapper from 'components/view-header-wrapper/ViewHeaderWrapper';
import { useDispatch } from 'react-redux';
import { useUser } from 'features/user/userSlice';
import { services } from 'features/permissions';
import { DRIVER_ID_PATHS } from './constants';
import { FeatureFlag, useCanFeatureFlag } from 'features/permissions';
import {
  useGetDriverIdConfigurationTemplateByIdQuery,
  useRestoreDriverIdConfigurationTemplateByIdMutation,
  useDeleteDriverIdConfigurationTemplateByIdMutation,
  executeRestoreDriverIdConfigurationTemplate,
  executeDeleteDriverIdConfigurationTemplate
} from 'services/nextgen/ngDriverIdConfigurationApi';
import { setBackButton, setPageTitle, addBackButtonLink } from 'features/page/pageSlice';
import { AUDIT_ENTITY } from 'components/auditsTable/constants';
import { DeletedEntityAlert, DeletableEntityType } from 'features/common/deletedEntityAlert';
import InfoRow from 'components/form/info-row/InfoRow';
import { useTranslation } from 'react-i18next';
import { useLocalization } from 'features/localization/localizationSlice';
import { LoadingCentered } from 'components/loading/Loading';
import { format } from 'utils/dates';
import {
  useCompanies,
  useRedirectToMainFeaturePageOnCompanyChange
} from 'features/company/companySlice';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import styles from '../GPIO/GPIO.module.scss';

export const DriverIdView = () => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const currentUser = useUser();
  const companies = useCompanies();
  const isSiteAdmin = currentUser.siteAdmin;
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const indexBeginingId = window.location.pathname.lastIndexOf('/');
  const id = window.location.pathname.substr(
    indexBeginingId + 1,
    window.location.pathname.length - 1
  );

  const [
    restoreDriverIdConfigurationTemplateById
  ] = useRestoreDriverIdConfigurationTemplateByIdMutation();
  const [
    deleteDriverIdConfigurationTemplateById
  ] = useDeleteDriverIdConfigurationTemplateByIdMutation();
  const { data: driverIdTemplate, isFetching } = useGetDriverIdConfigurationTemplateByIdQuery(
    { templateId: id, embed: 'devices' },
    { skip: id === undefined }
  );
  useRedirectToMainFeaturePageOnCompanyChange(DRIVER_ID_PATHS.DEFAULT);

  const driverLoginalertFlag = useCanFeatureFlag({
    featureFlag: FeatureFlag.driverLoginAlert.flag
  });

  useEffect(() => {
    dispatch(
      setPageTitle(
        driverIdTemplate?.configurationTemplate?.name &&
          `${driverIdTemplate?.configurationTemplate?.name}`
      )
    );

    dispatch(setBackButton(true));
    addBackButtonLink({
      url: pathname,
      backLink: DRIVER_ID_PATHS.DEFAULT
    });
  }, [dispatch, driverIdTemplate]);

  const renderDevices = () => {
    if (driverIdTemplate?.associatedDevices?.length !== 0) {
      return driverIdTemplate?.associatedDevices.map(device => {
        return (
          <div key={`device-${device.id}`}>
            <Link to={`/settings/devices/id/${device.id}`}>{device.imei}</Link>
          </div>
        );
      });
    } else {
      return '-';
    }
  };

  const handleButtonAction = action => () => {
    switch (action) {
      case 'delete':
        confirmationModal(
          `${t('Common.DeleteButton')} ${driverIdTemplate?.configurationTemplate?.name}`,
          `${
            driverIdTemplate?.configurationTemplate?.default
              ? t('CompanyConfig.DeviceConfigurations.DeleteCompanyTemplateWarning', {
                  templateName: t('CompanyConfig.DeviceConfigurations.DriverIdConfig.Title')
                })
              : t('Common.SureDelete') + ' ' + driverIdTemplate?.configurationTemplate?.name + '?'
          }`,
          t('Common.DeleteButton'),
          t('Common.CancelButton'),
          () => {
            executeDeleteDriverIdConfigurationTemplate(
              { id: id, name: driverIdTemplate?.configurationTemplate?.name },
              deleteDriverIdConfigurationTemplateById,
              dispatch
            );
          },
          'delete'
        );
        break;
      case 'restore':
        executeRestoreDriverIdConfigurationTemplate(
          { id: id, name: driverIdTemplate?.configurationTemplate?.name },
          restoreDriverIdConfigurationTemplateById,
          dispatch
        );
        break;
      default:
    }
  };

  if (isFetching) {
    return <LoadingCentered />;
  }

  return (
    <React.Fragment>
      <ViewHeaderWrapper
        data={{
          entityName: AUDIT_ENTITY.CONFIGURATION_TEMPLATE,
          ...driverIdTemplate?.configurationTemplate
        }}
        editPath={`/settings/company_config/driverId/edit/id/${id}`}
        auditPath={`/settings/company_config/driverId/audit/id/${id}`}
        handleButtonAction={handleButtonAction}
        skipDeleteConfirmation={true}
        disableDelete={!isSiteAdmin}
        disableRestore={!isSiteAdmin}
        canService={[services.DRIVERPIN]}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '32px',
          color: '#181c21'
        }}
        className={styles.gpioView}
      >
        <DeletedEntityAlert
          entity={DeletableEntityType.ConfigurationTemplate}
          entityStatus={driverIdTemplate?.status}
        />
        <InfoRow
          label={t('CompanyConfig.DeviceConfigurations.View.Company')}
          value={
            companies.find(comp => comp.id === driverIdTemplate?.configurationTemplate?.company?.id)
              ?.name
          }
          styles={styles}
        />
        <InfoRow
          label={t('CompanyConfig.DeviceConfigurations.View.Template')}
          value={driverIdTemplate?.configurationTemplate?.name}
          sxValue={{ width: '75%' }}
          styles={styles}
        />
        <InfoRow
          label={t('CompanyConfig.DeviceConfigurations.View.Description')}
          value={driverIdTemplate?.configurationTemplate?.description}
          sxValue={{ width: '75%', whiteSpace: 'pre-wrap' }}
          styles={styles}
        />
        <InfoRow
          label={t('CompanyConfig.DeviceConfigurations.View.IsDefaultTemplate')}
          sxValue={{ width: '75%' }}
          value={
            driverIdTemplate?.configurationTemplate?.default ? t('Common.Yes') : t('Common.No')
          }
          styles={styles}
        />
        <InfoRow
          label={t('CompanyConfig.DeviceConfigurations.DriverIdTemplates.DelayAutoLogOff')}
          sxValue={{ width: '75%' }}
          value={
            (driverIdTemplate?.configurationTemplate?.configurations?.find(
              item => item.key === 'delayed.logoff'
            )?.value || 0) +
            ' ' +
            t('Common.seconds')
          }
          styles={styles}
        />
        {driverLoginalertFlag ? (
          <>
            <InfoRow
              label={t('CompanyConfig.DeviceConfigurations.DriverIdTemplates.DriverLoggedOn')}
              sxValue={{ width: '75%' }}
              value={
                (driverIdTemplate?.configurationTemplate?.configurations?.find(
                  item => item.key === 'driver.loggedoff.threshold'
                )?.value || 300) +
                ' ' +
                t('Common.seconds')
              }
              styles={styles}
            />
            <InfoRow
              label={t('CompanyConfig.DeviceConfigurations.DriverIdTemplates.AudiableAlert')}
              sxValue={{ width: '75%' }}
              value={
                driverIdTemplate?.configurationTemplate?.configurations?.find(
                  item => item.key === 'driver.pattern.gpio'
                )?.value === '8080'
                  ? t('Common.Yes')
                  : t('Common.No')
              }
              styles={styles}
            />
          </>
        ) : (
          ''
        )}
        <InfoRow
          label={t('Devices.Devices')}
          value={renderDevices()}
          styles={styles}
          sxValue={{ width: '75%' }}
        />
        <InfoRow
          sxValue={{ width: '75%' }}
          label={t('CompanyConfig.DeviceConfigurations.View.CreatedAt')}
          value={
            driverIdTemplate?.configurationTemplate?.createdAt
              ? format(
                  new Date(driverIdTemplate?.configurationTemplate?.createdAt),
                  localization.formats.time.formats.dby_imp
                )
              : ''
          }
          styles={styles}
        />
        <InfoRow
          label={t('CompanyConfig.DeviceConfigurations.View.UpdatedAt')}
          sxValue={{ width: '75%' }}
          value={
            driverIdTemplate?.configurationTemplate?.updatedAt
              ? format(
                  new Date(driverIdTemplate?.configurationTemplate?.updatedAt),
                  localization.formats.time.formats.dby_imp
                )
              : ''
          }
          styles={styles}
        />
      </div>
    </React.Fragment>
  );
};
