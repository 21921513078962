export const ENV = Object.freeze({
  TEST_ANZ: 'https://tst.api-au.telematics.com/v1',
  QA_ANZ: 'https://qa.api-au.telematics.com/v1',
  QA_INTERNAL_ANZ: 'https://qa-internal.api-au.telematics.com/v1',
  STAGING_ANZ: 'https://stg.api-au.telematics.com/v1',
  PROD_ANZ: 'https://api-au.telematics.com/v1',
  PROD_UK: 'https://api-uk.telematics.com/v1',
  PROD_US: 'https://api-us.telematics.com/v1',
  PROD_MX: 'https://api-mx.telematics.com/v1'
});

export const NON_PROD = [ENV.QA_ANZ, ENV.QA_INTERNAL_ANZ, ENV.STAGING_ANZ, ENV.TEST_ANZ];
export const PROD = [ENV.PROD_ANZ, ENV.PROD_UK, ENV.PROD_US, ENV.PROD_MX];

export const FeatureFlag = Object.freeze({
  npiExample: {
    flag: 'npiExample',
    allow: [ENV.TEST_ANZ, ENV.QA_ANZ, ENV.STAGING_ANZ],
    useNPI: true
  },
  changeLanguage: {
    flag: 'changeLanguage',
    allow: NON_PROD,
    useNPI: false,
    useSA: false
  },
  driverRestriction: {
    flag: 'driverRestriction',
    allow: NON_PROD,
    useNPI: true
  },
  bulkImportLocationsGeofences: {
    flag: 'bulkImportLocationsGeofences',
    useNPI: true
  },
  insightsQSConsole: {
    flag: 'insightsQSConsole',
    useSA: true
  },
  insightsUpdatedToolbarMask: {
    flag: 'insightsUpdatedToolbarMask',
    allow: NON_PROD,
    useSA: true
  },
  TCAEnrolments: {
    flag: 'TCAEnrolments',
    allow: [...NON_PROD, ENV.PROD_ANZ]
  },
  Regulatory: {
    flag: 'Regulatory',
    allow: NON_PROD
  },
  ssoGuard: {
    flag: 'ssoGuard',
    allow: NON_PROD
  },
  pretripChecklistPhotoOnPass: {
    flag: 'pretripChecklistPhotoOnPass'
  },
  vehMaintenanceInpspections: {
    flag: 'vehMaintenanceInpspections'
  },
  npi: {
    flag: 'npi',
    useNPI: true
  },
  churnzero: {
    flag: 'churnzero',
    allow: [ENV.QA_ANZ]
  },
  bulkImportCountryStateHint: {
    flag: 'bulkImportCountryStateHint',
    allow: NON_PROD,
    useNPI: true
  },
  startOfDay: {
    flag: 'startOfDay',
    allow: NON_PROD,
    useNPI: true
  },
  newVehicleMaintenanceForm: {
    flag: 'newVehicleMaintenanceForm',
    allow: [ENV.TEST_ANZ],
    useNPI: true
  },
  trackingLastUpdateColumn: {
    flag: 'trackingLastUpdateColumn',
    allow: NON_PROD,
    useNPI: true
  },
  generateTCAPDF: {
    flag: 'generateTCAPDF',
    useSA: true
  },
  trackingPublicPage: {
    flag: 'trackingPublicPage',
    useSA: true
  },
  logOnEventsConfiguration: {
    flag: 'logOnEventsConfiguration',
    allow: []
  },
  sapEquipmentId: {
    flag: 'sapEquipmentId',
    allow: NON_PROD,
    useNPI: true
  },
  vt202Output: {
    flag: 'vt202Output',
    allow: NON_PROD
  },
  evoPhase1: {
    flag: 'evoPhase1',
    allow: NON_PROD
  },
  evoPhaseTGE: {
    flag: 'evoPhaseTGE',
    allow: [ENV.PROD_ANZ],
    allowCompanies: {
      [ENV.PROD_ANZ]: {
        allowCompanyIds: [489624, 432639],
        allowSubCompanies: true
      }
    }
  },
  trackingEnhancement: {
    flag: 'trackingEnhancement',
    useNPI: true
  },
  multiIQFlip: {
    flag: 'multiIQFlip',
    allow: NON_PROD
  },
  addIQEvents: {
    flag: 'addIQEvents',
    allow: NON_PROD
  },
  addIQEventsCapitalElectric: {
    flag: 'addIQEventsCapitalElectric',
    allow: [ENV.PROD_US],
    allowCompanies: {
      [ENV.PROD_US]: {
        allowCompanyIds: [4221],
        allowSubCompanies: true
      }
    }
  },
  addAudioAlerts: {
    flag: 'addAudioAlerts',
    allow: NON_PROD,
    useNPI: true
  },
  pbsField: {
    flag: 'pbsField',
    allow: NON_PROD
  },
  fbtMultipleCalls: {
    flag: 'fbtMultipleCalls'
  },
  autoGeneratedTemplate: {
    flag: 'autoGeneratedTemplate',
    allow: NON_PROD,
    useNPI: true
  },
  removeFakeIgnition: {
    flag: 'removeFakeIgnition',
    useNPI: true
  },
  /* Team Serenity Flags */
  /*Global*/
  includeRulesetPreRelease: {
    flag: 'includeRulesetPreRelease',
    allow: NON_PROD,
    useNPI: true
  },
  //TN360-8677
  journeyPlannerFolders: {
    flag: 'journeyPlannerFolders',
    useNPI: true
  },
  //TN360-6136
  driverLoginAlert: {
    flag: 'driverLoginAlert',
    allow: NON_PROD,
    useNPI: true
  },
  //TN360-9699
  oneWireMultiProbe: {
    flag: 'oneWireMultiProbe',
    useNPI: true
  },
  //TN360-10504
  vehicleTypeEnhancement: {
    flag: 'vehicleTypeEnhancement',
    allow: NON_PROD,
    useNPI: true
  },
  edrVehicleDelete: {
    flag: 'edrVehicleDelete',
    allow: NON_PROD,
    useNPI: true
  },
  //hidden from all including NON_PROD.
  eldAlertWarnings: {
    flag: 'eldAlertWarnings',
    allow: [],
    useNPI: true
  },
  /*Regional*/
  dvirInspection: {
    flag: 'dvirInspection',
    allow: [...NON_PROD, ENV.PROD_US]
  },
  driverPortal: {
    flag: 'driverPortal',
    allow: [ENV.STAGING_ANZ, ENV.PROD_US]
  },
  driverPortalTripManager: {
    flag: 'driverPortalTripManager',
    allow: [ENV.STAGING_ANZ, ENV.TEST_ANZ, ENV.PROD_US, ENV.PROD_ANZ]
  },
  fbtBulkAssignment: {
    flag: 'fbtBulkAssignment',
    allow: [ENV.STAGING_ANZ, ENV.TEST_ANZ, ENV.PROD_US, ENV.PROD_ANZ]
  },
  deviceConfig_speedAssist: {
    flag: 'deviceConfig_speedAssist',
    allow: [ENV.STAGING_ANZ, ENV.PROD_ANZ]
  },
  idleMeter: {
    flag: 'idleMeter',
    allow: NON_PROD,
    useNPI: true
  },
  fleetLevelGpio: {
    flag: 'fleetLevelGpio',
    allow: NON_PROD,
    useNPI: true
  }
  /*#####################*/
});
