import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, DatePicker, Modal, Button, Radio, Input, InputNumber, Checkbox } from 'antd';
import { Select } from 'components/ant';
import { radioOptions } from './constants';
import styles from './NewDeclarationModal.module.scss';
import { useCurrentCompany } from 'features/company/companySlice';
import {
  useGetTimezonesAUQuery,
  useGetVehicleRegistrationQuery,
  useAddSelfDeclarationMutation,
  useGetVehicleTypeDefinitionsQuery
} from 'services/nextgen';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { parseErrorMessage } from 'utils/strings';

export const NewDeclarationModal = ({ isFormModalOpen, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompany = useCurrentCompany();
  const [recordType, setRecordType] = useState(1);
  const [isNoLoadChecked, setIsNoLoadChecked] = useState(false);
  const [selectedVehicleTypeCode, setSelectedVehicleTypeCode] = useState('');
  const [TCATimezoneCodes, setTCATimezoneCodes] = useState([]);
  const [TCAVehicleReg, setVehicleReg] = useState([]);
  const [TCAVehicleTypes, setTCAVehicleTypes] = useState([]);
  const [TCACommentTypes, setTCACommentTypes] = useState([]);
  const [numberOfAxlesOptions, setNumberOfAxlesOptions] = useState([]);
  const [
    addSelfDeclarationMutation,
    { isLoading: addSelfDeclarationLoading }
  ] = useAddSelfDeclarationMutation();
  const [newTMAForm] = Form.useForm();

  const { data: timezonesAU } = useGetTimezonesAUQuery();
  const { data: vehicleRegistrations } = useGetVehicleRegistrationQuery({
    companyId: currentCompany?.id
  });
  const { data: vehicleTypeDefinitions } = useGetVehicleTypeDefinitionsQuery();

  useEffect(() => {
    if (timezonesAU?.timezones) {
      const mappedTimezones = timezonesAU?.timezones.map(tz => ({
        id: tz.code,
        label: `${tz.name} (UTC ${tz.offset})`
      }));
      setTCATimezoneCodes(mappedTimezones);
    }
  }, [timezonesAU]);

  useEffect(() => {
    if (Array.isArray(vehicleRegistrations)) {
      const mappedVehicleReg = vehicleRegistrations.map(vehicle => ({
        id: vehicle.registration,
        label: vehicle.registration
      }));
      setVehicleReg(mappedVehicleReg);
    }
  }, [vehicleRegistrations]);

  useEffect(() => {
    if (vehicleTypeDefinitions?.vehicleTypes) {
      const mappedVehicleTypes = vehicleTypeDefinitions?.vehicleTypes.map(vehicleType => ({
        id: vehicleType.code,
        label: vehicleType.name
      }));
      setTCAVehicleTypes(mappedVehicleTypes);
    }

    if (vehicleTypeDefinitions?.commentTypes) {
      const mappedCommentTypes = vehicleTypeDefinitions?.commentTypes.map(({ type }) => ({
        id: type,
        label: t(`Regulatory.Modal.Form.CommentTypes.${type}`)
      }));
      setTCACommentTypes(mappedCommentTypes);
    }
  }, [vehicleTypeDefinitions]);

  useEffect(() => {
    const numberOfAxles = vehicleTypeDefinitions?.vehicleTypes?.find(
      ({ code }) => code === selectedVehicleTypeCode
    );

    const mappedNumberOfAxles = numberOfAxles?.axles?.map(num => ({
      id: num,
      label: `${num}`
    }));
    setNumberOfAxlesOptions(mappedNumberOfAxles);
  }, [selectedVehicleTypeCode]);

  const handleClose = () => {
    onClose();
    handleReset();
  };

  const handleRadioChange = e => {
    setRecordType(e.target.value);
  };

  const handleVehicleTypeChange = vehicleTypeCode => {
    setSelectedVehicleTypeCode(vehicleTypeCode);
    newTMAForm.setFieldsValue({
      NumAxles: null
    });
  };

  const handleNoLoadToggle = e => {
    setIsNoLoadChecked(e.target.checked);
    newTMAForm.setFieldsValue({
      TCM: 0
    });
  };

  const handleReset = () => {
    setRecordType(radioOptions(t)[0].value);
    setIsNoLoadChecked(false);
    setSelectedVehicleTypeCode('');
    setNumberOfAxlesOptions([]);
    newTMAForm.resetFields();
  };

  const isDateDisabled = current => {
    const today = dayjs().endOf('day');
    return current > today || current < today.subtract(7, 'day');
  };

  const handleSave = async () => {
    try {
      await newTMAForm.validateFields();
    } catch (errorInfo) {
      return;
    }
    const valuesFromForm = newTMAForm.getFieldsValue();
    const submitValues = {};

    const vehicleRegistration = vehicleRegistrations.find(
      ({ registration }) => registration === valuesFromForm.VehicleRegistration
    );
    if (recordType === 1) {
      submitValues.VehicleType = vehicleTypeDefinitions?.vehicleTypes?.find(
        ({ code }) => code === valuesFromForm.VehicleType
      )?.name;
      submitValues.NumAxles = valuesFromForm.NumAxles;
      submitValues.TCM = valuesFromForm.TCM;
    } else {
      submitValues.CommentType = valuesFromForm.CommentType;
      submitValues.Comment = valuesFromForm.Comment;
    }

    const selectedTimeZoneOffSet = timezonesAU.timezones?.find(
      timezone => timezone.code === valuesFromForm.timeZoneCode
    )?.offset;

    submitValues.EventDateTime = dayjs(valuesFromForm.EventDateTime)
      .format('YYYY-MM-DDTHH:mm:ss')
      .concat(selectedTimeZoneOffSet);
    submitValues.DeviceId = vehicleRegistration.imei;
    submitValues.VehicleId = vehicleRegistration.vehicleId;
    submitValues.Source = 'TN360';

    addSelfDeclarationMutation({
      deviceId: vehicleRegistration.imei,
      body: submitValues
    })
      .then(result => {
        if (result.error) {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: `${t('Regulatory.Modal.Form.Toasts.TMASDAddError')} ${parseErrorMessage(
                result?.error?.data
              )}`
            })
          );
          return;
        }
        dispatch(
          openToast({
            type: ToastType.Success,
            message: t('Regulatory.Modal.Form.Toasts.TMASDAddSuccess')
          })
        );
        handleClose();
        handleReset();
      })
      .catch(error => {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: parseErrorMessage(error?.data)
          })
        );
      });
  };

  const modalFooter = (
    <div className={styles.modalFooter}>
      <div>
        <Button
          type="primary"
          htmlType="submit"
          onClick={handleSave}
          disabled={addSelfDeclarationLoading}
        >
          {t('Regulatory.Modal.Form.Submit')}
        </Button>
        <Button onClick={handleClose}>{t('Regulatory.Modal.Form.Cancel')}</Button>
      </div>

      <div>
        <Button onClick={handleReset}>{t('Regulatory.Modal.Form.Reset')}</Button>
      </div>
    </div>
  );

  return (
    <>
      <Modal
        centered
        wrapClassName={styles.newTMAFormModal}
        onCancel={handleClose}
        open={isFormModalOpen}
        footer={modalFooter}
        title={t('Regulatory.Modal.Title')}
        maskClosable={false}
      >
        <Form className={styles.newTMAForm} form={newTMAForm}>
          <div className={styles.vehicleRegistrationWrapper}>
            <Form.Item
              className={styles.vehicleregistrationInputWrapper}
              name="VehicleRegistration"
              colon={false}
              label={t('Regulatory.Modal.Form.VehicleRegistration')}
              rules={[
                { required: true, message: t('Regulatory.Modal.Form.VehicleRegistrationRequired') }
              ]}
            >
              <Select
                placeholder={t('Regulatory.Modal.Form.SelectVehicleRegistration')}
                data={TCAVehicleReg}
                onChange={null}
              />
            </Form.Item>
          </div>
          <div className={styles.dateTimeZoneWrapper}>
            <div className={styles.dateWrapper}>
              <Form.Item
                name="EventDateTime"
                colon={false}
                label={t('Regulatory.Modal.Form.DateAndTimeLabel')}
                rules={[
                  {
                    required: true,
                    message: t('Regulatory.Modal.Form.DateAndTimeRequired')
                  }
                ]}
              >
                <DatePicker
                  showTime
                  placeholder={t('Common.SelectDate')}
                  controlClass={styles.datePicker}
                  disabledDate={current => isDateDisabled(current)}
                />
              </Form.Item>
              <span className={styles.infoContainer}>{t('Regulatory.Modal.Form.TimeMessage')}</span>
            </div>
            <div className={styles.timeZoneWrapper}>
              <Form.Item
                name="timeZoneCode"
                colon={false}
                label={t('Regulatory.Modal.Form.TimeZone')}
                rules={[
                  {
                    required: true,
                    message: t('Regulatory.Modal.Form.TimeZoneRequired')
                  }
                ]}
              >
                <Select
                  placeholder={t('Regulatory.Modal.Form.SelectTimezone')}
                  data={TCATimezoneCodes || []}
                  onChange={null}
                />
              </Form.Item>
            </div>
          </div>
          <div className={styles.radioGroupWrapper}>
            <Form.Item label={t('Regulatory.Modal.Form.RadioLabel')} colon={false} rules={[]}>
              <Radio.Group
                options={radioOptions(t)}
                defaultValue={recordType}
                value={recordType}
                onChange={handleRadioChange}
              />
            </Form.Item>
          </div>

          {recordType === 1 ? (
            <>
              <div className={styles.vehicleTypeAndAxlesWrapper}>
                <div className={styles.vehicleTypeWrapper}>
                  <Form.Item
                    name="VehicleType"
                    label={t('Regulatory.Modal.Form.VehicleTypeLabel')}
                    colon={false}
                    rules={[
                      {
                        required: true,
                        message: t('Regulatory.Modal.Form.VehicleTypeRequired')
                      }
                    ]}
                  >
                    <Select
                      placeholder={t('Regulatory.Modal.Form.SelectVehicleType')}
                      data={TCAVehicleTypes}
                      value={selectedVehicleTypeCode}
                      onChange={handleVehicleTypeChange}
                    />
                  </Form.Item>
                </div>

                <div className={styles.numberOfAxlesWrapper}>
                  <Form.Item
                    name="NumAxles"
                    label={t('Regulatory.Modal.Form.NumberOfAxlesLabel')}
                    colon={false}
                    rules={[
                      {
                        required: true,
                        message: t('Regulatory.Modal.Form.NumberOfAxlesRequired')
                      }
                    ]}
                  >
                    <Select
                      placeholder={t('Regulatory.Modal.Form.SelectNumberOfAxles')}
                      data={numberOfAxlesOptions || []}
                      onChange={null}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className={styles.totalCombinationMassWrapper}>
                <div className={styles.totalCombinationMassInputWrapper}>
                  <Form.Item
                    name="TCM"
                    label={t('Regulatory.Modal.Form.TotalCombinationMassLabel')}
                    colon={false}
                    rules={[
                      {
                        required: isNoLoadChecked ? false : true,
                        message: t('Regulatory.Modal.Form.TotalCombinationMassRequired')
                      }
                    ]}
                  >
                    <InputNumber
                      controls={false}
                      precision={1}
                      min={0}
                      max={999.9}
                      disabled={isNoLoadChecked}
                    />
                  </Form.Item>
                  <span className={styles.totalCombinationMassSuffix}>
                    {t('Regulatory.Modal.Form.TotalCombinationMassSuffix')}
                  </span>

                  <span className={styles.infoTotalCombinationMass}>
                    {t('Regulatory.Modal.Form.EnterTotalCombinationMass')}
                  </span>
                </div>
                <div className={styles.totalCombinationMassCheckboxWrapper}>
                  <Form.Item name="noLoad" label={t('Regulatory.Modal.Form.NoLoad')} colon={false}>
                    <Checkbox onChange={handleNoLoadToggle} />
                  </Form.Item>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={styles.commentTypeWrapper}>
                <Form.Item
                  name="CommentType"
                  label={t('Regulatory.Modal.Form.CommentType')}
                  colon={false}
                  rules={[
                    {
                      required: true,
                      message: t('Regulatory.Modal.Form.CommentTypeRequired')
                    }
                  ]}
                >
                  <Select
                    placeholder={t('Regulatory.Modal.Form.SelectCommentType')}
                    data={TCACommentTypes}
                    onChange={null}
                  />
                </Form.Item>
              </div>

              <div className={styles.additionalCommentsWrapper}>
                <Form.Item
                  name="Comment"
                  label={t('Regulatory.Modal.Form.AdditionalComments')}
                  colon={false}
                  rules={[
                    {
                      required: true,
                      message: t('Regulatory.Modal.Form.AdditionalCommentsRequired')
                    }
                  ]}
                >
                  <Input.TextArea maxLength={256} />
                </Form.Item>
              </div>
            </>
          )}
        </Form>
      </Modal>
    </>
  );
};
