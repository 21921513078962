import { Tooltip } from 'antd';

export const InfoTooltipHeaderCell = ({ label, tooltip }) => {
  return (
    <>
      <span style={{ marginRight: '2px' }}>{label}</span>
      <Tooltip overlayInnerStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.95)' }} title={tooltip}>
        <i
          className={'tn-i-info-outline'}
          style={{
            float: 'right',
            margin: '2px 4px 0 0',
            color: 'rgba(0, 0, 0, 0.85)',
            fontSize: '1.3rem'
          }}
          onClick={e => {
            e.stopPropagation();
          }}
        />
      </Tooltip>
    </>
  );
};

export const TooltipHeaderCell = ({ label, tooltip }) => {
  return <Tooltip title={tooltip}>{label}</Tooltip>;
};
