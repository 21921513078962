import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import moment from 'moment';
import { format } from 'utils/dates';
import { Alert, Spin, Typography, Tooltip, Space, Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Table } from 'components/ant';

import Accordion from 'components/form/accordion/Accordion';
import InfoRow from 'components/form/info-row/InfoRow';
import { MapModal } from 'components/modals/map-modal/MapModal';
import {
  useCompanies,
  useCurrentCompanyId,
  useRedirectToMainFeaturePageOnCompanyChange
} from 'features/company/companySlice';
import { useDevicesStats, useIsFetchingDevicesStats } from 'features/devices/devicesStatsSlice';
import {
  useDevices,
  useIsFetchingDevice as useIsFetchingDevices,
  useIsFetchingDevicesFinished
} from 'features/fleets/fleetsSlice';
import {
  useDeletedDevices,
  useIsFetchingDeletedDevicesFinished
} from 'features/devices/devicesDeletedSlice';
import {
  deleteDeviceApi,
  restoreDeviceApi,
  saveDeviceFleetsApi
} from 'features/devices/devicesSlice';
import { useIQCameraDevicesConfig } from 'features/company_config';
import { useLocalization } from 'features/localization/localizationSlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { useDeviceMeters, useIsFetchingDeviceMeters } from 'features/devices/devicesMetersSlice';
import { useUsers } from 'features/users/usersSlice';
import { useUserInfo } from 'features/user/userSlice';
import { useUserKey } from '../../../features/user/userSlice';
import ViewHeaderWrapper from 'components/view-header-wrapper/ViewHeaderWrapper';
import { RequestFootageButton } from 'features/requestFootage/RequestFootageButton';
import { MeterType, formatMeterValue, formatMeterBase, formatMeterDiff } from 'features/meters';

//constants
import {
  deviceMetersColumns,
  deviceMetersSAColumns,
  PATHS,
  devStatsTransformer,
  deviceTypeModelTranslate,
  getSupportedIQCameraAudioAlertTypes
} from './constants';
import { AUDIT_ENTITY } from 'components/auditsTable/constants';
import { Configure } from '../../../containers/Administration/Devices/DeviceConfigurations/Configure';
//styles
import styles from './Devices.module.scss';
import { DeletedEntityAlert, DeletableEntityType } from 'features/common/deletedEntityAlert';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { useCameraDiagnosticStatus } from 'features/camera/cameraSlice';
import { bytesToMB, getDataPlanUsage, isNewDevice as isNewDev } from './helpers';
import { StackedBarChart } from 'components/ant/Charts/stackedbar-chart/StackedBarChart';
import {
  useCanOneOfRoles,
  GlobalRoles,
  useCanNPI,
  useCan,
  FeatureFlag,
  useCanFeatureFlag
} from 'features/permissions';
import { BUTTON_IDS } from 'utils/globalConstants';

import { IQCameraDiagnostic } from './Diagnostic/IQCameraDiagnostic';
import DeviceAgreements from './DeviceAgreements';
import InfoTable from 'components/form/info-table/InfoTable';
import { useCurrentRegion } from 'features/regions/regionsSlice';
import services from 'features/permissions/services';
import DeviceConfigurations from './DeviceConfigurations';
import { OrderedTextRenderer } from './CellRenderers';
import { isIQCamera as isIQCam } from 'features/camera/CameraModelConfig';
import { isBoolean } from 'lodash';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { ChannelFlipsField } from 'features/camera/ChannelFlips';

export const DeviceView = () => {
  const indexBeginningId = window.location.pathname.lastIndexOf('/');
  const id = window.location.pathname.substr(
    indexBeginningId + 1,
    window.location.pathname.length - 1
  );
  const history = useHistory();
  const meters = useDeviceMeters(id);
  const isFetchingMeters = useIsFetchingDeviceMeters();
  const localization = useLocalization();
  const users = useUsers();
  const currentUser = useUserInfo();
  const devices = useDevices();
  const isFetchingDevices = useIsFetchingDevices();
  const deletedDevices = useDeletedDevices();
  const devicesStats = useDevicesStats();
  const isFetchingDevicesStats = useIsFetchingDevicesStats();
  const hasDevicesFetched = useIsFetchingDevicesFinished();
  const hasDeletedDevicesFetched = useIsFetchingDeletedDevicesFinished();
  const userKey = useUserKey();
  const can = useCan();
  const dispatch = useDispatch();
  const companies = useCompanies();
  const { t } = useTranslation();
  const [showMapModal, setShowMapModal] = useState(false);
  const [icon, setIcon] = useState([false, false, false]);
  const hasNPI = useCanNPI();
  const { cameraStatus, isFetching: isFetchingCameraStatus } = useCameraDiagnosticStatus(
    parseInt(id, 10)
  );

  const speedAssistFlag = useCanFeatureFlag({
    featureFlag: FeatureFlag.deviceConfig_speedAssist.flag
  });

  const driverLoginalertFlag = useCanFeatureFlag({
    featureFlag: FeatureFlag.driverLoginAlert.flag
  });

  const idleMeterFlag = useCanFeatureFlag({
    featureFlag: FeatureFlag.idleMeter.flag
  });

  const canControlSpeedAssistConfiguration = can({
    ...(speedAssistFlag
      ? {
          oneOfRoles: [GlobalRoles.Reseller, GlobalRoles.SiteAdmin, GlobalRoles.Admin]
        }
      : []),
    oneOfCompanyServices: [services.SPEEDASSIST]
  });

  const canControlDriverIdConfiguration = can({
    ...(driverLoginalertFlag
      ? {
          oneOfRoles: [GlobalRoles.Reseller, GlobalRoles.SiteAdmin, GlobalRoles.Admin]
        }
      : []),
    oneOfCompanyServices: [services.DRIVERPIN]
  });

  const hasSiteAdminOrResellerRole = useCanOneOfRoles([
    GlobalRoles.Reseller,
    GlobalRoles.SiteAdmin
  ]);

  const canControlGpioConfiguration = can({
    otherConditions: [() => hasSiteAdminOrResellerRole],
    oneOfCompanyServices: [services.GPIO]
  });
  const currentRegion = useCurrentRegion();

  const canSubCompanyManagement = can({
    oneOfRoles: [GlobalRoles.SubCompanyAdmin]
  });

  const isSiteAdmin = currentUser.siteAdmin;

  useRedirectToMainFeaturePageOnCompanyChange('/settings/devices');

  const handleFetchError = useCallback(() => {
    history.replace(PATHS.DEVICE_DEFAULT);
  }, [history]);

  const invalidRequest = useCallback(() => {
    if (history.location.pathname !== PATHS.DEVICE_DEFAULT) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('Common.Invalid Request ID')
        })
      );
      handleFetchError();
    }
  }, [dispatch, handleFetchError, history]);

  useEffect(() => {
    const parsedId = parseInt(id);
    if (parsedId <= 0 || isNaN(parsedId)) {
      invalidRequest();
    }
  }, [id, invalidRequest]);

  const {
    deviceData,
    company,
    loading: loadingDeviceData,
    diagnostic,
    isIQCamera,
    isMultiIQCamera,
    valueForNewDevice,
    isHermesDevice,
    disableVR,
    dataPlanUsage
  } = useMemo(() => {
    const allDevices = devices.concat(deletedDevices);
    const device = allDevices.find(device => parseInt(device.id, 10) === parseInt(id, 10));
    const deviceStats = devicesStats.find(ds => parseInt(ds.deviceId, 10) === parseInt(id, 10));
    const _deviceData = { ...device, stats: deviceStats };
    const company = companies?.find(company => company.id === _deviceData?.companyId);
    const isIQCamera = isIQCam(_deviceData);

    const getValueForNewDevice = () =>
      isNewDev(_deviceData) ? t('Devices.View.DataNotYetAvailable') : '';

    const isHermesDevice = _deviceData?.type?.code === 'HERMES';
    const canShowDataPlan = isIQCamera,
      { warning, ...planUsgae } = getDataPlanUsage({
        dataPlan: cameraStatus.dataPlan,
        currentRegionCode: currentRegion?.code,
        can
      });
    return {
      deviceData: _deviceData,
      company,
      loading: isFetchingDevicesStats || isFetchingDevices,
      diagnostic: cameraStatus.diagnostic,
      isIQCamera,
      isMultiIQCamera: _deviceData?.model?.name === 'MultiIQ',
      valueForNewDevice: getValueForNewDevice(),
      isHermesDevice,
      disableVR:
        !can({ oneOfRoles: [GlobalRoles.SiteAdmin] }) && isIQCamera && isFetchingCameraStatus,
      dataPlanUsage: {
        canShow: canShowDataPlan,
        warning:
          !can({ oneOfRoles: [GlobalRoles.SiteAdmin] }) &&
          canShowDataPlan &&
          !isFetchingCameraStatus
            ? warning
            : null,
        ...planUsgae
      }
    };
  }, [
    devices,
    deletedDevices,
    devicesStats,
    companies,
    id,
    isFetchingDevicesStats,
    isFetchingDevices,
    userKey,
    cameraStatus,
    currentRegion
  ]);

  const deviceMeters = useMemo(() => {
    const _meters = (meters || [])
      .filter(meter => idleMeterFlag || meter.type !== MeterType.Idle)
      .filter(
        meter =>
          currentUser.siteAdmin ||
          !deviceData.vehicle?.id ||
          (!currentUser.siteAdmin &&
            deviceData.vehicle?.id &&
            meter.type !== MeterType.Odometer &&
            meter.type !== MeterType.Hours)
      )
      .map((meter, index) => {
        const rebasedByUser = meter?.rebasedBy
          ? users.find(user => user?.id === +meter?.rebasedBy?.id)
          : null;

        return {
          key: index,
          meterType:
            meter.source === MeterType.Gpio
              ? meter?.customType
                ? t(`Vehicles.GPIO.${meter?.customType}`, { defaultValue: meter?.customType })
                : t(`Vehicles.GPIO.${meter?.type}`)
              : t(`MeterTypes.${meter?.type}`, meter?.type),
          source: meter.source,
          device: `${meter.device.name} - ${deviceData?.type?.name}`,
          base:
            meter.source === MeterType.Gpio
              ? `${meter.base.toFixed(1)} ${t('Common.hrs')}`
              : formatMeterBase(localization, meter),
          diff:
            meter.source === MeterType.Gpio
              ? `${meter.diff.toFixed(1)} ${t('Common.hrs')}`
              : formatMeterDiff(localization, meter),
          value:
            meter.source === MeterType.Gpio
              ? `${meter.value.toFixed(1)} ${t('Common.hrs')}`
              : formatMeterValue(localization, meter),
          rebasedAt: meter.rebasedAt
            ? format(
                moment(meter.rebasedAt, 'YYYY-MM-DD hh:mm:ss').toDate(),
                localization.formats.time.formats.dby_imp
              )
            : '',
          rebasedBy: rebasedByUser ? rebasedByUser.firstName + ' ' + rebasedByUser.lastName : ''
        };
      });
    const loading = isFetchingMeters || loadingDeviceData;
    return {
      columns: currentUser.siteAdmin ? deviceMetersSAColumns(t) : deviceMetersColumns(t),
      meters: loading ? [] : _meters,
      loading
    };
  }, [
    deviceData,
    loadingDeviceData,
    localization.formats.time.formats.dby_imp,
    meters,
    users,
    currentUser,
    isFetchingMeters,
    t
  ]);

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setPageTitle(deviceData.name && deviceData.name));
  }, [deviceData, dispatch]);

  useEffect(() => {
    if (hasDevicesFetched && hasDeletedDevicesFetched && !isFetchingDevices) {
      const parsedId = parseInt(id);
      if (!devices.some(d => d.id === parsedId) && !deletedDevices?.some(d => d.id === parsedId)) {
        invalidRequest();
      }
    }
  }, [
    hasDevicesFetched,
    hasDeletedDevicesFetched,
    devices,
    deletedDevices,
    id,
    invalidRequest,
    isFetchingDevices
  ]);

  const deleteConfirmationMsg = useMemo(() => {
    let content;
    if (deviceData.type?.code === 'EDR') {
      content = (
        <div>
          {t('Vehicles.Form.UpdateEDRWarning', {
            action: t('Vehicles.Form.Delete'),
            type: t('Common.Device').toLowerCase()
          })}
          <br />
          {t('Vehicles.Form.UpdateEDRWarning2')}
        </div>
      );
    }
    return content;
  }, [deviceData]);

  const handleButtonAction = action => () => {
    switch (action) {
      case 'delete':
        dispatch(saveDeviceFleetsApi(deviceData.id, []));
        dispatch(deleteDeviceApi(deviceData, history));
        break;
      case 'restore':
        dispatch(restoreDeviceApi(deviceData));
        break;
      default:
    }
  };

  const handleConfigureClick = event => {
    event.preventDefault(); // Prevent default link behavior
    showLargeDrawer(); // Open the drawer
  };

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [size, setSize] = useState();

  const showLargeDrawer = () => {
    setSize('large');
    setDrawerOpen(true);
  };

  const onClose = () => {
    setDrawerOpen(false);
  };

  const hasServiceAnddeviceHasServiceSpeedAssist =
    speedAssistFlag &&
    canControlSpeedAssistConfiguration &&
    (deviceData?.type?.code === 'IFACE' || deviceData?.type?.code === 'EXTERNAL') &&
    deviceData?.services?.includes('SPEEDASSIST');

  const hasServiceAnddeviceHasServiceDriverLoginAlert =
    driverLoginalertFlag &&
    canControlDriverIdConfiguration &&
    deviceData?.type?.code === 'HERMES' &&
    deviceData?.services?.includes('DRIVERPIN');

  const hasServicesToConfigure =
    hasServiceAnddeviceHasServiceSpeedAssist || hasServiceAnddeviceHasServiceDriverLoginAlert;

  return (
    <React.Fragment>
      <ViewHeaderWrapper
        data={{ entityName: AUDIT_ENTITY.DEVICE, ...deviceData }}
        editPath={`/settings/devices/edit/id/${id}`}
        auditPath={`/settings/devices/audit/id/${id}`}
        canUse="DEVICE"
        handleButtonAction={handleButtonAction}
        disableDelete={!isSiteAdmin}
        customDeleteContent={deleteConfirmationMsg}
        typeOfEntityToDelete={t('Common.device')}
      >
        {canSubCompanyManagement && (
          <Button
            onClick={() => {
              history.push(`/settings/devices/agreement`, { devices: [{ id: deviceData.id }] });
            }}
            size="large"
          >
            {t('Agreement.AssignAgreement')}
          </Button>
        )}

        {hasServicesToConfigure && (
          <Button
            size="large"
            type="secondary"
            id={BUTTON_IDS.configureViewHeader}
            onClick={handleConfigureClick}
          >
            {t('Devices.DeviceConfigurations.Configure')}
          </Button>
        )}

        {deviceData.type?.name === 'Camera' && (
          <RequestFootageButton
            deviceId={id}
            vehicleId={deviceData.vehicle?.id}
            disabled={disableVR}
          />
        )}
      </ViewHeaderWrapper>
      <Spin spinning={isFetchingDevices || isFetchingDevicesStats || isFetchingMeters}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '32px',
            color: '#181c21'
          }}
          className="deviceView"
        >
          {
            <DeletedEntityAlert
              entity={DeletableEntityType.Device}
              entityStatus={deviceData?.status}
            />
          }
          {dataPlanUsage.warning && (
            <Alert type="warning" showIcon closable message={dataPlanUsage.warning} />
          )}
          <InfoRow
            label={t('Devices.ActualForm.CompanyLabel')}
            value={company ? company.name : ''}
            styles={styles}
          />
          <InfoRow
            label={t('Devices.ActualForm.VehicleLabel')}
            value={
              deviceData.vehicleInfo && deviceData.vehicle ? (
                <Link to={`/settings/vehicles/id/${deviceData.vehicleInfo.id}`}>
                  {deviceData.vehicleInfo?.name}
                </Link>
              ) : (
                valueForNewDevice
              )
            }
            styles={styles}
          />
          <InfoRow
            label={t('Devices.ActualForm.FleetLabel')}
            value={
              deviceData.fleetInfo &&
              deviceData.fleetInfo.length > 0 &&
              deviceData.fleetInfo[0].id !== undefined
                ? deviceData.fleetInfo.map(fleet => (
                    <div key={`device-fleet-${fleet.id}`}>{fleet.name}</div>
                  ))
                : valueForNewDevice
            }
            styles={styles}
          />

          <InfoRow
            label={t('Devices.View.LastLocation')}
            value={
              deviceData.stats?.location ? (
                <>
                  <span onClick={() => setShowMapModal(true)} className={styles.linkSubstitute}>
                    {deviceData.stats.location}
                  </span>
                  <MapModal
                    show={showMapModal}
                    coordinates={{
                      lat: deviceData?.stats?.gps?.Lat,
                      lng: deviceData?.stats?.gps?.Lng
                    }}
                    title={deviceData.stats.location}
                    onHide={() => setShowMapModal(false)}
                  />
                </>
              ) : (
                valueForNewDevice
              )
            }
            styles={styles}
          />
          {!isIQCamera && (
            <InfoRow
              label={t('Devices.View.LastContact')}
              value={
                deviceData.stats && deviceData.stats.lastCommsAt
                  ? format(
                      new Date(deviceData.stats.lastCommsAt),
                      localization.formats.time.formats.dby_imp
                    )
                  : valueForNewDevice
              }
              styles={styles}
            />
          )}
          {deviceData?.type?.code === 'CAMERA' && (
            <CameraDeviceMeta
              t={t}
              localization={localization}
              deviceId={deviceData.id}
              cameraStatus={cameraStatus}
              dataPlanUsage={dataPlanUsage}
              isFetching={isFetchingCameraStatus}
              isIQCamera={isIQCamera}
            />
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              maxWidth: '100%',
              borderBottom: '1px solid #EEE',
              padding: '10px 0',
              lineHeight: '24px'
            }}
          >
            <div style={{ width: '100%' }}>
              <Accordion
                title={t('Devices.View.TechnicalDetails')}
                body={
                  <>
                    {deviceData.stats &&
                      deviceData.stats.devInfo &&
                      JSON.parse(deviceData.stats.devInfo).IAPID && (
                        <InfoRow
                          label={t('Devices.View.IAPID')}
                          value={JSON.parse(deviceData.stats.devInfo).IAPID}
                          styles={styles}
                        />
                      )}
                    <InfoRow
                      label={t('Devices.ActualForm.ImeiLabel')}
                      value={deviceData.imei ? deviceData.imei : ''}
                      styles={styles}
                    />
                    <InfoRow
                      label={t('Devices.ActualForm.SerialNumberLabel')}
                      value={deviceData.serialNumber ? deviceData.serialNumber : ''}
                      styles={styles}
                    />
                    {FeatureFlag.sapEquipmentId && currentUser.siteAdmin && (
                      <InfoRow
                        label={t('Devices.ActualForm.SAPEquipmentID')}
                        value={
                          deviceData && deviceData.sapEquipmentId && deviceData.sapEquipmentId
                            ? deviceData.sapEquipmentId
                            : ''
                        }
                        styles={styles}
                      />
                    )}
                    <InfoRow
                      label={t('Devices.ActualForm.ExternalIDLabel')}
                      value={deviceData.externalId ? deviceData.externalId : ''}
                      styles={styles}
                    />
                    <InfoRow
                      label={t('Devices.ActualForm.SimLabel')}
                      value={deviceData.sim ? deviceData.sim : ''}
                      styles={styles}
                    />
                    <InfoRow
                      label={t('Devices.ActualForm.PhoneLabel')}
                      value={deviceData.phone ? deviceData.phone : ''}
                      styles={styles}
                    />
                    <InfoRow
                      label={t('Devices.ActualForm.TypeLabel')}
                      value={deviceTypeModelTranslate(deviceData.type?.name || '')}
                      styles={styles}
                    />
                    <InfoRow
                      label={t('Devices.ActualForm.ModelLabel')}
                      value={deviceTypeModelTranslate(
                        isMultiIQCamera &&
                          !can({
                            oneOfFeatureFlags: [
                              FeatureFlag.evoPhase1.flag,
                              FeatureFlag.evoPhaseTGE.flag
                            ]
                          })
                          ? 'IQ Camera'
                          : deviceData?.model?.name || ''
                      )}
                      styles={styles}
                    />
                    {isIQCamera && <DeviceIQConfig t={t} deviceId={id} />}
                    {can({
                      oneOfFeatureFlags: [
                        FeatureFlag.multiIQFlip.flag,
                        FeatureFlag.evoPhaseTGE.flag
                      ]
                    }) &&
                      isMultiIQCamera && (
                        <InfoRow
                          label={t('Devices.MultiView.Configurations')}
                          sxValue={{ width: '75%' }}
                          styles={styles}
                          value={
                            <ChannelFlipsField
                              deviceImei={deviceData?.imei}
                              channelItemStyle={{ paddingLeft: 0 }}
                              readOnly
                            />
                          }
                        />
                      )}
                    <InfoRow
                      label={t('Devices.ActualForm.ModemType')}
                      value={deviceData.modemType ? deviceData.modemType : ''}
                      styles={styles}
                    />
                    <InfoRow
                      label={t('Devices.ActualForm.ModemIMEI')}
                      value={deviceData.modemImei ? deviceData.modemImei : ''}
                      styles={styles}
                    />
                    {isHermesDevice &&
                      canControlGpioConfiguration &&
                      deviceData?.services?.includes(services.GPIO) && (
                        <InfoRow
                          label={t('Devices.ActualForm.GpioConfig')}
                          value={deviceData.gpioTemplate ? deviceData.gpioTemplate?.name : ''}
                          styles={styles}
                        />
                      )}
                    <InfoRow
                      label={t('Devices.ActualForm.NotesLabel')}
                      value={deviceData.note ? deviceData.note : ''}
                      styles={styles}
                    />
                    {deviceData.type &&
                      deviceData.type.name &&
                      ['iFace', 'IVU', 'HERMES'].includes(deviceData.type.name) && (
                        <InfoRow
                          label={t('Devices.View.ReleaseVersionLabel')}
                          value={deviceData.releaseVersion ? deviceData.releaseVersion : ''}
                          styles={styles}
                        />
                      )}
                    <DeviceDevStatsFields
                      t={t}
                      deviceStats={deviceData.stats}
                      isIQCamera={isIQCamera}
                    />
                    {deviceData.type && deviceData.type.name !== 'Camera' && (
                      <InfoRow
                        label={t('Devices.View.IgnitionLabel')}
                        value={(deviceData.stats && deviceData.stats.ignition) || ''}
                        styles={styles}
                      />
                    )}
                    {deviceData.type && deviceData.type.code === 'ATRACKER' && (
                      <InfoRow
                        label={t('Devices.View.BatteryStatus')}
                        value={
                          deviceData.stats && deviceData.stats.batteryStatus
                            ? t('Devices.View.Battery.' + deviceData.stats.batteryStatus)
                            : ''
                        }
                        styles={styles}
                      />
                    )}
                    <InfoRow
                      label={t('Devices.View.InServiceAtLabel')}
                      value={
                        deviceData.inServiceAt
                          ? format(
                              new Date(deviceData.inServiceAt),
                              localization.formats.time.formats.dby_imp
                            )
                          : ''
                      }
                      styles={styles}
                    />
                    <InfoRow
                      label={t('Devices.View.OutServiceAtLabel')}
                      value={
                        deviceData.outServiceAt
                          ? format(
                              new Date(deviceData.outServiceAt),
                              localization.formats.time.formats.dby_imp
                            )
                          : ''
                      }
                      styles={styles}
                    />
                    <InfoRow
                      label={t('Devices.View.CreatedAtLabel')}
                      value={
                        deviceData.createdAt
                          ? format(
                              new Date(deviceData.createdAt),
                              localization.formats.time.formats.dby_imp
                            )
                          : ''
                      }
                      styles={styles}
                    />
                    <InfoRow
                      label={t('Devices.View.UpdatedAtLabel')}
                      value={
                        deviceData.updatedAt
                          ? format(
                              new Date(deviceData.updatedAt),
                              localization.formats.time.formats.dby_imp
                            )
                          : ''
                      }
                      styles={styles}
                    />

                    <div className={styles.deviceMetersLabel}>{t('Devices.View.DeviceMeters')}</div>
                    <Table
                      loading={deviceMeters.loading}
                      columns={deviceMeters.columns}
                      dataSource={deviceMeters.meters}
                      pagination={false}
                    />
                  </>
                }
                icon={icon[1]}
                onClick={() => setIcon([icon[0], !icon[1], icon[2]])}
              />
              {hasSiteAdminOrResellerRole && (
                <Accordion
                  title={t('Agreement.Agreements')}
                  body={<DeviceAgreements deviceId={deviceData.id} />}
                  icon={icon[1]}
                  onClick={() => setIcon([icon[0], !icon[1], icon[2]])}
                />
              )}
              {hasSiteAdminOrResellerRole && canControlGpioConfiguration && (
                <Accordion
                  title={t('Devices.View.ConfigurationProtocolAccordionTitle')}
                  body={<DeviceConfigurations device={deviceData} />}
                  icon={icon[1]}
                  onClick={() => setIcon([icon[0], !icon[1], icon[2]])}
                />
              )}
              <Accordion
                title={t('Devices.View.DataInfrastuctureAccordionTitle')}
                body={
                  hasNPI && deviceData?.type?.code === 'CAMERA' ? (
                    <>
                      {isIQCamera && diagnostic ? (
                        <IQCameraDiagnostic diagnostic={diagnostic} />
                      ) : (
                        t('Devices.View.DiagnosticReport.NotAvailable')
                      )}
                    </>
                  ) : (
                    t('Devices.View.ComingSoon')
                  )
                }
                icon={icon[1]}
                onClick={() => setIcon([icon[0], !icon[1], icon[2]])}
              />
            </div>
          </div>
        </div>
      </Spin>
      <Configure
        open={drawerOpen}
        onClose={onClose}
        size={size}
        width={800}
        data={deviceData}
        speedAssistAvailable={hasServiceAnddeviceHasServiceSpeedAssist}
        driverIdAvailable={hasServiceAnddeviceHasServiceDriverLoginAlert}
      />
    </React.Fragment>
  );
};

const DeviceDevStatsFields = ({ t, deviceStats, isIQCamera }) => {
  const fields = useMemo(() => {
    const getDevStatFields = devStatKey => {
      let devStatValues = {};
      try {
        devStatValues = deviceStats?.[devStatKey] ? JSON.parse(deviceStats[devStatKey]) : {};
      } catch (error) {
        devStatValues = {};
      }

      if (isIQCamera && devStatKey === 'devVersion') {
        const {
          FirmwareVersion: FirmwareVersionBase,
          RideviewAppVersion: FirmwareVersion,
          Firmware1Semver: FirmwareRegionalVersion
        } = devStatValues;
        devStatValues = { FirmwareVersionBase, FirmwareVersion, FirmwareRegionalVersion };
      }

      return devStatsTransformer(devStatValues);
    };

    return [
      { label: t('Devices.View.DeviceInfoLabel'), rows: getDevStatFields('devInfo') },
      { label: t('Devices.View.DeviceVersionLabel'), rows: getDevStatFields('devVersion') },
      { label: t('Devices.View.SmartNavInfoLabel'), rows: getDevStatFields('smartNavInfo') },
      { label: t('Devices.View.AppInfoLabel'), rows: getDevStatFields('devAppInfo') },
      { label: t('Devices.View.AppVersionLabel'), rows: getDevStatFields('devAppVersion') },
      { label: t('Devices.View.SquarellInfoLabel'), rows: getDevStatFields('squarellInfo') },
      { label: t('Devices.View.SquarellVersionLabel'), rows: getDevStatFields('sqarellVersion') }
    ].filter(row => row.rows.length > 0);
  }, [deviceStats, isIQCamera]);

  return fields.map(({ label: rowLabel, rows }, index) => (
    <InfoRow
      key={`devStats-row-${index}`}
      label={rowLabel}
      value={rows.map(({ key, label: nestedRowLabel, value }) => (
        <InfoRow
          key={key}
          label={nestedRowLabel}
          value={value}
          styles={styles}
          sxValue={{ width: '75%' }}
        />
      ))}
      styles={styles}
      sxValue={{ width: '75%' }}
    />
  ));
};

const DeviceIQConfig = ({ t, deviceId }) => {
  const can = useCan();

  const companyId = useCurrentCompanyId();
  const { iqCameraDevicesConfig } = useIQCameraDevicesConfig({
    companyId,
    deviceIds: [deviceId]
  });
  const deviceIQConfig = useMemo(() => iqCameraDevicesConfig?.[deviceId]?.config, [
    deviceId,
    iqCameraDevicesConfig
  ]);

  const supportedAudioAlertTypes = getSupportedIQCameraAudioAlertTypes(can);

  return (
    <>
      <InfoRow
        label={
          <Space>
            {t('Devices.ActualForm.CameraSensitivity')}
            <Tooltip
              overlayInnerStyle={{
                width: 500
              }}
              title={
                <OrderedTextRenderer
                  text={[
                    t('Devices.ActualForm.CameraSensitivityTooltip.part1'),
                    t('Devices.ActualForm.CameraSensitivityTooltip.part2'),
                    t('Devices.ActualForm.CameraSensitivityTooltip.part3'),
                    t('Devices.ActualForm.CameraSensitivityTooltip.part4'),
                    [
                      t('Devices.ActualForm.CameraSensitivityTooltip.light'),
                      t('Devices.ActualForm.CameraSensitivityTooltip.medium'),
                      t('Devices.ActualForm.CameraSensitivityTooltip.heavy')
                    ],
                    t('Devices.ActualForm.CameraSensitivityTooltip.part5')
                  ]}
                />
              }
            >
              <InfoCircleOutlined />
            </Tooltip>
          </Space>
        }
        value={
          deviceIQConfig?.dutyType ? t(`CompanyConfig.IQCamera.${deviceIQConfig?.dutyType}`) : '-'
        }
        styles={styles}
      />
      <InfoRow
        label={t('Devices.ActualForm.DeviceVolumeSetting')}
        value={deviceIQConfig?.deviceAudioVolume || '-'}
        styles={styles}
      />
      <InfoRow
        label={t('Devices.ActualForm.CameraAudioAlerts')}
        sxValue={{ width: '75%' }}
        styles={styles}
        value={
          <Typography.Paragraph
            ellipsis={{ expandable: true, symbol: t('Common.Show More Content') }}
            className={styles.ellipsisText}
          >
            {deviceIQConfig?.audioAlertsEnabled?.reduce(
              (str, typeValue, idx, arr) =>
                supportedAudioAlertTypes[typeValue]
                  ? str.concat(
                      `${t(`Devices.View.CameraAudioAlert.${typeValue}`)}${
                        idx < arr.length - 1 ? ', ' : ''
                      }`
                    )
                  : str,
              ''
            ) || '-'}
          </Typography.Paragraph>
        }
      />
      <InfoRow
        label={t('Devices.ActualForm.DriverCamera')}
        styles={styles}
        value={
          isBoolean(deviceIQConfig?.enableDriverCamera)
            ? deviceIQConfig.enableDriverCamera
              ? t('Common.On')
              : t('Common.Off')
            : '-'
        }
      />
    </>
  );
};

const CameraDeviceMeta = ({
  t,
  localization,
  deviceId,
  cameraStatus,
  dataPlanUsage,
  isFetching,
  isIQCamera
}) => {
  const { canShowDataUsage, total, chartData } = useMemo(() => {
    const usageCats = [
      'mobileDataUploadedBytes',
      'mobileDataDownloadedBytes',
      'wifiDataUploadedBytes',
      'wifiDataDownloadedBytes'
    ];
    const { total, historicalDays } = (cameraStatus?.historyDataUsageReports || []).reduce(
      ({ total, historicalDays }, dailyUsage) => {
        const date = format(
          moment.utc(dailyUsage.reportDateTime).toISOString(true),
          localization.formats.time.formats.default
        );
        return {
          total: usageCats.reduce((a, cat) => ({ ...a, [cat]: total[cat] + dailyUsage[cat] }), {}),
          historicalDays: historicalDays[date]
            ? {
                ...historicalDays,
                [date]: {
                  date,
                  ...usageCats.reduce((a, cat) => ({ ...a, [cat]: bytesToMB(dailyUsage[cat]) }), {})
                }
              }
            : historicalDays
        };
      },
      {
        total: {
          mobileDataUploadedBytes: 0,
          mobileDataDownloadedBytes: 0,
          wifiDataUploadedBytes: 0,
          wifiDataDownloadedBytes: 0
        },
        historicalDays: new Array(21).fill(1).reduce((a, _, index) => {
          const date = format(
            moment()
              .subtract(index, 'days')
              .startOf('day'),
            localization.formats.time.formats.default
          );
          return {
            ...a,
            [date]: usageCats.reduce((a, cat) => ({ ...a, [cat]: 0 }), { date })
          };
        }, {})
      }
    );

    return {
      canShowDataUsage: cameraStatus?.historyDataUsageReports?.length > 0,
      total,
      chartData: Object.values(historicalDays).reverse()
    };
  }, [localization, cameraStatus?.historyDataUsageReports]);

  const dataPlanCols = useMemo(
    () => [
      {
        label: t('Devices.View.DataPlan.CustomerPlan'),
        width: 450,
        className: styles.tableRowColumn,
        cellRenderer: ({ rowData }) =>
          rowData?.title?.map((str, tIdx) => <div key={`plan-title-${tIdx}`}>{str}</div>)
      },
      {
        label: `${t('Devices.View.DataPlan.AllowedEventsPerMonth')} (${t(
          `${'Devices.View.DataPlan.MonthlyRenew.Each.'}${dataPlanUsage?.monthlyRenewOn || 1}`
        )})`,
        cellRenderer: ({ rowData }) => rowData.details
      }
    ],
    [t, dataPlanUsage?.monthlyRenewOn]
  );

  return (
    <Spin spinning={isFetching}>
      {isIQCamera && (
        <InfoRow
          label={t('Devices.View.LastConnection')}
          value={
            cameraStatus?.lastConnection
              ? format(
                  moment.utc(cameraStatus.lastConnection).toISOString(true),
                  localization.formats.time.formats.dby_imp
                )
              : cameraStatus.signals && cameraStatus.signals[0]?.signalType === 'noConnection'
              ? t(
                  `Devices.View.${cameraStatus.signals[0]?.likelyIssue}`,
                  cameraStatus.signals[0]?.likelyIssue
                )
              : ''
          }
          styles={styles}
        />
      )}

      {dataPlanUsage.canShow && (
        <InfoRow
          label={t('Devices.View.DataPlan.Title')}
          sxValue={{
            width: '75%',
            minHeight: dataPlanUsage.minHeight
          }}
          styles={styles}
          value={
            <InfoTable
              data={dataPlanUsage.planItems}
              columns={dataPlanCols}
              styles={styles}
              rowHeight={dataPlanUsage.rowHeight}
              customRowStyle={{ alignItems: 'center', background: '#fff' }}
            />
          }
        />
      )}
      {isIQCamera && (
        <div className={styles.deviceDataUsage}>
          <div className={styles.deviceDataUsageLabel}>{t('Devices.DataUsage.Title')}</div>
          <div className={styles.deviceDataUsageValues}>
            {canShowDataUsage &&
              Object.keys(total).map((dataUsageType, index) => (
                <div key={`${deviceId}-${dataUsageType}`}>
                  <span className={index % 2 !== 0 ? styles.lableWithGap : ''}>
                    {t(`Devices.DataUsage.${dataUsageType}`)}
                  </span>
                  <span className={index % 2 === 0 ? styles.valueWithGap : ''}>
                    {`${bytesToMB(total[dataUsageType])} MB`}
                  </span>
                </div>
              ))}
          </div>
        </div>
      )}
      {isIQCamera && canShowDataUsage && (
        <div className={styles.deviceDataUsageChart}>
          <StackedBarChart
            data={chartData}
            xAxisDataKey="date"
            yAxisLabel={`${t('Devices.DataUsage.Title')} (${t('Devices.DataUsage.Unit')})`}
            bars={[
              {
                name: t(`Devices.DataUsage.mobileDataUploadedBytes`),
                dataKey: 'mobileDataUploadedBytes',
                stackId: 'usage',
                fill: '#ff6361'
              },
              {
                name: t(`Devices.DataUsage.mobileDataDownloadedBytes`),
                dataKey: 'mobileDataDownloadedBytes',
                stackId: 'usage',
                fill: '#ffa600'
              },
              {
                name: t(`Devices.DataUsage.wifiDataUploadedBytes`),
                dataKey: 'wifiDataUploadedBytes',
                stackId: 'usage',
                fill: '#bc5090'
              },
              {
                name: t(`Devices.DataUsage.wifiDataDownloadedBytes`),
                dataKey: 'wifiDataDownloadedBytes',
                stackId: 'usage',
                fill: '#ff7c43'
              }
            ]}
            tooltipContent={activeEntry => {
              if (activeEntry) {
                const { name, value, color, payload: dailyUsage } = activeEntry;
                return (
                  <>
                    <div className={styles.day}>{dailyUsage.date}</div>
                    <div>
                      <span className={styles.dot} style={{ backgroundColor: color }}></span>
                      <span>{`${name}: ${value}`}</span>
                    </div>
                  </>
                );
              }
              return null;
            }}
            legendItemContent={barMeta => (
              <div>
                <span className={styles.dot} style={{ backgroundColor: barMeta.fill }}></span>
                <span>{barMeta.name}</span>
              </div>
            )}
          />
        </div>
      )}
    </Spin>
  );
};
