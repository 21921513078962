import React, { useEffect, useState } from 'react';
//hooks
import useDebounce from 'utils/hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import { useFleets, useVehiclesFromFleets } from 'features/fleets/fleetsSlice';
//components
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import { Card, Table, Typography } from 'antd';
// helpers
import { prepareDataForMultiselect } from 'utils/filters';
import { toLower } from 'lodash';
//constants
import { deviceColumns, templateTypeEnum } from './constants';

export const DevicesAssignmentTable = ({
  filteredDevices,
  devices,
  deviceIds = [],
  setDevices,
  templates,
  currentCompany,
  handleViewTemplate,
  rowSelection,
  templateType,
  titleAddon,
  useTitle = false,
  useFilter = true
}) => {
  const { t } = useTranslation();
  const [filterText, setFilterText] = useState('');
  const [filterVehicles, setFilterVehicles] = useState([]);
  const [filterVehicleTypes, setFilterVehicleTypes] = useState([]);
  const [filterModelOptions, setFilterModelOptions] = useState([]);
  const [filterTemplates, setFilterTemplates] = useState([]);
  const debouncedSearchText = useDebounce(filterText, 300);
  const { vehicles, isFetchingVehicles } = useVehiclesFromFleets();
  const fleets = useFleets();
  const [filterFleets, setFilterFleets] = useState([]);

  const vehicleMap = vehicles.reduce((map, vehicle) => {
    map[vehicle.id] = vehicle;
    return map;
  }, {});

  useEffect(() => {
    if (filteredDevices.length > 0) {
      const models = filteredDevices.filter(device => device.model).map(device => device.model);
      const uniqueModels = [...new Set(models.map(item => item.id))].map(id =>
        models.find(item => item.id === id)
      );
      setFilterModelOptions(
        prepareDataForMultiselect(
          uniqueModels.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
          t('Common.AllModels'),
          null
        )
      );
    }
  }, [filteredDevices]);

  useEffect(() => {
    if (filteredDevices.length > 0) {
      const vehicles = filteredDevices
        .filter(device => device.vehicle)
        .map(device => device.vehicle);
      const uniqueVehicles = [...new Set(vehicles.map(item => item.id))].map(id =>
        vehicles.find(item => item.id === id)
      );
      setFilterVehicles(
        prepareDataForMultiselect(
          uniqueVehicles.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
          t('Common.AllVehicles'),
          null
        )
      );
    }
  }, [filteredDevices, isFetchingVehicles, vehicles, t]);

  useEffect(() => {
    if (isFetchingVehicles) {
      return;
    }
    const uniqueVehicleTypeIds = new Set();
    const uniqueVehicleTypes = [];
    filterVehicles.forEach(v => {
      const vehicle = vehicleMap[v.id];
      if (vehicle?.type) {
        if (!uniqueVehicleTypeIds.has(vehicle.type.id)) {
          uniqueVehicleTypes.push(vehicle.type);
          uniqueVehicleTypeIds.add(vehicle.type.id);
        }
      }
    });
    setFilterVehicleTypes(
      prepareDataForMultiselect(
        uniqueVehicleTypes.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
        t('Common.AllVehicleTypes'),
        null
      )
    );
  }, [filterVehicles, isFetchingVehicles, vehicles, t]);

  useEffect(() => {
    const templateOptions = [
      ...(templates || []),
      //Leaving GPIO as is and making speed assist and future templates generic.
      {
        id: -1,
        name:
          templateType === templateTypeEnum.GPIO
            ? t('CompanyConfig.DeviceConfigurations.GPIOTemplates.None')
            : t('CompanyConfig.DeviceConfigurations.NoTemplate')
      }
    ];
    setFilterTemplates(
      prepareDataForMultiselect(
        templateOptions,
        t('CompanyConfig.DeviceConfigurations.GPIOTemplates.AllTemplate')
      )
    );
  }, [templates, t]);

  useEffect(() => {
    let devicesToFilter = filteredDevices;
    if (setDevices) {
      setDevices(
        devicesToFilter.filter(device => {
          let validDevice = true;

          //Filter by search field

          if (debouncedSearchText) {
            validDevice =
              validDevice &&
              [
                device.name,
                device?.vehicle?.name,
                device?.imei,
                device?.vehicle?.registration,
                device?.serialNumber
              ].some(value => toLower(value).indexOf(toLower(debouncedSearchText)) > -1);
          }

          //Filter by fleets
          let checkedFleetsIds = filterFleets
            .filter(fleet => fleet.checked)
            .map(fleet => parseInt(fleet.id, 10));
          if (!(checkedFleetsIds.indexOf(0) > -1)) {
            let isDeviceInFleet = false;
            (device.fleetInfo || []).forEach(fleet => {
              isDeviceInFleet =
                isDeviceInFleet || checkedFleetsIds.indexOf(parseInt(fleet.id, 10)) > -1;
            });
            if (!isDeviceInFleet && checkedFleetsIds.indexOf(-1) > -1) {
              isDeviceInFleet =
                !Array.isArray(device.fleetInfo) || !device.fleetInfo.find(fleet => true)?.id;
            }
            validDevice = validDevice && isDeviceInFleet;
          }

          // Filter by Model
          const checkedModelIds = filterModelOptions
            .filter(model => model.checked)
            .map(model => parseInt(model.id, 10));
          if (!(checkedModelIds.indexOf(0) > -1)) {
            validDevice =
              validDevice && checkedModelIds.indexOf(parseInt(device.model.id, 10)) > -1;
          }

          //Filter by vehicle
          const checkedVehicleIds = filterVehicles
            .filter(vehicle => vehicle.checked)
            .map(vehicle => parseInt(vehicle.id, 10));
          if (!(checkedVehicleIds.indexOf(0) > -1)) {
            validDevice =
              validDevice && device.vehicle && checkedVehicleIds.indexOf(device.vehicle.id) > -1;
          }

          //Filter by vehicle
          const checkedVehicleTypeIds = filterVehicleTypes
            .filter(vehicleType => vehicleType.checked)
            .map(vehicleType => parseInt(vehicleType.id, 10));
          if (!(checkedVehicleTypeIds.indexOf(0) > -1)) {
            validDevice =
              validDevice &&
              device.vehicle &&
              checkedVehicleTypeIds.indexOf(vehicleMap[device.vehicle.id]?.type?.id) > -1;
          }

          // Filter by Model
          const checkedTemplateIds = filterTemplates
            .filter(template => template.checked)
            .map(template => parseInt(template.id, 10));
          if (!(checkedTemplateIds.indexOf(0) > -1)) {
            validDevice =
              validDevice &&
              (checkedTemplateIds.indexOf(device.template?.id) > -1 ||
                (checkedTemplateIds.indexOf(-1) > -1 && device?.template?.id === undefined));
          }

          return validDevice;
        })
      );
    }
  }, [
    filteredDevices,
    filterFleets,
    filterText,
    debouncedSearchText,
    filterModelOptions,
    filterVehicles,
    filterVehicleTypes,
    filterTemplates,
    setDevices
  ]);

  useEffect(() => {
    const fleetsOptions = fleets.filter(
      fleet => fleet.id && fleet.company && currentCompany.id === fleet.company.id
    );
    fleetsOptions.push({ id: -1, name: t('Common.NoFleet') });
    setFilterFleets(prepareDataForMultiselect(fleetsOptions, t('Common.AllFleets')));
  }, [fleets, t]);

  return (
    <Card style={{ backgroundColor: '#f7f8f9', margin: '10px 20px' }}>
      {useTitle && (
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
          <Typography.Title level={4}>
            {t('CompanyConfig.DeviceConfigurations.DriverIdTemplates.AssignToDevices')}
          </Typography.Title>
          {titleAddon}
        </div>
      )}
      {useFilter && (
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <FilterWrapper>
            <AntSearchbar onFilter={value => setFilterText(value)} />
            <AntMultiselect
              title={
                filterFleets?.some(value => !value.checked)
                  ? t('Common.Fleets')
                  : t('Common.AllFleets')
              }
              data={filterFleets}
              onFilter={v => setFilterFleets(v)}
            />
            <AntMultiselect
              title={
                filterModelOptions?.some(value => !value.checked)
                  ? t('Common.Models')
                  : t('Common.AllModels')
              }
              data={filterModelOptions}
              onFilter={v => {
                setFilterModelOptions(v);
              }}
            />
            <AntMultiselect
              title={
                filterVehicles?.some(value => !value.checked)
                  ? t('Common.Vehicles')
                  : t('Common.AllVehicles')
              }
              data={filterVehicles}
              onFilter={v => {
                setFilterVehicles(v);
              }}
            />
            <AntMultiselect
              title={
                filterVehicleTypes?.some(value => !value.checked)
                  ? t('Common.VehicleTypes')
                  : t('Common.AllVehicleTypes')
              }
              data={filterVehicleTypes}
              onFilter={v => {
                setFilterVehicleTypes(v);
              }}
            />
            <AntMultiselect
              title={
                filterTemplates?.some(value => !value.checked)
                  ? t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Template')
                  : t('CompanyConfig.DeviceConfigurations.GPIOTemplates.AllTemplate')
              }
              data={filterTemplates}
              onFilter={t => {
                setFilterTemplates(t);
              }}
            />
          </FilterWrapper>
          <label
            style={{
              display: 'flex',
              width: '100%',
              marginBottom: 0,
              paddingRight: '20px',
              alignItems: 'center',
              justifyContent: 'flex-end',
              minHeight: '52px'
            }}
          >
            {devices.length} {devices.length === 1 ? t('Devices.Device') : t('Devices.Devices')}
          </label>
        </div>
      )}
      <Table
        columns={deviceColumns(t, handleViewTemplate, templateType, deviceIds)}
        dataSource={devices}
        rowKey={row => row.id}
        rowSelection={rowSelection}
      ></Table>
    </Card>
  );
};
