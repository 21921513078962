/* global google */
import React, { useEffect, useState, useMemo, memo } from 'react';
import { Marker, OverlayView } from 'react-google-maps';
import { isEqual } from 'lodash';

import { useLocalization } from 'features/localization/localizationSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import { FeatureFlag, services, useCan, useCanOneOfServices } from 'features/permissions';
import { useUserPreferences, useUserPreferencesFetched } from 'features/user/userPreferencesSlice';
import { useAutoHeliUpdates, useDeviceUpdates } from 'features/mqtt/mqttSlice';
import { Mixpanel, MPTrackingEvents } from 'features/mixpanel';
import { useVehiclesStats } from 'features/vehicles/vehiclesStatsSlice';
import { useRunsheets } from 'features/smartJobs/smartJobsSlice';

import { VehicleInfoWindow } from '../info-windows/VehicleInfoWindow';
import { RunsheetStatus } from 'containers/SmartJobs/utils/constants';
import {
  DEFAULT_DIRECTION,
  IGNITION_STATUS,
  ENTITIES,
  HEADINGS,
  ASSET_STATUS,
  MARKER_TYPE,
  MARKER_COLOR,
  MARKER_SIZE
} from './constants';
import {
  VEHICLE_MARKER_LABEL_TYPES,
  VEHICLE_MARKER_LABEL_VISIBILITY_TYPES
} from 'containers/Settings/user/Preferences/Preferences';
import { AUTO_HELI_THRESHOLD } from 'containers/Tracking/constants';
import { constructDynamicArrowSvg, constructDynamicSvg } from './helper';

const getOffset = (x, y) => () => ({ x, y });

const getNearestDirection = dir => {
  return Math.abs(Math.round(dir / 45) * 45);
};

const _VehicleMarker = ({
  device,
  gpsUpdate,
  mapBounds,
  labelType,
  showLabel,
  showInfoWindow,
  showInfoWindowActions,
  onFocused,
  onBlurred,
  onClicked,
  onDoubleClicked,
  onRouteToClicked
}) => {
  const localization = useLocalization();
  const userPreferences = useUserPreferences();
  const userPreferencesFetched = useUserPreferencesFetched();
  const deviceUpdates = useDeviceUpdates(device.id);
  const autoHeliUpdates = useAutoHeliUpdates();
  const company = useCurrentCompany();
  const canUseMessaging = useCanOneOfServices([services.MESSAGING]);
  const canUseTacho = useCanOneOfServices([services.TACHO]);
  const canUseSmartJobs = useCanOneOfServices([services.SMARTJOBS]);
  const vehiclesStats = useVehiclesStats();
  const [isHovered, setIsHovered] = useState(false);
  const can = useCan();
  const runsheets = useRunsheets().filter(
    runsheet =>
      runsheet.status === RunsheetStatus.OPENED &&
      ((runsheet.vehicle && device.vehicleId && runsheet.vehicle?.id === device.vehicleId) ||
        (runsheet.device && device.id && runsheet.device?.id === device.id))
  );

  const supportVehicleTypeEnhancement = can({
    featureFlag: FeatureFlag.vehicleTypeEnhancement.flag
  });

  const [position, setPosition] = useState({
    lat: device.deviceStats.gps.Lat,
    lng: device.deviceStats.gps.Lng
  });
  const [vehicleMarker, setVehicleMarker] = useState({});

  const [deviceDirection, setDeviceDirection] = useState(
    getNearestDirection(device.deviceStats.gps.Dir)
  );

  useEffect(() => {
    let newPosition;
    let newDirection;
    switch (true) {
      case !!gpsUpdate:
        newPosition = {
          lat: gpsUpdate.lat,
          lng: gpsUpdate.lng
        };
        newDirection = gpsUpdate.dir;
        break;
      case !!deviceUpdates:
        newPosition = {
          lat: deviceUpdates.position.Lat,
          lng: deviceUpdates.position.Lng
        };
        newDirection = deviceUpdates.dir;
        break;
      default:
        newPosition = {
          lat: device.deviceStats.gps.Lat,
          lng: device.deviceStats.gps.Lng
        };
        newDirection = device.deviceStats.gps.Dir;
        break;
    }
    setPosition(newPosition);
    setDeviceDirection(getNearestDirection(newDirection));
  }, [
    gpsUpdate,
    deviceUpdates?.position,
    deviceUpdates?.dir,
    device.deviceStats?.gps,
    device.deviceStats?.gps?.Dir
  ]);

  const vehicleInAction = useMemo(() => {
    let vehicleInAction = false;
    if (autoHeliUpdates[device.id]) {
      const lastUpdateSecondsAgo =
        (new Date().getTime() -
          autoHeliUpdates[device.id][autoHeliUpdates[device.id].length - 1]?.at?.getTime()) /
        1000;
      if (lastUpdateSecondsAgo <= AUTO_HELI_THRESHOLD) {
        vehicleInAction = true;
      }
    }
    // console.debug('VehicleMarker - vehicleInAction', { 'deviceid': device.id, vehicleInAction });
    return vehicleInAction;
  }, [autoHeliUpdates]);

  const isLabelAlwaysVisible = useMemo(() => {
    return (
      userPreferences?.vehicleMarkerVisibility === VEHICLE_MARKER_LABEL_VISIBILITY_TYPES.always
    );
  }, [userPreferences?.vehicleMarkerVisibility]);

  const isLabelHovered = useMemo(() => {
    return (
      isHovered &&
      userPreferences?.vehicleMarkerVisibility === VEHICLE_MARKER_LABEL_VISIBILITY_TYPES.onHover
    );
  }, [userPreferences?.vehicleMarkerVisibility, isHovered]);

  useEffect(async () => {
    const ignition = deviceUpdates?.ignition || device.deviceStats.ignition;
    const lastEventAt = deviceUpdates?.lastEventAt || device.deviceStats.lastEventAt;
    const oorThreshhold = new Date();
    oorThreshhold.setHours(oorThreshhold.getHours() - 6);
    const isOutOfRange = new Date(lastEventAt) < oorThreshhold;
    const vehicleStatus =
      ignition === IGNITION_STATUS.ON
        ? isOutOfRange
          ? ASSET_STATUS.out
          : ASSET_STATUS.on
        : ASSET_STATUS.off;
    const direction = HEADINGS[deviceDirection] || DEFAULT_DIRECTION;
    const markerType = MARKER_TYPE[device.markerType] || MARKER_TYPE.asset;
    const markerColor =
      (MARKER_COLOR[device.markerColor] || supportVehicleTypeEnhancement) && device.markerColor
        ? device.markerColor
        : vehicleStatus;
    const markerSize = MARKER_SIZE[device.markerSize] || MARKER_SIZE.small;
    let assetType =
      markerType === 'asset'
        ? device.icon
          ? device.icon.replace('tn-i-', '').replace('minibus', 'bus')
          : !device.vehicle
          ? ENTITIES.device
          : ENTITIES.truck
        : '';

    const markerUrl = `${markerType}/${
      markerType === MARKER_TYPE.arrow ? markerSize + '/' : ''
    }${markerColor}/${direction}${assetType ? '/' + assetType : ''}.png`.toLowerCase();
    // console.debug('VehicleMarker - marker', { 'deviceid': device.id, markerUrl, vehicleInAction });

    let markerObj = {
      anchor: vehicleInAction ? { x: 26, y: 26 } : { x: 16, y: 16 },
      labelOrigin: vehicleInAction ? { x: 46, y: 11 } : { x: 16, y: 0 }
    };

    try {
      markerObj.url = require(`./images/${markerUrl}`);
    } catch (err) {
      //it can be error for new svg that added without prepare the set of icon with direction and color,
      //so we will fallback to check if svg can generate the icon
      try {
        //if it is arrow but error because of custom arrow, we will look for svg from dynamic svg
        if (!assetType && markerType === MARKER_TYPE.arrow) {
          assetType = markerType + '_' + markerSize;
        }
        const response = await fetch(require(`./images/dynamicSvg/${assetType}.svg`));
        if (response.ok) {
          const svgString = await response.text();

          if (markerType === MARKER_TYPE.arrow) {
            markerObj.url = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
              constructDynamicArrowSvg(direction, markerColor, svgString)
            )}`;
          } else {
            markerObj.url = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
              constructDynamicSvg(direction, markerColor, svgString)
            )}`;
          }
        }
      } catch (error) {}
    }
    setVehicleMarker(markerObj);
  }, [device, deviceDirection, deviceUpdates, vehicleInAction]);

  const zIndex = useMemo(() => {
    const zIndex = vehicleInAction
      ? 99999999
      : parseInt(
          (showInfoWindow
            ? new Date()
            : new Date(device?.deviceStats?.lastCommsAt || device?.updatedAt)
          ).getTime() /
            (60 * 60 * 24),
          10
        ); // number of days
    // console.debug('VehicleMarker - zIndex', { 'deviceid': device.id, zIndex });
    return zIndex;
  }, [device?.deviceStats?.lastCommsAt, device?.updatedAt, vehicleInAction, showInfoWindow]);

  const label = useMemo(() => {
    let label = (device.vehicleName ? device.vehicleName : device.name) + ' - ' + device.driverName;

    if (
      device &&
      (showLabel ||
        userPreferences?.vehicleMarkerVisibility === VEHICLE_MARKER_LABEL_VISIBILITY_TYPES.always ||
        (isHovered &&
          userPreferences?.vehicleMarkerVisibility ===
            VEHICLE_MARKER_LABEL_VISIBILITY_TYPES.onHover))
    ) {
      switch (labelType || userPreferences?.vehicleMarker) {
        case VEHICLE_MARKER_LABEL_TYPES.vehicleName:
          label = device?.vehicleName || device.name;
          break;
        case VEHICLE_MARKER_LABEL_TYPES.driverName:
          label = device.driverName;
          break;
        case VEHICLE_MARKER_LABEL_TYPES.registrationNumber:
          if (device.vehicleRegistrationState && device.vehicleRegistration) {
            label = `${device.vehicleRegistrationState} | ${device.vehicleRegistration}`;
          }
          break;
        default:
          label =
            (device.vehicleName ? device.vehicleName : device.name) + ' - ' + device.driverName;
          break;
      }
    }

    if (device.overwriteLabel) {
      label = device.overwriteLabel;
    }

    return label;
  }, [device, labelType, userPreferences?.vehicleMarker, isHovered]);

  return (
    (!mapBounds || mapBounds.contains(new google.maps.LatLng(position.lat, position.lng))) &&
    vehicleMarker?.url !== undefined && (
      <Marker
        position={position}
        icon={vehicleMarker}
        id={device.id}
        zIndex={zIndex}
        clickable={onClicked ? true : false}
        onClick={() => {
          onClicked && onClicked(showInfoWindow ? null : device.id);
        }}
        onDoubleClick={() => {
          onDoubleClicked && onDoubleClicked(device);
        }}
        onMouseOver={() => {
          onFocused && onFocused(device.id);
          setIsHovered(true);
        }}
        onMouseOut={() => {
          onBlurred && onBlurred(device.id);
          setIsHovered(false);
        }}
        options={{
          optimized: false,
          label:
            showLabel || isLabelAlwaysVisible || isLabelHovered
              ? {
                  text: label,
                  fontFamily: 'Roboto'
                }
              : null
        }}
      >
        {showInfoWindow && (
          <OverlayView
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={getOffset(42, 42)}
            position={position}
          >
            <VehicleInfoWindow
              canUseMessaging={canUseMessaging}
              canUseTacho={canUseTacho}
              canUseSmartJobs={canUseSmartJobs}
              company={company}
              deviceUpdates={deviceUpdates}
              device={device}
              runsheets={runsheets}
              vehicleStats={vehiclesStats.find(e => e.vehicleId === device.vehicleId)}
              localization={localization}
              showInfoWindowActions={showInfoWindowActions}
              userPreferences={userPreferences}
              userPreferencesFetched={userPreferencesFetched}
              onClose={() => {
                onClicked(null);
              }}
              onViewDetailsClicked={() => {
                Mixpanel.sendTrackEvent(MPTrackingEvents.Tracking.Map.ViewVehicleDetails, {
                  entityType: !!device?.vehicleId ? 'Vehicle' : 'Device'
                });
                onDoubleClicked(device);
              }}
              onRouteToClicked={onRouteToClicked}
            />
          </OverlayView>
        )}
      </Marker>
    )
  );
};

const _isEqual = (prevProps, nextProps) => {
  return (
    isEqual(prevProps.device, nextProps.device) &&
    isEqual(prevProps.gpsUpdate, nextProps.gpsUpdate) &&
    isEqual(prevProps.labelType, nextProps.labelType) &&
    isEqual(prevProps.showLabel, nextProps.showLabel) &&
    isEqual(prevProps.showInfoWindow, nextProps.showInfoWindow) &&
    isEqual(prevProps.showInfoWindowActions, nextProps.showInfoWindowActions)
  );
};

export const VehicleMarker = memo(_VehicleMarker, _isEqual);
