import React, { useState, useEffect } from 'react';
import { Input, Select, Space, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { useGetQsUserQuery } from 'services/nextgen';
import {
  SortOrderOptions,
  SortOrdersById,
  CategoryFilterOptions,
  CategoryFilters,
  CategoryFiltersById,
  CategoryFiltersByGuidKey
} from '../constants';

import { useCurrentCompany } from 'features/company/companySlice';
import { useCan, services } from 'features/permissions';

import styles from './toolbars.module.scss';

const { Search } = Input;

export const isDashboardCategory = (dashboard, categoryId) => {
  const id = dashboard.dashboardId || dashboard.analysisId;
  const dashboardGuidKey = id.substr(id.indexOf('-') + 1, 4);
  const hasIdCategory = CategoryFiltersByGuidKey[dashboardGuidKey]?.value === categoryId;
  const hasConstCategory = CategoryFiltersById[categoryId]?.dashboards?.includes(dashboard.name);

  // console.debug('isDashboardCategory', { dashboard: dashboard.name, dashboardGuidKey, hasIdCategory, hasConstCategory });

  return hasIdCategory || hasConstCategory;
};

export const getDashboardCategoryLabels = dashboard => {
  return CategoryFilterOptions.filter(
    category =>
      category.value > CategoryFilters.Unpublished && isDashboardCategory(dashboard, category.value)
  ).map(filter => filter.label);
};

export const getDashboardCategoryIds = dashboard => {
  return CategoryFilterOptions.filter(
    category =>
      category.value > CategoryFilters.Unpublished && isDashboardCategory(dashboard, category.value)
  ).map(filter => filter.value);
};

export const DashboardsPageToolbar = ({ count, onSearch, onSort, onFilter, ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const currentCompany = useCurrentCompany();
  const can = useCan();
  const qsUser = useGetQsUserQuery()?.data;
  const isQsUserAuthor = qsUser?.role?.toUpperCase() === 'AUTHOR';
  const canBuilder = can({
    everyService: [services.INSIGHTSBUILDER],
    everyCompanyService: [services.INSIGHTSBUILDER]
    // otherConditions: [() => isQsUserAuthor]
  });
  const canCategoryOptions = CategoryFilterOptions.filter(
    option =>
      !(
        (!canBuilder &&
          [CategoryFilters.Published, CategoryFilters.Unpublished].includes(option.value)) ||
        (canBuilder && !isQsUserAuthor && option.value === CategoryFilters.Unpublished)
      )
  );
  const canCategoryValues = canCategoryOptions.map(option => option.value);

  // const [isCategorySelectOpen, setIsCategorySelectOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [sortOrder, setSortOrder] = useState();
  const [categories, setCategories] = useState(canCategoryValues);

  const isDirectorRoute = history?.location?.pathname?.startsWith('/insights/director')
    ? true
    : false;

  useEffect(() => {
    setSearchText('');
    setSortOrder();
    setCategories(canCategoryValues);
  }, [currentCompany]);

  useEffect(() => {
    setCategories(canCategoryValues);
  }, [canBuilder, isQsUserAuthor]);

  const handleSearchChange = e => {
    const input = e?.target?.value;

    setSearchText(input);
    onSearch && onSearch(input);
  };

  const handleSortChange = e => {
    const sortId = Number(e?.key || e);

    setSortOrder(SortOrdersById[sortId]);
    onSort && onSort(sortId);
  };

  const handleCategoryChange = (category, checked) => {
    const tags = checked
      ? [...categories, ...(!categories.includes(category) && [category])]
      : categories.filter(categoryId => categoryId !== category);

    // console.debug('handleCategoryChange', {
    //   tags,
    //   category,
    //   checked
    // });

    handleCategoriesChange(tags);
  };

  const handleCategoriesChange = categoryIds => {
    let added = categoryIds?.filter(x => !categories?.includes(x));
    let removed = categories?.filter(x => !categoryIds?.includes(x));

    const newCategories =
      added.length && added[0] === 0 // select all
        ? canCategoryValues
        : removed.length && removed[0] === 0 // deselect all
        ? []
        : removed.length // removed item
        ? categoryIds.filter(id => id !== CategoryFilters.All)
        : added.length && categoryIds.length + 1 === canCategoryOptions.length // added last item
        ? [0, ...categoryIds]
        : categoryIds;

    // console.debug('handleCategoriesChange', {
    //   categoryIds,
    //   newCategories,
    //   added,
    //   removed
    // });

    setCategories(newCategories);
    onFilter && onFilter(newCategories);
  };

  return (
    <header className={styles.dashboardsToolbar}>
      <Search
        className={styles.searchbox}
        placeholder={t('Common.SearchPlaceholder')}
        value={searchText}
        onChange={handleSearchChange}
        allowClear
        {...props}
      />

      {/*
      <Select
        className={styles.categoriesSelect}
        options={canCategoryOptions}
        value={categories}
        onChange={handleCategoriesChange}
        placeholder={
          categories.length < canCategoryOptions.length ? t('Categories') : t('All Categories')
        }
        size={'large'}
        mode={'multiple'}
        maxTagCount={'responsive'}
        filterOption={true}
        optionFilterProp={'label'}
        open={isCategorySelectOpen}
        onFocus={isFocused => {
          setIsCategorySelectOpen(isFocused);
        }}
        onDropdownVisibleChange={isVisible => {
          !isVisible && setIsCategorySelectOpen(isVisible);
        }}
        tagRender={props => {
          const { label, value, closable, onClose } = props;

          return (
            value !== CategoryFilters.All && (
              <Tag closable={closable} onClose={onClose} style={{ marginRight: 5 }}>
                {label}
              </Tag>
            )
          );
        }}
      />
      */}

      <Select
        className={styles.sortSelect}
        options={SortOrderOptions}
        value={sortOrder}
        onChange={handleSortChange}
        onDropdownVisibleChange={isVisible => {
          !sortOrder && isVisible && setSortOrder(SortOrdersById[0]);
        }}
        placeholder={'Sort'}
      />

      <span className={styles.count}>
        {`${count} ${count === 1 ? t('Insights.Dashboard') : t('Insights.Dashboards')}`}
      </span>

      {!isDirectorRoute && (
        <>
          <br />
          <Space className={styles.categoriesList} size={10}>
            {canCategoryOptions.map(category => (
              <Tag.CheckableTag
                checked={categories?.includes(category.value)}
                onChange={checked => handleCategoryChange(category.value, checked)}
                key={category.value}
                disabled
              >
                {category.label}
              </Tag.CheckableTag>
            ))}
          </Space>
        </>
      )}
    </header>
  );
};
